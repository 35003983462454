import React, {Fragment, memo} from 'react';
import ContextMenu from 'devextreme-react/context-menu';

const contextMenuList = [];
class SectionBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contextOpen: null
        }
    }

    getStyle(style, snapshot) {
        if (!snapshot.isDropAnimating) {
            return style;
        }
        return {
            ...style,
            // cannot be 0, but make it super tiny
            transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
        };
    }

    handleOnClick = () => {
        if(this.props.closed){
            this.props.toggle();
        }
        this
            .props
            .onClick(this.props.sections.id)
        document
            .getElementById(this.props.sections.id + "Section")
            .scrollIntoView({behavior: 'smooth'});
    }

    handleDeleteItem = (e) => {
        if(e.itemData.id.includes("DeleteBtn")){
            this.props.deleteSection(this.props.sections.id);
            } else if (e.itemData.id.includes("EditBtn")) {
                this.props.editSection(this.props.sections.id);
        }
        this.setState({contextOpen: false});
        setTimeout(() => {
            this.props.deleteSection("");
            this.setState({contextOpen: null});
        }, [300],);
    }
    render() {
        const {sections, provided, snapshot, isActive, isGrabbed, closed} = this.props

        // //console.log("is selected", isSelected)
        return (
            <Fragment>
                <li
                    id={sections.id + 'Item'}
                    onClick={this.handleOnClick}
                    className={(isGrabbed || isActive)
                    ? 'section-box active'
                    : 'section-box'}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={this.getStyle(provided.draggableProps.style, snapshot)}>
                    {!closed && <p>{sections.display}</p>}
                </li>
                <ContextMenu
                    visible={this.state.contextOpen}
                    dataSource={[{
                        text: 'Eliminar ' + sections.display,
                        icon: 'trash', id: sections.id+"DeleteBtn"
                    },
                    // {
                    //     text: 'Editar ' + sections.display,
                    //     icon: 'rename', id: sections.id+"EditBtn"
                    // }
                ]}
                    width={200}
                    target={"#" + sections.id + 'Item'}
                    closeOnOutsideClick={true}
                    onItemClick={this.handleDeleteItem}/>
            </Fragment>
        );
    }

}

export default SectionBox