import notify from 'devextreme/ui/notify';
import { createOrUpdateUserPanel, createPortal, getAllPortals, getAllRetries, getAllUsers, getAllUsersPanel, loginUser, updatePortal, updateUserPanel } from "../../utils/helpersPoints";
import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";

//LoginAdmin

export const startLoginAdmin = (email, pass) => {
    return async( dispatch ) => {
        dispatch( startLoading() );
        try {
            let user = await loginUser(email, pass);
            await dispatch(startLoadUsers(email, pass));

            sessionStorage.setItem('rowKeyAdmin', user.rowKey);
            sessionStorage.setItem('email', user.email);
            sessionStorage.setItem('pass', user.password);
            dispatch(setAdmin(user.rowKey, user.email, user.password));
            
            notify({
                message: 'Ingreso correctamente',
                width: 10,
                
                position: {
                  my: 'center top',
                  at: 'center top',
                }
              }, 'success', 3000);
        } catch (error) {
            //console.log('error: ', error)
            notify({
                message: 'Datos incorrectos, comuniquese con un administrador',
                width: 10,
                
                position: {
                  my: 'center top',
                  at: 'center top',
                }
              }, 'error', 2000);
        }
        dispatch( finishLoading() );
    }
};

export const setAdmin = (keyRow, email, password) => ({
    type: types.isAdmin,
    payload: {
        keyRow,
        email,
        password
    }
});


export const startCreateUser = (email, pass, user) => {
    return async(dispatch) => {
        dispatch( startLoading() );
        try {
            let rrr = await createOrUpdateUserPanel(email, pass, user);
            //console.log("rrr", rrr);
            notify({
                message: 'Usuario registrado correctamente',
                position: {
                    my: 'center top',
                    at: 'center top'
                }
                }, 'success', 3000);
                // setTimeout(() => {
            dispatch( startLoadUsers(email, pass));
                    
                // }, 200);
            dispatch( finishLoading() );

        } catch (error) {
            ////console.log('error: ', error)
            notify({
                message: 'Error al intentar registrar usuario, comuniquese con un administrador',
                width: 10,
                
                position: {
                  my: 'center top',
                  at: 'center top',
                }
              }, 'error', 2000);
            dispatch(finishLoading() );
        }
    }
};

export const startUpdateUser = (email, pass, user) => {
    return async(dispatch) => {
        dispatch( startLoading() );
        try {
            let rrr = await updateUserPanel(email, pass, user);
            ////console.log("rrr", rrr);
            notify({
                message: 'Usuario actualizado correctamente',
                position: {
                    my: 'center top',
                    at: 'center top'
                }
                }, 'success', 3000);
                // setTimeout(() => {
            dispatch( startLoadUsers(email, pass));
                    
                // }, 200);
            dispatch( finishLoading() );

        } catch (error) {
            ////console.log('error: ', error)
            notify({
                message: 'Error al intentar actualizar usuario, comuniquese con un administrador',
                width: 10,
                
                position: {
                  my: 'center top',
                  at: 'center top',
                }
              }, 'error', 2000);
            dispatch(finishLoading() );
        }
    }
};


export const startLoadUsers = (email, pass) => {
    return async(dispatch) => {
        dispatch( startLoading() );
        try {
            const allUsers = await getAllUsersPanel(email, pass);
            //console.log('allUsers action', allUsers);
            dispatch(setUsers(allUsers));
        } catch (error) {
            //console.log('error: ', error);
        }
        dispatch(finishLoading() );
    }
};

const setUsers = (users) => {
    return({
        type: types.dashboardUsers,
        payload: {
            users: users
        }
    })
};

export const startLoadRetries = (email, pass) => {
    return async(dispatch) => {
        dispatch( startLoading() );
        try {
            const allRetries = await getAllRetries(email, pass);
            ////console.log('allRetries', allRetries);
            dispatch(setRetries(allRetries));
            dispatch(finishLoading() );

        } catch (error) {
            //console.log('Error: ', error);
            notify({
                message: 'Error al cargar registros, comuniquese con un administrador',
                width: 10,
                
                position: {
                  my: 'center top',
                  at: 'center top',
                }
              }, 'error', 2000);
            dispatch(finishLoading() );
        }
    }
};

const setRetries = (retries) => {
    return({
        type: types.dashboardRetries,
        payload: {
            registers: retries
        }
    })
};

export const setChangesJsonView = (json) => {
    return({
        type: types.changesJsonView,
        payload: {
            pendingChanges: json
        }
    })
};

//Portals

export const startCreatePortal = (email, pass, portal) => {
    return async(dispatch) => {
        dispatch( startLoading() );
        try {
            let rrr = await createPortal(email, pass, portal);
            //console.log("rrr", rrr);
            notify({
                message: 'Portal registrado correctamente',
                position: {
                    my: 'center top',
                    at: 'center top'
                }
                }, 'success', 3000);
            dispatch( startLoadPortals(email, pass));
        } catch (error) {
            ////console.log('error: ', error)
            notify({
                message: 'Error al intentar registrar portal, comuniquese con un administrador',
                width: 10,
                
                position: {
                  my: 'center top',
                  at: 'center top',
                }
              }, 'error', 2000);
        }
        dispatch(finishLoading() );
    }
};
export const startUpdatePortal = (email, pass, portal) => {
    return async(dispatch) => {
        dispatch( startLoading() );
        try {
            await updatePortal(email, pass, portal);
            
            notify({
                message: 'Portal actualizado correctamente',
                position: {
                    my: 'center top',
                    at: 'center top'
                }
                }, 'success', 3000);
                // setTimeout(() => {
            dispatch( startLoadPortals(email, pass));  
                // }, 200);

        } catch (error) {
            ////console.log('error: ', error)
            notify({
                message: 'Error al intentar actualizar portal, comuniquese con un administrador',
                width: 10,
                
                position: {
                  my: 'center top',
                  at: 'center top',
                }
              }, 'error', 2000);
        }
        dispatch(finishLoading() );
    }
};

export const startLoadPortals = (email, pass) => {
    return async(dispatch) => {
        dispatch( startLoading() );
        try {
            const allPortals = await getAllPortals(email, pass);
            ////console.log('allPortals:: ', allPortals);
            dispatch(setPortals(allPortals));
        } catch (error) {
            //console.log('error: ', error);
        }
        dispatch(finishLoading() );
    }
}

const setPortals = (portals) => {
    return({
        type: types.dashboardPortals,
        payload: {
            portals
        }
    })
};

