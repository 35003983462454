import React, { Component } from 'react';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';

export default class NewItemPopup extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        gridRef: React.createRef(),
        events: [], 
        typeSelect: "",
        optionDataSource: [],
      };
      this.logEvent = this.logEvent.bind(this);
      this.onEditingStart = this.logEvent.bind(this, 'EditingStart');
      this.onInitNewRow = this.logEvent.bind(this, 'InitNewRow');
      this.onRowInserting = this.logEvent.bind(this, 'RowInserting');
      this.onRowInserted = this.logEvent.bind(this, 'RowInserted');
      this.onRowUpdating = this.logEvent.bind(this, 'RowUpdating');
      this.onRowUpdated = this.logEvent.bind(this, 'RowUpdated');
      this.onRowRemoving = this.logEvent.bind(this, 'RowRemoving');
      this.onRowRemoved = this.logEvent.bind(this, 'RowRemoved');
      this.onSaving = this.logEvent.bind(this, 'Saving');
      this.onSaved = this.logEvent.bind(this, 'Saved');
      this.onEditCanceling = this.logEvent.bind(this, 'EditCanceling');
      this.onEditCanceled = this.logEvent.bind(this, 'EditCanceled');
      this.clearEvents = this.clearEvents.bind(this);
      this.newRow = this.newRow.bind(this);
    }

    clearEvents() {
      this.setState({ events: [] });
    }

    logEvent(eventName) {
      this.setState((state) => {
        return { events: [eventName].concat(state.events) };
      });
      if (["Saving", "Saved"].includes(eventName)){
        this.idGenerator()
      }
    }

    idGenerator () {
      const { dataSource, parentDataSource } = this.props;
      const last = parentDataSource[dataSource].options.length - 1;
      let newId;
      if(parentDataSource[dataSource].options[last]) {
        newId = "opt" + parentDataSource[dataSource].options[last].display.split(" ")[0] + last
        this.props.parentDataSource[dataSource].options[last]["id"] = newId;
      }
    }

    shouldComponentUpdate(nextProps, nexState) {
      if(nexState !== this.state){
        return true
      } else if(nextProps !== this.props) {
        return true
      } 
      else return false
    }

    componentDidMount() {
      this.props.getFormRef(this.state.gridRef);
    }

    newRow = (e) => {
      e.data.canWrite = false;
    }
    
    render() {
      const { visible, dataSource, parentDataSource } = this.props

      return(
        <DataGrid
          visible={visible}
          ref={this.state.gridRef}
          dataSource={parentDataSource[dataSource]?.options}
          id="gridContainer"
          keyExpr="id"
          allowColumnReordering={true}
          showBorders={true}
          focusedRowEnabled={true}
          onEditingStart={this.onEditingStart}
          onInitNewRow={this.newRow}
          onRowInserting={this.onRowInserting}
          onRowInserted={this.onRowInserted}
          onRowUpdating={this.onRowUpdating}
          onRowUpdated={this.onRowUpdated}
          onRowRemoving={this.onRowRemoving}
          onRowRemoved={this.onRowRemoved}
          onSaving={this.onSaving}
          onSaved={this.onSaved}
          onEditCanceling={this.onEditCanceling}
          onEditCanceled={this.onEditCanceled}
          height={330}
        >
        <Editing
          mode="row"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          selectTextOnEditStart={true}
          useIcons={true}
          /> 
        {/* <Column dataField="id" caption="Id" width={150}/> */}
        <Column dataField="display" caption="Descripción" />
        <Column dataField="description" caption="Tooltip" />

        <Column dataField="canWrite" caption="Comentable" width={100}>
          <Lookup 
            dataSource={[{id: true, display: 'Sí'}, {id: false, display: 'No'}]}
            displayExpr="display" 
            valueExpr="id"
            />
        </Column>
    </DataGrid>
    )
  } 
}