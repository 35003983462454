import React, { Fragment, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setData } from '../../../../../redux/actions/parametrizer';
import IndigoSearchInput from './indigoSearchInput';

import Button from 'devextreme-react/button';
import ContextMenu from 'devextreme-react/context-menu';
import { Popup } from 'devextreme-react/popup';
import { Tooltip } from 'devextreme-react/tooltip';

import IndigoCommentInput from '../indigoCommentInput';
import IndigoItemsList from '../form/indigoItemsList';
import { IndigoSearchSmallTag } from '..';
import Observation from './../../utils/fhir/observation.js';
import { sectionUpdated } from '../../../../../redux/actions/fhir';
import moment from 'moment';
import { setAutoSaveSearchInput } from '../../../../../redux/actions/autoSave';

function IndigoSearch (props) {
    var classNames = require('classnames');
    const dispatch = useDispatch();
    const { data } = useSelector( state => state.parametrizer)
    const { responsive } = useSelector( state => state.ui)
    const { fhir } = useSelector(state => state)
    const { id: encounterId } = useParams();
    const { patient, practitioner, startDate } = useSelector(state => state.agenda.appointment);

    const { info, getFocused, focused, parentSize, expanded, granPaID, editable, render } = props;
   
    const [contextOpen, setContextOpen] = useState(null);
    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);    
    const [localItems, setLocalItems] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    
    const [maxSizeDefault, setMaxSizeDefault] = useState({"extralarge": info.size, "large": (parseInt(info.size,10)-1), "medium": (parseInt(info.size,10)-2), "small": 2, "extrasmall": 1});
    
    const [isWritable, setIsWritable] = useState(false);
    
    
    const autoSave = useSelector( state => state.autoSave);
    const savedData = localStorage.getItem(encounterId);
    
    let defaultItems = [];
    let defaultComments = {};
    if(savedData && savedData !== "undefined" && savedData !== "null") {
        if(JSON.parse(savedData)[info.id]?.items) {
            defaultItems = JSON.parse(savedData)[info.id]?.items;
        }
        if(JSON.parse(savedData)[info.id]?.comments) {
            defaultComments = JSON.parse(savedData)[info.id]?.comments;
        }
    }
    
    const [selectedItems, setSelectedItems] = useState(defaultItems);
    const [comment, setComment] = useState(defaultComments);

    const [clearSearch, setClearSearch] = useState(false);
    const [canDrop, setCanDrop] = useState(false);
    const [result, setResult] = useState([]);

    const [newObservation, setNewObservation] = useState(new Observation());

    useEffect(() => {
        localStorage.setItem(encounterId, JSON.stringify(autoSave[encounterId]))
    }, [JSON.stringify(autoSave[encounterId])])


    useEffect(() => {
        newObservation.patientID = patient ? patient : null;
        newObservation.encounterID = encounterId ? encounterId : null;
        newObservation.date = moment(new Date(startDate)).format('YYYY-MM-DD');
        newObservation.practitionerID = practitioner ? practitioner : null;

        newObservation.inputID = {type: "search", id: info.id, context: 100};
        newObservation.canWrite = true;
        newObservation.label = info.display;
        newObservation.questionNumber = info.questionNumber;
        newObservation.type = 2;
        newObservation.multipleCommentary = true;
        newObservation.value = null;
        sizeConvert();
        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        if(!mySection) return;
        let mySection = fhir[index];
        if(!mySection?.entry.some( ent => {return ent?.identifier[0]?.value?.includes(info.id)})) {
            mySection?.entry?.push(newObservation.newObvervation);
        }
        dispatch(sectionUpdated(mySection));
    },[render])

    const sizeConvert = () => {
        switch(info.size){
            case 4:
                setMaxSizeDefault({"extralarge": info.size, "large": 3,  "medium": 1, "small": 2, "extrasmall": 1});
                break;
            case 2:
                setMaxSizeDefault({"extralarge": info.size, "large": 2,  "medium": 1, "small": 2, "extrasmall": 1});
                break;
            case 3:
                setMaxSizeDefault({"extralarge": info.size, "large": 3, "medium": 1, "small": 2, "extrasmall": 1});
                break;
            default:
                setMaxSizeDefault({"extralarge": 5, "large": 3,  "medium": 1, "small": 2, "extrasmall": 1});
                break;
        }
    }

    useEffect(() => {
        sizeConvert();
        if(info.canWrite) {
            setLocalItems([]);
        } else {
            setLocalItems(info.options);
        }
    },[JSON.stringify(data), info.canWrite])

    useEffect(() => {
        if(!info.canWrite) return;
        let selectedItemsCopy = selectedItems.sort((a,b) => (a.display > b.display) ? 1 : ((b.display > a.display) ? -1 : 0))
        info.options.forEach(opt => {
            if(selectedItemsCopy.some(item => item.id === opt.id)) return;
            selectedItemsCopy.push(opt)});
        setSelectedItems(selectedItemsCopy); 
    },[JSON.stringify(info.options), info.canWrite])

    const openPopup = () => {
        setCancelPopUpVisible(true)
    }

    const closePopup = () => {
        setCancelPopUpVisible(false)
    }

    const toggleLeaveTooltip = () => {
        setShowTooltip(false);
    }

    const handleDeleteItem = () => {
        openPopup()
        setContextOpen(false);
        setTimeout(
            () => {
                // this.props.deleteSection("");
                setContextOpen(null);
            },
            [300],
        );
    }

    const handleChangeSize = size => {
        const newData = data;  

        newData.some( data => {
            data.options.some( quest => {
                quest.options.some( option => {
                    if(option.id === info.id){
                        option.size = size
                    }
                })
            })
        })
  
        dispatch( setData(newData))
    }

    const updateList = (list) => {
        const newData = data;        
        newData.some( data => {
            data.options.some( quest => {
                quest.options.some ( opt => {
                        if(opt.id === info.id){
                            opt.options = list
                        }
                })
            })
        })
        dispatch( setData(newData))
    }

    const itemClicked = (e) => {
        const item = e;
        let items = localItems;
        let selecItems = selectedItems;

        selecItems.push(items[items.map(function(e) {
            return e.display;
        }).indexOf(item)])

        selecItems.sort((a,b) => (a?.canWrite === b?.canWrite) ? 0 : a?.canWrite ? -1 : 1);
        selecItems.sort((a,b) => (a?.display === b?.display) ? 0 : a?.display ? -1 : 1);
        // getFocused(item)
        let writeList = []
        selecItems.map( selItem => {
            writeList.push(selItem?.canWrite)
            if(writeList.includes(true)){
                setIsWritable(true)
            } else {
                setIsWritable(false)
            }
        })

        items.splice(localItems.map(function(e) { 
            return e.display;
        }).indexOf(item),1);

        clearSearcher();
                
        setSelectedItems(selecItems);
        setLocalItems(items);
        printTags()
    }

    const clearSearcher = () => {
        setClearSearch(true)
        setTimeout(() => {
            setClearSearch(false)
        }, 200);
    }

    const deletetag = (e) => {
        const item = e;
        const items = localItems;
        let selecItems = selectedItems;

        selecItems.splice(selecItems.map(function(e) { 
            return e.id;
        }).indexOf(item.id),1);

        selecItems.sort((a,b) => (a?.canWrite === b?.canWrite) ? 0 : a?.canWrite ? -1 : 1);
        selecItems.sort((a,b) => (a?.display === b?.display) ? 0 : a?.display ? -1 : 1);

        let writeList = []
        selecItems.map( selItem => {
            writeList.push(selItem?.canWrite)
            if(writeList.includes(true)){
                setIsWritable(true)
            } else {
                setIsWritable(false)
            }
        })

        if(item !== null){
            items.push(item)
        };

        setSelectedItems(selecItems)
        setLocalItems(items);
        printTags()
    }

    const getCommentary = (com, id, index) => {
        setComment({...comment, [index]: {com: com, id}})
    }

    const handleCloseTagOnMiddleClick = (e, item) => {
        e.preventDefault();
        if(e.buttons !== 4) return;
        deletetag(item)
    }

    const printTags = () => {
        if (selectedItems.length === 0) return
        return selectedItems.map((item, key) => {
            return(
                <Fragment key={key} >
                    <div id={item.id+"TagContainer"}  className={ classNames('search-tag-comment', {'has-comment': item?.canWrite}, {'preloaded': info.canWrite})}>
                        <div id={item.id+"Tag"} 
                            onClick={() => onTagClick(item.id)}
                            onMouseDown={(e) => handleCloseTagOnMiddleClick(e,item)}
                            className={classNames('search-tag', {'active': focused === item.id})}
                            // onMouseEnter={() => toggleEnterTooltip(item.id, true)}
                            onMouseLeave={toggleLeaveTooltip}
                            >
                            <span>{item.display}</span> 
                            <Button
                                onClick={() => deletetag(item)} 
                                icon={'clear'} 
                                stylingMode="text"/>
                        </div>
                        
                        {item?.canWrite &&
                            <div className={'search-comment-container'}> 
                                <IndigoCommentInput maxChar={item.maxChar} id={item.id} isFocused={focused === info.id} getFocused={getFocused} valueFromParent={comment[key] ? comment[key].com : null} valueToParent={(e) => getCommentary(e,item.id, key)}/>
                            </div>
                        }
                    </div>
                    
                    {(focused === item?.id && item?.canWrite) && <Tooltip
                        target={"#"+item.id+"TagContainer"}
                        visible={showTooltip}
                        closeOnOutsideClick={false}
                        width={200}
                    >
                        <div style={{whiteSpace: 'normal'}}>
                        {item.display}
                        </div>
                    </Tooltip>}
                </Fragment>
            )
        })
    }

    const printTagsSmall = () => {
        if (selectedItems.length > 0){
            return (selectedItems.map((item, key) => {
                    let label = item.display;
                    if(label.length > 40) {
                        label = item.display.slice(0,30) + '...';
                    }
                    return(
                        <Fragment key={key}>
                            <IndigoSearchSmallTag 
                                events={{"click": onTagClick}} 
                                focused={focused} 
                                getFocused={getFocused}
                                deletetag={deletetag}
                                info={info} 
                                label={label} 
                                item={item}
                                canDrop={canDrop}
                                result={key === 0 ? result : []}
                                getCommentary={(e,f) => getCommentary(e,f,key)}
                                />
                        </Fragment>
                    )
                
                })
            )
        }
    }

    const onTagClick = (tag) => {
        const element = document.getElementById(tag+"SearchComment")
        if(element) {
            element.focus();
        }
        getFocused(tag);
    }
 
    const responsiveSmall = () => {
        return["extrasmall", "small", "medium"].includes(responsive);
    }

    const getCanDrop = (can) => {
        setCanDrop(can)
    }

    const getResult = (res) => {
        setResult(res)
    }

    useEffect(() => {
        printTags()
        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[index];
        const entryIndex = mySection?.entry?.map( ent => {
            return( ent?.identifier[0]?.value.substring(5,(ent?.identifier[0]?.value.length)))
        }).indexOf(info.id)
        let myEntry = mySection?.entry[entryIndex];

        const searchComment =  selectedItems?.map((value, i) => {
            let commentary = null;
            if (!comment[i] || comment[i]?.com.split("").length === 0) {
                commentary = null;
            } else {
                commentary = comment[i]?.com;
            }
            return(
                {"code": 
                    {"coding": 
                    [
                        {
                            "system": "http://indigo.tech",
                            "code": value.questionNumber,
                            "display":  value.display
                    }
                    ],
                    "text": value.display
                },
                "valueString": commentary
            }
            )
        })
        
        if(myEntry) {
            myEntry["component"] = searchComment;
        }

        if(!mySection) return;
        mySection?.entry?.splice(entryIndex,1);
        mySection?.entry?.splice(entryIndex,0,myEntry)
        dispatch(sectionUpdated(mySection))

        const autoSaveData = {
            id: info.id,
            items: selectedItems,
            comments: comment,
            encounterId: encounterId,
        } 
        
        dispatch(setAutoSaveSearchInput(autoSaveData));
    }, [JSON.stringify(selectedItems), localItems.length, JSON.stringify(comment)])

    return (
        <Fragment>
            {/* <div id={info.id+"FormContainer"} className={classNames('form-container', (isWritable && (responsiveSmall() === false)) ? 'section-w-size-'+(info.size+1) : 'section-w-size-'+info.size)}> */}
                <div id={info.id+"InputContainer"} className={classNames('text-input-container', {'focused' : focused === info.id},  'input-size-'+maxSizeDefault[responsive]+'-'+parentSize)}>
                    <div className={'text-input-title'}>
                        <div>{info.display}</div>
                    </div>
                    <div className={'search-content'}>
                            <IndigoSearchInput
                                id={info.id} 
                                items={localItems}
                                onItemClick={itemClicked}
                                getFocused={getFocused}
                                isWritable={isWritable}
                                clearSearch={clearSearch}
                                getCanDrop={getCanDrop}
                                getResult={getResult}
                            />
                            {(selectedItems.length > 0 && !responsiveSmall()) &&
                                <div className={classNames('search-tags-container', {'large': isWritable})}> {
                                    printTags()
                                }
                                </div>
                            }

                            {(selectedItems.length > 0 && responsiveSmall()) &&
                                <div className={'search-tags-container-small'}> {
                                    printTagsSmall()
                                }
                                </div>
                            }
                    </div>
                    {editable && <ContextMenu
                        visible={contextOpen}
                        dataSource={[{ text: 'Editar ' + info.display, icon: 'rename' }]}
                        width={200}
                        target={"#"+info.id+"InputContainer"}
                        closeOnOutsideClick={true}
                        onItemClick={handleDeleteItem} />}

                    {(cancelPopupVisible && editable) && <Popup
                        visible={cancelPopupVisible}
                        onHiding={closePopup}
                        dragEnabled={true}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        title={"Editar "+info.display}
                        width={350}
                        height={650}
                        >
                        <div className={'cancel-popup'}>

                            <div className={'popup-section'}>
                                <div className={'popup-section-title'}>Ordenar</div>
                                <div className={'popup-section-subtitle'}>Arrastra para ordenar</div>
                                <div className={'popup-section-content form-list'}>
                                    <IndigoItemsList items={info.options} updateList={updateList}/>
                                </div>
                            </div>
                            <div className={'popup-section'}>
                                <div className={'popup-section-title'}>Tamaño</div>
                                <div className={'popup-section-content size-btns'}>
                                    {[5,4,3,2,1].map( (size, key ) => {
                                        return (
                                            <Button
                                                key={key}
                                                text={size}
                                                type={size === info.size ? "success" : "normal"}
                                                stylingMode={size === info.size ? "contained" : "outlined"}
                                                onClick={() => handleChangeSize(size)}
                                            /> 
                                        );
                                    })
                                    }
                                </div>
                            </div>
                            <div className={'cancel-buttons'}>
                                <Button
                                width={130}
                                icon={'check'}
                                text="Cerrar"
                                type="success"
                                stylingMode="contained"
                                onClick={closePopup}                        
                                />
                            </div>

                        </div>
                    </Popup>}
                </div>

            {/* </div> */}
        </Fragment>
    )
}

export default memo(IndigoSearch)