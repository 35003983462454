import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './historial.scss';
import { startGetPatientByIndetifier, startGetPatientByProntuar } from '../../redux/actions/clinicHistory';

import notify from 'devextreme/ui/notify';
import Footer from '../../components/ui/footer/footer';
import { setInfoPatientProntuar } from './../../redux/actions/clinicHistory';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import { DataGrid, Scrolling, Column, Format, Button as GridButton } from 'devextreme-react/data-grid';

export default function HistorialPage(props) {
    const dispatch = useDispatch();
    const classNames = require('classnames');
    const [searchValue, setSearchValue] = useState('');
    const [visible, setVisible] = useState(false)
    const [dataList, setDataList] = useState([]);
    const [historialType, setHistorialType] = useState({
        display: "Prontuario",
        id: "medicalRecord"
    });
    const [showPopup, setShowPopup] = useState(false);
    const history = useHistory();

    const handleOnChange = (data) => {
        setSearchValue(data.target.value.replace(/\D/, ''))
    }

    const searchByProntuary = async (value) => {
        const patient = await dispatch(startGetPatientByProntuar(value || searchValue));
        if (patient?.patient?.id) {
            goToEncounter(value || searchValue);
        } else {
            notify({
                message: 'No se encontraron registros',
                width: 10,
                position: {
                    my: 'center top',
                    at: 'center top',
                }
            }, 'error', 2000);
        }
    }

    const searchById = async () => {
        let medicalRecord = await searchMedicalRecord(searchValue);
        //console.log('medicalRecord: ', medicalRecord)

        if (medicalRecord) {
            if (medicalRecord.compositions) {
                medicalRecord.patient = medicalRecord.patient[0];
                dispatch(setInfoPatientProntuar(medicalRecord));
                goToEncounter(medicalRecord.id);
            } else {
                notify({
                    message: 'Se encontró más de un registro que corresponde a este número de identificación.',
                    width: 10,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    }
                }, 'info', 2000);
                setShowPopup(true);
                let newdata = medicalRecord.patient.map(patient => {
                    return {
                        id: patient.resource.identifier[1].value,
                        name: patient.resource.name[0].text,
                        prontuary: patient.resource.identifier[0].value,
                        birthDate: patient.resource.birthDate,
                    }
                });
                setDataList(newdata);
            }
        } else {
            notify({
                message: 'No se encontraron registros',
                width: 10,
                position: {
                    my: 'center top',
                    at: 'center top',
                }
            }, 'error', 2000);
        }
    }

    const onEnterPress = async (e) => {
        if (((e.element && e.element.id === "seachMedicalRecord") || e.which === 13) && searchValue.length !== 0) {
            setVisible(true);
            if (historialType.id === "medicalRecord") {
                await searchByProntuary();
            } else if (historialType.id === "id") {
                await searchById();
            }
            setVisible(false);
        }
    }

    const goToEncounter = (value) => {
        history.push('/historial-clinico/' + value);
    }

    const historialTypeSelect = (e) => {
        const type = {
            "prontuario": {
                display: "Prontuario",
                id: "medicalRecord"
            },
            "id": {
                display: "Documento de Identidad",
                id: "id"
            }
        }
        setHistorialType(type[e]);
    }

    const searchMedicalRecord = async (patientId) => {
        const res = await dispatch(startGetPatientByIndetifier(patientId))
        if (res) {
            return res;
        }
    }

    useEffect(() => {
        const element = document.getElementById("AgendaEpecInput");
        element?.focus();
    }, [historialType.id])

    const handleItemClicked = (rowData) => {
        let data = rowData.data || rowData.row.data;
        if (!data) return;
        searchByProntuary(data.prontuary)
    }

    const PopupContent = () => {
        return <DataGrid
            dataSource={dataList}
            keyExpr="id"
            showBorders={true}
            onRowDblClick={handleItemClicked}
        >
            <Scrolling mode="standard" />
            <Column dataField="name" caption="Nombre" width="30%" />
            <Column dataField="id" caption="Identificación" width="20%" />
            <Column dataField="prontuary" caption="Prontuario" width="20%" />
            <Column dataField="birthDate" caption="Edad" width="20%" >
                <Format
                    type="dd/MM/yyyy"
                />
            </Column>
            <Column type="buttons" width="10%">
                <GridButton hint="Abrir"
                    icon="activefolder"
                    onClick={handleItemClicked}
                />
            </Column>
        </DataGrid>
    }

    return (
        <div className={' main-content-container scheduler-container'}>
            <h1 className={'content-block page-title'}>Historial Clínico</h1>
            <div
                className={classNames('text-input-container flex-center column')}
            >
                <div className={'historial-btn-container'}>
                    <div className={classNames('two-btns', historialType.id === 'medicalRecord' ? 'active' : 'inactive')}
                        onClick={() => historialTypeSelect('prontuario')}
                    >
                        Prontuario
                    </div>
                    <div className={classNames('two-btns', historialType.id === 'id' ? 'active' : 'inactive')}
                        onClick={() => historialTypeSelect('id')}
                    >
                        Documento
                    </div>
                </div>

                <div style={{ display: 'inline-block' }} className={classNames('text-input-title')}>
                    <label className={classNames('mgmt-group-label')}>Número de {historialType.id === "id" ? "documento de identidad" : "prontuario"} </label>
                </div>
                <div
                    className={'relative'}
                >
                    <input
                        id={"AgendaEpecInput"}
                        style={{ width: '400px' }}
                        className={'text-input enter-jump mgmt-input mgmt-input-item'}
                        onKeyDown={onEnterPress}
                        type={"text"}
                        required
                        name={"section"}
                        onChange={handleOnChange}
                        value={searchValue}
                        autoComplete={"off"}
                    />
                </div>
            </div>
            <div className={'btn-container '}>
                <Button
                    width={130}
                    id="seachMedicalRecord"
                    onClick={onEnterPress}
                    type="default"
                    stylingMode={searchValue.split("").length === 0 ? "outlined" : "contained"}
                    disabled={searchValue.length === 0}
                >
                    <span className="dx-button-text">{visible ? "Cargando.." : "Iniciar"}</span>
                </Button>
            </div>
            <Popup
                visible={showPopup}
                onHiding={() => setShowPopup(false)}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                titleRender={() => <h3 style={{textAlign: "center", padding: '10px'}}>Coincidencias</h3>}
                width={700}
                height={500}
                contentRender={PopupContent}
            />
            <Footer position={'fixed'} align={'end'} />
        </div>
    );
};