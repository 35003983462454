import React, { useEffect, useState } from 'react';
import Header from '../../components/renderer/header.jsx';
import Ficha from '../../components/ficha/ficha.jsx';
import { reset } from '../../redux/actions/fhir.js';
import { useDispatch, useSelector } from 'react-redux';
import { resetFicha } from '../../redux/actions/hcData.js';
const classNames = require('classnames');

export default (props) => {
    const { /* activeSection, activeAppointment, */ 
            header, readOnly, readOnlyData, getFilterValue, readOnlyMgmtData } = props;
    
    const { activeSection } = useSelector(state => state.parametrizer)
    const {  fhir } = useSelector(state => state)
    const { mcData } = useSelector(state => state.hcData);
    const { appointment: activeAppointment, especiality } = useSelector(state => state.agenda)
    const { responsive } = useSelector(state => state.ui)
    const  dispatch = useDispatch();

    const [state, setState] = useState({
        height: 0,
        headToggled: false,
        deleteSection: '',
        nameSpec: '',
    })

    const genreDic = {
        "female": "Mujer",
        "male": "Hombre"
    }

    useEffect(() => {
        getHeights('mainContainer');
        setState({...state, nameSpec: sessionStorage.getItem('name-spec')});
        return () => {
            mapDispatchToProps()
        }
    }, []);

    useEffect(() => {
        if(responsive !== responsive) {
            getHeights('mainContainer')
        }
    }, [responsive])




    const mapDispatchToProps = () => {
        dispatch(reset());
    } ;

    const getHeights = (element) => {
        const elmt = document.getElementById(element);
        if (elmt) {
            setState({...state, height: elmt.offsetHeight})
            return elmt.offsetHeight
        }
    }

    const handleHeadToggled = () => {
        setState({...state, headToggled: !state.headToggled})
    }

    const deleteItem = (del) => {
        setState({...state, deleteSection : del})
    } 
    
    useEffect(() => {
        sessionStorage.setItem('PruebaFhir', fhir);
    }, [fhir]);

    // useEffect(() => {
    //     dispatch(resetFicha());
    //     dispatch(reset());
        
    //     return function cleanup() {
    //         dispatch(resetFicha());
    //         dispatch(reset());
    //     }
    // },[])

    return (
        <div id={'mainContainer'} className={classNames('main-container', {'small' : state.headToggled})}>
            {readOnly ? 
                <Header  patient={header.patient} especiality={header.especiality} headToggled={handleHeadToggled} getFilterValue={getFilterValue} readOnly={readOnly} infoExists={readOnlyData?.options?.length > 0}/>
                :
                <Header  patient={{display: activeAppointment?.text, age: activeAppointment?.age?.split(",")[0], sex: genreDic[activeAppointment?.sex], id: activeAppointment.prontuar}} especiality={especiality.display} headToggled={handleHeadToggled} infoExists={mcData.length > 0}/>
            }
            <Ficha
                parentHeight={state.height}
                deleteSection={deleteItem}
                dataQuestions={readOnly ? readOnlyData : (mcData && mcData)}
                readOnlyMgmtData={readOnlyMgmtData}
                activeSection={activeSection}
                editable={false}
                encounterInfo={activeAppointment}
                readOnly={readOnly}
                showAgnesButton={props.showAgnesButton}
            />
        </div>
    )
}

////////////////////////////////TESTTTT/////

// const mapStateToProps = state => ({
//     activeSection: state.parametrizer.activeSection,
//     hcData: state.hcData,
//     mcData: state.hcData.mcData,
//     specialities: state.auth.specialities,
//     activeAppointment: state.patient.activeAppointment,
//     responsive: state.ui.responsive
// })


// const mapDispatchToProps = (dispatch) => {
//     return {
//         reset: () => dispatch(reset)
//     }
// } ;
// export default connect(mapStateToProps, mapDispatchToProps)(class extends React.PureComponent {
//     constructor(props) {
//         super(props);
//         this.state = {
//             height: 0,
//             headToggled: false,
//             deleteSection: '',
//             nameSpec: '',
//         }
//     }

//     getHeights = (element) => {
//         const elmt = document.getElementById(element);
//         if (elmt) {
//             this.setState({height: elmt.offsetHeight})
//             return elmt.offsetHeight
//         }
//     }

//     handleHeadToggled = () => {
//         this.setState({headToggled: !this.state.headToggled})
//     }

//     componentDidMount(){
//         this.getHeights('mainContainer');
//         this.setState({nameSpec: sessionStorage.getItem('name-spec')});
//     }

//     componentWillUnmount(){
//         this.props.reset();
//     }

//     componentDidUpdate(prevProps, prevState){
//         if(prevProps.responsive !== this.props.responsive) {
//             this.getHeights('mainContainer')
//         }
//     }

//     deleteItem = (del) => {
//         this.setState({deleteSection : del})
//     } 


//     render () {
//     const { activeSection, activeAppointment, match, mcData, hcData, specialities, header, readOnly, readOnlyData, getFilterValue} = this.props;
//     const spec = sessionStorage.getItem('spec');
//     var classNames = require('classnames');

//     const genreDic = {
//         "female": "Mujer",
//         "male": "Hombre"
//     }

//     //console.log("activeAppointment", activeAppointment);

//     return(
//         <div id={'mainContainer'} className={classNames('main-container', {'small' : this.state.headToggled})}>
//             {readOnly ? 
//                 <Header  patient={header.patient} especiality={header.especiality} headToggled={this.handleHeadToggled} hideAgnesButton={header.hideAgnesButton} getFilterValue={getFilterValue}/>
//                 :
//                 <Header  patient={{display: activeAppointment?.text, age: activeAppointment?.age?.split(",")[0], sex: genreDic[activeAppointment?.sex], id: activeAppointment.prontuar}} especiality={match.params && specialities?.find( espec => espec?.code === spec)?.display} headToggled={this.handleHeadToggled}/>
//             }
//             <Ficha
//                 parentHeight={this.state.height}
//                 deleteSection={this.deleteItem}
//                 dataQuestions={readOnly ? readOnlyData : (mcData && mcData.find( data => data.id === activeSection.id))} 
//                 activeSection={activeSection}
//                 editable={false}
//                 template={match?.params?.spec}
//                 encounterInfo={activeAppointment}
//                 readOnly={readOnly}
//                 showAgnesButton={this.props.showAgnesButton}
//             />
//         </div>
//     );
//     }
// });

