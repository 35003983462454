import React, {Fragment, memo} from 'react';
import ContextMenu from 'devextreme-react/context-menu';

const contextMenuList = [];
class SectionBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contextOpen: null
        }
    }

    getStyle(style, snapshot) {
        if (!snapshot.isDropAnimating) {
            return style;
        }
        return {
            ...style,
            // cannot be 0, but make it super tiny
            transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
        };
    }

    handleOnClick = () => {
        this
            .props
            .onClick(this.props.sections.id, this.props.index)
    }

    handleDeleteItem = (e) => {
        if(e.itemData.id.includes("DeleteBtn")){
            this.props.deleteSection(this.props.sections.id);
            } else if (e.itemData.id.includes("EditBtn")) {
                this.props.editSection(this.props.sections.id);
        }
        this.setState({contextOpen: false});
        setTimeout(() => {
            this.props.deleteSection("");
            this.setState({contextOpen: null});
        }, [300],);
        //console.log("Eeee:", e)
    }
    render() {
        const {sections, provided, snapshot, isActive, isGrabbed} = this.props

        // //console.log("is selected", isSelected)
        return (
            <Fragment>
                <li
                    id={sections.id + 'Item'}
                    onClick={this.handleOnClick}
                    className={(isGrabbed || isActive)
                    ? 'management-box active'
                    : 'management-box'}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={this.getStyle(provided.draggableProps.style, snapshot)}>
                    <p>{sections.display}</p>
                </li>
            </Fragment>
        );
    }

}

export default SectionBox