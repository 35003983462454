import React, { Fragment, useEffect, useState } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import { useSelector } from 'react-redux';
import moment from 'moment';

export default function Freq(props) {

    const [canDrop, setCanDrop] = useState(false);
    const [contentShown, setContentShown] = useState(false);
    const [warning, setWarning] = useState(false);
    const [warningShown, setWarningShown] = useState(false);
    const [dose, setDose] = useState({"display": "", "start": moment(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD')+'T'+moment(new Date().setDate(new Date().getDate() + 1)).format('HH:mm'), "code": ""});
    const { frequences } = useSelector(state => state.managmentPlan);
    const mgmtDropRef = React.createRef();
    let mgmtParentRef;
    const classNames = require('classnames');
    const { opened, close, open, parent, getData, data, getReset } = props;
    const units = [
        {id: 'm', display: 'Minuto(s)'},
        {id: 'h', display: 'Hora(s)'},
        {id: 'd', display: 'Día(s)'},
        {id: 'S', display: 'Semana(s)'},
        {id: 'M', display: 'Mes(es)'},
        {id: 'A', display: 'Año(s)'}
    ]

    const handleDocumentClick = (e) => {
        const target = e.target;
        const element = mgmtParentRef;
        const popup = document.getElementsByClassName('dx-scrollview-content')[0];
        const inputs = document.getElementsByClassName('mgmt-input-item');
        const drops = document.getElementsByClassName('mgmt-dropdown-container');
        let inputContains = [];
        let dropsContains = [];
        for(let i = 0; i < inputs.length; i++){
            inputContains.push(inputs[i].contains(target));
        }
        for(let i = 0; i < drops.length; i++){
            dropsContains.push(drops[i].contains(target));
        }
        if( (!element?.contains(target) 
            || 
            element?.firstChild.contains(target))
            &&
            popup?.contains(target)
            &&
            !inputContains.includes(true)
            &&
            !dropsContains.includes(true)
        ) {
            close(parent);
            setWarningShown(false);
        }
    }

    const getCurrentData = () => {
        let dosis = dose;
        dosis.quantity = data?.quantity;
        dosis.display = data?.display;
        dosis.unit = data?.unit;
        dosis.start = data?.start;
        setDose(prev => prev = JSON.parse(JSON.stringify(data)));   
    }

    useEffect(() => {
        // getCurrentData()
    },[data?.display])

    useEffect(() => {
        if(opened) {
            setTimeout(() => {
                setCanDrop(true)
            },200)
            setTimeout(() => {
                setContentShown(true)
            },400)
        } else {
            setContentShown(false)
            setCanDrop(false)
        }
    },[opened])

    useEffect(() => {
        if(warning) {
            setTimeout(() => {
                setWarningShown(true)
            },300)
        } else {
            setWarningShown(false)
        }
    },[warning])

    useEffect(() => {
        mgmtParentRef = mgmtDropRef.current.parentElement;
        setCanDrop(opened);
        setWarning(false);
        getYesterday();
        document.addEventListener('click', handleDocumentClick);

        return function cleanup () {
            document.removeEventListener('click', handleDocumentClick);
        }
    },[])

    useEffect(() => {
        generateDisplay();
    },[dose.unit, dose.quantity])


    const handleOnChange = (value, key) => {
        //console.log(frequences)
        switch(key){
            case 'start':
                setDose({...dose, [key]: value.value.toISOString()});
                break;
            case 'unit':
                setDose({...dose, 
                    "display": frequences?.compose?.include[0].concept.find( freq => freq.code === value.value)?.display,
                    "code": frequences?.compose?.include[0].concept.find( freq => freq.code === value.value)?.code,
                });
                break;
            default:
            break;
        }
    }

    const generateDisplay = () => {
        const index = units.map( unit => {return unit.id}).indexOf(dose.unit);
        let newDisplay = "";
        if(index !== -1) {
            newDisplay = "Cada " + dose.quantity + " " + units[index]?.display;
        } else {
            newDisplay =  "";
        }
        let dosis = dose;
        dosis.display = newDisplay;
        setDose(prev => prev = dosis);
    }

    const cancel = () => {
        const currentDose = dose.display
        const currentData = data.display
        if(currentDose === currentData){
            close(parent);
        } else {
            setWarning(true);
        }
    }

    const reset = () => {
        let dosis = dose;
        dosis.quantity = "";
        dosis.unit = "";
        dosis.display = "";
        dosis.term = "";
        setDose(prev => prev = dosis)
    }

    const save = () => {
        getData('freq', dose);
        setWarning(false);
        close(parent);
    }

    const closeNoSave = () => {
        getCurrentData();
        setWarning(false);
        close(parent);
    }

    const canSave = () => {
        if(dose.start !== "" && dose.display !== "" && dose.code !== "") {
            return false
        } else {
            return true
        }
    }

    const [dayBefore, setDayBefore] = useState("");
    const getYesterday = () => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setDayBefore(yesterday)
    }

    return(
        <Fragment>
            <div id={parent+"MgtmDropdown"} ref={mgmtDropRef} style={{height: canDrop ? warning ? '290px' : '250px' : '0px'}} className={classNames('mgmt-dropdown-container', {'droped' : canDrop})}>
                <div className={classNames('mgmt-dropdown-content', {'droped' : contentShown})}>
                    {/* <div className={classNames('mgmt-dropdown-group-nopadding mgmt-dropdown-group')}>
                        <div id={parent+'DoseInputContainer'} 
                                // onClick={() => getFocused(option.id)} 
                                className={classNames('text-input-container mgmt-input-container')}
                            >
                                <div className={classNames('mgmt-dropdown-label')}>
                                    Cada
                                </div>
                                <input 
                                    id={"Input"} 
                                    className={classNames('mgmt-drop-input')} 
                                    type={"number"}
                                    min={0}
                                    pattern="[0-9]*"
                                    required
                                    name={"section"}
                                    onChange={(e) => handleOnChange(e, 'quantity')}
                                    value={dose['quantity'] ? dose['quantity'] : ""}
                                    autoComplete={"off"}
                                    >
                                </input>
                        </div>
                    </div> */}
                    <div className={classNames('mgmt-dropdown-group-horizontal mgmt-dropdown-group')}>
                        <div id={parent+'UniteInputContainer'}>
                            <div className={classNames('mgmt-dropdown-label')}>
                                Cada
                            </div>
                            <div className={classNames('mgmt-input')}>
                                <SelectBox 
                                    dataSource={frequences?.compose?.include[0].concept}
                                    searchEnabled={true}
                                    defaultValue={dose.code}
                                    value={dose.code}
                                    valueExpr={'code'}
                                    displayExpr={'display'}
                                    placeholder={'Seleccionar'}
                                    onValueChanged={(e) => handleOnChange(e, 'unit')} 
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classNames('mgmt-dropdown-group-horizontal')}>
                        <div id={parent+'UniteInputContainer'}>
                            <div className={classNames('mgmt-dropdown-label')}>
                                Inicio aplicación
                            </div>
                            <div className={classNames('mgmt-input')}>
                                <DateBox 
                                    type={'datetime'}
                                    min={dayBefore}
                                    defaultValue={new Date()}
                                    placeholder={dose.start !== undefined ? (new Date(dose.start).toLocaleDateString('es-CO') + " " + new Date(dose.start).getHours()+":"+new Date(dose.start).getMinutes()) : "Seleccionar" }
                                    onValueChanged={(e) => handleOnChange(e, 'start')} 
                                />
                            </div>
                        </div>
                    </div>
                        
                    <div className={classNames('mgmt-dropdown-group-horizontal flex')}>
                                <div className={classNames('mgmt-input mgmt-cancel-btn')} onClick={cancel}>
                                    CANCELAR
                                </div>
                                <div className={classNames('mgmt-input')}>
                                    <Button 
                                        type={"default"}
                                        stylingMode={"contained"}
                                        text={'Aceptar'}
                                        onClick={save}
                                        disabled={canSave()}
                                    />
                                </div>
                    </div>
                    {warningShown && 
                    <div className={classNames('mgmt-dropdown-group-horizontal')}>
                        <div className={classNames('mgmt-dropdown-warning')}>
                            <i class="fas fa-exclamation-triangle"></i> 
                            Hay cambios sin guardar <br></br>
                            <span onClick={closeNoSave}>Cerrar sin guardar</span> | <span  className={classNames({'warning-disabled' : canSave()})} onClick={canSave() ? null : save}>Guardar y cerrar</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}