import { types } from "../types/types";

const initialState = [
    {
        "title": "Observations",
        "mode": "working",
        "entry": []
    },
    {
        "title": "ServiceRequest",
        "mode": "working",
        "entry": []
    },
    {
        "title": "MedicationRequest",
        "mode": "working",
        "entry": []
    },
    {
        "title": "Diagnostics",
        "mode": "working",
        "entry": []
    }
];

export const fhirReducer = ( state = initialState  , action ) => {

    switch ( action.type ) {
        case types.setSection:
            
            return [
                ...state, action.payload
            ]

        case types.sectionUpdated:
            return (
                state.map(
                    e => (e.title === action.payload?.title) ? action.payload : e
                )
            )

        case types.reset:
            return [
                {
                    "title": "Observations",
                    "mode": "working",
                    "entry": []
                },
                {
                    "title": "ServiceRequest",
                    "mode": "working",
                    "entry": []
                },
                {
                    "title": "MedicationRequest",
                    "mode": "working",
                    "entry": []
                },
                {
                    "title": "Diagnostics",
                    "mode": "working",
                    "entry": []
                }
            ];
            
        default:
            return state;
    }
}