import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TabPanel from 'devextreme-react/tab-panel';
import { Graph } from './Graph.js';
import Card from './Card.js';

const navigation = [
    { 'ID': 1, 'text': 'Especialidades'/* , component:  <Graph/> */ },
    { 'ID': 2, 'text': 'Fichas'/* , component:  <Tabular/>  */},
    // { 'ID': 3, 'text': 'Medicamentos', component: <Card/>  },
];

const CompanyItem = (props) => {
   
    const {productivitySpecialty, productivityTemplate} = useSelector(state => state.productivityPanel);

    const [state, setState] = useState({
        animationEnabled: true,
        swipeEnabled: true,
        loop: false,
        selectedIndex: 0
    });

    const { animationEnabled, loop, selectedIndex, swipeEnabled } = state;
    const infoCard = [
        {   
            title: 'Tiempo',
            info: productivitySpecialty?.TotalAverageTime + ` (min)`
        },
        // {
        //     title: 'Medicamentos',
        //     info: '520'
        // },
        {
            title: 'Atenciones',
            info: productivitySpecialty?.Total
        }];
    
    const infoCard2 = [
        {   
            title: 'Tiempo',
            info: productivityTemplate?.TotalAverageTime + ` (min)`
        },
        // {
        //     title: 'Medicamentos',
        //     info: '520'
        // },
        {
            title: 'Atenciones',
            info: productivityTemplate?.Total
        }];
    
    const itemTitleRender = (company)=> {
    return <span>{company.text}</span>;
    }

    const onSelectionChanged=(args)=> {
        if(args.name == 'selectedIndex') {
            setState({ ...state, selectedIndex: args.value });
        }
    }

    useEffect(() => {
        console.log('selectedIndex: ', selectedIndex);
    }, [selectedIndex])

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {
                    (selectedIndex === 0 ) ? 
                    infoCard.map( (e, i) => {
                        return (
                            <Card key={i} title={e.title} info={e.info} />
                        )
                    }) :
                    infoCard2.map( (e, i) => {
                        return (
                            <Card key={i} title={e.title} info={e.info} />
                        )
                    })
                }
            </div>

            <TabPanel
                height={600}
                dataSource={navigation}
                selectedIndex={selectedIndex}
                onOptionChanged={onSelectionChanged}
                loop={loop}
                itemTitleRender={itemTitleRender}
                itemComponent={Graph}
                //itemRender={Graph}
                animationEnabled={animationEnabled}
                swipeEnabled={swipeEnabled}
            />

        </div>
    )
}

export default CompanyItem;