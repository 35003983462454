
export default class ServiceRequest {

  constructor(id = "", service = { code: 0, display: '' }, type = "", priority = "routine", quantity = null, note = "", occurrence = "", date = "", performer = "", locationIdetifier = "", practitionerID, patientID, encounterID) {
    this._id = id;
    this._priority = priority;
    this._service = service;
    this._note = note;
    this._occurrence = occurrence;
    this._date = date;
    this._quantity = quantity;
    this._practitionerID = practitionerID;
    this._patientID = patientID;
    this._encounterID = encounterID;
    this._performer = performer;
    this._locationIdetifier = locationIdetifier;
  }

  set id(id) {
    this._id = id;
  }

  set priority(id) {
    this._priority = id;
  }

  set quantity(quantity) {
    this._quantity = quantity;
  }

  set service(service) {
    this._service = service;
  }

  set type(type) {
    this._type = type;
  }

  set note(note) {
    this._note = note;
  }

  set occurrence(occurrence) {
    this._occurrence = occurrence;
  }

  set practitionerID(practitionerID) {
    this._practitionerID = practitionerID;
  }

  set patientID(patientID) {
    this._patientID = patientID;
  }

  set encounterID(encounterID) {
    this._encounterID = encounterID;
  }

  set date(date) {
    this._date = date;
  }

  set performer(performer) {
    this._performer = performer;
  }

  set locationIdetifier(locationIdetifier) {
    this._locationIdetifier = locationIdetifier;
  }

  get newServiceRequest() {
    return new ServiceRequestObject(
      this._id,
      this._service,
      this._priority,
      this._quantity,
      this._note,
      this._occurrence,
      this._date,
      this._patientID,
      this._practitionerID,
      this._encounterID,
      this._type,
      this._performer,
      this._locationIdetifier
    )
  }
}

function ServiceRequestObject(id, service, priority, quantity, note, occurrence, date, patientID, practitionerID, encounterID, type, performer, locationIdetifier) {

  let estructure = {
    "resourceType": "ServiceRequest",
    // "id": "myringotomy",
    "identifier": [
      {
        "use": "official",
        "system": "http://indigo.tech",
        "value": id
      }
    ],
    "status": "active",
    "intent": "order",
    "category": [],
    "priority": priority,
    "code": {
      "coding": [
        {
          "system": "http://indigo.tech/",
          "code": service.code,
          "display": service.display
        }
      ],
      "text": service.display
    },
    "subject": {
      "reference": patientID,
    },
    "encounter": {
      "reference": "Encounter/" + encounterID,
    },
    "quantityQuantity": {
      "value": quantity
    },
    "occurrenceDateTime": occurrence,
    "authoredOn": date,
    "requester": {
      "reference": practitionerID,
    },
    "note": [
      {
        "authorReference": {
          "reference": practitionerID,
        },
        "time": date,
        "text": note !== undefined ? note : "No hay nota"
      }
    ]
  }

  switch (type) {
    case "lab":
      estructure["identifier"].push(
        {
          "use": "usual",
          "system": "https://portal.ips.gov.py/ipsportal/service/request/laboratories",
          "value": "Laboratorios" // FIX
        }
      )
      estructure["category"].push({
        "coding": [
          {
            "system": "https://portal.ips.gov.py/ipsportal/service/request/laboratories",
            "code": "Laboratories"
          }
        ],
        "text": "Laboratories"
      })
      break;
    case "img":
      estructure["identifier"].push(
        {
          "use": "usual",
          "system": "https://portal.ips.gov.py/ipsportal/service/request/images",
          "value": "Imagenes"//FIX
        }
      )
      estructure["category"].push({
        "coding": [
          {
            "system": "https://portal.ips.gov.py/ipsportal/service/request/images",
            "code": "Images"
          }
        ],
        "text": "Images"
      })
      break;
    case "inter":
      estructure["identifier"].push(
        {
          "use": "usual",
          "system": "https://portal.ips.gov.py/ipsportal/service/request/inter",
          "value": "Interconsultas"// FIX
        }
      )
      estructure["category"].push({
        "coding": [
          {
            "system": "https://portal.ips.gov.py/ipsportal/service/request/inter",
            "code": "Inter"
          }
        ],
        "text": "Inter"
      })

      estructure = {
        ...estructure,
        status: "on-hold",
        doNotPerform: true,
        performer: {
          //reference: `Practitioner/${}`, // FIX
          reference: `Practitioner/d1581f8b-51e3-490e-87db-f9507cc8bd95`,
          display: `${performer.code}-${performer.display}`
        },
        patientInstruction: note !== undefined ? note : "No hay nota",
        locationReference: [{ "reference": locationIdetifier }]
      }
      break;
    default:
      break;
  }
  return estructure
}