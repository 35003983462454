import React from 'react';
import { Button } from 'devextreme-react/button';
import { useSelector } from 'react-redux';
import { openAgfaViewer } from '../../utils/generic';

function FloatingButton(props) {
    const { patient: patientInfoProntuar } = useSelector(state => state.clinicHistory.patientInfoProntuar);
    const openAgfaViewerEvent = () => {
        let prontuario = patientInfoProntuar?.identifier[0].value;
        openAgfaViewer(prontuario);
    }
    return <Button
        elementAttr={{ class: props.classButton, style: "border-radius:30px; height:56px; color:fff !important" }}
        icon="activefolder"
        text="Visor"
        type="default"
        stylingMode="contained"
        onClick={openAgfaViewerEvent}
    />
}

export default FloatingButton;