import React, { Fragment, useEffect, useState } from 'react';
import IndigoQuestion from './indigoQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import './../ficha-styles.css';
import { resetFicha, setHcData } from '../../../redux/actions/hcData';
import { activateSection } from '../../../redux/actions/parametrizer';
import { ManagementPlan } from './components';
import notify from 'devextreme/ui/notify';
import Composition from './utils/fhir/composition.js';
import { reset, sectionUpdated } from '../../../redux/actions/fhir';
import moment from 'moment';
import { startCreatingComposition } from '../../../redux/actions/clinicHistory';
import { defaultMgmtPlan } from '../../../pages/parametrizer/default/defaultData';
import { resetAutoSave } from '../../../redux/actions/autoSave';
import { Popup } from 'devextreme-react';
import { AppointmentTooltip } from '../../../pages/agenda/AppointmentTooltip';
import { infoPerFicha } from './utils/fhir/aditionalInfo';
import Observation from './utils/fhir/observation';
import { setValidationList } from '../../../redux/actions/validations';
import { getPractitionerByCode } from '../../../utils/helpersPoints';

function Questions(props) {

    var classNames = require('classnames');
    var $ = require('jquery');
    const dispatch = useDispatch();
    const history = useHistory();
    const { codeSIH, name, lastname, emailUser, practitionerReference} = useSelector(state => state.auth);    
    const { activeSection, editable, encounterInfo, readOnly, dataQuestions, readOnlyMgmtData } = props
    const { data : finalData } = useSelector( state => state.parametrizer);
    const { patient: patientID, practitioner, patientIdentifiers, text: patientName } = useSelector(state => state.agenda.appointment);
    const { serverTime } = useSelector(state => state.productivityPanel);
    const { completedList, validationList} = useSelector( state => state.validation);
    const { especiality: spec} = useSelector(state => state.agenda);
    const [popupShow, setPopupShow] = useState(false);
    const { mcData } = useSelector(state => state.hcData);
    const { responsive } = useSelector(state => state.ui);
    const { fhir } = useSelector(state => state);
    const [focused, setFocused] = useState('');
    const [isDropped, setIsDropped] = useState(false);
    const [isValidadted, setIsValidated] = useState(false);
    const [isnotValidatedList, setIsnotValidatedList] = useState([]);
    const [diagnosticsInImpression, setDiagnosticsInImpression] = useState(false);
    const [phantomItem, setPhantomItem] = useState(0);
    const [newComposition, setNewComposition] = useState(new Composition());

    const handleFocusQuestion = (question) => {
        setFocused(question)
    }

    const handdleGetDropped = (drop) => {
        setIsDropped(drop)
    }

    const handleResetFocus = () => {
        setFocused('');
    }

    const handleOnFormChange = (e) => {
        dispatch(setHcData(e.data))
        localStorage.setItem("DataHC", JSON.stringify(e.data))

    }

    const scrollIntoView = (element) => {
        if(!element) return;
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
    }

    const inputEnterJumping = (isDropped) => {
        const inputContainer = document.querySelector('#questionContainer');
        const inputList = inputContainer.querySelectorAll('input.enter-jump, textarea.enter-jump,input.dx-texteditor-input');
        // const inputDevExtremeList = inputContainer.getElementsByClassName('dx-texteditor-input');
        // console.log("inputList", inputList)
        const inputLength = inputList.length;
        for (let i = 0; i < inputLength; i++) {
            $(inputList[i]).off('keydown');
            $(inputList[i]).on('keydown', function (e) {
                if(e.keyCode === 13) {
                    if (e.shiftKey) {
                            $(inputList[i - 1]).focus();
                            scrollIntoView(inputList[i - 1]);
                    } else {
                        if (!inputList[i]?.classList.contains("textarea")) {
                            if(isDropped) {
                                // e.stopPropagation();
                                // console.log("No puedo saltaaaaarme")
                            } else {
                                scrollIntoView(inputList[i + 1]);
                                $(inputList[i + 1]).focus();
                            }
                        }
                    }
                }
                // switch (e.keyCode) {
                //     case 13:
                //         if (!inputList[i]?.classList.contains("textarea")) {
                //             $(inputList[i + 1]).focus()
                //         }
                //         break;
                //     default:
                //         break;
                // }
            })
        }
    }

    useEffect(() => {
        inputEnterJumping(isDropped);
    },[isDropped])

    useEffect(() => {
        questionBody = getHeights("questionContainer");
        phantomBody();
    })

    const getHeights = (element) => {
        const elmt = document.getElementById(element);
        return elmt?.offsetHeight
    }
    let questionBody = 0;

    const phantomBody = () => {
        let lastItem = 0;
        if (finalData.length > 0) {
            lastItem = getHeights(finalData[finalData.length - 1].id + "Section");
        }
        if ($('.dx-icon-chevronup').hasClass('flip')) {
            setPhantomItem(Math.abs(questionBody + 190 - lastItem))
        } else {
            setPhantomItem(Math.abs(questionBody - lastItem));
        }
    }

    const setActiveSection = () => {
        let heights = [];
        const questionsBody = document.getElementById("questionContainer")?.getBoundingClientRect()?.top;
        mcData?.map(section => {
            heights.push({ id: section.id, height: document.getElementById(section.id + "Section")?.getBoundingClientRect()?.top })
        })
        heights?.map(height => {
            if (responsiveSmall()) {
                if ((height.height > (questionsBody - 30)) && (height.height < (questionsBody + 30))) {
                    dispatch(activateSection({ id: height.id, trigger: 'scroll' }))
                }
            } else {
                if ((height.height > (questionsBody - 15)) && (height.height < (questionsBody + 15))) {
                    dispatch(activateSection({ id: height.id, trigger: 'scroll' }))
                }
            }
        })
    }

    const responsiveSmall = () => {
        if ((responsive === "extrasmall" || responsive === "small")) {
            return true
        } else {
            return false
        }
    }

    const { id: encounterId, spec: template } = useParams();
    const {
        prontuar,
        codeEnterprise,
        idEncounterSIH,
        startDate
    } = useSelector(state => state.agenda.appointment);

    //const extendCode = `${template}-${prontuar}-${codeEnterprise}-${idEncounterSIH}-${codeSIH}-${spec.code}-${moment(serverTime).format('YYYYMMDDHHmmss')}`;
    const extendCode = `${template}-${prontuar}-${codeEnterprise}-${idEncounterSIH}-${emailUser?.MedicalCodeSIH}-${spec.code}-${moment(serverTime).format('YYYYMMDD')}`;

    // console.log("extendCode", startDate, extendCode)
    setTimeout(() => {        
        const savedData = localStorage.getItem(encounterId);
    
        if(savedData && savedData !== "undefined" && savedData !== "null") {
            let currentSavedData = JSON.parse(savedData);
            if(!practitionerReference) return;
            if(currentSavedData["practitionerID"] || currentSavedData["practitionerName"]) return;
            currentSavedData["practitionerID"] = practitionerReference.split("/")[1];
            currentSavedData["practitionerName"] = `${name} ${lastname}`;
            localStorage.setItem(encounterId, JSON.stringify(currentSavedData));
        } else {
            localStorage.setItem(encounterId, JSON.stringify({"practitionerID": practitionerReference.split("/")[1]}));
        }
    }, 500);
    
    useEffect(() => {
        // dispatch(activateSection({id: mcData[0]?.id, trigger: 'click'}))
        const savedData = localStorage.getItem("practitionerID");

        if(savedData && savedData !== "undefined" && savedData !== "null") {
            if(JSON.parse(savedData)["practitionerID"] !== practitionerReference.split("/")[1]) {
                setPopupShow(true)
            }
          }

        newComposition.practitionerID = practitionerReference;
        newComposition.practitionerDisplay = `${emailUser?.MedicalCodeSIH}-${name} ${lastname}`;
        newComposition.patientDisplay = `${patientIdentifiers}-${patientName}`;
        newComposition.patientID = patientID;
        newComposition.encounterID = encounterId;
        newComposition.date = moment(new Date()).format('YYYY-MM-DD') + 'T' + moment(new Date()).format('HH:mm') + ':00Z';
        newComposition.name = "Ficha " + template;
        newComposition.extendCode = extendCode;
        newComposition.code = template;
        // COD_PRONTUARIO - COD_EMPRESA -IDENCOUNTER - COD_MEDICO - COD_ESPC_MD - FECHA_INICIO
    }, [])

    const appointmentInfo =  useSelector(state => state.agenda.appointment);
    
    const fillDiagnosticImpression = () => {

        let diagnostics = newComposition?._sections[3]?.entry[0]?.diagnosisNames?.join(", ");
        let newDiagnosticImpression;
        let entryIndex = newComposition?._sections[0]?.entry?.findIndex( entry => {
            return (["impresion diagnostica", "diagnostico presuntivo"].includes(entry?.identifier[3]?.value?.toLowerCase()?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, "")))
        })

        if(entryIndex === -1 || entryIndex === undefined) return;
        if (diagnosticsInImpression) {
            newDiagnosticImpression = newComposition._sections[0]?.entry[entryIndex]?.component[0]?.code.coding[0]?.display?.split("; ")?.slice(1)?.join("; ");
        } else {
            newDiagnosticImpression = newComposition._sections[0]?.entry[entryIndex]?.component[0]?.code.coding[0]?.display || "";
        }
        // newDiagnosticImpression = /* diagnostics ? diagnostics + "; " + newDiagnosticImpression : */ newDiagnosticImpression;
        // newComposition._sections[0].entry[entryIndex].component[0].code.coding[0].display = newDiagnosticImpression;
        setDiagnosticsInImpression(true)
    }

    const addiontalInfoDictionary = {
        "Edad": appointmentInfo?.age,
        "Anos": appointmentInfo?.age?.split(", ")[0],
        "Meses": appointmentInfo?.age?.split(", ")[1],
        "Dias": appointmentInfo?.age?.split(", ")[2],
    }

    const fillAdditionalInformation = () => {
        const newObvervationList = infoPerFicha[template];
        if(!newObvervationList) return;
        newObvervationList?.forEach(ficha => {
            let newObservation = new Observation();
            newObservation.patientID = patientID ? patientID : null;
            newObservation.encounterID = encounterId ? encounterId : null;
            newObservation.date = moment(new Date(startDate)).format('YYYY-MM-DD');
            newObservation.practitionerID = practitionerReference;
            newObservation.canWrite = false;
            newObservation.questionNumber = ficha["questionNumber"];
            newObservation.type = 0;
            newObservation.label = ficha["display"];
            newObservation.inputID = {type: "text", id: ficha["display"], context: 100};            
            newObservation.value = addiontalInfoDictionary[ficha["display"]]

            const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
            let mySection = fhir[index];
            if(!mySection) return;
            if(!mySection?.entry.some( ent => {return ent?.identifier[0].value.includes(ficha["display"])})) {
                mySection?.entry?.push(newObservation.newObvervation);
            }

            dispatch(sectionUpdated(mySection));
        }) 
    }

    const toastNotify = () => {
        notify({
            message: '¡Encuentro finalizado satisfactoriamente!',
            // width: 10,
            position: {
                my: 'center top',
                at: 'center top',
            }
        }, 'success', 4000);
    }

    const sendFinalData = async () => {
        if(readOnly) return;
        newComposition.sections = fhir;
        fillAdditionalInformation();
        fillDiagnosticImpression();
        const finalDataFhir = JSON.stringify(newComposition.newComposition);
        history.push('/agenda');
        toastNotify();
        await dispatch(startCreatingComposition(finalDataFhir, emailUser));
        console.log("finalDataFhir", JSON.parse(finalDataFhir));
        dispatch(resetAutoSave());
        localStorage.removeItem(encounterId);
        localStorage.removeItem("undefined");
        dispatch(resetFicha());
        dispatch(reset());
    }

    const goToAgenda = () => {
        history.push('/agenda');
    }

    
    const closePopup = () => {
        setPopupShow(false);
    }

    const renderPopup = (e) => {
        return <AppointmentTooltip data={{}} spec={""} isAppoinmentStarted={false} closePopup={closePopup}/>
    }

    const goAndValidate = () => {
        const element = document.getElementById(isnotValidatedList[0] + "FormContainer");
        if(!element) return;
        element.scrollIntoView({behavior: 'smooth'})
    }

    useEffect(() => {
        setIsValidated(validationList.every(item => completedList.includes(item)))
        setIsnotValidatedList(validationList.filter(item => !completedList.includes(item)))
        // console.log("validation list:", validationList);
        // console.log("completed list:", completedList);
    }, [completedList, validationList])
    
    useEffect(() => {
        (editable ? finalData : mcData).map((data, key) => {
            const needValidation = data.options.filter(option => option.isDiag).map(option => option.id);
            if(needValidation?.length > 0) {
                dispatch(setValidationList(needValidation))
            }
        })    
    }, [editable, finalData, mcData])

    return (
        <Fragment>
            <div id={'questionContainer'}
                onScroll={setActiveSection}
                onDoubleClick={handleResetFocus}
                className={classNames('questions-container', { 'small-screen': responsiveSmall() })}>
                {readOnly ?
                    <>
                        <IndigoQuestion data={dataQuestions} focused={focused} getFocused={handleFocusQuestion} getDropped={handdleGetDropped} activeSection={activeSection} editable={editable} readOnly={readOnly}/>
                        <ManagementPlan data={defaultMgmtPlan[0]} readOnlyMgmtData={readOnlyMgmtData?.options} focused={focused} getFocused={handleFocusQuestion} activeSection={activeSection} editable={editable} readOnly={readOnly}/>
                    </>
                    :
                    (editable ? finalData : mcData).map((data, key) => {
                        return (
                            <Fragment key={key}>
                                {data.id !== "mgmtPlan" ?
                                    <IndigoQuestion data={data} focused={focused} getFocused={handleFocusQuestion} getDropped={handdleGetDropped} activeSection={activeSection} editable={editable} />
                                    :
                                    <ManagementPlan encounterInfo={encounterInfo} data={data} focused={focused} getFocused={handleFocusQuestion} activeSection={activeSection} editable={editable} />
                                }
                            </Fragment>
                        )
                    })}

                {(!readOnly && (mcData?.length > 0 || finalData.length > 0)) &&
                    <div style={{ height: phantomItem + 'px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                        <div id={'confirmationSection'} className={'send-btn-container'}>
                            <div className={'send-btn-title'}>{isValidadted ? `¿Está todo listo?` : `¡Falta agregar un diagnóstico!`}</div>
                            {isValidadted ? 
                            <div className={classNames('send-btn')} onClick={sendFinalData}>Confirmar y enviar</div> : 
                            <div className={classNames('send-btn incomplete')} onClick={goAndValidate}>Ir y completar</div>
                            }
                        </div>
                        {/* <Footer /> */}
                    </div>
                }

                {/* {(mcData.length === 0) &&
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div id={'confirmationSection'} className={'send-btn-container'}>
                            <h3>¿Por qué no veo información?</h3>
                            <p>¡No te preocupes!</p>
                            <div className={'send-btn'} onClick={goToAgenda}>Volver a agenda</div>
                        </div>
                        <Footer />
                    </div>
                } */}
            </div>
            <Popup
                visible={popupShow}
                onHiding={closePopup}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showTitle={false}
                width={450}
                height={250}
                contentComponent={renderPopup}
            >
            </Popup>
        </Fragment>
    );
}

export default Questions