import { types } from '../types/types';

const initialState = {
    validationList: [], 
    completedList : [], 
}

export const validationReducer = ( state = initialState, action) => {
    let currentCompletedList = JSON.parse(JSON.stringify(state.completedList));
    switch ( action.type ) {
        case types.setValidationList:
            
            return {
                ...state,
                validationList: action.payload,
            }

        case types.addCompletedItem:
            if(currentCompletedList.includes(action.payload)) return state;
            currentCompletedList.push(action.payload);
            return {
                ...state,
                completedList: currentCompletedList,
            }

        case types.removeCompletedItem:
            if(!currentCompletedList.includes(action.payload)) return state;
            const itemIndex = currentCompletedList.indexOf(action.payload);
            currentCompletedList.splice(itemIndex,1);
            return {
                ...state,
                completedList: currentCompletedList,
            }

        default:
            return state;
    }
}