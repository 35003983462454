import { types } from "../types/types";

export const setAutoSaveGeneralInput = (data) => {
    return ({
        type: types.setAutoSaveGeneralInput,
        payload: data
    })
};

export const setAutoSaveSelectInput = (data) => {
    return ({
        type: types.setAutoSaveSelectInput,
        payload: data
    })
};

export const setAutoSaveSearchInput = (data) => {
    return ({
        type: types.setAutoSaveSearchInput,
        payload: data
    })
};

export const setAutoSaveFHIR = (fhir) => {
    return ({
        type: types.setAutoSaveFHIR,
        payload: fhir
    })
}

export const resetAutoSave = () => {
    return ({ type: types.resetAutoSave })
};

