import { types } from "../types/types"


export const dashboardReducer = ( state = {} , action) => {
    switch ( action.type ) {
        case types.isAdmin:
            return {
                ...state,
                isAdmin: action.payload.keyRow,
                email: action.payload.email,
                password: action.payload.password
            }

        case types.dashboardUsers:
            
            return {
                ...state,
                users: action.payload.users
            };

        case types.dashboardRetries:
        
            return {
                ...state,
                registers: action.payload.registers
            };

        case types.changesJsonView:
    
            return {
                ...state,
                pendingChanges: action.payload.pendingChanges
            };

        case types.dashboardPortals:
        
            return {
                ...state,
                portals: action.payload.portals
            };

        case types.logout:
        
            return {}
            
        default:
            return state;
    }
}
