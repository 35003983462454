import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  StringLengthRule,
  AsyncRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { CheckBox } from 'devextreme-react/check-box';

import 'devextreme-react/autocomplete';
import { startCreateUser } from '../../redux/actions/dashboard';

export const RegisterUser = ({setCreateUser}) => {
    const dispatch = useDispatch();
    const {email, password} = useSelector(state => state.dashboard);
    const passwordOptions = {
      mode: 'password'
    };
    
    const [user, setUser] = useState({
      partitionKey: "User",
      firstName: '',
      lastName: '',
      email: '',
      specialty: '{}',
      /*/Code: '',*/  
      password: null,
      isAdmin: false
    });
    const initialUser =
    {
      partitionKey: "User",
      firstName: '',
      lastName: '',
      email: '',
      specialty: '{}',

      /*/Code: '',*/  
      password: null,
      isAdmin: false
    }

    const checkComparison = () => {
      return true;
    }
    const handleSubmit = (e) => {
      //console.log('customerr: ', user)
      dispatch(startCreateUser(email, password, JSON.stringify(user)))
      setUser(initialUser);
      setCreateUser(false);
  
      e.preventDefault();
    }

    const passwordComparison = () => {
      return user.password;
    }

    const sendRequest = (value) => {
      const validEmail = 'test@dx-email.com';
      return new Promise((resolve) => {
        setTimeout(function() {
          resolve(value !== validEmail);
        }, 1000);
      });
    }
    
    const asyncValidation = (params) => {
      return sendRequest(params.value);
    }

    const handleAdmin = (e) => {
      if (e.dataField === 'isAdmin') {
        setUser({ ...user, isAdmin: e.value})
      }
    }

    const buttonOptions = {
      text: 'Registrar',
      type: 'default',
      useSubmitBehavior: true,
      //onClick: 
    };

    const checkBoxOptions = {
      text: 'Aceptar términos y condiciones',
      value: false
    };
    const checkBoxAdmin = {
      text: 'Administrador',
      value: user.isAdmin
    };

    return (
        <form action="your-action" onSubmit={handleSubmit} 
        style={{margin: '10px',  maxHeight: '60vh', overflow: 'auto'}}
        /*style={{margin: '40px',  maxHeight: '60vh', overflow: 'auto'}}*/>
          <Form
            formData={user}
            readOnly={false}
            showColonAfterLabel={true}
            showValidationSummary={true}
            validationGroup="customerData"
            onFieldDataChanged= { (e)=> handleAdmin(e)}
          >

            <GroupItem caption="Registro usuario">
              <SimpleItem dataField="firstName" >
                <Label text="Nombres" />
                <RequiredRule message="Nombres son requeridos" />
                <PatternRule message="No usar digitos en el nombre"
                  pattern={/^[^0-9]+$/} />
              </SimpleItem>
              <SimpleItem dataField="lastName" >
                <Label text="Apellidos" />
                <RequiredRule message="Apellidos son requeridos" />
                <PatternRule message="No usar digitos en el apellido"
                  pattern={/^[^0-9]+$/} />
              </SimpleItem>
              {/* //Si es admin se oculta el codigo */}
              {/* <SimpleItem dataField="Code" visible={ !user.Admin }>
                <Label text="Código SIH" /> */}
                {/* <RequiredRule message="Apellidos son requeridos" />
                <PatternRule message="No usar digitos en el apellido"
                  pattern={/^[^0-9]+$/} /> */}
              {/* </SimpleItem> */}

              <SimpleItem dataField="email" editorType="dxTextBox">
                <Label text="Correo electronico" />
                <RequiredRule message="Correo es requerido" />
                <EmailRule message="Correo invalido" />
                <AsyncRule
                  message="El correo ya se encuentra registrado"
                  validationCallback={asyncValidation} />
              </SimpleItem>
              <SimpleItem dataField="specialty">
                <Label text="Especialidades"/>
              </SimpleItem>
            </GroupItem>

            {/* <GroupItem >
              <SimpleItem dataField="CodeSIH" editorType="dxTextBox">
                <Label text="CodeSIH" />
                <RequiredRule message="CodeSIH es requerida" />
              </SimpleItem>
              <SimpleItem dataField="UserCodeSIH" editorType="dxTextBox">
                <Label text="UserCodeSIH" />
                <RequiredRule message="UserCodeSIH es requerida" />
              </SimpleItem>
              <SimpleItem dataField="MedicalCodeSIH" editorType="dxTextBox">
                <Label text="MedicalCodeSIH" />
                <RequiredRule message="MedicalCodeSIH es requerida" />
              </SimpleItem>
            </GroupItem> */}

              {/* Condicion contraseña segun admin */}
              <GroupItem visible={user.isAdmin} >
                <SimpleItem dataField="password" editorType="dxTextBox" editorOptions={passwordOptions}>
                  <Label text="Contraseña" />
                  <RequiredRule message="Contraseña es requerida" />
                </SimpleItem>
                <SimpleItem editorType="dxTextBox" editorOptions={passwordOptions}>
                  <Label text="Confirmar Contraseña" />
                  <RequiredRule message="Confirmar contraseña es requerido" />
                  <CompareRule
                    message="Las contraseñas no coinciden"
                    comparisonTarget={passwordComparison}
                  />
                </SimpleItem>
              </GroupItem>
              
            <GroupItem>
              <SimpleItem dataField="isAdmin"
                editorType="dxCheckBox"
                editorOptions={checkBoxAdmin}
                //editorOptions= {{text: "Administrador"}}
                //value = {user.Admin}
              >
                <Label visible={false} />
              </SimpleItem>

              {/* <SimpleItem dataField="Accepted"
                editorType="dxCheckBox"
                editorOptions={checkBoxOptions}>
                <Label visible={false} />
                <CompareRule message="Debes aceptar los terminos y condiciones"
                  comparisonTarget={checkComparison}
                />
              </SimpleItem> */}
            
              <ButtonItem horizontalAlignment="left"
                buttonOptions={buttonOptions}
              />
            </GroupItem>

          </Form>
        </form>
    )
}
