import { types } from "../types/types";

export const authReducer = ( state = {}  , action ) => {

    switch ( action.type ) {
        case types.login:
            
            return {

                uid: action.payload.uid,
                codeSIH: action.payload.codeSIH,
                email: action.payload.email,
                displayName: action.payload.displayName,
                name: action.payload.name,
                lastname: action.payload.lastname,
                jobTitle: action.payload.jobTitle,
                mobilePhone: action.payload.mobilePhone,
                office: action.payload.office,
                language: action.payload.language,
                type: action.payload.type,
                specialities: action.payload.specialities,
                isActive: action.payload.isActive,
                emailUser: action.payload.emailUser,
                practitionerReference : action.payload.practitionerReference
            }
        
        case types.logout:
            
            return {}

            
        case types.imageUrl: 
        
            return {
                ...state,
                image: action.payload.image,
            }

        default:
            return state;
    }
}
