import React, { useRef, useEffect } from 'react';

import DataGrid, {
    Column,
    Format,
    Button,
    Scrolling
} from 'devextreme-react/data-grid';

export default function MedicalHistoryDataGrid(props) {
    const datagrid = useRef(null);
    const { dataSource, handleOpenMedicalRecord, gridSize } = props;

    useEffect(() => {
        if (gridSize !== 300) {
            //console.log("repainting one view:", gridSize)
            datagrid.current.instance.repaint()
        }
    }, [gridSize])

    return (
        <div>
            <DataGrid
                ref={datagrid}
                dataSource={dataSource}
                keyExpr="ID"
                showBorders={true}
                //height={gridSize}
                onRowDblClick={handleOpenMedicalRecord}
                elementAttr={{ style: `height: ${gridSize}px` }}
            >
                <Scrolling mode="standard" />
                <Column 
                    dataField="FechaAtencion"
                    dataType='datetime'
                    defaultSortOrder={"desc"}
                >
                    <Format
                        type="dd/MM/yyyy HH:mm"
                        precision={2}
                    />
                </Column>
                <Column dataField="TipoAtencion" />
                <Column dataField="FichaNombre" /* calculateCellValue={this.calculateFormName} */ />
                <Column dataField="NombreDoctor" />
                <Column dataField="Especialidad">
                    {/* <Lookup
                            dataSource={specialties}
                            valueExpr="code"
                            displayExpr="display"
                        /> */}
                </Column>
                <Column type="buttons" width={110}>
                    <Button hint="Abrir"
                        icon="activefolder"
                        onClick={handleOpenMedicalRecord} />
                </Column>
            </DataGrid>
        </div>
    );
}