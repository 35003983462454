import Button from 'devextreme-react/button';
import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import React, { useState, useCallback, useRef, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router';
import { Header, SideNavigationMenu, Footer } from '../../components';
import './side-nav-inner-toolbar.scss';
import { useScreenSize } from '../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';

export default function SideNavInnerToolbar ({ title, children }) {
  const scrollViewRef = useRef();
  const history = useHistory();
  const { isXSmall, isLarge } = useScreenSize();
  const [event, setEvent] = useState(true);
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(1);

  const classNames = require('classnames');

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event?.stopPropagation();
  }, []);

  const sideBarRef = React.createRef();
    let sideBarParent;
    useEffect(() => {
        sideBarParent = sideBarRef.current.parentNode;
    },[])

    const handleClickOusideBar = useCallback ((e) => {
      if (e && e.which === 3) return;
      const container = sideBarParent;
      // if(menuStatus === 2 || menuStatus === 3){
        if (container?.contains(e.target)) {
          setMenuStatus(1)
          setEvent(true)
        }
      // }
  }, [])
  
  useEffect(() => {
    //console.log("menuStatus inner", menuStatus)
  }, [menuStatus])

  useEffect(() => {
    if(menuStatus === 2 || menuStatus === 3){
        document.addEventListener('click', handleClickOusideBar);
    } else {
        document.removeEventListener('click', handleClickOusideBar);
    }

      return function cleanup () {
        document.removeEventListener('click', handleClickOusideBar);
      }
   },[menuStatus, event])

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
  }, [isLarge]);

  const onNavigationChanged = useCallback(({ itemData: { path }, event, node }) => {
    if (menuStatus === MenuStatus.Closed || !path || node.selected) {
      event.preventDefault();
      return;
    }

    history.push(path);
    scrollViewRef.current.instance.scrollTo(0);

    if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
      setMenuStatus(MenuStatus.Closed);
      event.stopPropagation();
    }
  }, [history, menuStatus, isLarge]);


  return (
    <Fragment>
    {/*<div className={'side-nav-inner-toolbar'}>*/}
      <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'before'}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'expand' : 'expand'}
        minSize={isXSmall ? 0 : 60}
        maxSize={180}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={'menu'}
        
      >
        <div ref={sideBarRef} className={'container'}>
          <div className={'top-frame'}></div>
          <Header
            menuToggleEnabled={isXSmall}
            toggleMenu={toggleMenu}
          />
          <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
            <div className={classNames('content',{'agenda' : (history.location.pathname.includes("/agenda") || history.location.pathname.includes("/histo"))})}>
              {React.Children.map(children, item => {
                return item.type !== Footer && item;
              })}
            </div>
            <div className={'content-block'}>
              {React.Children.map(children, item => {
                return item.type === Footer && item;
              })}
            </div>
          </ScrollView>
        </div>
        <Template name={'menu'}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
            isClosed={menuStatus === MenuStatus.Closed ? false : true}
            toggleMenu={toggleMenu}
          >
            <Toolbar id={'navigation-header'}>
              {
                !isXSmall &&
                <Item
                  location={'center'}
                  cssClass={'menu-button'}
                >
                  <Button icon={menuStatus === MenuStatus.Closed ? 'chevronright' : 'chevronleft'} stylingMode="text" onClick={toggleMenu} />
                </Item>
              }
              {/* <Item location={'before'} cssClass={'header-title'} text={title} /> */}
            </Toolbar>
          </SideNavigationMenu>
        </Template>
      </Drawer>
   {/*</div>*/}
   </Fragment>
  );
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};
