import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContextMenu from 'devextreme-react/context-menu';
import { Popup } from 'devextreme-react/popup';

import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../../../../redux/actions/parametrizer.js';

import Button from 'devextreme-react/button';
import Observation from '../../utils/fhir/observation';
import { sectionUpdated } from '../../../../../redux/actions/fhir';
import moment from "moment";
import { setAutoSaveGeneralInput } from '../../../../../redux/actions/autoSave.js';

export default function IndigoTextArea (props) {

    
    const dispatch = useDispatch();
    const { fhir } = useSelector(state => state);
    const { data } = useSelector(state => state.parametrizer);
    const { responsive } = useSelector( state => state.ui);
    const { id: encounterId } = useParams();
    const { patient, practitioner, startDate } = useSelector(state => state.agenda.appointment);
    var classNames = require('classnames');
    
    const { disable, focused, getFocused, info, editable, granPaID, render, readOnly} = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const [contextOpen, setContextOpen] = useState(null);
    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);
    const [inputSizes, setInputSizes] = useState([]);
    const [maxSizeDefault, setMaxSizeDefault] = useState({XL: info.size, L: (parseInt(info.size,10)-1), M: (parseInt(info.size,10)-2), S: 2, XS: 1});
    
    const autoSave = useSelector( state => state.autoSave);
    const savedData = localStorage.getItem(encounterId);
    
    let defaultValue = "";
    if(savedData && savedData !== "undefined" && savedData !== "null") {
        if(JSON.parse(savedData)[info.id]?.value) {
            defaultValue = JSON.parse(savedData)[info.id]?.value;
        }
    }    
    const [searchValue, setSearch] = useState(defaultValue);

    useEffect(() => {        
        localStorage.setItem(encounterId, JSON.stringify(autoSave[encounterId]));
    }, [JSON.stringify(autoSave[encounterId])])

    const [newObservation, setNewObservation] = useState(new Observation());
    
    useEffect(() => {
        newObservation.patientID = patient ? patient : null;
        newObservation.encounterID = encounterId ? encounterId : null;
        newObservation.date = moment(new Date(startDate)).format('YYYY-MM-DD');
        newObservation.practitionerID = practitioner ? practitioner : null;
        newObservation.inputID = {type: "select", id: info.id, context: "000"};
        newObservation.canWrite = false;
        newObservation.label = info.display;
        newObservation.questionNumber = info.questionNumber;
        newObservation.type = 0;
        sizeConvert()
        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[index];
        if(!mySection) return;
        if(!mySection?.entry.some( ent => {return ent?.identifier[0].value.includes(info.id)})) {
            mySection?.entry?.push(newObservation.newObvervation);
        }
        dispatch(sectionUpdated(mySection));
    },[render])
    const windowSingin = () => {
        switch (responsive) {
            case "extralarge":
                setInputSizes([1,2,3,4,5]);
                updateSize(5);
                break;

            case "large":
                setInputSizes([1,2,3,4]);
                updateSize(4);
            break;

            case "medium":
                setInputSizes([1,2,3]);
                updateSize(3);
            break;

            case "small":
                setInputSizes([1,2]);
                updateSize(2);
            break;

            default:
                setInputSizes([1]);
                updateSize(1);
                break;
        }
        
        return window.screen.width
    }
    
    const updateSize = (size) => {
        const sizeCon = {
             "extralarge": maxSizeDefault.XL,
             "large": maxSizeDefault.L,
             "medium": maxSizeDefault.M,
             "small": maxSizeDefault.S,
             "extrasmall": maxSizeDefault.XS,
        }

        handleChangeSize(sizeCon[Object.keys(sizeCon).find( el => 
            el === responsive
        )])

        if(info.size > size) {
            handleChangeSize(size)
        }
    }

    useEffect(() => {
        windowSingin();
      }, [responsive]);

    const sizeConvert = () => {
        switch(info.size){
            case 1:
                setMaxSizeDefault({XL: info.size, L: 1, M: 1, S: 2, XS: 1});
                break;
            case 2:
                setMaxSizeDefault({XL: info.size, L: 2, M: 2, S: 2, XS: 1});
                break;
            case 3:
                setMaxSizeDefault({XL: info.size, L: 2, M: 2, S: 2, XS: 1});
                break;
            case 4:
                setMaxSizeDefault({XL: info.size, L: 3, M: 2, S: 2, XS: 1});
                break;
            default:
                setMaxSizeDefault({XL: info.size, L: 4, M: 3, S: 2, XS: 1});
                break;
        }
    }
    const inputRef = React.createRef();
    let inputParent;
    useEffect(() => {
        inputParent = inputRef.current.parentNode;
    },[])

    const handleGetFocused = () => {
        if(searchValue !== "") return;
        setTimeout(() => {
            setSearch("")
        }, 10);
        getFocused(info.id);
    }

    const toggleEnterTooltip = () => {
        setShowTooltip(true);
    }

    const toggleLeaveTooltip = () => {
        setShowTooltip(false);
    }

    const openPopup = () => {
        setCancelPopUpVisible(true)
    }

    const closePopup = () => {
        setCancelPopUpVisible(false)
    }

    const handleChangeSize = size => {
        const newData = data;
        newData.some( data => {
            data.options.some( quest => {
                if(quest.id === info.id){
                    quest.size = size
                }
            })
        })
        dispatch( setData(newData))
    }

    const handleDeleteItem = () => {
        openPopup()
        setContextOpen(false);
        setTimeout(
            () => {
                setContextOpen(null);
            },
            [300],
        );
    }

    const handleOnChange = (data) => { 
        setSearch(data.target.value)
        newObservation.value = data.target.value;
    }

    useEffect(() => {
        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[index];
        const entryIndex = mySection?.entry?.map( ent => {
            return( ent?.identifier[0]?.value.substring(5,(ent?.identifier[0]?.value.length)))
        }).indexOf(info.id)

        let myEntry = mySection?.entry[entryIndex];

        let searchValueDisplay = null; 
        if(myEntry){
            if(searchValue.split("").length === 0) {
                searchValueDisplay = null;
            } else {
                searchValueDisplay = searchValue;
            }
            myEntry["component"][0]["code"]["coding"][0]["display"] = searchValueDisplay;
    
        }
        if(!mySection) return;
        mySection?.entry?.splice(entryIndex,1);
        mySection?.entry?.splice(entryIndex,0,myEntry);
        dispatch(sectionUpdated(mySection));

        const autoSaveData = {
            id: info.id,
            value: searchValueDisplay,
            comment: "",
            encounterId: encounterId,
        }

        dispatch(setAutoSaveGeneralInput(autoSaveData));
    },[searchValue])

    return (
        <div ref={inputRef} id={info.id+'InputContainer'} onClick={handleGetFocused} className={classNames('form-container', {'focused' : focused === info.id}, 'section-w-size-'+info.size)}
        >
            <div className={'text-input-container'}>
                <div className={'text-input-title'}>
                    <label htmlFor={info.id} >{info.display}</label>
                </div>
                <textarea 
                    id={info.id+"Input"} 
                    className={'text-input textarea enter-jump'}
                    placeholder={info.display} 
                    onChange={handleOnChange}
                    value={readOnly ? info.options[0].display : searchValue}
                    readOnly={readOnly}
                    onMouseEnter={toggleEnterTooltip}
                    onMouseLeave={toggleLeaveTooltip}
                    onFocus={handleGetFocused}
                    autoComplete={"off"}
                    maxLength={info.maxChar}
                    >
                </textarea>
            </div>

            {editable && <ContextMenu
                visible={contextOpen}
                dataSource={[{ text: 'Editar ' + info.display, icon: 'rename' }]}
                width={200}
                target={"#"+info.id+'InputContainer'}
                closeOnOutsideClick={true}
                onItemClick={handleDeleteItem} />}

            {(cancelPopupVisible && editable)&& <Popup
                visible={cancelPopupVisible}
                onHiding={closePopup}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showTitle={true}
                title={"Editar "+info.display}
                width={350}
                height={350}
                >
                <div className={'cancel-popup'}>
                    <div className={'popup-section'}>
                        <div className={'popup-section-title'}>Tamaño</div>
                        <div className={'popup-section-content size-btns'}>
                            {inputSizes.map( (size, key ) => {
                                return (
                                    <Button
                                        key={key}
                                        text={size}
                                        type={size === info.size ? "success" : "normal"}
                                        stylingMode={size === info.size ? "contained" : "outlined"}
                                        onClick={() => handleChangeSize(size)}
                                    /> 
                                );
                            })
                            }
                        </div>
                    </div>
                    <div className={'cancel-buttons'}>
                        <Button
                        width={130}
                        icon={'check'}
                        text="Cerrar"
                        type="success"
                        stylingMode="contained"
                        onClick={closePopup}                        
                        />
                    </div>

                </div>
            </Popup>}
        </div>
    )
}