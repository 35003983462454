import React from 'react';

import List, { ItemDragging } from 'devextreme-react/list';

class IndigoItemsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        plannedTasks: this.props.items,
        };
        this.onDragStart = this.onDragStart.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onReorder = this.onReorder.bind(this);
    }
    onDragStart(e) {
        e.itemData = this.state[e.fromData][e.fromIndex];
    }
    onAdd(e) {
        const tasks = this.state[e.toData];
        this.setState({
        [e.toData]: [...tasks.slice(0, e.toIndex === 0 ? e.toIndex : e.toIndex), e.itemData, ...tasks.slice(e.toIndex === 0 ? e.toIndex : e.toIndex)]
        });
    }
    onRemove(e) {
        const tasks = this.state[e.fromData];
        this.setState({
        [e.fromData]: [...tasks.slice(0, e.fromIndex), ...tasks.slice(e.fromIndex + 1)]
        });
    }
    onReorder(e) {
        this.onRemove(e);
        this.onAdd(e);
        this.props.updateList(this.state.plannedTasks)
        console.log("PlannedTasks", this.state.plannedTasks)
    }
    render() {
        const { plannedTasks } = this.state;
        return (
        <div className="widget-container">
            <List
                items={plannedTasks}
                keyExpr="id"
                displayExpr="display"
                repaintChangesOnly={true}>
                <ItemDragging
                    allowReordering={true}
                    group="tasks"
                    data="plannedTasks"
                    onDragStart={this.onDragStart}
                    onAdd={this.onAdd}
                    onRemove={this.onRemove}
                    onReorder={this.onReorder}>
                </ItemDragging>
            </List>
        </div>
        );
    }
}

export default IndigoItemsList;
