import React, { Fragment, useEffect, useState } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, Paging, FilterRow, Scrolling, Pager, Column } from 'devextreme-react/data-grid';

export default function Laterality(props) {

    const [canDrop, setCanDrop] = useState(false);
    const [contentShown, setContentShown] = useState(false);
    const [warningShown, setWarningShown] = useState(false);
    const [warning, setWarning] = useState(false);
    const [dose, setDose] = useState({"medicine": "", "display": ""})
    const mgmtDropRef = React.createRef();
    let mgmtParentRef;
    const classNames = require('classnames');
    const { opened, close, open, parent, getData, data, getReset } = props;

    const units = [
        {id: '15454', display: 'Derecha'},
        {id: '13665', display: 'Izquierda'},
        {id: '16665', display: 'Ambos'},
        {id: '14665', display: 'No aplica'},
    ]

    const handleDocumentClick = (e) => {
        const target = e.target;
        const element = mgmtParentRef;
        const popup = document.getElementsByClassName('dx-scrollview-content')[0];
        const inputs = document.getElementsByClassName('mgmt-input-item');
        const drops = document.getElementsByClassName('mgmt-dropdown-container');
        let inputContains = [];
        let dropsContains = [];
        for(let i = 0; i < inputs.length; i++){
            inputContains.push(inputs[i].contains(target));
        }
        for(let i = 0; i < drops.length; i++){
            dropsContains.push(drops[i].contains(target));
        }
        if( (!element?.contains(target) 
            || 
            element?.firstChild.contains(target))
            &&
            popup?.contains(target)
            &&
            !inputContains.includes(true)
            &&
            !dropsContains.includes(true)
        ) {
            close(parent);
            setWarningShown(false);
        }
    }

    const getCurrentData = () => {
        let dosis = dose;
        dosis.indication = data?.indication;
        dosis.display = data?.display;
        setDose(prev => prev = JSON.parse(JSON.stringify(data)));   
    }

    useEffect(() => {
        mgmtParentRef = mgmtDropRef.current.parentElement;
    },[])

    useEffect(() => {
        getCurrentData()
    },[data?.display])

    useEffect(() => {
        if(opened) {
            setTimeout(() => {
                setCanDrop(true)
            },200)
            setTimeout(() => {
                setContentShown(true)
            },400)
        } else {
                setContentShown(false)
                setCanDrop(false)
        }
    },[opened])

    useEffect(() => {
        if(warning) {
            setTimeout(() => {
                setWarningShown(true)
            },300)
        } else {
            setWarningShown(false)
        }
    },[warning])

    useEffect(() => {
        setCanDrop(opened);
        setWarning(false);
        document.addEventListener('click', handleDocumentClick);

        return function cleanup () {
            document.removeEventListener('click', handleDocumentClick);
        }
    },[])

    const handleOnChange = (value, key) => {
        setDose({...dose, 'display': value?.currentSelectedRowKeys[0]?.display, [key]: value?.currentSelectedRowKeys[0]});
    }

    const cancel = () => {
        const currentDose = dose.display
        const currentData = data.display
        if(currentDose === currentData){
            close(parent);
        } else {
            setWarning(true);
        }
    }

    const save = () => {
        getData('imgLaterality', dose);
        setWarning(false);
        close(parent);
    }

    const reset = () => {
        let dosis = dose;
        dosis.quantity = "";
        dosis.unit = "";
        dosis.display = "";
        dosis.term = "";
        setDose(prev => prev = dosis)
    }

    const closeNoSave = () => {
        getCurrentData();
        setWarning(false);
        close(parent);
    }

    const canSave = () => {
        if(dose.indication !== "") {
            return false
        } 
        else {
            return true
        }
    }


    return(
        <Fragment>
            <div id={parent+"MgtmDropdown"} ref={mgmtDropRef} style={{minHeight: canDrop ? warning ? '445px' : '405px' : '0px'}} className={classNames('mgmt-dropdown-container', {'droped' : canDrop})}>
                <div className={classNames('mgmt-dropdown-content', {'droped' : contentShown})}>
                    <div className={classNames('mgmt-dropdown-group-nopadding')}>
                        <div id={parent+'UniteInputContainer'}>
                                    <div className={classNames('mgmt-dropdown-label')}>
                                        Lateralidad
                                    </div>
                                    <DataGrid
                                        // ref={selectGridRef}
                                        dataSource={units}
                                        onSelectionChanged={(e) => handleOnChange(e, "medicine")}
                                        hoverStateEnabled={true}
                                        height={300}
                                        showBorders={true}
                                    >
                                        <Paging enabled={true} defaultPageSize={5}></Paging>
                                        <Pager
                                            showPageSizeSelector={true}
                                            allowedPageSizes={[5, 10, 20]}
                                            showInfo={true} />
                                        <Column dataField="display" caption="Lateralidad" width={'80%'} />
                                        <Selection mode="single"></Selection>
                                        <FilterRow visible={true}></FilterRow>
                                    </DataGrid>
                            </div>
                    </div>
                    <div className={classNames('mgmt-dropdown-group-horizontal flex')}>
                            <div className={classNames('mgmt-input mgmt-cancel-btn')} onClick={cancel}>
                                CANCELAR
                            </div>
                            <div className={classNames('mgmt-input')}>
                                <Button 
                                    type={"default"}
                                    stylingMode={"contained"}
                                    text={'Aceptar'}
                                    onClick={save}
                                />
                            </div>
                    </div>
                    {warningShown && 
                    <div className={classNames('mgmt-dropdown-group-horizontal')}>
                        <div className={classNames('mgmt-dropdown-warning')}>
                        <i class="fas fa-exclamation-triangle"></i> Hay cambios sin guardar <br></br>
                            <span onClick={closeNoSave}>Cerrar sin guardar</span> | <span  className={classNames({'warning-disabled' : canSave()})} onClick={canSave() ? null : save}>Guardar y cerrar</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}