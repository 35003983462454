import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Switch } from 'devextreme-react/switch';
import ContextMenu from 'devextreme-react/context-menu';
import { Popup } from 'devextreme-react/popup';
import IndigoCommentInput from './../indigoCommentInput.jsx';

import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../../../../redux/actions/parametrizer.js';
import Button from 'devextreme-react/button';
import Observation from '../../utils/fhir/observation.js';
import { sectionUpdated } from '../../../../../redux/actions/fhir.js';
import moment from 'moment';

export default function IndigoSwitch (props) {

    
    const dispatch = useDispatch();
    // const { hcData : searchValue } = useSelector(state => state.hcData)
    const { data } = useSelector(state => state.parametrizer)
    const { fhir } = useSelector(state => state)
    const { id: encounterId } = useParams();
    const { patient, practitioner, startDate } = useSelector(state => state.agenda.appointment);
    var classNames = require('classnames');
    
    const { disable, focused, getFocused, info, editable, granPaID, render} = props;
    const [searchValue, setSearchValue] = useState(false);
    const inputSizes = [1,2,3];
    const [showTooltip, setShowTooltip] = useState(false);
    const [contextOpen, setContextOpen] = useState(null);
    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);
    const [switchValue, setSwitchValue] = useState({ true: '', false: ''})
    const [newObservation, setNewObservation] = useState(new Observation());
    const inputRef = React.createRef();
    let inputParent;
    useEffect(() => {
        newObservation.patientID = patient ? patient : null;
        newObservation.encounterID = encounterId ? encounterId : null;
        newObservation.date = moment(new Date(startDate)).format('YYYY-MM-DD');
        newObservation.practitionerID = practitioner ? practitioner : null;
        newObservation.inputID = {type: "boolean", id: info.id, context: "000"};
        newObservation.canWrite = info.canWrite;
        newObservation.label = info.display;
        newObservation.questionNumber = info.questionNumber;
        newObservation.type = 0;
        newObservation.value = info.defaultValue;
        inputParent = inputRef.current.parentNode;

        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[index];
        if(!mySection?.entry.some( ent => {return ent.identifier[0].value.includes(info.id)})) {
            mySection?.entry?.push(newObservation.newObvervation);
        }
        dispatch(sectionUpdated(mySection));
    },[render])

    const handleGetFocused = () => {
        getFocused(info.id);
    }

    const toggleEnterTooltip = () => {
        setShowTooltip(true);
    }

    const toggleLeaveTooltip = () => {
        setShowTooltip(false);
    }

    const openPopup = () => {
        setCancelPopUpVisible(true)
    }

    const closePopup = () => {
        setCancelPopUpVisible(false)
    }

    const handleChangeSize = size => {
        const newData = data;
        newData.some( data => {
            data.options.some( quest => {
                if(quest.id === info.id){
                    quest.size = size
                }
            })
        })
        dispatch( setData(newData))
    }

    const handleDeleteItem = () => {
        openPopup()
        setContextOpen(false);
        setTimeout(
            () => {
                // this.props.deleteSection("");
                setContextOpen(null);
            },
            [300],
        );
    }

    const handleOnChange = (data) => { 
        setSearchValue(data.value)
        newObservation.value = data.value;
        if(data.value) {
            newObservation.componentCommentary = switchValue["true"];
        } else {
            newObservation.componentCommentary = switchValue["false"];
        }
    }

    const handleCommentChange = (data) => { 
        if(searchValue) {
            setSwitchValue({...switchValue, true: data})
        } else {
            setSwitchValue({...switchValue, false: data})
        }
        newObservation.componentCommentary = data;
    }

    const switches = document.getElementsByClassName('dx-switch')

    if(switches){
        for(let i = 0; i < switches.length; i++){
            if(switches[i].parentNode.id === info.id+'Switch') {
                if(switches[i].classList.contains('dx-state-focused')){
                    getFocused(info.id);
                } 
                break;
            }
        }
    }

    useEffect(() => {
        if(info.defaultValue) {
            setSwitchValue({...switchValue, true: info.message})
            setSearchValue(true)
        } else {
            setSwitchValue({...switchValue, false: info.message})
            setSearchValue(false)
        }
    }, [])

    useEffect(() => {
        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[index];
        const entryIndex = mySection?.entry?.map( ent => {
            return( ent?.identifier[0]?.value.substring(5,(ent?.identifier[0]?.value.length)))
        }).indexOf(info.id)

        let myEntry = mySection?.entry[entryIndex];

        if(myEntry){
            if(info.canWrite){
                myEntry["component"][0]["code"]["coding"][0]["display"] = switchValue[searchValue];
            }
    
            // myEntry["valueBoolean"] = searchValue;
    
            mySection?.entry?.splice(entryIndex,1);
            mySection?.entry?.splice(entryIndex,0,myEntry)
        }
        dispatch(sectionUpdated(mySection))
    },[searchValue, switchValue])

    return (
        <div ref={inputRef} id={info.id+'InputContainer'} onClick={handleGetFocused} className={classNames('form-container', {'focused' : focused === info.id}, (info.canWrite) ? 'section-w-size-'+(info.size+1) : 'section-w-size-'+info.size)}
        >
                <div className={'text-input-title'}>
                    <label htmlFor={info.id} >{info.display}</label>
                </div>
            <div className={classNames('switch-comment-container')}> 
                <div id={info.id+'Switch'} className={classNames('switch-container', {'large' : !info.canWrite}, {'focused' : focused === info.id})}>
                    <span className={classNames('switch-text', {'on' : !searchValue})}>
                        No
                    </span>
                    <Switch 
                        onValueChanged={handleOnChange}
                        switchedOffText={"No"}
                        switchedOnText={"Sí"}
                        hoverStateEnabled={false}
                        defaultValue={info.defaultValue} />
                    <span className={classNames('switch-text', {'on' : searchValue})}>
                        Sí
                    </span>
                </div>
                {(info.canWrite) &&
                    <div className={'search-comment-container'}>
                        <IndigoCommentInput id={info.id} isFocused={focused === info.id} getFocused={getFocused} valueToParent={handleCommentChange} defaultComment={info.canWriteDefault && switchValue[searchValue]}/>
                    </div>
                }
            </div>
                {editable && <ContextMenu
                    visible={contextOpen}
                    dataSource={[{ text: 'Editar ' + info.display, icon: 'rename' }]}
                    width={200}
                    target={"#"+info.id+'InputContainer'}
                    closeOnOutsideClick={true}
                    onItemClick={handleDeleteItem} />}

                {(cancelPopupVisible && editable) && <Popup
                    visible={cancelPopupVisible}
                    onHiding={closePopup}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title={"Editar "+info.display}
                    width={350}
                    height={350}
                    >
                    <div className={'cancel-popup'}>
                        <div className={'popup-section'}>
                            <div className={'popup-section-title'}>Tamaño</div>
                            <div className={'popup-section-content size-btns'}>
                                {inputSizes.map( (size, key ) => {
                                    return (
                                        <Button
                                            key={key}
                                            text={size}
                                            type={size === info.size ? "success" : "normal"}
                                            stylingMode={size === info.size ? "contained" : "outlined"}
                                            onClick={() => handleChangeSize(size)}
                                        /> 
                                    );
                                })
                                }
                            </div>
                        </div>
                        <div className={'cancel-buttons'}>
                            <Button
                            width={130}
                            icon={'check'}
                            text="Cerrar"
                            type="success"
                            stylingMode="contained"
                            onClick={closePopup}                        
                            />
                        </div>

                    </div>
                </Popup>}
        </div>
    )
}