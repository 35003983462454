import React, { Fragment, useEffect, useState } from 'react';
import DataGrid, { Selection, Paging, FilterRow, Pager, Column, Scrolling, Grouping } from 'devextreme-react/data-grid';
import { startLoadMedicationFhir } from '../../../../../../../redux/actions/mgmtPlan';
import { getDataStore } from './../../../../../../../utils/xpo';
import { startLoadMedicationStock } from './../../../../../../../redux/actions/mgmtPlan';
import { useDispatch } from 'react-redux';

export default function ManagementDropGrid(props) {

    const [canDrop, setCanDrop] = useState(false);
    const [contentShown, setContentShown] = useState(false);
    const [warningShown, setWarningShown] = useState(false);
    const [warning, setWarning] = useState(false);
    //const [dataSource, setDataSource] = useState([]);
    const [dose, setDose] = useState({ "medicine": "", "display": "", "type": ""});
    const dispatch = useDispatch();

    const mgmtDropRef = React.createRef();
    let mgmtParentRef;
    const classNames = require('classnames');
    const { opened, close, open, parent, getData, data, getReset, encounterInfo } = props;

    const store = getDataStore("/api/xpo/get/medications");

    const handleDocumentClick = (e) => {
        const target = e.target;
        const element = mgmtParentRef;
        const popup = document.getElementsByClassName('dx-scrollview-content')[0];
        const inputs = document.getElementsByClassName('mgmt-input-item');
        const drops = document.getElementsByClassName('mgmt-dropdown-container');
        let inputContains = [];
        let dropsContains = [];
        for (let i = 0; i < inputs.length; i++) {
            inputContains.push(inputs[i].contains(target));
        }
        for (let i = 0; i < drops.length; i++) {
            dropsContains.push(drops[i].contains(target));
        }
        if ((!element?.contains(target)
            ||
            element?.firstChild.contains(target))
            &&
            popup?.contains(target)
            &&
            !inputContains.includes(true)
            &&
            !dropsContains.includes(true)
        ) {
            close(parent);
            setWarningShown(false);
        }
    }

    const getCurrentData = () => {
        let dosis = dose;
        dosis.indications = data?.indication;
        dosis.display = data?.display;
        setDose(prev => prev = JSON.parse(JSON.stringify(data)));
    }

    useEffect(() => {
        mgmtParentRef = mgmtDropRef.current.parentElement;
    }, [])

    useEffect(() => {
        getCurrentData()
    }, [data?.display])

    useEffect(() => {
        if (opened) {
            setTimeout(() => {
                setCanDrop(true)
            }, 200)
            setTimeout(() => {
                setContentShown(true)
            }, 400)
        } else {
            setContentShown(false)
            setCanDrop(false)
        }
    }, [opened])

    useEffect(() => {
        if (warning) {
            setTimeout(() => {
                setWarningShown(true)
            }, 300)
        } else {
            setWarningShown(false)
        }
    }, [warning])

    useEffect(() => {
        setCanDrop(opened);
        setWarning(false);
        document.addEventListener('click', handleDocumentClick);
        startLoadMedicationFhir()
        return function cleanup() {
            document.removeEventListener('click', handleDocumentClick);
        }
    }, [])

    const getAvailableQuantity = async (code) => {
        let fd = new FormData();
        fd.append("NRO_REG_AMB", encounterInfo.idEncounterSIH);
        fd.append("COD_EMPRESA", encounterInfo.codeEnterprise);
        fd.append("COD_PRODUTO", code.split("-")[0]);
        fd.append("COD_APRES_PRD", code.split("-")[1]);
        dispatch(startLoadMedicationStock(fd));
    }

    const handleOnChange = async (value, key) => {
        let newDose = { ...dose, 'display': value?.selectedRowsData[0]?.Display, 'type': value?.selectedRowsData[0].TypeCode, [key]: { display: value?.selectedRowsData[0].Display, code: value?.selectedRowsData[0].Code +"-"+ value?.selectedRowsData[0].PresentationId} };
        setDose(newDose);
        save(newDose);
        await getAvailableQuantity(value?.selectedRowsData[0].Identifier);
    }

    const cancel = () => {
        const currentDose = dose.display
        const currentData = data.display
        if (currentDose === currentData) {
            close(parent);
        } else {
            setWarning(true);
        }
    }

    const save = (newDose) => {
        getData(parent, newDose || dose);
        setWarning(false);
        close(parent);
    }

    const reset = () => {
        let dosis = dose;
        dosis.quantity = "";
        dosis.unit = "";
        dosis.display = "";
        dosis.term = "";
        setDose(prev => prev = dosis)
    }

    const closeNoSave = () => {
        getCurrentData();
        setWarning(false);
        close(parent);
    }

    const canSave = () => {
        if (dose.indication !== "") {
            return false
        }
        else {
            return true
        }
    }

    return (
        <Fragment>
            <div id={parent + "MgtmDropdown"} ref={mgmtDropRef} style={{ minHeight: canDrop ? warning ? '445px' : '340px' : '0px' }} className={classNames('mgmt-dropdown-container service', { 'droped': canDrop })}>
                <div className={classNames('mgmt-dropdown-content', { 'droped': contentShown })}>
                    <div className={classNames('mgmt-dropdown-group-nopadding')}>
                        <div id={parent + 'UniteInputContainer'}>
                            <div className={classNames('mgmt-dropdown-label')}>
                                Medicamento
                            </div>
                            <DataGrid
                                showColumnHeaders={false}
                                dataSource={store}
                                remoteOperations= {{groupPaging: true}}
                                noDataText={'No hay coincidencias'}
                                onSelectionChanged={async (e) => await handleOnChange(e, "medicine")}
                                hoverStateEnabled={true}
                                height={300}
                                showBorders={true}
                            >
                                <Grouping autoExpandAll={true} /* allowCollapsing={true} expandMode={"rowClick"}  */ />
                                <Paging enabled={true} defaultPageSize={20}></Paging>
                                <Scrolling mode="infinite" />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[10, 20]}
                                    showInfo={true} />
                                <Column dataField="Identifier" caption="Código" width={'20%'} />
                                <Column dataField="Display" caption="Nombre medicamento" />
                                <Column dataField="TypeCode" groupIndex={0} caption="Tipo" />
                                <Selection mode="single"></Selection>
                                <FilterRow visible={true}></FilterRow>
                            </DataGrid>
                        </div>
                    </div>
                    {/* <div className={classNames('mgmt-dropdown-group-horizontal flex')}>
                            <div className={classNames('mgmt-input mgmt-cancel-btn')} onClick={cancel}>
                                CANCELAR
                            </div>
                            <div className={classNames('mgmt-input')}>
                                <Button 
                                    type={"default"}
                                    stylingMode={"contained"}
                                    text={'Aceptar'}
                                    onClick={save}
                                />
                            </div>
                    </div> */}
                    {warningShown &&
                        <div className={classNames('mgmt-dropdown-group-horizontal')}>
                            <div className={classNames('mgmt-dropdown-warning')}>
                                <i class="fas fa-exclamation-triangle"></i> Hay cambios sin guardar <br></br>
                                <span onClick={closeNoSave}>Cerrar sin guardar</span> | <span className={classNames({ 'warning-disabled': canSave() })} onClick={canSave() ? null : save}>Guardar y cerrar</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}