import { types } from "../types/types";


export const setValidationList = (list) => ({
    type: types.setValidationList,
    payload: list
    
})

export const addCompletedItem = (item) => ({
    type: types.addCompletedItem,
    payload: item
})

export const removeCompletedItem = (item) => ({
    type: types.removeCompletedItem,
    payload: item
})