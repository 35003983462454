import React, { Fragment, useCallback, useEffect, useState } from 'react';
import IndigoTextInput from './indigoTextInput.jsx';
import { useDispatch,useSelector } from 'react-redux';
import { setData } from '../../../../../redux/actions/parametrizer.js';

import Button from 'devextreme-react/button';
import ContextMenu from 'devextreme-react/context-menu';
import { Popup } from 'devextreme-react/popup';
import IndigoItemsList from './indigoItemsList.jsx';
import { IndigoSearchAsInput, IndigoSelectAsInput } from '../index.js';

export default function IndigoForm (props) {

    var classNames = require('classnames');

    const dispatch = useDispatch();
    const { data } = useSelector( state => state.parametrizer)
    const { responsive } = useSelector( state => state.ui)
    const [IMC, setIMC] = useState({height: 0, weight: 0, IMC: 0});
    const { info, children, getFocused, focused, granPaID, editable, render } = props;
    const [layout, setLayout] = useState({columns : [], rows: 1, phantomItem: 0});
    const [contextOpen, setContextOpen] = useState(null);
    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);
    const [inputSizes, setInputSizes] = useState([]);
    const [maxSizeDefault, setMaxSizeDefault] = useState({XL: info.size, L: (parseInt(info.size,10)-1), M: (parseInt(info.size,10)-2), S: 2, XS: 1});
    const [inputExpanded, setInputExpanded] = useState({ids: [], qty: 0})

    useEffect(() => {
        sizeConvert()
    },[])
    const windowSingin = () => {
        switch (responsive) {
            case "extralarge":
                setInputSizes([1,2,3,4,5]);
                updateSize(5);
                break;

            case "large":
                setInputSizes([1,2,3,4]);
                updateSize(4);
            break;

            case "medium":
                setInputSizes([1,2,3]);
                updateSize(3);
            break;

            case "small":
                setInputSizes([1,2]);
                updateSize(2);
            break;

            default:
                setInputSizes([1]);
                updateSize(1);
                break;
        }
        layouSizeUpdater()
        
        return window.screen.width
    }
    
    const updateSize = (size) => {
        const sizeCon = {
             "extralarge": maxSizeDefault.XL,
             "large": maxSizeDefault.L,
             "medium": maxSizeDefault.M,
             "small": maxSizeDefault.S,
             "extrasmall": maxSizeDefault.XS,
        }

        handleChangeSize(sizeCon[Object.keys(sizeCon).find( el => 
            el === responsive
        )])

        if(info.size > size) {
            handleChangeSize(size)
        }
    }

    useEffect(() => {
        windowSingin();
      }, [responsive]);

    const sizeConvert = () => {
        switch(info.size){
            case 1:
                setMaxSizeDefault({XL: info.size, L: 1, M: 1, S: 2, XS: 1});
                break;
            case 2:
                setMaxSizeDefault({XL: info.size, L: 2, M: 2, S: 2, XS: 1});
                break;
            case 3:
                setMaxSizeDefault({XL: info.size, L: 2, M: 2, S: 2, XS: 1});
                break;
            case 4:
                setMaxSizeDefault({XL: info.size, L: 3, M: 2, S: 2, XS: 1});
                break;
            default:
                setMaxSizeDefault({XL: info.size, L: 4, M: 3, S: 2, XS: 1});
                break;
        }
    }

    const formRef = React.createRef();
    let formChildren;
    
    useEffect(() => {
        formChildren = formRef.current
    },[])

    const handleDocumentClick = (e) => {
        const children = formChildren;
        if (children.contains(e.target)) {
            setContextOpen(false);
        } else {
            setContextOpen(null);
        }
    }

    useEffect(() => {
        const element = document.getElementById(info.id+"FormContainer") 
        element.addEventListener('contextmenu', handleDocumentClick);
     
        return function cleanup () {
            element.removeEventListener('contextmenu', handleDocumentClick);
        }
     }, [])

    const openPopup = () => {
        setCancelPopUpVisible(true)
    }

    const closePopup = () => {
        sizeConvert()
        setCancelPopUpVisible(false);
    }

    const handleDeleteItem = () => {
        openPopup()
        setContextOpen(false);
        setTimeout(
            () => {
                setContextOpen(null);
            },
            [300],
        );
    }

    const handleChangeSize = size => {
        const newData = data;        
        newData.some( data => {
            data.options.some( quest => {
                if(quest.id === info.id){
                    quest.size = size
                }
            })
        })
        dispatch( setData(newData))
    }

    const getInputExpanded = (itemId, status) => {
    }
    const layouSizeUpdater = () => {
        let columns = 0;
        let phantomItem = 0;
        const newData = data;
        newData.some( data => {
            data.options.some( quest => {
                if(quest.id === info.id){
                    quest.options.some( option => {
                            columns += option.size 
                    })
                }
            })
        })

        if(columns<=info.size) {
            phantomItem = (info.size - columns) / info.size
        } else {
            let phantomSize = 0;
            let phantomColumn = columns;
            while((phantomColumn + phantomSize) % info.size !== 0) {
                phantomSize += 1;
            }
            phantomItem = phantomSize/info.size
        }
        setLayout({columns: columns, rows: [1], phantomItem: phantomItem})
    }

    const updateList = (list) => {
        let newData = data;        
        newData.some( data => {
            data.options.some( quest => {
                if(quest.id === info.id){
                    quest.options = list
                }
            })
        })
        dispatch( setData(newData))
    }

    useEffect(() => {
        layouSizeUpdater();
    }, [data, inputSizes, inputExpanded])

    const getIMCHeight = (height)  => {
        setIMC(IMC => IMC = {...IMC, height: parseInt(height,10)})
    }

    const getIMCWeight = (weight)  => {
        setIMC(IMC => IMC = {...IMC, weight: parseInt(weight,10)})
    }

    useEffect(() => {
        const  result = IMC.weight / ((IMC.height*0.01)*(IMC.height*0.01));
        if(isNaN(result)) {
            setIMC(IMC => IMC = {...IMC, IMC: 0 })
        } else {
            setIMC(IMC => IMC = {...IMC, IMC: result.toFixed(1) })
        }
    }, [IMC.height, IMC.weight])

    return (
        <div id={info.id+"FormContainer"} className={classNames('form-container', 'section-w-size-'+(info.size))}>
            <div className={'text-input-title'}>
                <div>{info.display}</div>
            </div>
            <div ref={formRef} className={'form-content'}
                    style={{display: 'inline-flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                }}
                >
                {info.options.map((option, key) => {
                    return (<Fragment key={key}>
                                {(!["search", "select"].includes(option.type))&& <IndigoTextInput
                                    parentId={info.id}
                                    parentSize={info.size}
                                    display={option.display}
                                    description={option.description}
                                    inputType={option.type}
                                    getFocused={getFocused} 
                                    isFocused={focused === option.id} 
                                    canWrite={option.canWrite}
                                    questionNumber={option.questionNumber}
                                    focused={focused}
                                    options={option.options ? option.options : null}
                                    disable={true}
                                    id={option.id}
                                    size={option.size}
                                    maxChar={option.maxChar}
                                    minValue={option.minValue}
                                    maxValue={option.maxValue}
                                    granPaID={granPaID}
                                    editable={editable}
                                    render={render}
                                    getIMCHeight={getIMCHeight}
                                    getIMCWeight={getIMCWeight}
                                    IMC={IMC.IMC}
                                />}
                                {option.type === "search" && <IndigoSearchAsInput info={{id: option.id, display: option.display, questionNumber: option.questionNumber,
                                options: option.options ? option.options : [], size : option.size}} focused={focused} getFocused={getFocused}
                                parentSize={info.size} expanded={getInputExpanded} granPaID={granPaID} editable={editable} render={render}/>}
                                {option.type === "select" && <IndigoSelectAsInput info={{id: option.id, display: option.display, questionNumber: option.questionNumber,
                                options: option.options ? option.options : [], size : option.size}} focused={focused} getFocused={getFocused}
                                parentSize={info.size} expanded={getInputExpanded} granPaID={granPaID} editable={editable} render={render}/>}
                            </Fragment>
                        )
                    })}
                {
                    layout.phantomItem !== 0 && <div style={{width: (layout.phantomItem*98)+'%'}}></div>
                }
            </div>

            {editable && <ContextMenu
                visible={contextOpen}
                dataSource={[{ text: 'Editar ' + info.display, icon: 'rename' }]}
                width={200}
                target={"#"+info.id+"FormContainer"}
                closeOnOutsideClick={true}
                onItemClick={handleDeleteItem} />}

            {(cancelPopupVisible && editable) && <Popup
                visible={cancelPopupVisible}
                onHiding={closePopup}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showTitle={true}
                title={"Editar "+info.display}
                width={350}
                height={650}
                >
                <div className={'cancel-popup'}>

                    <div className={'popup-section'}>
                        <div className={'popup-section-title'}>Ordenar</div>
                        <div className={'popup-section-subtitle'}>Arrastra para ordenar</div>
                        <div className={'popup-section-content form-list'}>
                            <IndigoItemsList items={info.options} updateList={updateList}/>
                        </div>
                    </div>
                    <div className={'popup-section'}>
                        <div className={'popup-section-title'}>Tamaño</div>
                        <div className={'popup-section-subtitle'}>¿Cúantas columnas quieres que ocupe <b>{info.display}</b>?</div>
                        <div className={'popup-section-content size-btns'}>
                            {inputSizes.map( (size, key ) => {
                                return (
                                    <Button
                                        key={key}
                                        text={size.toString()}
                                        type={size === info.size ? "success" : "normal"}
                                        stylingMode={size === info.size ? "contained" : "outlined"}
                                        onClick={() => handleChangeSize(size)}
                                    /> 
                                );
                            })
                            }
                        </div>
                    </div>
                    <div className={'cancel-buttons'}>
                        <Button
                        width={130}
                        icon={'check'}
                        text="Cerrar"
                        type="success"
                        stylingMode="contained"
                        onClick={closePopup}                        
                        />
                    </div>

                </div>
            </Popup>}
        </div>
    );
}