import React from 'react'
import { types } from '../types/types';

const initialState = {
    fhir: {}
}

export const autoSaveReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case types.setAutoSaveGeneralInput: 
            return {
                ...state,
                [action.payload?.encounterId]: {...state[action.payload?.encounterId], [action.payload?.id] : {value: action.payload?.value, comment: action.payload?.comment}}
            }

        case types.setAutoSaveSelectInput: 
            return {
                ...state,
                [action.payload?.encounterId]: {...state[action.payload?.encounterId], [action.payload?.id] : {value: action.payload?.value, comment: action.payload?.comment}}
            }

        case types.setAutoSaveSearchInput: 
            return {
                ...state,
                [action.payload?.encounterId]: {...state[action.payload?.encounterId], [action.payload?.id] : {items: action.payload?.items, comments: action.payload?.comments}}
            }

        case types.resetAutoSave: 
            return {
                ...state, fhir: {}
            }

        case types.setAutoSaveFHIR: 
            return {
                ...state,
                fhir: {...action.payload},
            }

        default:
            return state;
    }
    
}
// const initialState = {
//     generalInput: {},
//     selectInput: {},
//     searchInput: {},
//     fhir: {}
// }

// export const autoSaveReducer = ( state = initialState, action ) => {
//     switch (action.type) {
//         case types.setAutoSaveGeneralInput: 
//             return {
//                 ...state,
//                 generalInput: {...state.generalInput ,[action.payload?.id] : {value: action.payload?.value, comment: action.payload?.comment}}
//             }

//         case types.setAutoSaveSelectInput: 
//             return {
//                 ...state,
//                 selectInput: {...state.selectInput, [action.payload?.id] : {value: action.payload?.value, comment: action.payload?.comment}}
//             }

//         case types.setAutoSaveSearchInput: 
//             return {
//                 ...state,
//                 searchInput: {...state.searchInput, [action.payload?.id] : {items: action.payload?.items, comments: action.payload?.comments}}
//             }

//         case types.resetAutoSave: 
//             return {
//                 ...state, generalInput: {}, selectInput: {}, searchInput: {}, fhir: []
//             }

//         case types.setAutoSaveFHIR: 
//             return {
//                 ...state,
//                 fhir: {...action.payload},
//             }

//         default:
//             return state;
//     }
    
// }
