import React, { Fragment } from 'react';
import IndigoLogo from './../../../assets/img/logos/indigoLogo.jpeg'

export default function SideNavigationContainer (props) {

    const {
        children,
      } = props;

    return (
        <Fragment>
            <div
            className={'menu-button-container'}
            >
                {children}
            </div>
            {/* <div className={'header-logo'}>
                <img src={IndigoLogo} width={150} alt={'Indigo Logo'}/>
            </div> */}
        </Fragment>
    );
}