import { types } from "../types/types";


const initialState = {
    activeAppointment: {
        patient: 'idPatient',
        practitioner: 'idPract',
        startDate: new Date()
    }
}
/**
 * {   
            id: 'abcasdas',
            title: 'ReviewTraining Course',
            start: moment().toDate(),  //newDate
            end: moment().add( 2, 'hours' ).toDate(),
            ficha: 'Comprar el pastel',
            historialMedico: 'view historial',
            user: {
                _id: '123',
                name: 'Stiven'
            }
    } 
 **/

export const patientReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case types.appointmentSetActive:
            return {
                ...state,
                activeAppointment: action.payload
            }

        case types.clearActiveAppointment: 
            return {
                ...state,
                activeAppointment: null
        }   
            
        default:
            return state;
    }
}