import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomStore from 'devextreme/data/custom_store';

import './historial.scss';
import Accordion from 'devextreme-react/accordion';
import Form, {
    SimpleItem,
    GroupItem
} from 'devextreme-react/form';

import MedicalHistoryDataGrid from './panel/MedicalHistoryDataGrid';
import { handleHistoricPagination, setHistoricalData, startGetComposition, startGetCompositionSIH } from './../../redux/actions/clinicHistory';
import { getAge } from './../../utils/generic';
import { LoadPanel } from 'devextreme-react/load-panel';
import notify from 'devextreme/ui/notify';
import TabPanel from 'devextreme-react/tab-panel';
import { Button } from 'devextreme-react/button';
import FloatingButton from './FloatingButton';
import Box, { Item } from 'devextreme-react/box';
import MedicalHistoryDataGridSIH from './panel/MedicalHistoryDataGridSIH';
import { error } from 'jquery';

export default function HistorialPage(props) {
    const dispatch = useDispatch();
    const { patient: patientInfoProntuar, compositions, pagination } = useSelector(state => state.clinicHistory.patientInfoProntuar);
    const { loading } = useSelector(state => state.ui);
    const [paginationUrls, setPaginationUrls] = useState({before: "", after: ""});
    const validForms = [450, 451, 452, 810, 811, 812, 1000];
    const history = useHistory();

    const genders = { male: "Masculino", female: "Femenino" }

    const store = new CustomStore({
        key: 'ID',
        load: function (loadOptions) {
            return fetch("/api/clinicalreport/before/sih/" + patientInfoProntuar?.identifier[0].value)
                .then(response => {
                    if (response.ok) { return response.json(); }
                    else {
                        throw response.json();
                    }
                }).then((data) => {
                    console.log("data", data)
                    if (data.Message != "" && data.Source == "IndigoVieWeb.Backend") {
                        console.error(data.Message)
                        return null;
                    }
                    let rows = data.map(comp => {
                        let res = {};
                        res["ID"] = comp?.NRO_REG_AMB;
                        res["TipoAtencion"] = comp?.NRO_REG_AMB === null ? "Intrahospitalario" : "Ambulatorio";
                        res["NombreDoctor"] = comp?.NM_MEDICO;
                        res["Especialidad"] = comp?.NM_ESPEC_MED;
                        res["FechaAtencion"] = comp?.DTHR_REALIZ;
                        res["FichaNombre"] = comp?.NM_TP_FCLN;
                        res["CodEmpresa"] = comp?.COD_EMPRESA;
                        res["CodEspecialidad"] = comp?.COD_ESPEC_MD;
                        res["CodMedico"] = comp?.COD_MEDICO;
                        res["CodFicha"] = comp?.COD_TP_FCLN;
                        res["IDIntra"] = comp?.NRO_REG_INT;
                        return res;
                    });

                    let obj = { data: rows, totalCount: rows.length };
                    return obj;
                })
                .catch((ex) => { console.log(ex); throw 'Data Loading Error ' + ex.Message; });
                //.catch((ex) => { console.log(ex); throw 'Data Loading Error'; });
        }
    });


    let Telefono = [];
    if (patientInfoProntuar?.telecom[0]?.value) {
        Telefono.push(patientInfoProntuar?.telecom[0]?.value);
    }
    if (patientInfoProntuar?.telecom[1]?.value) {
        Telefono.push(patientInfoProntuar?.telecom[1]?.value);
    }

    Telefono = Telefono.filter(item => item).join(" / ");

    const [patienInfoBase, setPatienInfoBase] = useState([{
        ID: patientInfoProntuar?.identifier[1].value,
        Nombre: patientInfoProntuar?.name[0].text,
        Prontuario: patientInfoProntuar?.identifier[0].value,
        FechaNacimiento: patientInfoProntuar?.birthDate,
        Edad: getAge(patientInfoProntuar?.birthDate),
        Genero: genders[patientInfoProntuar?.gender],
        Telefono: Telefono || "No registra",
        Empresa: "Indira healthtech",
        Convenio: 1285
    }])

    const [gridSize, setGridSize] = useState(300);
    const [gridSIHSize, setGridSIHSize] = useState(300);
    const [tabSize, setTabSize] = useState(350);

    /* useEffect(() => {
        calculateGridSize()
    }, []) */

    /* const tabSelectionChanged = (args) => {
        //console.log("selectedIndex", args.itemIndex)
        setSelectedIndex(args.itemIndex);
    } */

    const historic = compositions.map(composition => {
        let res = {};
        res["ID"] = composition?.id;
        res["NombreDoctor"] = composition?.author;
        res["Especialidad"] = composition?.specialty;
        res["FechaAtencion"] = composition?.date;
        res["FichaNombre"] = composition?.title;
        res["TipoAtencion"] = composition?.type?.coding[1]?.display
        return res;
    });

    function CustomItem(patientInfo) {
        return (
            <Form formData={patientInfo} labelLocation="left">
                <GroupItem colCount={3} >
                    <SimpleItem dataField="Nombre" disabled />
                    <GroupItem colCount={2} >
                        <SimpleItem dataField="ID" disabled />
                        <SimpleItem dataField="Prontuario" disabled />
                    </GroupItem>
                    <SimpleItem dataField="Edad" disabled />
                    <SimpleItem dataField="Genero" disabled />
                    <SimpleItem dataField="Telefono" disabled />
                    <SimpleItem dataField="FechaNacimiento" editorType="dxDateBox" disabled editorOptions={{ dateSerializationFormat: "dd/MM/yyyy" }} />
                    {/*< SimpleItem dataField="Empresa" disabled />
                    <SimpleItem dataField="Convenio" disabled /> */}
                </GroupItem>
            </Form>
        );
    }

    function CustomTitle() {
        return (
            <h6 style={{ margin: 0 }}>Informacion del paciente</h6>
        );
    }

    async function getMedicalRecordComposition(ID) {
        return dispatch(startGetComposition(ID));
    }

    async function getMedicalRecordCompositionSIH(data) {
        const formData = new FormData();

        let clinical_record_data = {
            "COD_EMPRESA": data.CodEmpresa,
            "COD_ESPEC_MD": data.CodEspecialidad,
            "COD_MEDICO": data.CodMedico,
            "COD_TP_FCLN":data.CodFicha,
            "DTHR_REALIZ":data.FechaAtencion,
            "NRO_REG_AMB": data.ID,
            "NRO_REG_INT": data.IDIntra
        }
      
        formData.append("clinical_record_data",JSON.stringify(clinical_record_data))
     
        return dispatch(startGetCompositionSIH(formData));
    }

    async function handleOpenMedicalRecordSIH(rowData) {
        let data = rowData.data || rowData.row.data;
        let comp;
        let fromType = validForms.includes(parseInt(data.FichaNombre.replace("Ficha ", ""))) ? 0 : 1;
        let medicalRecordInfo = { ...data };
        //console.log("Historial Clínico: ", patienInfoBase[0], medicalRecordInfo)
        dispatch(setHistoricalData(patienInfoBase[0], medicalRecordInfo));
        comp = await getMedicalRecordCompositionSIH(data);
        if (comp) {
            history.push('/preview/' + fromType);
        } else {
            notify({
                message: 'Error al consultar la ficha medica',
                width: 10,
                position: {
                    my: 'center top',
                    at: 'center top',
                }
            }, 'error', 2000);
        }
    }

    async function handleOpenMedicalRecord(rowData) {
        let data = rowData.data || rowData.row.data;
        let comp;
        let fromType = validForms.includes(parseInt(data.FichaNombre.replace("Ficha ", ""))) ? 0 : 1;
        let medicalRecordInfo = { ...data };
        //console.log("Historial Clínico: ", patienInfoBase[0], medicalRecordInfo)
        dispatch(setHistoricalData(patienInfoBase[0], medicalRecordInfo));
        comp = await getMedicalRecordComposition(data.ID);
        if (comp) {
            history.push('/preview/' + fromType);
        } else {
            notify({
                message: 'Error al consultar la ficha medica',
                width: 10,
                position: {
                    my: 'center top',
                    at: 'center top',
                }
            }, 'error', 2000);
        }
    }

    const [selectedItems, setSelectedItems] = useState([patienInfoBase[0]]);

    const selectionChanged = (e) => {
        let newItems = [...selectedItems];
        e.removedItems.forEach((item) => {
            let index = newItems.indexOf(item);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        });
        if (e.addedItems.length) {
            newItems = [...newItems, ...e.addedItems];
        }
        setSelectedItems(newItems);
        calculateGridSize();
    };

    const itemTitleRender = (item) => {
        return <span>{item.text}</span>;
    }
    
    useEffect(() => {
        if(pagination.length === 1) {
            localStorage.setItem('historicPagination', JSON.stringify({after: null, before: null, beforeHistory: [], currentIndex: 0}));
        } else {
            let before = null;
            const after = pagination.find(page => page.relation === "next");
            localStorage.setItem('historicPagination', JSON.stringify({after: after?.url, before: before, beforeHistory: [], currentIndex: 0}));
        }
    }, [])

    const handlePaginationNext = () => {
        let currentPagination = JSON.parse(localStorage.getItem('historicPagination'));
        let before = pagination.find(page => page.relation === "self")?.url;
        if(!currentPagination?.beforeHistory.includes(before)) {
            currentPagination?.beforeHistory?.push(before);
        } 
        currentPagination.before = before;
        currentPagination.currentIndex += 1;
        localStorage.setItem('historicPagination', JSON.stringify(currentPagination));
        dispatch(handleHistoricPagination(currentPagination?.after));
    }

    const handlePaginationBefore = () => {
        let currentPagination = JSON.parse(localStorage.getItem('historicPagination'));
        let beforeHistory = currentPagination?.beforeHistory;
        let before = null;
        const after = pagination.find(page => page.relation === "next");
        currentPagination.currentIndex -= 1;
        console.log("Current UrL", beforeHistory, beforeHistory[currentPagination.currentIndex]);
        dispatch(handleHistoricPagination(beforeHistory[currentPagination.currentIndex]));
        localStorage.setItem('historicPagination', JSON.stringify(currentPagination));
    }

    const itemComponent = React.memo((item) => {
        const localHistoryPagination = JSON.parse(localStorage.getItem('historicPagination'));
        //console.log("itemComponent", item)
        if (item.data.id == '1') {
            return <>
                {/* pagination.length > 1 &&  */<div id="pagination-historic" style={{ backgroundColor: '#f5f5f5', padding: "10px", margin: "5px 0px 0px 0px" }}>
                    <Box
                        direction="row"
                        width="100%"
                        height={36}>
                        <Item ratio={1}>
                            <Button
                                text={"Anterior"}
                                type={"default"}
                                stylingMode={"contained"}
                                disabled={localHistoryPagination?.currentIndex === 0}
                            onClick={handlePaginationBefore}
                            />
                        </Item>
                        <Item ratio={2}>
                            <div>
                            </div>
                        </Item>
                        <Item ratio={1}>
                            <Button
                                text={"Siguiente"}
                                type={"default"}
                                stylingMode={"contained"}
                                disabled={localHistoryPagination?.after === null}
                            onClick={handlePaginationNext}
                            />
                        </Item>
                    </Box>
                </div>}
                <MedicalHistoryDataGrid
                    gridSize={gridSize}
                    dataSource={historic}
                    validForms={validForms}
                    handleOpenMedicalRecord={handleOpenMedicalRecord}
                    loading
                />
            </>
        } else {
            return <>
                <MedicalHistoryDataGridSIH
                    gridSize={gridSIHSize}
                    dataSource={store}
                    handleOpenMedicalRecord={handleOpenMedicalRecordSIH}
                    loading
                />
            </>;
        }
    })

    const calculateGridSize = () => {
        setTimeout(() => {
            try {
                //console.log("calculating grid size")
                const headerSize = parseFloat(document.getElementsByClassName("header-toolbar dx-toolbar dx-widget dx-visibility-change-handler dx-collection")[0]?.offsetHeight || 0);

                const titleSize = parseFloat(document.getElementById("text-title").offsetHeight || 0);

                const accordionComponent = document.getElementsByClassName("dx-accordion dx-widget dx-visibility-change-handler dx-collection")[0];
                const accordeonCS = window.getComputedStyle(accordionComponent)
                const accordeonSize = parseFloat(accordionComponent.offsetHeight) + parseFloat(accordeonCS.marginTop.replace("px", "")) + parseFloat(accordeonCS.marginBottom.replace("px", ""));

                const paginationComponent = document.getElementById("pagination-historic");
                let paginationCS, paginationSize = 0;
                if (paginationComponent) {
                    paginationCS = window.getComputedStyle(paginationComponent)
                    paginationSize = parseFloat(paginationComponent.offsetHeight) + parseFloat(paginationCS.marginTop.replace("px", "")) + parseFloat(paginationCS.marginBottom.replace("px", ""));

                }

                const tabPanelComponent = document.getElementsByClassName("dx-tabpanel-tabs")[0];
                const tabPanelCS = window.getComputedStyle(tabPanelComponent)
                const tabPanelSize = parseFloat(tabPanelComponent.offsetHeight) + parseFloat(tabPanelCS.marginTop.replace("px", "")) + parseFloat(tabPanelCS.marginBottom.replace("px", ""));

                const totalsize = document.getElementsByTagName("Body")[0]?.offsetHeight;

                const gridSize = totalsize - (headerSize + titleSize + accordeonSize + paginationSize + tabPanelSize);
                setGridSize(gridSize - 50);
                setGridSIHSize((gridSize - 50) + paginationSize);
                setTabSize(gridSize + paginationSize);
            } catch (error) {
                console.error("Error", error);
            }
        }, 500);
    }

    return (
        <>
            {
                loading
                    ? <LoadPanel visible={loading} />
                    :
                    <div className={'p5'}>
                        <h1 id="text-title" className={'content-block page-title'}>Historial Clínico</h1>

                        <Accordion
                            dataSource={patienInfoBase}
                            collapsible={true}
                            animationDuration={300}
                            selectedItems={selectedItems}
                            onSelectionChanged={selectionChanged}
                            itemTitleRender={CustomTitle}
                            itemRender={CustomItem}
                            onItemRendered={calculateGridSize}
                        />
                        <TabPanel
                            height={tabSize}
                            dataSource={[{ text: 'Actual OneView', id: '1' }, { text: 'Anterior SIH', id: '2' }]}
                            //selectedIndex={parseInt(selectedIndex)}
                            //onItemClick={tabSelectionChanged}
                            onItemClick={calculateGridSize}
                            loop={false}
                            itemTitleRender={itemTitleRender}
                            itemComponent={itemComponent}
                            animationEnabled={false}
                            swipeEnabled={false}
                        />
                        <FloatingButton classButton="floating-button-viewer" />
                        {/* <Footer position={'fixed'} align={'start'} /> */}
                    </div>
            }
        </>
    );
};
