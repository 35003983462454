import { types } from '../types/types';

const initialState = {
    hcData: [], 
    itemsList : [], 
    mcData: []
}

export const hcDataReducer = ( state = initialState, action) => {

    switch ( action.type ) {
        case types.setHcData:
            
            return {
                ...state,
                hcData: action.payload.data,
            }

        case types.setMcData:
        
            return {
                ...state,
                mcData: action.payload.data,
            }

        case types.setItemsList:
        
            return {
                ...state,
                itemsList: action.payload.data,
            }

        default:
            return state;
    }
}