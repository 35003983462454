import React from 'react'

export default function Card(props){
    const { title, info} = props;
    return (
        <div  className="card-container">
            <div className="card-content">
                <div className="card-title">
                    {title}
                </div>
                <div className="card-text">
                    {info}
                </div>
            </div>
        </div>
    )
}
