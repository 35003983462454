import moment from "moment";
import config from '../config';

export const getAge = (birthd) => {
    let age, edad;
    var a = moment(new Date());
    var b = moment(birthd);

    var years = a.diff(b, 'year');
    b.add(years, 'years');

    var months = a.diff(b, 'months');
    b.add(months, 'months');

    var days = a.diff(b, 'days');

    age = { years, months, days };
    edad = age.years + ' años, ' + age.months + ' meses, ' + age.days + ' días';
    return edad;
}

export const openAgfaViewer = (prontuario) => {
    return window.open(config.DirectAgfaViewer.replace("{0}", prontuario));
}