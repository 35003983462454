export const localPanel = [
    // Anamnesis
    {
        id: 'anamnesis',
        display: 'Anamnesis',
        options: []
    },
    {
        id: 'historicParaclinics',
        display: 'Valoración paraclínicos histórico',
        options: [],
    },
    // Revisión por sistemas
    {
        id: 'paraclinics',
        display: 'Valoración de paraclínicos',
        options: [],
    },
    // Antecedentes
    {
        id: 'sysReview',
        display: 'Revisión por sistemas',
        options: [],
    },
    // Exámen Físico
    {
        id: 'periodontogram',
        display: 'Periodontograma',
        options: [],
    },
    // Información General
    {
        id: 'generalInfo',
        display: 'Información médica general',
        options: [],
    },
    // Antecedentes
    {
        id: 'records',
        display: 'Antecedentes',
        options: [],
    },
    {
        id: 'aiepi',
        display: 'AIEPI',
        options: [],
    },
    {
        id: 'analysis',
        display: 'Analisis',
        options: [],
    },
    {
        id: 'scales',
        display: 'Escalas',
        options: [],
    },
    {
        id: 'diagnosticImpression',
        display: 'Impresión diagnóstica',
        options: [],
    },
    {
        id: 'mgmtPlan',
        display: 'Plan de manejo',
        options: [{
            id: 'prescriptions',
            display: 'Prescripciones',
            type: 'form',
            size: 5,
            options: [
                // Opciones Sigos Vitales
                {
                    id: 'medicines',
                    display: 'Medicamento',
                    description: 'Medicamento',
                    canDrop: true,
                    type: 'text',
                    canWrite: false,
                    maxChar: 3,
                    value: null,
                    size: 1,
                    message: '',
                },
                {
                    id: 'dose',
                    display: 'Dosis',
                    description: 'Dosis',
                    type: 'int',
                    canDrop: false,
                    canWrite: false,
                    maxChar: 3,
                    value: null,
                    size: 1,
                    message: '',
                },
                {
                    id: 'meaUnit',
                    display: 'Unidad medida',
                    description: 'Unidad medida',
                    type: 'text',
                    canWrite: false,
                    canDrop: true,
                    maxChar: 3,
                    value: null,
                    size: 1,
                    message: '',
                },
                {
                    id: 'freq',
                    display: 'Frecuencia',
                    description: 'Frecuencia',
                    type: 'text',
                    canWrite: false,
                    maxChar: [3,2],
                    value: null,
                    canDrop: true,
                    size: 1,
                    message: '',
                },
                {
                    id: 'term',
                    display: 'Duración',
                    description: 'Duración',
                    type: 'text',
                    canWrite: false,
                    maxChar: [3,2],
                    value: null,
                    size: 1,
                    canDrop: true,
                    message: '',
                }
            ]},
                {
                    id: 'images',
                    display: 'Imágenes',
                    type: 'form',
                    size: 5,
                    options: [
                        // Opciones Sigos Vitales
                        {
                            id: 'imgService',
                            display: 'Servicio',
                            description: 'Servicio',
                            type: 'text',
                            canWrite: false,
                            maxChar: 3,
                            value: null,
                            size: 1,
                            message: '',
                        },
                        {
                            id: 'imgPriority',
                            display: 'Prioridad',
                            description: 'Prioridad',
                            type: 'text',
                            canWrite: false,
                            maxChar: 3,
                            value: null,
                            size: 1,
                            message: '',
                        },
                        {
                            id: 'imgQuantity',
                            display: 'Cantidad',
                            description: 'Cantidad',
                            type: 'text',
                            canWrite: false,
                            maxChar: 3,
                            value: null,
                            size: 1,
                            message: '',
                        }
                    ]},
                    {
                        id: 'laboratories',
                        display: 'Otros Servicios',
                        type: 'form',
                        size: 5,
                        options: [
                            // Opciones Sigos Vitales
                            {
                                id: 'labService',
                                display: 'Servicio',
                                description: 'Servicio',
                                type: 'text',
                                canWrite: false,
                                maxChar: 3,
                                value: null,
                                size: 1,
                                message: '',
                            },
                            {
                                id: 'labPriority',
                                display: 'Prioridad',
                                description: 'Prioridad',
                                type: 'text',
                                canWrite: false,
                                maxChar: 3,
                                value: null,
                                size: 1,
                                message: '',
                            },
                            {
                                id: 'labQuantity',
                                display: 'Cantidad',
                                description: 'Cantidad',
                                type: 'text',
                                canWrite: false,
                                maxChar: 3,
                                value: null,
                                size: 1,
                                message: '',
                            }
                        ]},
                    {
                        id: 'interencounter',
                        display: 'Interconsultas',
                        type: 'form',
                        size: 5,
                        options: [
                            // Opciones Sigos Vitales
                            {
                                id: 'interEspeciality',
                                display: 'Especialidad',
                                description: 'Especialidad',
                                type: 'text',
                                canWrite: false,
                                maxChar: 3,
                                value: null,
                                size: 1,
                                message: '',
                            },
                            {
                                id: 'interEspecialist',
                                display: 'Especialista',
                                description: 'Especialista',
                                type: 'text',
                                canWrite: false,
                                maxChar: 3,
                                value: null,
                                size: 1,
                                message: '',
                            },
                            {
                                id: 'interPriority',
                                display: 'Prioridad',
                                description: 'Prioridad',
                                type: 'text',
                                canWrite: false,
                                maxChar: 3,
                                value: null,
                                size: 1,
                                message: '',
                            },
                            {
                                id: 'interObservation',
                                display: 'Obervación',
                                description: 'Observación',
                                type: 'text',
                                canWrite: false,
                                maxChar: 3,
                                value: null,
                                size: 1,
                                message: '',
                            }
                        ]},
        ],
    },
    {
        id: 'patientDestination',
        display: 'Destino del paciente',
        options: [],
    },
    {
        id: 'pysicExamsSec',
        display: 'Examen físico',
        options: [],
    },
    {
        id: 'objetiveAnlysis',
        display: 'Objetivo - Análisis',
        options: [{
            id: 'phyExam75Da',
            size: 5,
            display: 'Test',
            type: 'form',
            options: [],
        },],
    },
    {
        id: 'subjetive',
        display: 'Subjetivo',
        options: [],
    },
]