import moment from 'moment';
import { getServerTime, getAllProductivity, getAllProductivitySpecialty, getProductivityTabular, getTemplateBySpecialty, productivityFilterByMonth, productivityGetByTemplate, productivityGetDateInformation } from "../../utils/helpersPoints";
import { types } from "../types/types"
import { finishLoading, startLoading } from "./ui";



export const startLoadingAllProductivity = () => {
    return async(dispatch) => {
        dispatch(startLoading());
        try {
            const allProductivity = await getAllProductivity();
            if (allProductivity) {
                dispatch(setAllProductivity(allProductivity))
            }
        } catch (error) {
            console.log('error: ', error);
        }
        dispatch(finishLoading());
    }
}

export const startLoadingProductivityBySpecialty = (codeSIH, startDate=null, endDate=null, spcialty=null) => {
    console.log('codeSIH: ', codeSIH, 'startDate: ', startDate, 'endDate: ', endDate, 'spcialty: ', spcialty);
    return async(dispatch) => {
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {
            if (!startDate) {
                console.log('Entre a start Null ! ');
                const getDateInformation = await productivityGetDateInformation();
                const { StartDateMonth, EndDateMonth } = getDateInformation;
    
                let form_data = new FormData();
                form_data.append("start_date", moment(StartDateMonth).format('YYYY-MM-DD'))
                form_data.append("end_date", moment(EndDateMonth).format('YYYY-MM-DD'))
                form_data.append("code_doctor", codeSIH)
                form_data.append("specialty", spcialty) // Codigo de la especialidad
    
                const productivity = await getAllProductivitySpecialty(auth, form_data);
                // console.log('Productivity Especialty: ', productivity);
                if (productivity) {
                    dispatch(setProductivityBySpecialty(productivity));
                }
            }else{
                console.log('Fecha recibida!!!');
                let form_data = new FormData();
                form_data.append("start_date", moment(startDate).format('YYYY-MM-DD'))
                form_data.append("end_date", moment(endDate).format('YYYY-MM-DD'))
                form_data.append("code_doctor", codeSIH)
                form_data.append("specialty", spcialty) // Codigo de la especialidad
    
                const productivity = await getAllProductivitySpecialty(auth, form_data);
                // console.log('Productivity Especialty: ', productivity);
                if (productivity) {
                    dispatch(setProductivityBySpecialty(productivity));
                }
            }
        } catch (error) {
            console.log('error: ', error);
        }
        dispatch(finishLoading());
    }
}

export const startLoadingProductivityByTemplate = ( codeSIH, startDate=null, endDate=null, template=null) => {
   return async (dispatch) => {
       const auth = sessionStorage.getItem('auth');
       console.log(codeSIH, startDate, endDate, 'template: ', template);
       dispatch(startLoading());
       try {
           if (!startDate) {
               const getDateInformation = await productivityGetDateInformation();
               const { StartDateMonth, EndDateMonth } = getDateInformation;
    
               let form_data2 = new FormData();
                form_data2.append("start_date", moment(StartDateMonth).format('YYYY-MM-DD'))
                form_data2.append("end_date", moment(EndDateMonth).format('YYYY-MM-DD'))
                form_data2.append("code_doctor", codeSIH)
                form_data2.append("template", template) // Codigo de la ficha
                const productivityTemplate = await productivityGetByTemplate(auth, form_data2);
                // console.log('productivityTemplate;; ', productivityTemplate)
                if (productivityTemplate) {
                    dispatch(setProductivityByTemplate(productivityTemplate));
                }
           }else{
                let form_data2 = new FormData();
                form_data2.append("start_date", moment(startDate).format('YYYY-MM-DD'))
                form_data2.append("end_date", moment(endDate).format('YYYY-MM-DD'))
                form_data2.append("code_doctor", codeSIH)
                form_data2.append("template", template) // Codigo de la ficha
                const productivityTemplate = await productivityGetByTemplate(auth, form_data2);
                // console.log('productivityTemplate;; ', productivityTemplate)
                if (productivityTemplate) {
                    dispatch(setProductivityByTemplate(productivityTemplate));
                }
           }
       } catch (error) {
            console.log('error: ', error);
       }
       dispatch(finishLoading());

   }
}

export const setProductivityFichas = (specialty=null) => {
    const auth = sessionStorage.getItem('auth');
    return async(dispatch)=>{
        if (specialty) {
            const fichas2 = await getTemplateBySpecialty(auth, specialty);
            console.log(specialty);
            console.log('fichass: ', fichas2);
            const optsFichas = fichas2?.map( fich => {
                return {
                    code: JSON.parse(fich.Data).id,
                    id: fich.RowKey,
                    text: fich.Name,
                    canWrite: false,
                }
            });
            console.log('optsFichas: ', optsFichas);
            dispatch(setFichas(optsFichas));
        }
    }
}

const setFichas=(data)=>({
    type: types.setProductivityFichas,
    payload: data
});

export const startLoadingProductivityTabular = (codeSIH) => {
    return async(dispatch)=> {
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {
            const getDateInformation = await productivityGetDateInformation();
            const { StartDateMonth, EndDateMonth } = getDateInformation;
    
            let form_data = new FormData();
            form_data.append("start_date", moment(StartDateMonth).format('YYYY-MM-DD'))
            form_data.append("end_date", moment(EndDateMonth).format('YYYY-MM-DD'))
            form_data.append("code_doctor", codeSIH);
    
            const productivityTabular = await getProductivityTabular(auth, form_data);
            if (productivityTabular) {
                dispatch(setProductivityTabular(productivityTabular));
            }
        } catch (error) {
            console.log('error: ', error);
        }
        dispatch(finishLoading());
    }
}


export const startProductivityFilterByMonth = ( codeSIH, start, end) => {
    return async(dispatch)=> {
        console.log('startt: ', start, 'endd: ', end);
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {    
            let form_data = new FormData();
            form_data.append("start_date", start)
            form_data.append("end_date", end)
            form_data.append("code_doctor", codeSIH);
    
            const productivityByMonth = await productivityFilterByMonth(auth, form_data);
            if (productivityByMonth) {
                dispatch(setProductivityTabular(productivityByMonth));
            }
        } catch (error) {
            console.log('error: ', error);
        }
        dispatch(finishLoading());
    }
}

export const setServerTime = () => {
    const auth = sessionStorage.getItem('auth');
    return async(dispatch)=>{
        const time = await getServerTime(auth);
        dispatch(setTime(time));
    }
}

const setTime=(data)=>({
    type: types.setServerTime,
    payload: data
});

const setAllProductivity = (data) => ({
    type: types.setAllProductivity,
    payload: data
});

const setProductivityBySpecialty = (data) => ({
    type: types.setProductivityBySpecialty,
    payload: data
});

const setProductivityByTemplate =(data) => ({
    type: types.setProductivityByTemplate,
    payload: data
});

const setProductivityTabular = (data) => ({
    type: types.setProductivityTabular,
    payload: data
});