import React, {Fragment, useState, useEffect} from 'react';

function IndigoSearchDrop (props) {

    const { items, onItemClick, onDeleteItem, onClickOutside, arrowDropIndex, parentId, selectList } = props;
    const [showTooltip, setShowTooltip] = useState({toggle: false, item: '', description: ''})
    const [selList, setSelList] = useState({start: 0, end: 0});
    const [dataSource, setDataSource] = useState(items);
    const classNames = require('classnames');
    const $ = require('jquery');


    const toggleEnterTooltip = (id, description) => {
        setShowTooltip({...showTooltip, toggle: true, item: id, description: description});
    }

    const toggleLeaveTooltip = () => {
        setShowTooltip({...showTooltip, toggle: false, item: '', description: ''});
    }

    const dropRef = React.createRef();
    let dropParent;
    useEffect(() => {
        dropParent = dropRef.current.parentNode;
    },[])

    const handleDocumentClick = (e) => {
        if (e && e.which === 3) return;
        const container = dropParent;
        if (!container.contains(e.target)) {
            onClickOutside();
            return onClickOutside;
        }
    }

    useEffect(() => { 
        document.addEventListener('click', handleDocumentClick);
        document.addEventListener('scroll', noScrollOnDropdown);
        return function cleanup () {
            document.removeEventListener('click', handleDocumentClick);
            document.removeEventListener('scroll', noScrollOnDropdown);
        }
     }, [])

    const noScrollOnDropdown = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        window.onscroll = function() {
            window.scrollTo(scrollTop);
        };
    }

     useEffect(() => {
        const allItems = document.getElementsByClassName('search-dropdown-item');
        if(selectList.start > -1 && selectList.end > -1) {
            if(!document.getElementById(parentId+"SearchDrop")) return;
            if(arrowDropIndex === -1) return;
            if(selectList.start === selectList.end) {
                const elementTop = allItems[arrowDropIndex].offsetTop;
                document.getElementById(parentId+"SearchDrop").scrollTop = elementTop;
            } else if (selectList.start > selectList.end) {
                const elementTop = allItems[selectList.end].offsetTop;
                document.getElementById(parentId+"SearchDrop").scrollTop = elementTop;
            } else if (selectList.start < selectList.end) {
                const elementTop = allItems[selectList.end].offsetTop;
                document.getElementById(parentId+"SearchDrop").scrollTop = elementTop;
            }
        } else {
            window.onscroll = function() {};
        }
        allItems[arrowDropIndex]?.focus();
     },[arrowDropIndex, selectList.end, selectList.start])

    const getSelectionList = (start, end) => {
        if(start < end) {
            setSelList({start, end})
        }
        if(start > end) {
            setSelList({start: end, end: start})
        }
    }

    const sortItems = (items) => {
        const newItems = items.sort((a,b) => (a.display > b.display) ? 1 : ((b.display > a.display) ? -1 : 0));
        // const newImtes = items;
        setDataSource(newItems)
    }

    useEffect(() => {
        getSelectionList(selectList.start, selectList.end)
    },[selectList.start, selectList.end])

    useEffect(() => {
        sortItems(items)
    }, [JSON.stringify(items)])
    
    return (
        <div ref={dropRef} className={classNames('search-dropdown')}>
            <div id={parentId+"SearchDrop"} className={'search-dropdown-list-container'}>

                {dataSource.map( (item,key) => {
                    // //console.log("Item:", item)
                    return (
                        <Fragment key={key}>
                        <div className={classNames('search-dropdown-item',{'focus' : ((selectList.end === selectList.start)? arrowDropIndex === key : (key >= selList.start &&  key <= selList.end))}, {'no-selectable' : item.id === "default"})} 
                            id={item.id.toString()+"Input"}
                            // onMouseEnter={() => toggleEnterTooltip(item.id,item.description)}
                            // onMouseLeave={toggleLeaveTooltip}
                            onClick={() => item.id !== "default" ? onItemClick(item.display) : null}
                            // onMouseUp={() => onDeleteItem(item.display)}
                            >
                                <p>
                                    {item.display}
                                </p>
                        </div>
                    </Fragment>
                    )
                })}
            </div>
        </div>
    )
}

export default IndigoSearchDrop