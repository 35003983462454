import React, {Fragment, useState, useEffect} from 'react';
import ContextMenu from 'devextreme-react/context-menu';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import IndigoItemsList from './../../components/form/indigoItemsList';
import ManagementPlanPanel from './ManagementPlanPanel.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from './../../../../../redux/actions/parametrizer';
import PrescriptionsPlanInputPanel from './Prescriptions/PrescriptionsPlanInputPanel';
import './mgmtStyle.css';
import LabsInputPanel from './Labs/LabsInputPanel';
import ImagesInputPanel from './Images/ImagesInputPanel';
import EncountersInputPanel from './Encounters/EncountersInputPanel';


function ManagementPlan (props) {

    var classNames = require('classnames');

    const [contextOpen, setContextOpen] = useState(null);
    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);
    const { data : finalData } = useSelector( state => state.parametrizer);
    const { responsive } = useSelector( state => state.ui);
    const [selected, setSelected] = useState(0);
    const [currentDay, setCurrentDay] = useState('');

    const [mgmtData, setMgmtData] = useState({});
    const [mgmtDataGrid, setMgmtDataGrid] = useState({});

    const dispatch = useDispatch();
    const questionRef = React.createRef();
    let questionChildren;
    
    useEffect(() => {
        questionChildren = questionRef.current.lastChild
    },[])

    const handleDocumentClick = (e) => {
        const children = questionChildren;
        if (children.contains(e.target)) {
            setContextOpen(false);
        } else {
            setContextOpen(null);
        }
    }

    useEffect(() => {
        let currentData = mgmtData;
        data.options.forEach( qst => {
            currentData[qst.id] = [];
        })
        let currentDataGrid = mgmtDataGrid;
        data.options.forEach( qst => {
            currentDataGrid[qst.id] = [];
        })

        finalData.map( (data, key) => {
            const element = document.getElementById(data.id+"Section");
            element?.addEventListener('contextmenu', handleDocumentClick);
        
            return function cleanup () {
                element?.removeEventListener('contextmenu', handleDocumentClick);
            }
        })
     }, [])

     const openPopup = () => {
        setCancelPopUpVisible(true)
    }

    const closePopup = () => {
        setCancelPopUpVisible(false)
    }

    const handleDeleteItem = (section) => {
        openPopup()
        setContextOpen(false);
        setTimeout(
            () => {
                setContextOpen(null);
            },
            [300],
        );
    }

    const updateList = (list) => {
        const newData = finalData;        
        newData.some( dato => {
            if (dato.id === data.id){
                dato.options = list;
            }
        })
        dispatch( setData(newData))
    }
    

    const getSelected = (selection) => {
        setSelected(selection);
    }

    const getMgmtData = (id, list) => {
        let currentData = mgmtData;
        currentData[id] = list;
        setMgmtData(prev => prev = currentData);
    }

    const getMgmtDataGrid = (id, list) => {
        let currentData = mgmtDataGrid;
        currentData[id] = list;
        setMgmtDataGrid(prev => prev = currentData);
    }

    const getToday = () => {
        const today = new Date();
        setCurrentDay(today)
    }

    const { data, focused, getFocused, activeSection, readOnly, readOnlyMgmtData, encounterInfo } = props

    return(

        <Fragment>
            <div ref={questionRef} id={data.id+"Section"} className={'section-layout'}>
                { !(responsive === "extrasmall" || responsive === "small") && <div className={classNames('section-title', {'active': activeSection.id === data.id})}></div>}
                <div className={'section-content'} style={{flexWrap: "wrap"}}>
                    <ManagementPlanPanel info={data.options} focused={focused} getFocused={getFocused} getSelected={getSelected} editable={props.editable}/>
                    {
                        data !== undefined && 
                            <Fragment> 
                                {data?.options[selected].id === "prescriptions" &&
                                    <PrescriptionsPlanInputPanel 
                                        info={data?.options[selected]} 
                                        focused={focused} 
                                        getFocused={getFocused} 
                                        getData={getMgmtData} 
                                        data={mgmtData}
                                        getDataGrid={getMgmtDataGrid} 
                                        dataGrid={readOnly ? readOnlyMgmtData : mgmtDataGrid}
                                        readOnly={readOnly}
                                        encounterInfo={encounterInfo}
                                        />
                                }

                                {data?.options[selected].id === "laboratories" &&
                                    <LabsInputPanel 
                                        info={data?.options[selected]} 
                                        focused={focused} 
                                        getFocused={getFocused} 
                                        getData={getMgmtData} 
                                        data={mgmtData}
                                        getDataGrid={getMgmtDataGrid} 
                                        dataGrid={readOnly ? readOnlyMgmtData : mgmtDataGrid}
                                        readOnly={readOnly}
                                        encounterInfo={encounterInfo}
                                        />
                                }

                                {data?.options[selected].id === "images" &&
                                    <ImagesInputPanel 
                                        info={data?.options[selected]} 
                                        focused={focused} 
                                        getFocused={getFocused} 
                                        getData={getMgmtData} 
                                        data={mgmtData}
                                        getDataGrid={getMgmtDataGrid} 
                                        dataGrid={readOnly ? readOnlyMgmtData : mgmtDataGrid}
                                        readOnly={readOnly}
                                        encounterInfo={encounterInfo}
                                        />
                                }

                                {data?.options[selected].id === "interencounter" &&
                                    <EncountersInputPanel 
                                        info={data?.options[selected]} 
                                        focused={focused} 
                                        getFocused={getFocused} 
                                        getData={getMgmtData} 
                                        data={mgmtData}
                                        getDataGrid={getMgmtDataGrid} 
                                        dataGrid={readOnly ? readOnlyMgmtData : mgmtDataGrid}
                                        readOnly={readOnly}
                                        encounterInfo={encounterInfo}
                                        />
                                }
                            </Fragment>
                    }
                </div>
                
            </div>
            {!readOnly && <ContextMenu
                visible={contextOpen}
                dataSource={[{ text: 'Editar ' + data.display, icon: 'rename' }]}
                width={200}
                target={"#"+data.id+'Section'}
                closeOnOutsideClick={true}
                onItemClick={handleDeleteItem} />
            }
            {(cancelPopupVisible) && <Popup
                visible={cancelPopupVisible}
                onHiding={closePopup}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showTitle={true}
                title={"Editar "+data.display}
                width={350}
                height={500}
                >
                <div className={'cancel-popup'}>
                    <div className={'popup-section'}>
                        <div className={'popup-section-title'}>Ordenar</div>
                        <div className={'popup-section-subtitle'}>Arrastra para ordenar</div>
                        <div className={'popup-section-content form-list'}>
                            <IndigoItemsList items={data.options} updateList={updateList}/>
                        </div>
                    </div>
                    <div className={'cancel-buttons'}>
                        <Button
                        width={130}
                        icon={'check'}
                        text="Cerrar"
                        type="success"
                        stylingMode="contained"
                        onClick={closePopup}                        
                        />
                    </div>

                </div>
            </Popup>}
        </Fragment> 

    )
}

export default React.memo(ManagementPlan)