import React, { useRef, useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../../components/renderer/header.jsx';
import Form, {
    SimpleItem,
    GroupItem,
    Label
} from 'devextreme-react/form';
import TextArea from 'devextreme-react/text-area';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import { MC } from '../index.js';
//import { setComposition } from '../../redux/actions/clinicHistory.js';
import FloatingButton from './FloatingButton';


export default function HistorialPage(props) {
    const { fromType } = useParams();
    const { patientInfo, medicalRecordInfo, composition } = useSelector(state => state.clinicHistory);
    const { responsive } = useSelector(state => state.ui);
    const [numCols, setNumCols] = useState(4);

    useEffect(() => {
        switch (responsive) {
            case 'extralarge':
            default:
                setNumCols(4);
                break;
            case 'large':
                setNumCols(3);
                break;
            case 'medium':
                setNumCols(2);
                break;
            case 'small':
                setNumCols(1);
                break;
            case 'extrasmall':
                setNumCols(1);
                break;

        }
    }, [responsive])

    const form = useRef(null);
    let defaultShowingData = composition.Sections.SectionObservations.Observations.flatMap(entry => {
        let type, question, sortingValue;

        type = entry.Identifier[1].ValueElement.Value;
        question = entry.Identifier[3].ValueElement.Value;
        sortingValue = parseInt(entry.Identifier[2].ValueElement.Value);

        let items = entry.Component.map(component => {
            let value, comment;
            // value = component.coding[0].display;
            // value = component.Code.Coding[0].DisplayElement?.Value;
            value = component.Component ? component.Component[0]?.Code?.Coding[0]?.DisplayElement?.Value : component.Code.Coding[0].DisplayElement?.Value;
            comment = component.Value ? component.Value?.Value : null;
            return { answers: [{ value, comment }], type, question, sortingValue }
        });

        return items
    });
    defaultShowingData = defaultShowingData.sort((a, b) => a.sortingValue - b.sortingValue);

    const [showingData, setShowingData] = useState(defaultShowingData);
    const [beautyData, setBeautyShowingData] = useState({ options: [] });
    
    let mainDiagnostics = { 
        id: 'MainDiagnostics', 
        display: 'Diagnóstico principal', 
        type: 'textarea', 
        size: 25, 
        sortingValue: 1000, 
        options: [{ 
            id: 'diagnosisMain', 
            display: '', 
            comment: null, 
            canWrite: false
        } ] }

    composition?.Sections?.SectionDiagnostics?.Diagnostics.flatMap((entry, index) => {
        if(entry?.Category[0]?.Coding[0]?.CodeElement?.Value === "Principal") {
            mainDiagnostics.options[0].display = entry?.Code?.Coding[0]?.DisplayElement?.Value;
        }
    });

    let secondaryDiagnostics = {
        id: 'SecondaryDiagnostics', 
        display: 'Diagnósticos complementarios', 
        type: 'textarea', 
        size: 25, 
        sortingValue: 1000,
        options: [{}] //todo fix
    }
    composition?.Sections?.SectionDiagnostics?.Diagnostics.flatMap((entry, index) => {
        if (entry?.Category[0]?.Coding[0]?.CodeElement?.Value === "Principal") return;        
        secondaryDiagnostics.options[0].display += ` ${entry?.Code?.Coding[0]?.DisplayElement?.Value} ,`;
        //if(secondaryDiagnostics?.options[0]?.display === '') {
        //    secondaryDiagnostics.options[0].display = entry?.Code?.Coding[0]?.DisplayElement?.Value;
        //} else {
        //}
    });

    const typeDictionary = {
        "0": { id: "textarea", size: 5 },
        "1": { id: "text", size: 2 },
        "2": { id: "search", size: 5 }
    }
    
    useEffect(() => {
        let beautyShowingData = { options: [] }
        showingData.forEach(entry => {
            if(["ANOS","MESES","DIAS","EDAD"].includes(entry.question.toUpperCase())) return;
            let valueResult
            if(entry?.answers[0]?.value?.includes("Hh:Mm")) {
                valueResult = entry?.answers[0]?.value?.split("Hh:Mm")[1];
            } else {
                valueResult = entry?.answers[0]?.value;
            }
            let items;
            if (!beautyShowingData?.options?.some(item => item?.sortingValue === entry?.sortingValue)) {
                console.log(entry.question, "Algun input tiene texto", showingData.some(item => item.answers[0]?.comment !== null && item.question === entry.question)/* , showingData */)
                items = { id: entry.question?.split(" ").join(""), display: entry.question, type: (entry.type == "2" && !entry.answers[0].comment) ? "textarea" : typeDictionary[entry.type].id, size: (entry.type == "2" && entry.answers[0].comment) ? 5 : 25, sortingValue: entry.sortingValue, options: [{ id: valueResult?.split(" ").join(""), display: valueResult, comment: entry.answers[0].comment, canWrite: entry.answers[0].comment ? true : false }] }
            } else {
                const index = beautyShowingData?.options?.findIndex(item => item.sortingValue === entry.sortingValue);
                if ((entry.type == "2" && !entry.answers[0].comment)) {
                    beautyShowingData.options[index].options[0].display = beautyShowingData.options[index]?.options[0].display + `, ${valueResult}`;
                } else {
                    beautyShowingData.options[index]?.options?.push({ id: valueResult?.split(" ").join(""), display: valueResult, comment: entry.answers[0].comment, canWrite: entry.answers[0].comment ? true : false })
                }
            }
            items && beautyShowingData?.options?.push(items)
        })
        if(secondaryDiagnostics.options.length > 0) {
            beautyShowingData.options = [mainDiagnostics, secondaryDiagnostics, ...beautyShowingData.options]
        } else {
            beautyShowingData.options = [mainDiagnostics, ...beautyShowingData.options]
        }
        setBeautyShowingData(beautyShowingData);
    }, [JSON.stringify(showingData), JSON.stringify(mainDiagnostics), JSON.stringify(secondaryDiagnostics)])

    let mgmtData = {
        "options":
        {
            "prescriptions": [],
            "laboratories": [],
            "images": [],
            "interencounter": []
        }
    }

    composition.Sections.SectionMedicationRequests.MedicationRequest.map(mr => {
        try {mgmtData?.options?.prescriptions?.push({
            id: mr?.Identifier ? mr?.Identifier[0]?.ValueElement?.Value : '',
            dose: mr?.DosageInstruction ? mr?.DosageInstruction[0]?.DoseAndRate[0]?.Dose?.ValueElement?.Value : '',
            freq: mr?.DosageInstruction ? `Cada ${mr?.DosageInstruction[0]?.Timing?.Repeat?.PeriodUnitElement?.Value} horas` : '',
            resume: mr?.DosageInstruction ? mr?.DosageInstruction[0].TextElement?.Value : '',
            medicines: mr?.Medication?.Coding ? mr?.Medication?.Coding[0]?.DisplayElement?.Value : '',
        }) }
        catch (error) {
            console.warn("Error", error);
        }
    })

    composition.Sections.SectionServiceRequests?.ServiceRequests.map(obs => {
        const images = () => {
            mgmtData?.options?.images?.push({
                id: obs?.Identifier ? obs?.Identifier[0]?.ValueElement?.Value : '',
                imgService: obs?.Code?.TextElement?.Value,
                imgSuggDate: obs?.AuthoredOnElement?.Value,
                imgPriority: obs?.PriorityElement?.Value === 0 ? "Urgente" : "Rutinario",
                imgQty: obs?.Quantity?.ValueElement?.Value,
                imgIndications: obs?.Note ? obs?.Note[0]?.Text?.Value : ''
            })
        }

        const laboratories = () => {
            mgmtData?.options?.laboratories?.push({
                id: obs?.Identifier ? obs?.Identifier[0]?.ValueElement?.Value : '',
                labService: obs?.Code?.TextElement?.Value,
                labSuggDate: obs?.AuthoredOnElement?.Value,
                labPriority: obs?.PriorityElement?.Value === 0 ? "Urgente" : "Rutinario",
                labQty: obs?.Quantity?.ValueElement?.Value,
                labIndications: obs?.Note ? obs?.Note[0]?.Text?.Value : ''
            })
        }

        const encounters = () => {
            mgmtData?.options?.interencounter?.push({
                id: obs?.Identifier ? obs?.Identifier[0]?.ValueElement?.Value : '',
                interEspeciality: obs?.Code?.TextElement?.Value,
                interEspecialist: obs?.Performer[0]?.DisplayElement.Value.split("-")[1],
                interPriority: obs?.PriorityElement?.Value === 0 ? "Urgente" : "Rutinario",
                interIndications: obs?.Note ? obs?.Note[0]?.Text?.Value : ''
            })
            
        }

        switch (obs?.Identifier[1]?.ValueElement?.Value) {
            case 'Imagenes': images();
                break;

            case 'Laboratorios': laboratories();
                break;

            case 'Interconsultas': encounters();
                break;
        }
    })

    //const [filterValue, setFilterValue] = useState("");

    function showComment(comment, index) {
        let showingDataCopy = JSON.parse(JSON.stringify(showingData));
        showingDataCopy = showingDataCopy.map(item => { delete item.displayComment; return item });
        showingDataCopy[index].displayComment = comment

        if (numCols > 1 && ((index + 1) % numCols) === 0) {
            let temporal = showingDataCopy.splice(index, 1)[0];
            temporal.interchanged = true;
            showingDataCopy.splice(index - 1, 0, temporal);
        }

        setShowingData(showingDataCopy);
        form.current.instance.repaint()
    }

    function closeComment(index) {
        let showingDataCopy = JSON.parse(JSON.stringify(showingData));
        delete showingDataCopy[index].displayComment;

        if (showingDataCopy[index].interchanged) {
            let temporal = showingDataCopy.splice(index, 1)[0];
            delete temporal.interchanged;
            showingDataCopy.splice(index + 1, 0, temporal);
        }

        setShowingData(showingDataCopy);
        form.current.instance.repaint();
    }

    function filterFilds(filterValue) {
        let filter = filterValue.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        let newShowingData = defaultShowingData.filter(item => {
            return item.question?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(filter) > -1
                || item.answers[0].value?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(filter) > -1
                || item.answers[0].comment?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(filter) > -1;
        });
        setShowingData(newShowingData);
    }

    function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };


    const getFilterValue = (val) => {
        filterFilds(val);
    }

    return (
        <Fragment>
            {/* {fromType == 0 ? */}
                <MC
                    showAgnesButton={false}
                    readOnlyData={beautyData}
                    readOnlyMgmtData={mgmtData}
                    readOnly={true}
                    header={{ patient: { display: patientInfo.Nombre, age: patientInfo.Edad, sex: patientInfo.Genero, id: patientInfo.ID }, especiality: medicalRecordInfo.Especialidad }}
                    getFilterValue={getFilterValue}
                />
                {/* // :
                // <div>
                //     <Header patient={{ display: patientInfo.Nombre, age: patientInfo.Edad, sex: patientInfo.Genero, id: patientInfo.ID }} especiality={medicalRecordInfo.Especialidad} />
                //     <div className={'p5'} >
                //         <div className="dx-field">
                //             <div className="dx-field-label" style={{ marginLeft: "10px" }}>
                //                 <TextBox placeholder="Buscar"
                //                     stylingMode="outlined"
                //                     onKeyUp={debounce((e) => filterFilds(e.element.getElementsByTagName("input")[0].value), 400)}
                //                 />
                //             </div>
                //         </div>

                //         <Form formData={patientInfo} ref={form}
                //             elementAttr={{ style: "overflow: auto; height: calc(100vh - 300px)" }}>
                //             <GroupItem colCount={numCols} elementAttr={{ style: "overflow: auto" }}>
                //                 {
                //                     showingData.map(({ type, question, answers, displayComment }, index, array) => {
                //                         let hasComment = !!displayComment;

                //                         let colCount = hasComment ? 2 : 1;
                //                         let colSpan = hasComment ? 2 : 1;

                //                         if (type === "0" || type === "1") {
                //                             if (hasComment) {
                //                                 return (
                //                                     <GroupItem key={index} cssClass={"show-form-item"} colCount={colCount} colSpan={colSpan}>
                //                                         <SimpleItem>
                //                                             <Label text={question} />
                //                                             <Button
                //                                                 elementAttr={{ style: "position: absolute; top: 10px; right: 20px" }}
                //                                                 icon="close"
                //                                                 type="normal"
                //                                                 stylingMode="outlined"
                //                                                 onClick={(e) => closeComment(index)}
                //                                             />
                //                                             <TextArea text={answers[0].value}
                //                                                 stylingMode="outlined"
                //                                                 readOnly={true} />
                //                                         </SimpleItem>
                //                                         <SimpleItem
                //                                             editorType="dxTextArea"
                //                                             editorOptions={{ text: displayComment, stylingMode: "outlined", readOnly: true }}
                //                                         >
                //                                             <Label text={"Comentario"} />
                //                                         </SimpleItem>
                //                                     </GroupItem>
                //                                 )
                //                             } else {
                //                                 return (
                //                                     <GroupItem key={index} cssClass={"show-form-item"} colCount={colCount} colSpan={colSpan}>
                //                                         <SimpleItem>
                //                                             <Label text={question} />
                //                                             {answers[0].comment && answers[0].comment !== "" && <Button
                //                                                 elementAttr={{ style: "position: absolute; top: 10px; right: 20px" }}
                //                                                 icon="comment"
                //                                                 type="normal"
                //                                                 stylingMode="outlined"
                //                                                 onClick={(e) => showComment(answers[0].comment, index)} />}
                //                                             <TextArea text={answers[0].value}
                //                                                 stylingMode="outlined"
                //                                                 readOnly={true} />
                //                                         </SimpleItem>
                //                                     </GroupItem>
                //                                 )
                //                             }
                //                         } else if (type === "2") {
                //                             return (
                //                                 <GroupItem key={index} cssClass={"show-form-item"} colCount={colCount} colSpan={colSpan}>
                //                                     <SimpleItem>
                //                                         <Label text={(answers[0].comment && answers[0].comment !== "") ? answers[0].value : question} />
                //                                         <TextArea text={(answers[0].comment && answers[0].comment !== "") ? answers[0].comment : answers[0].value}
                //                                             stylingMode="outlined"
                //                                             readOnly={true} />
                //                                     </SimpleItem>
                //                                 </GroupItem>
                //                             )
                //                         } else {
                //                             return null;
                //                         }
                //                     })
                //                 }
                //             </GroupItem>
                //         </Form>
                //     </div >
                // </div>
            //} */}
            <FloatingButton classButton="floating-button" />
        </Fragment>
    )
}