import { createNewComposition, getPatientByIdentifier, getPatientByProtuarId, getComposition, historicPagination, getCompositionSIH } from "../../utils/helpersPoints";
import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";

export const setHistoricalData = (patientInfo, medicalRecordinfo) => ({
    type: types.setHistoricalData,
    payload: {
        patientInfo,
        medicalRecordinfo
    }
});

export const startGetPatientByProntuar = (id) => {
    const auth = sessionStorage.getItem('auth');
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const patient = await getPatientByProtuarId(auth, id);
            if (patient) {
                dispatch(setInfoPatientProntuar(patient));
            }
            dispatch(finishLoading());
            return patient;
        } catch (error) {
            //console.log('error peticion obtener paciente por prontuario', error);
            dispatch(finishLoading());
        }
    }
}

export const setInfoPatientProntuar = (patientInfoProntuar) => ({
    type: types.setInfoPatientByProntuar,
    payload: {
        patientInfoProntuar
    }
});


export const handleHistoricPagination = (url) => {
    let form_data = new FormData();
    form_data.append('url', url);
    return async (dispatch) => {
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {
            const pagination = await historicPagination(auth, form_data);
            if (pagination) {
                dispatch(setInfoPatientProntuar(pagination));
                let currentPagination = JSON.parse(localStorage.getItem('historicPagination'));
                if(pagination?.pagination?.length === 1) {
                    localStorage.setItem('historicPagination', JSON.stringify({...currentPagination, after: null}));
                } else {
                    let after = pagination?.pagination?.find(page => page.relation === "next")?.url;
                    localStorage.setItem('historicPagination', JSON.stringify({...currentPagination, after: after}));
                }
            }
        } catch (error) {
            console.error('error peticion crear composition', error);
        }
        dispatch(finishLoading());
    }
}

export const setComposition = (composition) => ({
    type: types.setComposition,
    payload: {
        composition
    }
});

export const startGetPatientByIndetifier = (id) => {
    const auth = sessionStorage.getItem('auth');
    return async (dispatch) => {

        dispatch(startLoading());
        try {
            const patient = await getPatientByIdentifier(auth, id);
            if (patient) {
                dispatch(setInfoPatientProntuar(patient));
            }
            dispatch(finishLoading());
            return patient;
        } catch (error) {
            //console.log('error', error);
            dispatch(finishLoading());
        }
    }
}

export const startGetComposition = (id) => {
    return async (dispatch) => {
        let composition;
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {
            composition = await getComposition(auth, id);
            if (composition) {
                dispatch(setComposition(composition));
            }
        } catch (error) {
            console.error('error', error);
        }
        dispatch(finishLoading());
        return composition;
    }
}

export const startGetCompositionSIH = (data) => {
   
    // let form_data = new FormData();
    // form_data.append('fhir_data', data);

    return async (dispatch) => {
        let composition;
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {
            composition = await getCompositionSIH(auth, data);
            if (composition) {
                dispatch(setComposition(composition));
            }
        } catch (error) {
            console.error('error', error);
        }
        dispatch(finishLoading());
        return composition;
    }
}

export const startCreatingComposition = (data ,user) => {
    let form_data = new FormData();
    form_data.append('fhir_data', data);
    form_data.append('user_data', JSON.stringify(user));

    return async (dispatch) => {
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {
            await createNewComposition(auth, form_data);
        } catch (error) {
            console.error('error peticion crear composition', error);
        }
        dispatch(finishLoading());
    }
}
