import { getAllParameters, getAllTemplates } from "../../utils/helpersPoints";
import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";

export const setData = (data) => ({

    type: types.parametrizerLoadData,
    payload: {
        data
    }

});

export const setRemoteData = (remoteData) => ({

    type: types.parametrizerLoadRemoteData,
    payload: {
        remoteData
    }

});

export const resetData = () => ({

    type: types.parametrizerResetData,
    payload: []

});

export const setRowKey = (rowKey) => ({

    type: types.parametrizerSetRowKey,
    payload: {
        rowKey
    }

});

export const setTotalData = (data) => ({

    type: types.parametrizerTotalData,
    payload: {
        data
    }

})

export const getFormStructure = (form) => ({

    type: types.parametrizerGetForm,
    payload: {
        form
    }

})

export const activateSection = (section) => ({

    type: types.setActiveSection,
    payload: {
        section
    }

})

export const deleteSection = (section) => ({

    type: types.setDeleteSection,
    payload: {
        section
    }

});

const defaultStructure =  {
    id: '', 
    display: '', 
    questionNumber: 0,
    type: '', 
    size: 1, 
    canWrite: false,
    genre: 'both',
    dependency: {},
    dependsOn: '',
    expression: {},
    active: true,
    minAge: 0,
    maxAge: 120,
    maxChar: 1000,
    value: '',
    message: '',
    description: '',
    options: []
}

export const startLoadAllParameters = () => {
    const auth = sessionStorage.getItem('auth');
    return async(dispatch) => {
        dispatch( startLoading() );
        try {
            const parameters = await getAllParameters(auth);
            if (parameters) {
                console.log("Parameters", parameters)
                dispatch(parameterListConstructor(parameters));
                dispatch(setParameterList(parameters));
            }
        } catch (error) {
            console.log('error: ', error);
        }
        dispatch( finishLoading() );
    }
}

const setParameterList = (parametersList) => ({
    type: types.setParametersList,
    payload: {
        parametersList
    }
});

export const parameterListConstructor = (parametersList, newParameter = null) => {
    let parameters = [...parametersList];
    if(newParameter) {
        parameters.push(newParameter);
    }
    
    // console.log("Let's set new parameter list", parameters, newParameter);

    let preSections = parameters.filter( parameter => parameter.Hierarchy === 'section');
    let sections = preSections.map( section => {return ({id: section.ID, display: section.Display})});
    
    let preQuestions = parameters.filter((parameter) => parameter.Hierarchy === 'question');
    let questions = preQuestions.map( question => {return ({...defaultStructure, ...question.Details, id: question.ID, display: question.Display, parent: question.Parent, type: question.Type})});
    
    let preOptions = parameters.filter((parameter) => parameter.Hierarchy === 'option');
    let options = preOptions.map( option => {return ({...defaultStructure, ...option.Details, id: option.ID, display: option.Display, parent: option.Parent, type: option.Type})});

    let preOptionsOptions = parameters.filter((parameter) => parameter.Hierarchy === 'optionsOption');
    let optionsOptions = preOptionsOptions.map( option => {return ({...defaultStructure, ...option.Details, id: option.ID, display: option.Display, parent: option.Parent, type: option.Type})});

    sections.forEach((section) => {
        section.options = questions.filter((question) => question.parent === section.id);
    }) 

    questions.forEach((question) => {
        question.options = options.filter((option) => option.parent === question.id);
    })

    options.forEach((option) => {
        option.options = optionsOptions.filter((optionsOption) => optionsOption.parent === option.id);
    })

    return async (dispatch) => {
        try {
            if (parameters) {
                console.log("Sections:", sections);
                dispatch(setTotalData(sections));
            }
        } catch (error) {
            console.log('error: ', error);
        }
    }
}


export const startLoadAllTemplates = () => {
    const auth = sessionStorage.getItem('auth');
    return async(dispatch) => {
        dispatch( startLoading() );
        try {
            const templates = await getAllTemplates(auth);
            if (templates) {
                dispatch(setTemplateList(templates));
            }
        } catch (error) {
            //console.log('error: ', error);
        }
        dispatch( finishLoading());
    }
}

const setTemplateList = (templateList) => ({
    type: types.setTemplateList,
    payload: {
        templateList
    }
})
