import { types } from "../types/types";


const initialState = {
    appointments: [],
    especiality: {code: '', display: ''},
    appointment: {},
}
/**
 * {   
            id: 'abcasdas',
            title: 'ReviewTraining Course',
            start: moment().toDate(),  //newDate
            end: moment().add( 2, 'hours' ).toDate(),
            ficha: 'Comprar el pastel',
            historialMedico: 'view historial',
            user: {
                _id: '123',
                name: 'Stiven'
            }
    } 
 **/

export const agendaReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.agendaLoaded:
            return {
                ...state,
                appointments: [ ...action.payload]
            }

        case types.agendaLogoutCleaning:
            return {
                ...state,
                appointments: []
            }
        
        case types.selectEspeciality:
            return {
                ...state,
                especiality: action.payload
            }

        case types.selectAppointment:
            return {
                ...state,
                appointment: action.payload
            }
            
        default:
            return state;
    }
}