export const types = {
    //AUTH
    login: '[Auth] Login',
    logout: '[Auth] Logout',
    imageUrl: '[Auth] set imageUrl',
    //Admin -Dashboard
    isAdmin: '[Auth Admin] set Key Admin',
    dashboardUsers: '[dashboard] Load Users',
    dashboardRetries: '[dashboard] Load Retries',
    changesJsonView: '[dashboard] Changes JsonView',
    dashboardPortals: '[dashboard] Load portals',

    //UI
    uiSetError: '[UI] Set error',
    uiRemoveError: '[UI] Remove error',
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',
    setResponsive: '[UI] Set Responsive',

    //Agenda
    agendaLoaded: '[Agenda] Load Appointments',
    agendaLogoutCleaning: '[Agenda] Logout Cleaning',
    selectEspeciality: '[Agenda] Select Encounter',
    selectAppointment: '[Agenda] Select Appointment',
    //Patient
    appointmentSetActive: '[Patient] appointment set Active',
    clearActiveAppointment: '[Patient] clear Active Appointment',


    //Parametrizer
    parametrizerLoadData: '[Parametrizer] Load data',
    parametrizerResetData: '[Parametrizer] Reset data',
    parametrizerTotalData: '[Parametrizer] Load global data',
    parametrizerGetForm: '[Parametrizer] Get form',
    parametrizerSetRowKey: '[Parametrizer] setRowKey',
    parametrizerLoadRemoteData: '[Parametrizer] load remote data',
    setActiveSection: '[Sections] Active Section',
    setDeleteSection: '[Sections] Delete Section',

    //Parametrizer - FIcha
    setTemplateList: '[Parametrizer] setTemplateList',
    setParametersList: '[Parametrizer] setParametersList',


    //HCDAta
    setHcData: '[HcData] Set Data',
    setMcData: '[McData] Set Data',
    setItemsList: '[HcData] Set Items',


    //FHIR 
    setSection: '[Fhir] setSection',
    sectionUpdated: '[Fhir] updatedSection',
    reset: '[Fhir] Reset',


    // clinic history
    setHistoricalData: '[Historical] setHistoricalData',
    setInfoPatientByProntuar: '[Historical] setInfoPatientByProntuar',
    setComposition: '[Historical] setComposition',
    
    //Managment Plan
    setImageServices: '[Mgmt Plan] setImageServices',
    setLabServices: '[Mgmt Plan] setLabServices',
    setFrequences: '[Mgmt Plan] setFrequences',
    setMedicationFhir: '[Mgmt Plan] setMedicationFhir',
    setPractitionersFhir: '[Mgmt Plan] setPractitionerFhir',
    setDiagnosticsData: '[Mgmt Plan] setDiagnosticsData',
    setMedicationStock: '[Mgmt Plan] setMedicationStock',

    // setMedicationServices: '[Mgmt Plan] setImageServices',

    //autoSave
    setAutoSaveGeneralInput: '[autoSave] setAutoSaveGeneralInput',
    setAutoSaveSelectInput: '[autoSave] setAutoSaveSelectInput',
    setAutoSaveSearchInput: '[autoSave] setAutoSaveSearchInput',
    setAutoSaveFHIR: '[autoSave] setAutoSaveSearchInput',
    resetAutoSave: '[autoSave] resetAutoSave',


    //ProductivityPanel
    setAllProductivity: '[Productivity Panel] setAllProductivity',
    setProductivityBySpecialty: '[Productivity Panel] setProductivityBySpecialty',
    setProductivityByTemplate: '[Productivity Panel] setProductivityByTemplate',
    setProductivityTabular: '[Productivity Panel] setProductivityTabular',
    setProductivityFichas: '[Productivity Panel] setProductivityFichas',
    setServerTime: '[Productivity Panel] setServerTime',

    
    //ValidationRule
    setValidationList: '[Validation Rule] setValidationList',
    addCompletedItem: '[Valitadion Rule] addCompletedItem',
    removeCompletedItem: '[Valitadion Rule] removeCompletedItem',
}