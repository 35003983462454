import React from 'react';
import Button from 'devextreme-react/button';
import DataGrid, { Column, Editing, Paging, Lookup, FilterRow } from 'devextreme-react/data-grid';


class SaveTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { events: [] };
    this.logEvent = this.logEvent.bind(this);
    this.onEditingStart = this.logEvent.bind(this, 'EditingStart');
    this.onInitNewRow = this.logEvent.bind(this, 'InitNewRow');
    this.onRowInserting = this.logEvent.bind(this, 'RowInserting');
    this.onRowInserted = this.logEvent.bind(this, 'RowInserted');
    this.onRowUpdating = this.logEvent.bind(this, 'RowUpdating');
    this.onRowUpdated = this.logEvent.bind(this, 'RowUpdated');
    this.onRowRemoving = this.logEvent.bind(this, 'RowRemoving');
    this.onRowRemoved = this.logEvent.bind(this, 'RowRemoved');
    this.onSaving = this.logEvent.bind(this, 'Saving');
    this.onSaved = this.logEvent.bind(this, 'Saved');
    this.onEditCanceling = this.logEvent.bind(this, 'EditCanceling');
    this.onEditCanceled = this.logEvent.bind(this, 'EditCanceled');
    this.clearEvents = this.clearEvents.bind(this);
    this.removed = this.removed.bind(this);
    this.gridRef = React.createRef();
  }

  logEvent(eventName) {
    this.setState((state) => {
      return { events: [eventName].concat(state.events) };
    });
  }

  clearEvents() {
    this.setState({ events: [] });
  }

  componentDidMount() {
      this.props.getGridRef(this.gridRef.current._instance)
  }

  removed (e) {
    this.props.deleteItem(e.data.id)
  }
  
  render() {
          
    return (
      <React.Fragment>
        <DataGrid
          id="saveGridContainer"
          dataSource={this.props.dataSource}
          keyExpr="code"
          height={250}
          allowColumnReordering={true}
          showBorders={true}
          onRowRemoving={this.onRowRemoving}
          onRowRemoved={this.removed}
          remoteOperations={true}
          showColumnLines
          ref={this.gridRef}
          >
          <Paging enabled={true} />
          <Editing
            mode="row"
            // allowAdding={true}
            // allowDeleting={true} 
            />
            <FilterRow visible={true}/>

          <Column dataField="code" caption="Código" width={'8%'}/>
          <Column dataField="display" caption="Nombre actividad" />

        </DataGrid>

      </React.Fragment>
    );
  }
}

export default React.memo(SaveTable);