import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Tooltip } from 'devextreme-react/tooltip';
import {formatMessage } from 'devextreme/localization';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';

import { setData } from '../../../../../redux/actions/parametrizer.js';
import Button from 'devextreme-react/button';
import { Switch } from 'devextreme-react/switch';
import IndigoContextMenu from './IndigoContextMenu';
import Observation from './../../utils/fhir/observation.js';
import { sectionUpdated } from '../../../../../redux/actions/fhir';
import moment from 'moment'
import { vitalSigns, masks } from '../../../../../utils/units.js';
import { setAutoSaveGeneralInput } from '../../../../../redux/actions/autoSave.js';
import DateBox from 'devextreme-react/date-box';

export default React.memo(function IndigoTextInput (props) {


    
    const dispatch = useDispatch();
    const { id: encounterId } = useParams();
    const { patient, practitioner, startDate } = useSelector(state => state.agenda.appointment);

    const { fhir } = useSelector(state => state)
    const { data } = useSelector(state => state.parametrizer)
    const { responsive } = useSelector( state => state.ui)

    var classNames = require('classnames');
    
    const { disable, focused, canWrite, isFocused, getFocused, id, display, questionNumber, options, inputType, description, size, parentId, parentSize, maxChar, minValue, maxValue, granPaID, editable, render, getIMCHeight,
        getIMCWeight, IMC } = props;
    const textareaSizes = [1,2,3,4,5];
    
    const [showTooltip, setShowTooltip] = useState(false);
    const [contextOpen, setContextOpen] = useState(null);
    const [dropComment, setDropComment] = useState(false);
    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);
    const [inputSizes, setInputSizes] = useState([]);
    const [newObservation, setNewObservation] = useState(new Observation());
    
    const autoSave = useSelector( state => state.autoSave);
    const savedData = localStorage.getItem(encounterId);
    
    let defaultValue = "";
    // if(inputType === "date") defaultValue = moment(new Date).format('YYYY-M-D')?.split('T')[0];
    if(inputType === "time") defaultValue = moment(new Date).format('HH:mm');

    let defaultComment = "";

    if(savedData && savedData !== "undefined" && savedData !== "null") {
        if(JSON.parse(savedData)[id]?.value) {
            defaultValue = JSON.parse(savedData)[id]?.value;
        }
        if(JSON.parse(savedData)[id]?.comment) {
            defaultComment = JSON.parse(savedData)[id]?.comment;
        }
    }
    const [value, setValue] = useState(defaultValue);
    const [comment, setComment] = useState(defaultComment);
    
    useEffect(() => {        
        localStorage.setItem(encounterId, JSON.stringify(autoSave[encounterId]))
    }, [JSON.stringify(autoSave[encounterId])])

    const windowSingin = () => {
        switch (responsive) {              
            case "medium":
                setInputSizes([1,2])
                break;

            case "small":
                setInputSizes([1,2])
                break;
            
            case "extrasmall":
                setInputSizes([1])
                break;
                    
            default:
                setInputSizes([1,2,3])
                break;
        }
        return window.screen.width
    }

    useEffect(() => {
        windowSingin();
      }, [responsive]);


    const inputRef = React.createRef();
    let inputParent;
    useEffect(() => {
        newObservation.patientID = patient ? patient : null;
        newObservation.encounterID = encounterId ? encounterId : null;
        newObservation.date = moment(new Date(startDate)).format('YYYY-MM-DD');
        newObservation.practitionerID = practitioner ? practitioner : null;
        newObservation.canWrite = canWrite;
        newObservation.questionNumber = questionNumber;
        newObservation.type = 0;
        newObservation.label = display;
        newObservation.inputID = {type: inputType, id: id, context: 100};
        inputParent = inputRef.current.parentNode;
        
        
        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[index];
        if(!mySection) return;
        if(!mySection?.entry.some( ent => {return ent?.identifier[0].value.includes(id)})) {
            mySection?.entry?.push(newObservation.newObvervation);
        }
        dispatch(sectionUpdated(mySection));

        if(inputType === "boolean") {
            setValue(false)
        }
    },[render])

    const handleGetFocused = () => {
        getFocused(id);
    }

    const handleOnChange = (data, isNormal) => { 
        switch(true) {
            case ["int", "float", "boolean"].includes(inputType):
                if(isNormal) {   
                    // console.log("Es normal", data.target.value);     
                        newObservation.value = data.target.value.replace(/\D/,'');
                        setValue(data.target.value.replace(/\D/,''));
                } else {
                    // console.log("No es normal / Tiene máscara", data.value);     

                        newObservation.value = data.value;        
                        setValue(data.value);                
                }
                break;
            default:
                newObservation.value = data.target.value;
                setValue(data.target.value);
                break;
        }       
    }

    const handleCommentOnChange = (data) => {
        newObservation.componentCommentary = data.target.value;
        setComment(data.target.value);
    }

    const toggleEnterTooltip = () => {
        setShowTooltip(true);
    }

    const toggleLeaveTooltip = () => {
        setShowTooltip(false);
    }

    const openPopup = () => {
        setCancelPopUpVisible(true)
    }

    const closePopup = () => {
        setCancelPopUpVisible(false)
    }

    const getType = () => {
        let type;
        switch(inputType) {
            case "int":
                return type = "number"
            case "float":
                return type = "number"
            case "text":
                return type = "text"
        }
        return type
    }

    const handleToggleComment = (e) => {
        if(e.altKey) {e.preventDefault()}
        if(e.which === 67 && e.altKey) {
            toggleComment()
        }
    }

    const intInput = () => {

        let maskConditions;

        switch(true) {
            default:
                maskConditions = masks[display.split(" ").join("").toUpperCase()];
                break;
        }

        return (
            maskConditions ? 
            <TextBox 
                id={id+"Input"}
                placeholder={display}
                // maskRules={inputRegEx[inputType]}
                mask={maskConditions}
                elementAttr={{class: 'enter-jump'}}
                onValueChanged={handleOnChange}
                value={display.toUpperCase().includes("IMC") ? IMC : value}
                readOnly={display.toUpperCase().includes("IMC")}
                hoverStateEnabled={true}
                onFocusIn={handleGetFocused}
                maskInvalidMessage={"Valor no aceptado"}
            /> :
            <input 
                id={id+"Input"} 
                className={'text-input enter-jump'}
                type={getType()} 
                pattern="[0-9]*"
                placeholder={display} 
                name={"section"}
                onKeyDown={handleToggleComment}
                onChange={(e) => handleOnChange(e, true)}
                min={minValue ? minValue : 0}
                max={maxValue}
                // onKeyPress={isNumberKey}
                value={value}
                readOnly={display.toUpperCase().includes("IMC")}
                onFocus={handleGetFocused}
                autoComplete={"off"}
                >
            </input>
        )
    }

    const floatInput = () => {
        return (
            intInput()
            // <input 
            //     id={id+"Input"} 
            //     className={'text-input enter-jump'} 
            //     type={getType()} 
            //     placeholder={display} 
            //     name={"section"}
            //     // onChange={handleOnChange}
            //     onKeyDown={handleToggleComment}
            //     // value={display.toUpperCase().includes("IMC") ? IMC : value}
            //     min={minValue ? minValue : 0}
            //     max={maxValue}
            //     onMouseEnter={toggleEnterTooltip}
            //     onMouseLeave={toggleLeaveTooltip}
            //     onFocus={handleGetFocused}
            //     autoComplete={"off"}
            //     >
            // </input>
        )
    }

    const textArea = () => {
        return (
            <textarea 
                id={id+"Input"} 
                className={'text-input textarea enter-jump'}
                placeholder={display} 
                onChange={handleOnChange}
                onKeyDown={handleToggleComment}
                value={value}
                maxLength={maxChar}
                onMouseEnter={toggleEnterTooltip}
                onMouseLeave={toggleLeaveTooltip}
                onFocus={handleGetFocused}
                autoComplete={"off"}
                >
            </textarea>
        )
    }

    const inputComment = () => {
        return (
            <div className={classNames('inputComment-container', dropComment ? 'dropped' : 'no-dropped')}>
                <div className={'inputComment-label'}> 
                    <label>Comentario</label>
                    <li onClick={toggleComment} className={'dx-link dx-link-chevronup dx-icon-chevronup dx-link-icon'}></li>
                </div>
                <textarea 
                    id={id+"InputComment"} 
                    className={classNames('text-input textarea')}
                    placeholder={"...Expande información"} 
                    onChange={handleCommentOnChange}
                    onKeyDown={handleToggleComment}
                    value={comment}
                    onFocus={handleGetFocused}
                    autoComplete={"off"}
                    maxLength={1000}
                    disabled={!dropComment}
                    >
                </textarea>
            </div>
        )
    }

    const boolean = () => {
        const switches = document.getElementsByClassName('dx-switch')
        let switchFocused = false;
        newObservation.value = false
        if(switches){
            for(let i = 0; i < switches.length; i++){
                if(switches[i].parentNode.id === id+'Switch') {
                    if(switches[i].classList.contains('dx-state-focused')){
                        getFocused(id);
                    } 
                    break;
                }
            }
        }

        return (
            <div id={id+'Switch'} className={classNames('switch-container', {'focused' : isFocused})}>
                <span className={classNames('switch-text', {'on' : !value})}>
                    No
                </span>
                <Switch 
                    onValueChanged={handleOnChange}
                    switchedOffText={"No"}
                    switchedOnText={"Sí"}
                    hoverStateEnabled={false}
                    defaultValue={false} />
                <span className={classNames('switch-text', {'on' : value})}>
                    Sí
                </span>
            </div>
        )
    }

    const date = () => {
        return (
            <input 
                id={id+"Input"} 
                className={'text-input enter-jump'} 
                type={"date"} 
                placeholder={display} 
                name={"section"}
                onChange={handleOnChange}
                onKeyDown={handleToggleComment}
                value={value}
                onMouseEnter={toggleEnterTooltip}
                onMouseLeave={toggleLeaveTooltip}
                onFocus={handleGetFocused}
                autoComplete={"off"}
                >
            </input>
        )
    }

    const time = () => {
        return (
            // <DateBox 
            //     placeholder={display}
            //     value={value}
            //     onValueChanged={handleOnChange}
            //     pickerType={"rollers"}
            //     type="time" />
            <input 
                id={id+"Input"} 
                className={'text-input enter-jump'} 
                type={"time"} 
                placeholder={display} 
                name={"section"}
                onChange={handleOnChange}
                onKeyDown={handleToggleComment}
                value={value}
                onMouseEnter={toggleEnterTooltip}
                onMouseLeave={toggleLeaveTooltip}
                onFocus={handleGetFocused}
                autoComplete={"off"}
                >
            </input>
        )
    }
    
    const handleChangeSize = size => {
        const newData = data;
        newData.some( data => {
            data.options.some( quest => {
                if(quest.id === parentId){
                    quest.options.some( option => {
                        if(option.id === id) {
                            option.size = size
                        }
                    })
                }
            })
        })
        dispatch( setData(newData))
    }

    const handleDeleteItem = (e) => {
        if(e.itemData.text.includes("Editar")) {
                openPopup()
                
        } else if(e.itemData.text.includes("Comentario")){ 
            if(e.itemData.text.includes("Abrir")){
                setDropComment(true)
            } else {
                setDropComment(false)
            }
        }
        setContextOpen(false);
        setTimeout(
            () => {
                setContextOpen(null);
            },
            [300],
        );
    }

    const units = () => {
        if(vitalSigns[display.split(" ").join("").toUpperCase()]?.includes("**")) {
            return(
                <span className={'unit'}>
                    {vitalSigns[display.split(" ").join("").toUpperCase()].split("**")[0]}<sup>{vitalSigns[display.split(" ").join("").toUpperCase()].split("**")[1]}</sup>
                </span>
            )
        } else {
            return (
                <span className={'unit'}>
                    {vitalSigns[display.split(" ").join("").toUpperCase()]}
                </span>
            )
        }
                
    
    }
    const handleOpenContext = () => {
        setContextOpen(true);
    }

    const toggleComment = () => {
        setDropComment(prev => prev = !prev);
        if(!dropComment) {
            setTimeout(() => {
                document.getElementById(id+"InputComment")?.focus();
            }, 100);
        } else {
            setTimeout(() => {
                document.getElementById(id+"Input")?.focus();
            }, 100);
        }
    }

    useEffect(() => {
        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[index];
        const entryIndex = mySection?.entry?.map( ent => {
            return( ent?.identifier[0]?.value.substring(5,(ent?.identifier[0]?.value.length)))
        }).indexOf(id)

        let myEntry = mySection?.entry[entryIndex];

        // if(myEntry){
        //     if(canWrite){
        //         myEntry["component"][0]["valueString"] = comment;
        //     }
        //     myEntry["component"][0]["code"]["coding"][0]["display"] = value;
        // }

        if(myEntry){
            let searchValueDisplay = null;
            if(value?.length === 0) {
                searchValueDisplay = null;
            } else {
                if(masks[display.split(" ").join("").toUpperCase()]) {
                    let mask =  masks[display.split(" ").join("").toUpperCase()].split("");
                    let maskedValue = value ? value?.split("") : [];
                    for(let i = 0; i < mask.length; i++) {
                        if(!["0","1","2","3","4","5","6","7","8","9"].includes(mask[i])) {
                            maskedValue.splice(i,0,mask[i])
                        }
                    }
                    if(display.toUpperCase().includes("IMC")) {
                        searchValueDisplay = IMC;
                    } else {
                        searchValueDisplay =  maskedValue.join("");
                    }
                } else {
                    if(display.toUpperCase().includes("IMC")) {
                        searchValueDisplay = IMC;
                    }else {
                        searchValueDisplay = value;
                    }
                }
                // console.log("IMC", IMC)
            }
            let commentDisplay = null;
            if(comment?.length === 0) {
                commentDisplay = null;
            } else {
                commentDisplay = comment;
            }
            myEntry["component"][0]["code"]["coding"][0]["display"] = searchValueDisplay;
            myEntry["component"][0]["valueString"] = commentDisplay;

        }
        if(!mySection) return;
        mySection?.entry?.splice(entryIndex,1);
        mySection?.entry?.splice(entryIndex,0,myEntry);
        dispatch(sectionUpdated(mySection))

        if(display.toUpperCase().includes("TALLA")) {
            getIMCHeight(value);
        } else if(display.toUpperCase().includes("PESO")){
            getIMCWeight(value);
        }

        const autoSaveData = {
            id: id,
            value: display.toUpperCase().includes("IMC") ? IMC : value,
            comment: comment,
            encounterId: encounterId,
        }

        // console.log("Data value", autoSaveData)
        dispatch(setAutoSaveGeneralInput(autoSaveData));

    },[value, comment, IMC])
    
    return (
        <div ref={inputRef} id={id+'InputContainer'} onClick={handleGetFocused} className={classNames('text-input-container', {'focused' : isFocused}, 'input-size-'+size+'-'+parentSize )}
        onMouseEnter={toggleEnterTooltip}
        onMouseLeave={toggleLeaveTooltip}
        >
            <div className={classNames('text-input-title', {'contextOpen' : contextOpen})}>
                <label htmlFor={id} >{display}</label>
                {(canWrite && editable) &&  <li onClick={toggleComment} className={'dx-link dx-link-contains dx-icon-contains dx-link-icon'}></li>
                } 
                {(canWrite && !editable) &&  <li onClick={handleOpenContext} className={'dx-link dx-link-overflow dx-icon-overflow dx-link-icon'}></li>
                } 
            </div>
            {(["int", "float"].includes(inputType)) && intInput()}
            {(inputType === "textarea" || inputType === "text") && textArea()}
            {inputType === "boolean" && boolean()}
            {inputType === "date" && date()}
            {inputType === "time" && time()}
            {units()}
            {canWrite && inputComment()}
            {description && <Tooltip
                target={"#"+id.toString()+"Input"}
                visible={showTooltip}
                closeOnOutsideClick={false}
            >
                <div>{description} <br></br>
                {/* {formatMessage("Type")}: {inputType} */}
                </div>
            </Tooltip>}
            <IndigoContextMenu 
                dataSource={editable ? [
                    { text: dropComment ? 'Cerrar Comentario' : 'Abrir Comentario', icon: 'contains' },
                    { text: 'Editar ' + display, icon: 'rename' },
                ] : [
                    { text: dropComment ? 'Cerrar Comentario' : 'Abrir Comentario', icon: 'contains' },
                ]}
                width={200}
                target={"#"+id+'InputContainer'}
                closeOnOutsideClick={true}
                onItemClick={handleDeleteItem}
                open={contextOpen}
                toggle={setContextOpen}
            />
            {(cancelPopupVisible && editable) && <Popup
                visible={cancelPopupVisible}
                onHiding={closePopup}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showTitle={true}
                title={"Editar "+display}
                width={350}
                height={350}
                >
                <div className={'cancel-popup'}>
                    <div className={'popup-section'}>
                        <div className={'popup-section-title'}>Tamaño</div>
                        <div className={'popup-section-content size-btns'}>
                            {inputType !== "textarea" ? inputSizes.map( (size, key ) => {
                                return (
                                    <Button
                                        key={key}
                                        text={size}
                                        type={size === props.size ? "success" : "normal"}
                                        stylingMode={size === props.size ? "contained" : "outlined"}
                                        onClick={() => handleChangeSize(size)}
                                    /> 
                                );
                            }) :
                            textareaSizes.map( (size, key ) => {
                                return (
                                    <Button
                                        key={key}
                                        text={size}
                                        type={size === props.size ? "success" : "normal"}
                                        stylingMode={size === props.size ? "contained" : "outlined"}
                                        onClick={() => handleChangeSize(size)}
                                    /> 
                                );
                            })
                            }
                        </div>
                    </div>
                    <div className={'cancel-buttons'}>
                        <Button
                        width={130}
                        icon={'check'}
                        text="Cerrar"
                        type="success"
                        stylingMode="contained"
                        onClick={closePopup}                        
                        />
                    </div>

                </div>
            </Popup>}
        </div>
    )
})