import React, { Fragment, useEffect, useState } from 'react';
import Button from 'devextreme-react/button';
import { IndigoCommentInput } from '..';

export default function IndigoSearchSmallTag (props) {

    const {ref, events, focused, getFocused, deletetag, info, label, item, canDrop, result, getCommentary, comment} = props
    const classNames = require('classnames');
    const [value, setValue] = useState('');
    const smallTagRef = React.createRef();
    let smallTagParent;
    useEffect(() => {
        smallTagParent = smallTagRef.current.parentNode;
    },[])

    const handleDocumentClick = (e) => {
        if (e && e.which === 3) return;
        const container = smallTagParent;
        if (!container.contains(e.target)) {
            events["click"]("");
            // return onClickOutside;
        }
    }

    const getValue = (val, id) => {
        getCommentary(val, id)
        setValue(val)
    }

    useEffect(() => { 
        document.addEventListener('click', handleDocumentClick);
     
        return function cleanup () {
          document.removeEventListener('click', handleDocumentClick);
        }
     }, [])

    const calculateSlideDown = () => {
        if(result?.length < 5) {
            return ({marginTop: (result.length*36)+"px"})
        } else {
            return ({marginTop: (4*36)+"px"})
        }
    }

    const handleCloseTagOnMiddleClick = (e, item) => {
        e.preventDefault();
        if(e.buttons !== 4) return;
        deletetag(item)
    }

        return(
            <Fragment>
                <div ref={smallTagRef} id={item.id+"Tag"}  
                    onClick={() => events["click"](item.id)} 
                    className={classNames('search-tag small', {'active': focused === item.id}, {'selected' : (item.canWrite || value !== '')} , {'slide-down' : canDrop})}
                    onMouseDown={(e) => handleCloseTagOnMiddleClick(e, item)}
                    style={(canDrop) ? calculateSlideDown() : {}}
                    >
                    <p>{label}</p> 
                    <div className={'search-small-icons-container'}>
                        {item.canWrite && <i className="dx-icon dx-icon-contains"></i> }
                        <Button
                            onClick={() => deletetag(item)} 
                            icon={'clear'} 
                            stylingMode="text"/>
                    </div>
                    {item.canWrite && <div className={classNames('search-comment-small', {'selected': focused === item.id})}>
                        <IndigoCommentInput id={item.id} isFocused={focused === info.id} getFocused={getFocused} valueToParent={(e) => getValue(e,item.id)} valueFromParent={comment}/>
                    </div>}
                </div>
            </Fragment>
        )
            
}