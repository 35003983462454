import moment from 'moment';
import { types } from "../types/types";
import notify from 'devextreme/ui/notify';
import { getEncountersToday, getEncountersWeek } from "../../utils/helpersPoints";
import { finishLoading, startLoading } from "./ui";
import { getAge } from './../../utils/generic';

export const agendaStartLoading = (spec, date, week, view) => {
    //console.log('spec: ', spec, 'date: ', date, 'week: ', week, 'view: ', view)
    
    return async (dispatch) => {
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {
            let form_data = new FormData();

            form_data.append('id_speciality', spec);

            let allEncounters;
            if (view === 'day' || !view) {
                form_data.append('date', moment(date).format('YYYY-MM-DD'));
                allEncounters = await getEncountersToday(auth, form_data);
                //console.log('Por dias, consulta: ', allEncounters)
            }else{
                form_data.append('start_date', moment(week.startDay).format('YYYY-MM-DD'));
                form_data.append('end_date', moment(week.endDay).format('YYYY-MM-DD'));
                allEncounters = await getEncountersWeek(auth, form_data);
                //console.log('Por semana, consulta: ', allEncounters)

            }
            let encountersData = [];

            if (allEncounters === undefined || allEncounters.length === 0) {
                let patientObject = 'none';
                dispatch(appointmentsLoaded(encountersData || []));
                //console.log('No hay citas por el dia de hoy');
                notify({
                    message: 'No hay citas agendadas',
                    width: 10,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    }
                }, 'warning', 2000);
                dispatch(finishLoading());
                if (allEncounters === undefined) {
                    notify({
                        message: 'Error al consultar las citas, comuniquese con un administrador',
                        width: 10,
                        position: {
                            my: 'center top ',
                            at: 'center top ',
                        }
                    }, 'error', 2000);
                }
                return patientObject;
            } else {

                console.log("allEncounters", allEncounters)

                encountersData = allEncounters?.map( encounter => {
                    // console.log("PATIEEEEEEEEEENT:", encounter.resource.subject)
                    let encounter_ = {};
                    encounter_.idEncounterFhir = encounter.resource.id;
                    encounter_.idEncounterSIH = encounter.resource.identifier[0].value;
                    encounter_.codeEnterprise = encounter?.resource?.location[0]?.identifier[0]?.value;
                    encounter_.prontuar = encounter.resource.subject.identifier[0].value;
                    encounter_.patientIdentifiers = `${encounter.resource.subject.identifier[0].value}-${encounter.resource.subject.identifier[1].value}-${encounter.resource.subject.identifier[2].value}`;
                    encounter_.appointment = encounter.resource.appointment.id;
                    encounter_.text = encounter.resource.subject.name[0].text;
                    encounter_.sex = encounter.resource.subject.gender;
                    encounter_.age = getAge(encounter.resource.subject.birthDate);
                    encounter_.patient = encounter.resource.appointment.participant[0].actor.reference;
                    encounter_.practitioner = encounter.resource.appointment.participant[1].actor.reference;
                    encounter_.location = encounter.resource.location;
                    encounter_.startDate = new Date(encounter.resource.appointment.start);
                    encounter_.endDate = new Date(encounter.resource.appointment.end);
                    encounter_.status = encounter.resource.status;
                    // encounter_.status = "finished"
                    return encounter_

                });
                dispatch(appointmentsLoaded(encountersData));
                //console.log('encountersData::', encountersData);
            }

        } catch (error) {
            //console.log('Error al obtener encounters:::', error);
        }
        dispatch(finishLoading());
    }
}

const appointmentsLoaded = (appointments) => ({
    type: types.agendaLoaded,
    payload: appointments
})

export const selectEspeciality = (encounter) => ({
    type: types.selectEspeciality,
    payload: encounter
})

export const selectAppointment = (appointment) => ({
    type: types.selectAppointment,
    payload: appointment
})

export const agendaLogout = () => ({ type: types.agendaLogoutCleaning });