import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadPanel from 'devextreme-react/load-panel';
import moment from 'moment';
import Sections from './sections/sections.jsx';
import Questions from './questions/questions.jsx';
import './ficha-styles.css';
import { startLoadDiagnosticsData, startLoadFrequences, startLoadImgServices, startLoadLabServices, startLoadMedicationFhir, startLoadPractitionerFhir } from '../../redux/actions/mgmtPlan.js';
import { SpeedDialAction } from 'devextreme-react';
import { openAgnes } from './../../utils/helpersPoints';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react/button';
import { startGetPatientByProntuar } from '../../redux/actions/clinicHistory.js';
import { reset } from '../../redux/actions/fhir.js';

function Ficha(props) {
    const dispatch = useDispatch();
  const history = useHistory();
    useEffect(() => {
        // dispatch(startLoadImgServices());
        // dispatch(startLoadLabServices());
        dispatch(startLoadFrequences());
        // dispatch(startLoadMedicationFhir());
        // dispatch(startLoadPractitionerFhir());
        //dispatch(startLoadDiagnosticsData());
    }, [])
    var classNames = require('classnames');
    var $ = require('jquery');
    const { responsive, loading } = useSelector(state => state.ui);
    const { mcData } = useSelector(state => state.hcData);

    const [editSection, setEditSection] = useState('');

    const {children,
    deleteSection,
    dataQuestions,
    activeSection,
    encounterInfo,
    readOnly,
    readOnlyMgmtData,
    editable} = props;

    //console.log("dataQuestions", dataQuestions);
    
    const handleEditSection = (edit) => {
        setEditSection(edit)

    }

    const agnesLauncher = () => {
        let location = encounterInfo?.location?.find((location) => location?.identifier[0]?.system === "https://portal.ips.gov.py/ipsportal/location/enterprises/identifier")?.id; // TODO: obtener location 
        console.log("encounterInfo", encounterInfo)

        let reception = encounterInfo?.location?.find((location) => location?.identifier[0]?.system === "https://portal.ips.gov.py/ipsportal/location/reception/identifier");
        let agnesIdentifier = encounterInfo?.prontuar + "-" + reception.identifier[0].value + "-" + encounterInfo?.idEncounterSIH

        if (location) {
            const auth = sessionStorage.getItem('auth');
            openAgnes(auth, location, agnesIdentifier);
        } else {
            notify({
                message: 'Error al consultar el AgnesId-location, comuniquese con un administrador',
                width: 10,
                position: {
                    my: 'center top',
                    at: 'center top',
                }
            }, 'error', 2000);
        }
        //const agnesURL = "";
        //LLAMADO A UN SERVICIO DE AZURE TABLE (PORTALS) USANDO EL PARÁMETRO DE LOCATION (ID)
        //RECIBIR EL ID DEL PORTAL
        //CONCATENAR EL ID DEL PORTAL A agnesURL
        //ABRIR EN OTRA VENTANA EL URL
    }

    const handleToHistorial = async() => {
        const patient = await dispatch(startGetPatientByProntuar(encounterInfo?.prontuar));
        if (patient?.patient?.id) {
          history.push('/historial-clinico/'+encounterInfo.prontuar);
        }else{
          notify({
            message: 'El paciente no tiene historial médico',
            width: 10,
            position: {
                my: 'center top',
                at: 'center top',
            }
        }, 'error', 2000);
        }
      }

    const momentDictionary = {
        'en': {
            future: "in %s",
            past: "%s ago",
            s: 'a few seconds',
            ss: '%d seconds',
            m: "a minute",
            mm: "%d minutes",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            w: "a week",
            ww: "%d weeks",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
        },
        'es': {
            future: "en %s",
            past: "hace %s",
            s: 'pocos segundos',
            ss: '%d segundos',
            m: "un minuto",
            mm: "%d minutos",
            h: "una hora",
            hh: "%d horas",
            d: "un día",
            dd: "%d días",
            w: "una semana",
            ww: "%d semanas",
            M: "un mes",
            MM: "%d meses",
            y: "un año",
            yy: "%d años"
        }
    }

    moment.updateLocale('es', {
        relativeTime: momentDictionary['es']
    });

    const responsiveSmall = () => {
        if ((responsive === "extrasmall" || responsive === "small" || responsive === "medium")) {
            return true
        } else return false
    }

    if (loading) {
        return (
            <LoadPanel visible={true} />
            )
    } else {
        return (
            <Fragment>
                <div style={{ height: 'calc(100vh - 286px)' }}
                    onContextMenu={(e) => e.preventDefault()}
                    className={classNames('ficha-container')}>
                    {(!readOnly && !responsiveSmall() && mcData?.length > 0) && <Sections
                        deleteSection={deleteSection}
                        editSection={handleEditSection}
                        editable={editable}
                        readOnly={readOnly}
                    />}
                <Questions 
                    dataQuestions={dataQuestions}
                    readOnlyMgmtData={readOnlyMgmtData}
                    activeSection={activeSection}
                    editSection={editSection}
                    setEditSection={handleEditSection}
                    editable={editable}
                    encounterInfo={encounterInfo}
                    readOnly={readOnly}
                    />
                    {children}
                </div>
                {props.showAgnesButton && 
                <Fragment>
                    <Button
                        elementAttr={{ class: "floating-button", style: "border-radius:30px; height:56px; color:fff !important" }}
                        icon="group"
                        //label="AGNES"  
                        text="AGNES"
                        type="default"
                        stylingMode="contained"
                        onClick={agnesLauncher}
                        />
                    <div className={'historial-floating-btn'}
                    onClick={handleToHistorial}
                    >
                        HISTORIAL
                    </div>
                </Fragment>
                }
            </Fragment>
        );
    }
}

export default React.memo(Ficha);