import { type } from "jquery";
import { types } from "../types/types";

export const clinicHistoryReducer = (state = {}, action) => {

    switch (action.type) {
        case types.setHistoricalData:
            return {
                ...state,
                patientInfo: action.payload.patientInfo,
                medicalRecordInfo: action.payload.medicalRecordinfo
            }
        case types.setInfoPatientByProntuar:
            return {
                ...state,
                patientInfoProntuar: action.payload.patientInfoProntuar,
            }
        case types.setComposition:
            return {
                ...state,
                composition: action.payload.composition,
            }
        default:
            return state;
    }
}