import React from 'react';
import { Provider } from 'react-redux';

import { store } from './redux/store/store';
import { AppRouter } from './routers/AppRouter';

import './dx-styles.scss';
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './assets/styles/styles.css';

export default function App () {
  //const screenSizeClass = useScreenSizeClass();
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
      
  );
}
