import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
  ConstantLine,
  Label
} from 'devextreme-react/chart';

function customizeTooltip(pointInfo) {
  return {
    html: `<div><div class="tooltip-header">${
      pointInfo?.argumentText
    }</div><div class="tooltip-body"><div class="series-name"><span class='top-series-name'>${
      pointInfo?.points[0]?.seriesName
    }</span>: </div><div class="value-text"><span class='top-series-value'>${
      pointInfo?.points[0]?.valueText
    }</span></div><div class="series-name"><span class='bottom-series-name'>${
      pointInfo?.points[1]?.seriesName
    }</span>: </div><div class="value-text"><span class='bottom-series-value'>${
      pointInfo?.points[1]?.valueText
    }</span>% </div></div></div>`
  };
}

function customizePercentageText({ valueText }) {
  return `${valueText}%`;
}

const ChartData = ({data, selection }) => {
  const [dataArray, setDataArray] = useState([]);
  const {productivitySpecialty, productivityTemplate} = useSelector(state => state.productivityPanel);

  useEffect(() => {
    // console.log('dataaa: ', data);
    // console.log('selection: ', selection);
    // console.log('productivitySpecialty: ', productivitySpecialty);
    // console.log('productivityTemplate: ', productivityTemplate);

    if (data === 0) {
      if (selection === 'Tiempo promedio(min)') {
        if (productivitySpecialty !== undefined) {
          const data = productivitySpecialty?.ProductivityStatistics.sort(function(a, b) {
            return b.AverageTime - a.AverageTime;
          });
          
          const totalCount = data.reduce(function(prevValue, item) {
            return prevValue + item.AverageTime;
          }, 0);
          
          let cumulativeCount = 0;
          
          setDataArray(data.map(function(item) {
            cumulativeCount += item.AverageTime;
            return {
              complaint: item.SpecialtyName,
              count: item.AverageTime,
              cumulativePercentage: Math.round(cumulativeCount * 100 / totalCount)
            };
          })); 
        }
      }else if (selection === 'Atenciones') {
        if (productivitySpecialty !== undefined) {
          const data = productivitySpecialty?.ProductivityStatistics.sort(function(a, b) {
            return b.Total - a.Total;
          });
          
          const totalCount = data.reduce(function(prevValue, item) {
            return prevValue + item.Total;
          }, 0);
          
          let cumulativeCount = 0;
          
          setDataArray(data.map(function(item) {
            cumulativeCount += item.Total;
            return {
              complaint: item.SpecialtyName,
              count: item.Total,
              cumulativePercentage: Math.round(cumulativeCount * 100 / totalCount)
            };
          })); 
        }
      }
    }else if (data === 1){
      if (selection === 'Tiempo promedio(min)') {
        if (productivityTemplate !== undefined) {
          const data = productivityTemplate?.ProductivityStatistics.sort(function(a, b) {
            return b.AverageTime - a.AverageTime;
          });
          
          const totalCount = data.reduce(function(prevValue, item) {
            return prevValue + item.AverageTime;
          }, 0);
          
          let cumulativeCount = 0;
          
          setDataArray(data.map(function(item) {
            cumulativeCount += item.AverageTime;
            return {
              complaint: item.TemplateName,
              count: item.AverageTime,
              cumulativePercentage: Math.round(cumulativeCount * 100 / totalCount)
            };
          })); 
        }
      }else if (selection === 'Atenciones') {
        if (productivityTemplate !== undefined) {
          const data = productivityTemplate?.ProductivityStatistics.sort(function(a, b) {
            return b.Total - a.Total;
          });
          
          const totalCount = data.reduce(function(prevValue, item) {
            return prevValue + item.Total;
          }, 0);
          
          let cumulativeCount = 0;
          
          setDataArray(data.map(function(item) {
            cumulativeCount += item.Total;
            return {
              complaint: item.TemplateName,
              count: item.Total,
              cumulativePercentage: Math.round(cumulativeCount * 100 / totalCount)
            };
          })); 
        }
      }
    }
    
  }, [data, selection, JSON.stringify(productivitySpecialty), JSON.stringify(productivityTemplate)]);

  return (
    <div>
       <Chart
          dataSource={dataArray }
          palette="Harmony Light"
          id="chart"
        >
          <CommonSeriesSettings argumentField="complaint" />
          <Series
            name={selection === 'Tiempo promedio(min)' ? 'Promedio tiempo (m)' : selection === 'Atenciones' ? ' Atenciones' : ''}
            valueField="count"
            axis="frequency"
            type="bar"
            // color="#fac29a"
            color={data === 0 ? "#fac29a" : "#EA961C"}

          />
          <Series
            name="Porcentaje acumulado"
            valueField="cumulativePercentage"
            axis="percentage"
            type="spline"
            // color="#6b71c3"
            color={data === 0 ? "#6b71c3" : "#338DFF"}

          />
  
          <ArgumentAxis>
            <Label overlappingBehavior="stagger" />
          </ArgumentAxis>
  
          <ValueAxis
            name="frequency"
            position="left"
            tickInterval={5}
          />
          <ValueAxis
            name="percentage"
            position="right"
            tickInterval={20}
            showZero={true}
            valueMarginsEnabled={false}
          >
            <Label customizeText={customizePercentageText} />
            <ConstantLine
              value={80}
              width={2}
              color="#fc3535"
              dashStyle="dash"
            >
              <Label visible={false} />
            </ConstantLine>
          </ValueAxis>
  
          <Tooltip
            enabled={true}
            shared={true}
            customizeTooltip={customizeTooltip}
          />
  
          <Legend
            verticalAlignment="top"
            horizontalAlignment="center"
          />
        </Chart>
    </div>
  )
}


export default ChartData;