
export default class Composition {

    constructor (date = '', practitionerID = '', practitionerDisplay= '', patientID = '', patientDisplay= '', encounterID = '', sections, code = 0, extendCode = '', name  = '') {
        this._practitionerID = practitionerID;
        this._practitionerDisplay = practitionerDisplay;
        this._patientID = patientID;
        this._patientDisplay = patientDisplay;
        this._encounterID = encounterID;
        this._date = date;
        this._sections = sections;
        this._code = code;
        this._extendCode = extendCode;
        this._name = name;
    }

    set practitionerID (id) {
        this._practitionerID = id;
    }

    set practitionerDisplay (display) {
      this._practitionerDisplay = display;
    }

    set patientID (id) {
      this._patientID = id;
    }

    set patientDisplay (display) {
      this._patientDisplay = display;
    }

    set encounterID (id) {
      this._encounterID = id;
    }

    set date (date) {
        this._date = date;
    }

    set sections (sections) {
      this._sections = sections;
    }

    set code (code) {
      this._code = code;
    }

    set extendCode (code)  {
      this._extendCode = code;
    }

    set name (name) {
      this._name = name;
    }

    get newComposition () {
        return new CompositionObject(
          this._practitionerID,
          this._practitionerDisplay,
          this._patientID,
          this._patientDisplay,
          this._encounterID,
          this._date,
          this._sections,
          this._code,
          this._extendCode,
          this._name
          )
    }
}

function CompositionObject (practitionerID, practitionerDisplay, patientID, patientDisplay, encounterID, date, sections, code, extendCode, name) {
    
    const estructure = {
        "composition": {
          "status": "final",
          "identifier": [
            // {
            //   "use": "official",
            //   "system": "https://portal.ips.gov.py/ipsportal/composition/identifier/code",
            //   "value": code
            // },
            {
              "use": "usual",
              "system": "https://portal.ips.gov.py/ipsportal/composition/identifier/extend",
              "value": extendCode
            },
          ],
          "type": {
            "coding": [
              {
                "system": "https://portal.ips.gov.py/ipsportal/composition/type/code",
                "code": code,
                "display": name
              },
              { "system": "https://portal.ips.gov.py/ipsportal/attention/type/code", 
                "code": "0", 
                "display": "Ambulatorio" 
              }
            ],
            "text": name
          },
          "date": date,
          "author":[ 
            {
              "reference": practitionerID,
              "display": practitionerDisplay
            }
          ],
          "subject": 
            {
              "reference": patientID,
              "display": patientDisplay
            }
          ,
          "encounter": 
            {
              "reference": "Encounter/"+encounterID
            }
          ,
          "title": name
        },
        "sections": sections
      }
    return estructure
}