import DateBox from 'devextreme-react/date-box';
import React, { Fragment, useEffect, useState } from 'react';
import { Switch } from 'devextreme-react/switch';
import moment from 'moment';
import { NumberBox } from 'devextreme-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Validator,
    RangeRule
} from 'devextreme-react/validator';

export default function ManagementGeneralInput(props) {

    const classNames = require('classnames');



    const { info, parentInfo, focused, onChange, onClick, data, disabled, display, readOnly } = props;
    const [dayBefore, setDayBefore] = useState('');
    const [currentDay, setCurrentDay] = useState('');
    const [value, setValue] = useState(0);
    const medicationStock = useSelector(state => state?.managmentPlan?.medicationStock);

    const getYesterday = () => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setDayBefore(yesterday)
    }

    const getToday = () => {
        const today = new Date();
        setCurrentDay(today)
    }

    useEffect(() => {
        if (info.type === "date") {
            getYesterday()
            getToday()
        }
    }, [])

    const DateTime = () => {
        return (
            <Fragment>
                <div className={classNames('mgmt-input mgmt-input mgmt-input-item')} >
                    <DateBox
                        type={'datetime'}
                        min={dayBefore}
                        placeholder={
                            data?.value ?
                                moment(data.value).format('YYYY-MM-DD HH:mm')
                                :
                                moment(currentDay).format('YYYY-MM-DD HH:mm')
                        }
                        value={data?.value}
                        onValueChanged={(e) => onChange(
                            {
                                value: e.value.toString(),
                                display: moment(e.value).format('YYYY-MM-DD HH:mm')
                            }
                            , info.id)
                        }
                        disabled={disabled}
                    />
                </div>
            </Fragment>
        )
    }

    const Toggle = () => {
        return (
            <Fragment>
                <div className={classNames('mgmt-dropdown-group-horizontal flex mgmt-input mgmt-input-item')} onClick={() => onClick(info.id)}>
                    <span className={classNames('switch-text', { 'on': !data?.value }, { 'disabled': disabled })}>
                        Rutinario
                    </span>
                    <Switch
                        onValueChanged={(e) => onChange({ value: e.value, display: e.value ? 'Urgente' : 'Rutinario' }, info.id)}
                        switchedOffText={"Rutinario"}
                        switchedOnText={"Urgente"}
                        disabled={disabled}
                        hoverStateEnabled={false}
                        value={data?.value && data?.value}
                        defaultValue={false} />
                    <span className={classNames('switch-text', { 'on': data?.value }, { 'disabled': disabled })}>
                        Urgente
                    </span>
                </div>
            </Fragment>
        )
    }

    const Int = () => {
        return (
            <Fragment>
                <NumberBox
                    elementAttr={{ id: info.id + "Input", class: "number-box-indigo", style: "border: 0px !important" }}
                    inputAttr={{
                        class: "number-input-indigo",
                        id: info.id + "Input"
                    }}
                    min={0}
                    onOptionChanged={(e) => { e.name === "value" && onChange({ value: e.value, display: e.value }, info.id) }}
                    value={parseInt(data?.value ? data?.value : 0)}
                    onFocusIn={() => onClick(info.id)}
                    disabled={disabled || medicationStock?.status === "LOADING"}
                    readOnly={disabled}
                    validationMessageMode="always"
                    validationStatus={(() => {
                        if (medicationStock?.status === 'LOADING') {
                            return 'pending';
                        } else if (medicationStock?.status === 'LOADED') {
                            if (parseInt(data?.value ? data?.value : 0) > parseInt(medicationStock?.stock)) {
                                return 'invalid';
                            } else {
                                return 'valid';
                            }
                        } else {
                            return 'valid';
                        }
                    })()}
                >
                    <Validator>
                        <RangeRule message="No hay stock suficiente" max={parseInt(medicationStock?.stock)} />
                    </Validator>
                </NumberBox>
                {/* <input
                    id={info.id + "Input"}
                    className={classNames('text-input enter-jump mgmt-input mgmt-input-item', { 'disabled': disabled })}
                    min={0}
                    type="number"
                    value={data?.value ? data?.value : 0}
                    onChange={(e) => onChange({ value: e.target.value, display: e.target.value }, info.id)}
                    disabled={disabled}
                    readOnly={disabled}
                    // onKeyPress={isNumberKey}
                    autoComplete={"off"}
                    onClick={() => { onClick(info.id) }}
                >
                </input> */}
            </Fragment>
        )
    }

    const Text = () => {
        return (
            <Fragment>
                <input
                    id={info.id + "Input"}
                    className={classNames('text-input enter-jump mgmt-input mgmt-input-item', { 'disabled': disabled })}
                    min={0}
                    type="text"
                    value={data?.value ? data?.value : ""}
                    onChange={(e) => onChange({ value: e.target.value, display: e.target.value }, info.id)}
                    disabled={disabled}
                    readOnly={disabled || readOnly}
                    // onKeyPress={isNumberKey}
                    autoComplete={"off"}
                    onClick={onClick ? () => onClick(info.id) : null}
                >
                </input>
            </Fragment>
        )
    }

    const Textarea = () => {
        return (
            <Fragment>
                <textarea
                    id={info.id + "Input"}
                    className={classNames('text-input textarea enter-jump', { 'disabled': disabled })}
                    pattern="[0-9]*"
                    required
                    disabled={disabled}
                    readOnly={disabled}
                    onChange={(e) => onChange({ value: e.target.value, display: e.target.value }, info.id)}
                    value={data?.value ? data?.value : ""}
                    autoComplete={"off"}
                ></textarea>
            </Fragment>
        )
    }

    const Resume = () => {
        return (
            <div className={classNames('mgmt-content-text')}>
                {
                    display
                }
            </div>
        )
    }

    return (
        <Fragment>
            <div id={'InputContainer'+info.id}
                className={classNames('text-input-container mgmt-input-container', { 'focused': focused === info.id }, 'input-size-' + info.size + '-' + parentInfo.size)}
            >
                <div className={classNames('text-input-title')} onClick={onClick ? () => onClick(info.id) : null}>
                    <label className={classNames('mgmt-group-label', { 'disabled': disabled })} >{info.display}</label>
                </div>
                {
                    info.type.includes("date") &&
                    <DateTime />
                }

                {
                    info.type === "switch" &&
                    Toggle()
                }
                {
                    info.type === "int" &&
                    Int()
                }
                {
                    info.type === "textarea" &&
                    Textarea()
                }
                {
                    info.type === "text" &&
                    Text()
                }
                {
                    info.type === "resume" &&
                    Resume()
                }
            </div>
        </Fragment>
    )
}