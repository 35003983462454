import React from 'react';

export default function AgendaIcon (props) {

    const {isActive, isClosed} = props

    return (
        <svg className={isClosed ? 'menu-icon icon-openned' : 'menu-icon icon-closed'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 65">
                        <g className={isActive ? 'icon-active' : 'icon-inactive'}>
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M42.06,8.65H36.41V5.29A1.25,1.25,0,0,0,36,4.38,1.28,1.28,0,0,0,35.13,4H30.2a6.68,6.68,0,0,0-6.12-4h0A6.55,6.55,0,0,0,18,4H13.09a1.34,1.34,0,0,0-1.34,1.29V8.65H6.23a6.14,6.14,0,0,0-6.23,6V58.09a6.13,6.13,0,0,0,6.23,6H42.06a6.12,6.12,0,0,0,6.23-6V14.63A6.13,6.13,0,0,0,42.06,8.65ZM14.33,6.59H18.9a1.29,1.29,0,0,0,1.25-1,4,4,0,0,1,3.91-3h0a3.94,3.94,0,0,1,3.91,3,1.31,1.31,0,0,0,1.27,1h4.57V13H14.33Zm31.38,51.5a3.54,3.54,0,0,1-3.65,3.38H6.23a3.54,3.54,0,0,1-3.65-3.38V14.63a3.56,3.56,0,0,1,3.65-3.4h5.52V14.3a1.36,1.36,0,0,0,1.34,1.32h22a1.3,1.3,0,0,0,1.28-1.32V11.23h5.65a3.56,3.56,0,0,1,3.65,3.4Z"/>
                            <path d="M37.54,32h-14a1.29,1.29,0,0,0,0,2.58h14a1.29,1.29,0,0,0,0-2.58Z"/>
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M37.54,52.13H10.75a1.3,1.3,0,0,0,0,2.59H37.54a1.3,1.3,0,0,0,0-2.59Z" />
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M11.5,38.09h.09a1.25,1.25,0,0,0,.88-.36l5.6-5.6a1.25,1.25,0,0,0-1.76-1.77l-4.59,4.58-1.53-2a1.25,1.25,0,0,0-2,1.5l2.4,3.2A1.24,1.24,0,0,0,11.5,38.09Z" />
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M37.54,42h-14a1.29,1.29,0,0,0,0,2.58h14a1.29,1.29,0,0,0,0-2.58Z" />
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M37.54,22h-14a1.29,1.29,0,0,0,0,2.58h14a1.29,1.29,0,0,0,0-2.58Z" />
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M10.59,27.59a1.24,1.24,0,0,0,.91.5h.09a1.25,1.25,0,0,0,.88-.36l5.6-5.6a1.25,1.25,0,1,0-1.76-1.77l-4.59,4.58-1.53-2.05a1.25,1.25,0,1,0-2,1.5Z" />
                        </g>
            </svg>
    )
}
