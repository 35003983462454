
export default class MedicationRequest {

    constructor (id = "" ,service = "", type = "",quantity = 0, frequency = 0, term = 0, dosage = 0, note = "", occurrence = "", date = "", practitionerID, patientID, encounterID ) {
        this._id = id;
        this._service = service;
        this._note = note;
        this._type = type;
        this._quantity = quantity;
        this._frequency = frequency;
        this._term = term;
        this._dosage = dosage;
        this._occurrence = occurrence;
        this._date = date;
        this._practitionerID = practitionerID;
        this._patientID = patientID;
        this._encounterID = encounterID;
    }

    set id (id) {
        this._id = id;
    } 

    set quantity (quantity) {
        this._quantity = quantity;
    }

    set frequency (frequency) {
      this._frequency = frequency;
    }

    set term (term) {
      this._term = term;
    }

    set dosage (dosage) {
      this._dosage = dosage;
    }

    set service (service) {
        this._service = service;
    }
    
    set note (note) {
        this._note = note;
    }

    set type (type) {
      this._type = type;
    }

    set occurrence (occurrence) {
        this._occurrence = occurrence;
    }

    set practitionerID (practitionerID) {
        this._practitionerID = practitionerID;
    }

    set patientID (patientID) {
        this._patientID = patientID;
    }

    set encounterID (encounterID) {
        this._encounterID = encounterID;
    }

    set date (date) {
      this._date = date;
  }

    get newMedicationRequest () {
        return new MedicationRequestObject(
            this._id,
            this._service,
            this._quantity,
            this._frequency,
            this._term,
            this._dosage,
            this._note,
            this._occurrence,
            this._date,
            this._patientID,
            this._practitionerID,
            this._encounterID,
            this._type
        )
    }
}

function MedicationRequestObject (id, service, quantity, frequency, term, dosage, note, occurrence, date, patientID, practitionerID, encounterID, type ) {
    
    const estructure = {
      "resourceType": "MedicationRequest",
      "identifier": [
        {
          "use": "official",
          "system": "http://indigo.tech",
          "value": id
        },
        {
          "use": "usual",
          "system": "http://indigo.tech",
          "value": type === "Normal" ? type : "Protocolizado"
        }
      ],
      "status": "completed",
      "intent": "order",
      "medicationCodeableConcept": {
        "coding": [
          {
            "system": "http://snomed.info/sct",
            "code": service.code,
            "display": service.display
          }
        ]
      },
      // "medicationReference": {
      //   "reference": "Medication/"+service
      // },
      "subject": {
        "reference": patientID,
      },
      "authoredOn": date,
      "requester": {
        "reference": practitionerID,
      },
      "encounter": {
        "reference": "Encounter/"+encounterID,
      },
      "dosageInstruction": [
        {
          "text": note !== undefined ? note : " ",
          "timing": {
            "repeat": {
                "boundsDuration": {
                    "value": term,
                    "unit": "hour",
                    "system": "http://unitsofmeasure.org",
                    "code": "h"
                },
                "frequency": (24/frequency.time).toString(), /*duracion(Hs)/Frecuencia(Hs) */
                "period": 1,
                "periodUnit": "d"
            }
        },
          "additionalInstruction": [
            {
              "coding": [
                {
                  "system": "http://snomed.info/sct",
                  "code": frequency.code,
                  "display": frequency.display
                }
              ]
            },
            {
              "coding": [
                {
                  "system": "http://snomed.info/sct",
                  "code": Math.floor(term/24),
                  "display": Math.floor(term/24) + " Días"
                }
              ]
            }
          ],
          "doseAndRate": [
            {
              "doseQuantity": {
                "value": quantity,
              },
              "type": {
                "coding": [
                  {
                    "system": "http://snomed.info/sct",
                    "code": "QTY",
                    "display": "Dosage Quantity"
                  }
                ],
                "text": "Dosage Quantity"
              }
            }
          ]
        }
      ],
      "note": [
        {
          "authorReference": {
              "reference": practitionerID,
            },
          "time": occurrence,
          "text": note !== undefined ? note : " "
        }
      ]
    }

    console.log("Estructure", estructure)
    return estructure
}