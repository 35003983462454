export const vitalSigns = {
    "PRESIONARTERIALSISTOLICA": "Pas (mmHg)",
    "PRESIONARTERIALDIASTOLICA": "Pad (mmHg)",
    "PA": "(mmHg)",
    "FRECUENCIACARDIACA": "x 1min",
    "FC": "x 1min",
    "FRECUENCIARESPIRATORIA": "x 1min",
    "FR": "x 1min",
    "SATURACIONO2": "SaO**2",
    "SATO2": "SaO**2",
    "PESO": "Kg",
    "TALLA": "cm",
    "IMC": "Kg/m**2",
    "TEMPERATURAAXILAR": "°C",
    "TEMPERATURARECTAL": "°C",
    "CIRCUNFERENCIAABDOMINAL": "cm",
    "CIRCUNFERENCIACEFALICA": "cm",
    "GLASGOW": "",
}

export const masks = {
    // "PRESIONARTERIALSISTOLICA": "Pas (mmHg)",
    // "PRESIONARTERIALDIASTOLICA": "Pad (mmHg)",
    "HORA": "Hh:Mm",
    "PA": "000/00",
    "HGT": "000",
    "NHISS": "00",
    // "FRECUENCIACARDIACA": "x 1min",
    "FC": "000",
    // "FRECUENCIARESPIRATORIA": "x 1min",
    "FR": "00",
    // "SATURACIONO2": "SaO**2",
    "PESO": "000",
    "TALLA": "000",
    "IMC": "00",
    // "TEMPERATURAAXILAR": "°C",
    // "TEMPERATURARECTAL": "°C",
    // "CIRCUNFERENCIAABDOMINAL": "cm",
    // "CIRCUNFERENCIACEFALICA": "cm",
    // "GLASGOW": "",
}