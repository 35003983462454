import { types } from "../types/types";
import { callGraphFromClient, display, getClientSideToken, getServerSideToken, requestConsent, serverSideToken } from "../../utils/microsoftTeams";
import { finishLoading, startLoading } from "./ui";
import { getAllSpecialitiesFhir, getAllUsers, getAllValueSets, getUserByMail, loginUser } from "../../utils/helpersPoints";
import { getPractitionerByCode } from '../../utils/helpersPoints';

const refreshToken = async() => {
    const clientSideToken = await getClientSideToken()  
    sessionStorage.setItem('auth', clientSideToken);
}
setInterval(() => {
    refreshToken()
}, 5*60*1000);

export const startLogin = () => {
    return async( dispatch ) => {
        // dispatch( startLoading() );
        try {
            // 1. Get auth token
            // Ask Teams to get us a token from AAD
            await getClientSideToken()
            ////console.log('decodedToken: ', decodedToken);
                .then(async (clientSideToken) => {
                    sessionStorage.setItem('auth', clientSideToken);
                    return getServerSideToken(clientSideToken);
                })
                .then(async(serverSideTokenAux) => {
                    //console.log('serverSideTokenAux: ', serverSideTokenAux)
                    const auth = sessionStorage.getItem('auth');
                    //console.log('token authh: ', auth);
                    const profile = await serverSideToken(serverSideTokenAux);
                    // const allValuesSet = await getAllValueSets(auth);

                    const allValuesSet = await getAllSpecialitiesFhir(auth);
                    //console.log('allValuesSet: ', allValuesSet );
                    //const specialities = allValuesSet.find( spec => spec?.nameElement?.value === "SPECIALITIES");
                    const specialitiesValues = allValuesSet.compose.include[0]?.concept;
                    ////console.log('Specialities: ', specialitiesValues)

                    // const composeSpecialities = specialities.compose.include[0].concept;
                    //const composeSpecialities = specialities.compose.include[0].concept;
                    ////console.log('composeSpecialities: ', composeSpecialities)

                    const emailUser = await getUserByMail(auth, profile.mail);

                    const practitioner = await getPractitionerByCode(auth, emailUser?.MedicalCodeSIH);

                    const practitionerReference = practitioner && practitioner.resourceType+'/'+practitioner.id 
                    if (emailUser) {
                        // console.log('EmaillUser: ', emailUser);
                        let specialities = emailUser?.Specialty;
                        let isActive = emailUser?.IsActive;
                        let codeSIH =emailUser?.CodeSIH;

                        let specialities2 = JSON.parse(specialities.replace("{", "[").replace("}", "]"));

                        let allSpecialities = specialities2.map( esp => {
                            return specialitiesValues.find( espe => espe.code === esp)
                        });
                        ////console.log('allSpecialities: ', allSpecialities);
                        const type = 2;
                        ////console.log('Profileee: ', profile);
                        dispatch( login(profile.id, codeSIH, profile.mail, profile.displayName,
                            profile.givenName, profile.surname,
                            profile.jobTitle, profile.mobilePhone,
                            profile.officeLocation, profile.preferredLanguage, type,
                            allSpecialities, isActive, emailUser, practitionerReference
                            ));

                        localStorage.setItem('token', profile.id );
                        localStorage.setItem('type', type);

                        const urlImage = await callGraphFromClient(profile.id, serverSideTokenAux);
                        dispatch( setImageUrl(urlImage));
                    }
                    else{
                        const type = 2;
                        dispatch( login(profile.id, profile.mail, profile.displayName,
                            profile.givenName, profile.surname,
                            profile.jobTitle, profile.mobilePhone,
                            profile.officeLocation, profile.preferredLanguage, type, emailUser,practitionerReference ));
    
                        localStorage.setItem('token', profile.id );
                        localStorage.setItem('type', type);
    
                        const urlImage = await callGraphFromClient(profile.id, serverSideTokenAux);
                        dispatch( setImageUrl(urlImage));
                    }

                    dispatch(finishLoading());
                    
                    return profile;
                }).catch((error) => {
                    if ("unauthorized_client" === error || "invalid_grant" === error) {
                        console.error(`Error: ${error} - user or admin consent required`);
                        // Display in-line button so user can consent
                        let button = display("Consent", "button");
                        button.onclick = (() => {
                            requestConsent()
                                .then((result) => {
                                    // Consent succeeded - use the token we got back
                                    let accessToken = result.accessToken;
                                    display(`Received access token ${accessToken}`);
                                    serverSideToken(accessToken);
                                })
                                .catch((error) => {
                                    display(`ERROR ${error}`);
                                    // Consent failed - offer to refresh the page
                                    button.disabled = true;
                                    let refreshButton = display("Refresh page", "button");
                                    refreshButton.onclick = (() => { window.location.reload(); });
                                });
                        });
                    } else {
                        // Something else went wrong
                        display(`Error from web service: ${error}`);
                    }
                    dispatch(finishLoading());

                });

        } catch (error) {
            alert('Error: ', error);
            dispatch(finishLoading());
        }
    }
}

export const setImageUrl = (imageUrl )=> {
    return({
        type: types.imageUrl,
        payload: {
            image: imageUrl
        }
    })
}

export const login = ( 
    uid , codeSIH, email,  displayName, name, lastname, 
    jobTitle, mobilePhone, office, 
    language, type=2 ,specialities = {},
    isActive = false, emailUser,practitionerReference
    ) => ({
    type: types.login,
    payload: {
        uid,
        codeSIH,
        email,
        displayName,
        name,
        lastname,
        jobTitle,
        mobilePhone,
        office,
        language,
        type,
        specialities,
        isActive,
        emailUser,
        practitionerReference
    }
});

export const startLogout = () => {
    return ( dispatch ) => {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('type');
        sessionStorage.removeItem('rowKeyAdmin');
        dispatch( logout() );
        //window.location.href = '/';
        dispatch(finishLoading());
    }
};

export const logout = () => ({
    type: types.logout
});

