import React, { useState, use } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import go from './../../assets/img/logos/go.png'

import Drawer from 'devextreme-react/drawer';
import RadioGroup from 'devextreme-react/radio-group';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import List from 'devextreme-react/list.js'; //NavigationList
import Button from 'devextreme-react/button';

import './style/dashboard.css';
import { Panela } from './Panel';
import { RegisterUser } from './RegisterUser';
import { Users } from './Users';
import { logout, startLogout } from '../../redux/actions/auth';
import { Portals } from './Portals';
import LoadPanel from 'devextreme-react/load-panel';

export const Dashboard = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        opened: true,
        openedStateMode: 'shrink',
        revealMode: 'slide',
        position: 'left'
    });
    const { loading  } = useSelector(state => state.ui);


    const handleLogout = () => {
        dispatch(startLogout());
        history.push('/dashboard')
    }

    //Data
    const navigation = [
        { id: 1, text: 'Usuarios', icon: 'card', component:  <Users/>},
        { id: 2, text: 'Panel reintentos', icon: 'chart', component: <Panela/> },
        { id: 3, text: 'Portales', icon: 'columnchooser', component: <Portals/> },
    ];
    /*/*/

    const [component, setComponent] = useState(navigation[0].component)

    const handleComponent = (e) => {
        console.log(e)
        setComponent(e.itemData.component)
    }

    class NavigationList extends React.PureComponent {
        render() {
          return (
            <div className="list" style={{ width: '200px'}}>
              <List
                dataSource={navigation}
                hoverStateEnabled={false}
                activeStateEnabled={false}
                focusStateEnabled={true}
                //elementAttr={{ class: 'panel-list dx-theme-accent-as-background-color' }}
                elementAttr={{class: 'panel-list2'}} 
                onItemClick = {(e)=> handleComponent(e)}
                />
            </div>
          );
        }
      }

    ////////

    const onOpenedStateModeChanged = ({ value }) => {
        //this.setState({ openedStateMode: value });
        setState({...state, openedStateMode: value})
    }
    
    const onRevealModeChanged = ({ value }) => {
        //this.setState({ revealMode: value });
        setState({...state, revealMode: value})
    }

    const onPositionChanged = ({ value }) => {
        //this.setState({ position: value });
        setState({...state, position: value})
    }

    const onOutsideClick = () => {
        //this.setState({ opened: false });
        setState({...state, opened: false})
    }

    const onClickMenuBtn = () =>{
        setState({...state,opened: !state.opened})
    }

    const { opened, openedStateMode, position, revealMode } = state;
     
    return (
        <>
            <Toolbar >
                <Item
                    visible={true}
                    location={'before'}
                    widget={'dxButton'}
                    cssClass={'menu-button'}
                >
                    <Button icon="menu" stylingMode="text" onClick={onClickMenuBtn} 
                    />
                </Item>
                <Item
                    visible={true}
                    location={'before'}
                    //widget={'dxButton'}
                    text="OneView"
                    //cssClass={'menu-button'}
                >
                </Item>
                <Item
                    visible={true}
                    location={'after'}
                    widget={'dxButton'}
                    cssClass={'menu-button'}
                >
                    <Button 
                        icon="runner" 
                        stylingMode="outlined"
                        text="Salir"
                        rtlEnabled={false}
                        elementAttr={{class: 'exit-button'}} 
                        //cssClass={'exit-button'}
                        onClick={handleLogout} 
                    />
                </Item>
                </Toolbar>
            <Drawer
                opened={opened}
                openedStateMode={openedStateMode}
                position={position}
                revealMode={revealMode}
                component={NavigationList}
                height="auto"
                closeOnOutsideClick={onOutsideClick}
                >
                
                <div id="content" className="dx-theme-background-color">
                    {/* { <LoadPanel visible={loading} />} */}
                    { component}
                </div>
            </Drawer>
            {/* <div className="options">
                <div className="caption">Options</div>
                <div className="option">
                    <label>Opened state mode</label>
                    <RadioGroup
                        items={['push', 'shrink', 'overlap']}
                        layout="horizontal"
                        value={openedStateMode}
                        onValueChanged={onOpenedStateModeChanged}
                    />
                </div>
                {' '}
                <div className="option">
                    <label>Position</label>
                    <RadioGroup
                        items={['left', 'right']}
                        layout="horizontal"
                        value={position}
                        onValueChanged={onPositionChanged}
                    />
                </div>
                {' '}
                {openedStateMode !== 'push' && (<div className="option">
                    <label>Reveal mode</label>
                    <RadioGroup
                        items={['slide', 'expand']}
                        layout="horizontal"
                        value={revealMode}
                        onValueChanged={onRevealModeChanged}
                    />
                </div>)}
            </div> */}
        </>
    )
}
