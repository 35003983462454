import React, { Fragment } from 'react';

import DataGrid, {
  Column,
  Editing,
  Paging,
  FilterRow
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { connect } from 'react-redux';
import { sectionUpdated } from '../../../../../redux/actions/fhir';
import Popup from 'devextreme-react/popup';
import Button from 'devextreme-react/button';


const mapStateToProps = state => ({
  fhir: state.fhir,
  responsive: state.ui.responsive,
})

const mapDispatchToProps = (dispatch) => {
  return {
    sectionUpdated: () => dispatch(sectionUpdated())
  }
} ;
class ManagementDataGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnRef: React.createRef(),
      dataSource: [],
      events: [],
      selectTextOnEditStart: true,
      startEditAction: 'click',
      managementGridRef: React.createRef(),
      popupOpen: false,
      selectedRow: 0,
    };
    this.onSelectTextOnEditStartChanged = this.onSelectTextOnEditStartChanged.bind(this);
    this.onStartEditActionChanged = this.onStartEditActionChanged.bind(this);
    this.onEditingStart = this.logEvent.bind(this, 'EditingStart');
  }

  togglePopup = () => {
    this.setState({...this.state, popupOpen: !this.state.popupOpen});
  }

  logEvent(eventName) {
    this.setState((state) => {
      return { events: [eventName].concat(state.events) };
    });
  }

  onSelectTextOnEditStartChanged(args) {
    this.setState({
      selectTextOnEditStart: args.value
    });
  }
  onStartEditActionChanged(args) {
    this.setState({
      startEditAction: args.value
    });
  }

  componentDidMount() {
    this.props.getGridRef(this.state.managementGridRef)
  }

  editItem = (e) => {
    this.props.getEditItem(e.data.id);
    e.cancel = true;
  }

  deleteItem = (e) => {
    let id = "MedicationRequest";
    if(this.props.info.id !== "prescriptions") {
      id = "ServiceRequest";
    } else {
      id = "MedicationRequest";
    }
    const index = this.props.fhir.map( f => {return (f?.title)}).indexOf(id);
    let mySection = this.props.fhir[index];
    const entryIndex = mySection?.entry?.map( ent => {
        return(ent?.identifier[0]?.value)
    }).indexOf(e.data.id);

    mySection?.entry?.splice(entryIndex,1);

    this.props.sectionUpdated(mySection);
    this.props.getDeleteItem(e.data.id);
    e.cancel = true;
  }  

  isNotEmpty = (value) => {
    return value !== undefined && value !== null && value !== '';
  }

  extendInformation = (e) => {
    if(["extrasmall","small","medium"].includes(this.props.responsive)) {
      this.togglePopup();
    }
    this.setState({selectedRow: e.rowIndex})
  }
  
  render() {

    const { dataSource, responsive, readOnly } = this.props;

    const { popupOpen, selectedRow } = this.state;

    return (
      <div id="data-grid-demo">
        <DataGrid
          dataSource={dataSource}
          keyExpr="id"
          showBorders={true}
          onEditingStart={this.editItem}
          noDataText={"Aún no hay servicios"}
          onRowRemoved={this.deleteItem}
          remoteOperations={true}
          ref={this.state.managementGridRef}
          height={475}
          onRowClick={this.extendInformation}
        >
          <FilterRow visible={true} />
          <Paging enabled={false} />
          <Editing
            mode="row"
            allowUpdating={!readOnly}
            allowDeleting={!readOnly}
            selectTextOnEditStart={this.state.selectTextOnEditStart}
            startEditAction={this.state.startEditAction} />
          {!this.props.current &&
            <Column dataField={'dateTime'} caption={'Fecha'} dataType={'date'} ref={this.state.columnRef[this.props?.info?.options?.length]}/>
          }
        {this.props.info.options.map( (opt, key) => {
          if(["extrasmall","small","medium"].includes(responsive)) {
            if(["medicines", "resume"].includes(opt.id)) {
              return (
                <Column key={key} dataField={opt.id} caption={opt.display} ref={this.state.columnRef[key]}/>
              )
            }
          } else {
            if(opt.id.includes("Qty")) return;
            return (
                <Column key={key} dataField={opt.id} caption={opt.display} ref={this.state.columnRef[key]}/>
            )
          }
        })}
        </DataGrid>
        {<Popup
            visible={popupOpen}
            onHiding={this.togglePopup}
            dragEnabled={true}
            closeOnOutsideClick={true}
            showTitle={true}
            title={"Prescripción N° "+ (selectedRow+1)}
            width={350}
            maxHeight={500}
            >
            <div className={'cancel-popup'} style={{justifyContent: "space-between"}}>

            <div className={'popup-section'}>
              {this.props.info.options.map((opt, index) => {
                if((this.props.dataSource && this.props.dataSource[selectedRow]) && this.props.dataSource[selectedRow][opt.id] === "") return;
                return (
                  <div className={'popup-section-info'} key={index}>
                    <div className={'popup-section-title'}>{opt.display}</div>
                    <div className={'popup-section-paragraph'}>{(this.props.dataSource && this.props.dataSource[selectedRow]) && this.props.dataSource[selectedRow][opt.id]}</div>
                  </div>
                )
              })  }
            </div>
            <div className={'cancel-buttons'}>
                <Button
                  width={130}
                  icon={'check'}
                  text="Cerrar"
                  type="success"
                  stylingMode="contained"
                  onClick={this.togglePopup}                        
                />
            </div>
            </div>
        </Popup>}
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ManagementDataGrid);