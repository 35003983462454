import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
  ButtonOptions,
  EmptyItem
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { CheckBox } from 'devextreme-react/check-box';

import 'devextreme-react/autocomplete';
import { startCreatePortal, startCreateUser } from '../../redux/actions/dashboard';

export const RegisterPortal = ({setCreatePortal}) => {
    const dispatch = useDispatch();
    const {email, password} = useSelector(state => state.dashboard);
    
    const [portal, setPortal] = useState({
      //partitionKey: "User",
      IdAgnes: '',
      Location: '',
      Name: '',
      //portal: '',
      IsActive: false,
    
    });
    const initialPortal =
    {
      //partitionKey: "User",
      IdAgnes: '',
      Location: '',
      Name: '',
      //portal: '',
      IsActive: false,
    }

    const handleSubmit = (e) => {
      ////console.log('portal: ', portal)
      dispatch(startCreatePortal(email, password, JSON.stringify(portal)))
      setPortal(initialPortal);
      setCreatePortal(false);
  
      e.preventDefault();
    }

    const handleActive = (e) => {
      if (e.dataField === 'IsActive') {
        setPortal({ ...portal, IsActive: e.value})
      }
    }
    const buttonOptions = {
      text: 'Registrar',
      type: 'default',
      useSubmitBehavior: true,
      //onClick: 
    };

    const checkBoxActive = {
      text: 'Activo',
      value: portal.IsActive
    };

    const searchFHIRid = (e) => {
        //TODO
    }

    return (
        <form action="your-action" onSubmit={handleSubmit} 
        style={{margin: '10px',  maxHeight: '50vh', overflow: 'auto'}}
        /*style={{margin: '40px',  maxHeight: '60vh', overflow: 'auto'}}*/>
          <Form
            formData={portal}
            readOnly={false}
            showColonAfterLabel={true}
            showValidationSummary={true}
            validationGroup="customerData"
            onFieldDataChanged= { (e)=> handleActive(e)}
          >

            <GroupItem caption="Registro portal">

              <SimpleItem dataField="Name">
                <Label text="Nombre" />
                <RequiredRule message="Nombres son requeridos" />
                {/* <PatternRule message="No usar digitos en el nombre" pattern={/^[^0-9]+$/} /> */}
              </SimpleItem>

              <SimpleItem dataField="Location" >
                <Label text="Ubicación-FHIR" /><ButtonItem alignment="left">
                    <ButtonOptions
                        icon = "search"
                        visible={true}
                        text="Search idFHIR"
                        onClick={searchFHIRid} />
                    </ButtonItem>
              </SimpleItem>

              <SimpleItem dataField="IdAgnes">
                <Label text="IdAgnes"/>
              </SimpleItem>

              {/* <SimpleItem dataField="portal" >
                <Label text="Portal" />
                <RequiredRule message="Portal es requerido" />
                <PatternRule message="No usar digitos en el portal"
                  pattern={/^[^0-9]+$/} />
              </SimpleItem> */}

              <SimpleItem dataField="IsActive"
                editorType="dxCheckBox"
                editorOptions={checkBoxActive}
                //editorOptions= {{text: "Administrador"}}
                //value = {user.Admin}
              >
                <Label visible={false} />
              </SimpleItem>
            
              <ButtonItem horizontalAlignment="left"
                buttonOptions={buttonOptions}
              />
            </GroupItem>

          </Form>
        </form>
    )
}
