import React, { /*useEffect, useRef, useCallback, useMemo*/ } from 'react';


export default function SideNavigationContainer (props) {

    const {
        isClosed,
        isActive,
        title,
        //Icon,
        children,
        select,
      } = props;

    return (
        <div
        className={isActive ? 'menu-button-content active' : 'menu-button-content inactive'}
        onClick={select}
        >   
            {children}
            <p className={isClosed ? isActive ? 'menu-button-title active roboto' : 'menu-button-title inactive roboto' : 'menu-button-title none'}>{title}</p>
        </div>
    );
}