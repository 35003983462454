import React, {Fragment, useState, useEffect, useRef} from 'react';
import { Tooltip } from 'devextreme-react/tooltip';

function IndigoSelectDrop (props) {
    
    var classNames = require('classnames');
    const { items, onItemClick, onClickOutside, arrowDropIndex, onItemFocus } = props;
    const [showTooltip, setShowTooltip] = useState({toggle: false, item: '', description: ''});
    const [dataSource, setDataSource] = useState(items);
    const $ = require('jquery');

    const dropRef = React.createRef();
    let dropParent;
    useEffect(() => {
        dropParent = dropRef.current.parentNode;
    },[])


    const toggleEnterTooltip = (id, description) => {
        if (description !== undefined) {
            if (description?.length > 0) {
                setShowTooltip({...showTooltip, toggle: true, item: id, description: description});
            }
        }
    }

    const toggleLeaveTooltip = () => {
        setShowTooltip({...showTooltip, toggle: false, item: '', description: ''});
    }
    
    const handleDocumentClick = (e) => {
        if (e && e.which === 3) return;
        const container = dropParent;
        if (!container.contains(e.target) && 
        (!e.target.classList.contains("search-dropdown-item") &&
        !e.target.classList.contains("search-dropdown-item-label")
        )) {
            //console.log("e.target;:", e.target)
            onClickOutside();
            return onClickOutside;
        }
    }

    const focusElement = (index) => {
        if(!items) return;
        const item = items[index]
        if(!item) return;
        onItemFocus({id: item.id, display: item.display, description: item.description, canWrite: item.canWrite})
    }

    useEffect(() => { 
        document.addEventListener('click', handleDocumentClick);
        document.addEventListener('scroll', noScrollOnDropdown);
        return function cleanup () {
            document.removeEventListener('click', handleDocumentClick);
            document.removeEventListener('scroll', noScrollOnDropdown);
        }
     }, [])


    const noScrollOnDropdown = (e) => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //console.log(scrollTop)
        window.onscroll = function() {
            window.scrollTo(scrollTop);
        };
        e.preventDefault();
        e.stopPropagation();
    }

    const sortItems = (items) => {
        const newItems = items?.sort((a,b) => (a.display > b.display) ? 1 : ((b.display > a.display) ? -1 : 0));
        setDataSource(newItems)
    }

    useEffect(() => {
        const allItems = document.getElementsByClassName('search-dropdown-item');

        if(arrowDropIndex > -1) {
            $('.search-dropdown-item').each(
                function() {
                    const scrollPos = $(this).position().top;
                    if(this === allItems[arrowDropIndex]) {
                        $('.search-dropdown-list-container').animate({
                            scrollTop: scrollPos
                        }, 100)
                    }
                }
            )
            focusElement(arrowDropIndex)
        } else {
            window.onscroll = function() {};
        }
        // allItems[arrowDropIndex]?.focus()
     },[arrowDropIndex])
    
     useEffect(() => {
        sortItems(items)
    }, [JSON.stringify(items)])

    return (
        <div ref={dropRef} className={classNames('search-dropdown')}>
            <div className={'search-dropdown-list-container'}>
                {dataSource?.map( (item,key) => {
                return (
                    <Fragment key={key}>
                        <div className={classNames('search-dropdown-item', {'focus' : arrowDropIndex === key})} 
                            id={item.id.toString()+"Input"}
                            onMouseEnter={() => toggleEnterTooltip(item.id,item.description)}
                            onMouseLeave={toggleLeaveTooltip}
                            onClick={() => onItemClick({id: item.id, display: item.display, description: item.description, canWrite: item.canWrite, questionNumber: item.questionNumber})}
                        >
                            <p className="search-dropdown-item-label">
                                {item.display}
                            </p>
                        </div>
                    </Fragment>
                )
            })}
            </div>
        </div>
    )
}

export default IndigoSelectDrop