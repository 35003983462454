import LoadPanel from 'devextreme-react/load-panel';
import React from 'react';

export const TabComponent = () => {
    return (
        
        <div>
            <LoadPanel visible={true} />
        </div>
    )
}