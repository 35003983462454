import React from 'react';
import { HomePage } from '../../pages';

class ErrorBoundaryWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    // static getDerivedStateFromError(error) {
    //   // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    //   console.log("Error", error)
    //   if(error) {
    //       setTimeout(() => {
    //         // this.props.history.push({
    //         //     pathname: '/home',
    //         //     state: {
    //         //         // from: this.props.location.pathname
    //         //     }
    //         // });
    //       }, 1500);
    //   }
    //   return { hasError: true };
    // }
  
    componentDidCatch(error, errorInfo) {
      // También puedes registrar el error en un servicio de reporte de errores
    //   logErrorToMyService(error, errorInfo);
        console.error("error", error, errorInfo);
        if(error) {
            this.setState({ hasError: true });
        }
    }
  
    render() {
        // if (this.state.hasError) {
        //     // Puedes renderizar cualquier interfaz de repuesto

        //     return <HomePage/>;
        // }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundaryWrapper