import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


import { setData } from '../../../../../redux/actions/parametrizer';
import IndigoSearchInput from './indigoSearchInput';

import Button from 'devextreme-react/button';
import ContextMenu from 'devextreme-react/context-menu';
import { Popup } from 'devextreme-react/popup';
import { Tooltip } from 'devextreme-react/tooltip';
import { Lookup } from 'devextreme-react/lookup';

import IndigoCommentInput from '../indigoCommentInput';
import IndigoItemsList from '../form/indigoItemsList';
import { IndigoSearchSmallTag } from '..';
import Observation from '../../utils/fhir/observation';
import { sectionUpdated } from '../../../../../redux/actions/fhir';
import moment from 'moment';
import { getDataStore } from './../../../../../utils/xpo';
import { setAutoSaveSearchInput } from '../../../../../redux/actions/autoSave';
import { addCompletedItem, removeCompletedItem } from '../../../../../redux/actions/validations';

function IndigoSearch(props) {
    var classNames = require('classnames');
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.parametrizer)
    const { responsive } = useSelector(state => state.ui)
    const { fhir } = useSelector(state => state)

    const { info, getFocused, getDropped, focused, granPaID, editable, render, readOnly } = props;
    const { id: encounterId } = useParams();
    const { patient, practitioner, startDate, idEncounterSIH, idEncounterFhir } = useSelector(state => state.agenda.appointment);
    const { completedList, validationList} = useSelector( state => state.validation);
    const location = useSelector(state => state?.agenda?.appointment?.location);

    const [contextOpen, setContextOpen] = useState(null);
    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);
    const [localItems, setLocalItems] = useState([]);

    const autoSave = useSelector( state => state.autoSave);
    const savedData = localStorage.getItem(encounterId);

    let defaultItems = [];
    let defaultComments = {};
    if(savedData && savedData !== "undefined" && savedData !== "null") {
        if(JSON.parse(savedData)[info.id]?.items) {
            defaultItems = JSON.parse(savedData)[info.id]?.items;
        }
        if(JSON.parse(savedData)[info.id]?.comments) {
            defaultComments = JSON.parse(savedData)[info.id]?.comments;
        }
    }
    const [selectedItems, setSelectedItems] = useState(defaultItems);
    const [comment, setComment] = useState(defaultComments)
    const [mainDiagnosis, setMainDiagnosis] = useState("");
    const [newItem, setNewItem] = useState({status: false, display: ''});
    const [maxSizeDefault, setMaxSizeDefault] = useState({ "extralarge": info.size, "large": (parseInt(info.size, 10) - 1), "medium": (parseInt(info.size, 10) - 2), "small": 2, "extrasmall": 1 });
    const [isWritable, setIsWritable] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [canDrop, setCanDrop] = useState(false);
    const [result, setResult] = useState([]);

    const lookupRef = useRef(null);

    useEffect(() => {
        localStorage.setItem(encounterId, JSON.stringify(autoSave[encounterId]))
    }, [JSON.stringify(autoSave[encounterId])])


    useEffect(() => {
        let questionContainer = document.getElementById("questionContainer");
        if (lookupRef.current) {
            let closeFunction = () => lookupRef?.current?.instance?.option("opened", false);
            questionContainer?.addEventListener("wheel", closeFunction)
            return () => {
                questionContainer?.removeEventListener("wheel", closeFunction)
            }
        }
    }, [])

    //const { diagnosticsData } = useSelector(state => state.managmentPlan);

    const store = getDataStore("/api/xpo/get/diagnostic");

    let locationIdetifier;

    if (location?.length >= 1) {
        locationIdetifier = `${location[0].id}.${location[0].identifier[0].value}`;
    }
    let key = info.isDiag ? "Diagnostics" : "Observations";

    /* useEffect(() => {
        if (info.isDiag) {
            //let mappedData = JSON.parse(JSON.stringify(diagnosticsData?.mappedData));
            let mappedData = diagnosticsData?.mappedData && JSON.parse(JSON.stringify(diagnosticsData?.mappedData));
            mappedData && setLocalItems(mappedData);
        }
    }, [diagnosticsData]) */

    const [newObservation, setNewObservation] = useState(new Observation());
    useEffect(() => {
        if (info.isDiag) {
            let tempObject = {
                encounterID: `${idEncounterFhir}.${idEncounterSIH}`,
                locationIdetifier,
                diagnosisCodes: selectedItems?.map(item => item.id).filter(item => item !== mainDiagnosis),
                mainDiagnosisCode: mainDiagnosis
            }

            const index = fhir.map(f => { return (f?.title) }).indexOf(key);
            let mySection = fhir[index];

            if(!mySection) return;
            mySection.entry = [tempObject];

            dispatch(sectionUpdated(mySection));
        } else {
            newObservation.patientID = patient ? patient : null;
            newObservation.encounterID = encounterId ? encounterId : null;
            newObservation.date = moment(new Date(startDate)).format('YYYY-MM-DD');
            newObservation.practitionerID = practitioner ? practitioner : null;

            newObservation.inputID = { type: "search", id: info.id, context: 100 };
            newObservation.canWrite = true;
            newObservation.label = info.display;
            newObservation.questionNumber = info.questionNumber;
            newObservation.type = 2;
            newObservation.multipleCommentary = true;
            newObservation.value = null;

            sizeConvert();

            const index = fhir.map(f => { return (f?.title) }).indexOf(key);
            let mySection = fhir[index];

            if (!mySection?.entry.some(ent => { return ent?.identifier[0]?.value?.includes(info.id) })) {
                mySection?.entry?.push(newObservation.newObvervation);
            }
            try {
                dispatch(sectionUpdated(mySection));
            } catch (error) {
                debugger
            }
        }
    }, [render])

    const sizeConvert = () => {
        switch (info.size) {
            case 4:
                setMaxSizeDefault({ "extralarge": info.size, "large": 3, "medium": 3, "small": 2, "extrasmall": 1 });
                break;
            case 2:
                setMaxSizeDefault({ "extralarge": info.size, "large": 2, "medium": 3, "small": 2, "extrasmall": 1 });
                break;
            case 3:
                setMaxSizeDefault({ "extralarge": info.size, "large": 3, "medium": 3, "small": 2, "extrasmall": 1 });
                break;
            default:
                setMaxSizeDefault({ "extralarge": 5, "large": 3, "medium": 3, "small": 2, "extrasmall": 1 });
                break;
        }
    }

    useEffect(() => {
        sizeConvert();
        if (info.canWrite || readOnly) {
            setLocalItems([]);
        } else {
            if (!info.isDiag) {
                setLocalItems(info.options);
            }
        }
    }, [JSON.stringify(data), info.canWrite, readOnly])

    useEffect(() => {
        if (!info.canWrite && !readOnly) return;
        let selectedItemsCopy
        if (info.selfSort) {
            selectedItemsCopy = selectedItems.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));
        } else {
            selectedItemsCopy = selectedItems.sort((a, b) => (a.display > b.display) ? 1 : ((b.display > a.display) ? -1 : 0));;
        }
        info.options.forEach(opt => {
            if (selectedItemsCopy.some(item => item.id === opt.id)) return;
            if (localItems.some(item => item.id === opt.id)) return;
            selectedItemsCopy.push(opt)
        }
        );
        setSelectedItems(selectedItemsCopy);
    }, [JSON.stringify(info.options), info.canWrite, info.selfSort, JSON.stringify(localItems), readOnly])

    const openPopup = () => {
        setCancelPopUpVisible(true)
    }

    const closePopup = () => {
        setCancelPopUpVisible(false)
    }

    const handleDeleteItem = () => {
        openPopup()
        setContextOpen(false);
        setTimeout(
            () => {
                // this.props.deleteSection("");
                setContextOpen(null);
            },
            [300],
        );
    }

    const handleChangeSize = size => {
        const newData = data;
        if (props.formChildren === true) {
            newData.some(data => {
                data.options.some(quest => {
                    quest.options.some(option => {
                        if (option.id === info.id) {
                            option.size = size
                        }
                    })
                })
            })
        } else {
            newData.some(data => {
                data.options.some(quest => {
                    if (quest.id === info.id) {
                        quest.size = size
                    }
                })
            })
        }
        dispatch(setData(newData))
    }

    const updateList = (list) => {
        const newData = data;
        newData.some(data => {
            data.options.some(quest => {
                if (quest.id === info.id) {
                    quest.options = list
                }
            })
        })
        dispatch(setData(newData))
    }

    const itemClickedLookup = (e) => {
        const item = { id: e.itemData.Code, display: e.itemData.Display};

        let indexSelectedItems = selectedItems.findIndex(selectedItem => selectedItem.id === item.id);
        if (indexSelectedItems >= 0) return;

        selectedItems.push(item);

        selectedItems.sort((a, b) => (a?.display === b?.display) ? 0 : a?.display ? -1 : 1);
        setSelectedItems(JSON.parse(JSON.stringify(selectedItems)));

        // if (!mainDiagnosis) {
            setMainDiagnosis(e.itemData.Code);
        // }

        // let selectedItemsTemp = JSON.parse(JSON.stringify(selectedItems));
        // let item = selectedItemsTemp.splice(selectedItemsTemp.findIndex((item) => item.id === tag), 1);
        // selectedItemsTemp.unshift(item);
        // setMainDiagnosis(tag);

    }

    const itemClicked = (e) => {
        const item = e;
        let items = localItems;
        let selecItems = selectedItems;

        selecItems.push(items[items.map(function (e) {
            return e.display;
        }).indexOf(item)])

        selecItems.sort((a, b) => (a?.canWrite === b?.canWrite) ? 0 : a?.canWrite ? -1 : 1);
        selecItems.sort((a, b) => (a?.display === b?.display) ? 0 : a?.display ? -1 : 1);
        // getFocused(item)
        let writeList = []
        selecItems.map(selItem => {
            writeList.push(selItem?.canWrite)
            if (writeList.includes(true)) {
                setIsWritable(true)
            } else {
                setIsWritable(false)
            }
        })

        items.splice(localItems.map(function (e) {
            return e.display;
        }).indexOf(item), 1);

        clearSearcher();

        setSelectedItems(selecItems);
        setLocalItems(items);

        if(selecItems.length === 0) return;
        setNewItem({status: true, display: item});
    }

    const clearSearcher = () => {
        setClearSearch(true)
        setTimeout(() => {
            setClearSearch(false)
        }, 200);
    }

    const deletetagLookup = (e) => {
        const item = e;
        let selecItems = selectedItems;

        selecItems.splice(selecItems.map(function (e) {
            return e.id;
        }).indexOf(item.id), 1);

        setSelectedItems(JSON.parse(JSON.stringify(selecItems)))
    }

    const deletetag = (e) => {
        const item = e;
        if (info.isDiag) {
            deletetagLookup(item);
            return;
        }
        const items = localItems;
        let selecItems = selectedItems;

        selecItems.splice(selecItems.map(function (e) {
            return e.id;
        }).indexOf(item.id), 1);

        selecItems.sort((a, b) => (a?.canWrite === b?.canWrite) ? 0 : a?.canWrite ? -1 : 1);
        selecItems.sort((a, b) => (a?.display === b?.display) ? 0 : a?.display ? -1 : 1);

        let writeList = []
        selecItems.map(selItem => {
            writeList.push(selItem?.canWrite)
            if (writeList.includes(true)) {
                setIsWritable(true)
            } else {
                setIsWritable(false)
            }
        })

        if (item !== null) {
            if(items.some(i => i.id === item.id)) return;
            items.push(item)
        };

        setSelectedItems(selecItems)
        setLocalItems(items);
    }

    const getCommentary = (com, id) => {
        setComment({ ...comment, [id]: com})
    }

    const handleCloseTagOnMiddleClick = (e, item) => {
        e.preventDefault();
        if (e.buttons !== 4) return;
        deletetag(item)
    }

    const [IMC, setIMC] = useState({ weight: 0, height: 0, IMC: 0 });
    const printTags = () => {
        const isExam = info.display.split(" ").join("").toUpperCase().includes("FÍSICO");
        return (
            selectedItems.map((item, key) => {
                return (
                    <Fragment key={key} >
                        <div id={item?.id+"TagContainer"} 
                         className={ classNames('search-tag-comment', {'has-comment': item?.canWrite}, {'preloaded': info?.canWrite || readOnly})}>
                            <div id={item?.id+"Tag"}
                                onClick={() => readOnly ? null : onTagClick(item?.id)}
                                onMouseDown={(e) => readOnly ? null : handleCloseTagOnMiddleClick(e, item)}
                                className={classNames('search-tag', { 'active': focused === item?.id || item?.id === mainDiagnosis })}
                            >
                                <span>{item?.display}</span>
                                {!readOnly && <Button
                                    onClick={() => deletetag(item)}
                                    icon={'clear'}
                                    stylingMode="text" />}
                            </div>

                            {item?.canWrite &&
                                <div className={'search-comment-container'}>
                                    <IndigoCommentInput
                                        unitDisplay={item?.display?.split(" ").join("")}
                                        readOnly={readOnly}
                                        maxChar={item.maxChar}
                                        id={item?.id}
                                        isFocused={focused === info?.id}
                                        getFocused={getFocused}
                                        isExam={isExam}
                                        valueFromParent={readOnly ? item?.comment : (comment[item.id] ? comment[item?.id] : null)} 
                                        valueToParent={(e) => getCommentary(e,item?.id, key)}
                                    />
                                </div>
                            }
                        </div>

                        {/* {(tooltipInfo === item?.id && item?.canWrite) && <Tooltip
                            target={"#" + item?.id + "TagContainer"}
                            visible={showTooltip}
                            closeOnOutsideClick={false}
                            position={'bottom'}
                        >
                            <div style={{ whiteSpace: 'normal' }}>
                                {item?.display}
                            </div>
                        </Tooltip>} */}
                    </Fragment>
                )
            })
        )
    }

    const printTagsSmall = () => {
        if (selectedItems.length > 0) {
            return (selectedItems.map((item, key) => {
                let label = item.display;
                if (label.length > 40) {
                    label = item.display.slice(0, 30) + '...';
                }
                return (
                    <Fragment key={key}>
                        <IndigoSearchSmallTag
                            events={{ "click": onTagClick }}
                            focused={focused}
                            getFocused={getFocused}
                            deletetag={deletetag}
                            info={info}
                            label={label}
                            item={item}
                            canDrop={canDrop}
                            result={key === 0 ? result : []}
                            comment={comment[item.id] ? comment[item.id] : null}
                            getCommentary={(e, f) => getCommentary(e, f, key)}
                        />
                    </Fragment>
                )

            })
            )
        }
    }

    const onTagClick = (tag) => {
        const element = document.getElementById(tag + "SearchComment")
        if (element) {
            element.focus();
        }
        getFocused(tag);


        if (info.isDiag) {
            let selectedItemsTemp = JSON.parse(JSON.stringify(selectedItems));
            let item = selectedItemsTemp.splice(selectedItemsTemp.findIndex((item) => item.id === tag), 1);
            selectedItemsTemp.unshift(item);
            setMainDiagnosis(tag);
        }
    }

    const responsiveSmall = () => {
        return ["extrasmall", "small", "medium"].includes(responsive);
    }

    const getCanDrop = (can) => {
        setCanDrop(can);
        getDropped(can);
    }

    const getResult = (res) => {
        setResult(res)
    }

    useEffect(() => {
        if (info.isDiag) {
            let tempObject = {
                encounterID: `${idEncounterFhir}.${idEncounterSIH}`,
                locationIdetifier,
                diagnosisCodes: (selectedItems || [])?.map(item => item.id).filter(item => item !== mainDiagnosis),
                mainDiagnosisCode: mainDiagnosis
            }

            tempObject["diagnosisNames"] = [mainDiagnosis, ...(tempObject["diagnosisCodes"] || [])].filter(item => item).map(item => selectedItems.find(selectedItem => selectedItem.id === item)?.display);

            console.log("tempObject:", tempObject)

            const index = fhir.map(f => { return (f?.title) }).indexOf(key);
            let mySection = fhir[index];

            if(!mySection) return;
            mySection.entry = [tempObject];

            dispatch(sectionUpdated(mySection));

        } else {
            const index = fhir.map(f => { return (f?.title) }).indexOf(key);
            let mySection = fhir[index];
            const entryIndex = mySection?.entry?.map(ent => {
                return (ent?.identifier[0]?.value.substring(5, (ent?.identifier[0]?.value.length)))
            }).indexOf(info?.id)
            let myEntry = mySection?.entry[entryIndex];

            const searchComment = selectedItems?.map((value, i) => {
                if(comment[value?.id] === undefined && info?.canWrite) return;
                let commentary = null;
                if (!comment[value?.id] || comment[value?.id].length === 0) {
                    commentary = null;
                } else {
                    commentary = comment[value?.id];
                }


                return (
                    {
                        "code":
                        {
                            "coding":
                                [
                                    {
                                        "system": "http://indigo.tech",
                                        "code": value.questionNumber,
                                        "display": value.display
                                    }
                                ],
                            "text": value.display
                        },
                        "valueString": commentary
                    }
                )
            })

            if (myEntry) {
                myEntry["component"] = searchComment.filter(component => component !== undefined);
            }

            // console.log(info.display, searchComment.filter(component => component !== undefined))

            if(!mySection) return;

            mySection?.entry?.splice(entryIndex, 1);
            mySection?.entry?.splice(entryIndex, 0, myEntry)
            dispatch(sectionUpdated(mySection));
            
        }
        
        const autoSaveData = {
            id: info.id,
            items: selectedItems,
            comments: comment,
            encounterId: encounterId,
        } 
        
        dispatch(setAutoSaveSearchInput(autoSaveData));
        if(selectedItems.length > 0 && validationList.includes(info.id)) {
            dispatch(addCompletedItem(info.id));
        } else {
            dispatch(removeCompletedItem(info.id));
        }
    }, [JSON.stringify(selectedItems), localItems.length, JSON.stringify(comment), mainDiagnosis])

    useEffect(() => {
        if(!newItem.status) return;
        setTimeout(() => {
            setNewItem({...newItem, status: false});
        }, 1500);
    }, [selectedItems.length, newItem.status])

    function Item(data) {
        return (
            <div style={selectedItems.some(item=> item.id === data.Code) ? {color:'var(--gray-border)', cursor: 'default'} : {}}>
                {`${data.Code} - ${data.Display}`}
            </div>
        );
    }


    return (
        <Fragment>
            <div id={info.id + "FormContainer"} className={classNames('form-container', `section-w-size-${readOnly ? info.size : maxSizeDefault[responsive]}`)} style={{ padding: readOnly ? 0 : null }}>
                <div className={classNames('text-input-container', { 'focused': focused === info.id })}>
                    <div className={'text-input-title'}>
                        <div>{info.display}</div>
                        <span className={classNames('search-toast', {'active': newItem.status})}><b>AGREGADA: </b> {newItem.display}</span>
                    </div>
                    <div className={'search-content'}>
                        {!readOnly && (
                            info.isDiag
                                ? <Lookup
                                    ref={lookupRef}
                                    dataSource={store}
                                    displayExpr="Display"
                                    searchExpr={["Display", "Code"]}
                                    searchEnabled={true}
                                    onItemClick={itemClickedLookup}
                                    stylingMode="filled"
                                    elementAttr={{ class: "lookup-text-input enter-jump" }}
                                    placeholder={`Buscar ${info.display?.toLowerCase()}`}
                                    searchPlaceholder="Buscar"
                                    itemRender={Item}
                                    dropDownOptions={{ elementAttr: { class: "lookup-item-indigo-dropdown" } }}
                                />
                                : <IndigoSearchInput
                                    parentName={info.display}
                                    id={info.id}
                                    items={localItems}
                                    onItemClick={itemClicked}
                                    getFocused={getFocused}
                                    isWritable={isWritable}
                                    clearSearch={clearSearch}
                                    getCanDrop={getCanDrop}
                                    getResult={getResult}
                                    getDropped={getDropped}
                                />
                        )}
                        {(selectedItems?.length > 0 && !responsiveSmall()) &&
                            <div className={classNames('search-tags-container', { 'large': isWritable }, { 'preloaded': info.canWrite }, { 'read-only': readOnly })}
                                style={{ maxHeight: info.display.includes("sistem") ? '190px' : `${Math.ceil(selectedItems.length/2) * 50}px` }}> {
                                    printTags()
                                }
                            </div>
                        }

                        {(selectedItems?.length > 0 && responsiveSmall()) &&
                            <div className={'search-tags-container-small'}> {
                                printTagsSmall()
                            }
                            </div>
                        }
                    </div>
                </div>

                {editable && <ContextMenu
                    visible={contextOpen}
                    dataSource={[{ text: 'Editar ' + info.display, icon: 'rename' }]}
                    width={200}
                    target={"#" + info.id + "FormContainer"}
                    closeOnOutsideClick={true}
                    onItemClick={handleDeleteItem} />}

                {(cancelPopupVisible && editable) && <Popup
                    visible={cancelPopupVisible}
                    onHiding={closePopup}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title={"Editar " + info.display}
                    width={350}
                    height={650}
                >
                    <div className={'cancel-popup'}>

                        <div className={'popup-section'}>
                            <div className={'popup-section-title'}>Ordenar</div>
                            <div className={'popup-section-subtitle'}>Arrastra para ordenar</div>
                            <div className={'popup-section-content form-list'}>
                                <IndigoItemsList items={info.options} updateList={updateList} />
                            </div>
                        </div>
                        <div className={'popup-section'}>
                            <div className={'popup-section-title'}>Tamaño</div>
                            <div className={'popup-section-content size-btns'}>
                                {[1, 2, 3, 4, 5].map((size, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            text={size}
                                            type={size === info.size ? "success" : "normal"}
                                            stylingMode={size === info.size ? "contained" : "outlined"}
                                            onClick={() => handleChangeSize(size)}
                                        />
                                    );
                                })
                                }
                            </div>
                        </div>
                        <div className={'cancel-buttons'}>
                            <Button
                                width={130}
                                icon={'check'}
                                text="Cerrar"
                                type="success"
                                stylingMode="contained"
                                onClick={closePopup}
                            />
                        </div>

                    </div>
                </Popup>}
            </div>
        </Fragment>
    )
}

export default IndigoSearch