import React, { useMemo } from 'react';
import { useHistory } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';

import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../../../redux/actions/auth';
import { agendaLogout } from '../../../redux/actions/agenda';
import { clearActiveAppointment } from '../../../redux/actions/patient';

export default function UserPanel ({ menuMode }) {
  
 
  const dispatch = useDispatch();
  const {name, lastname, image} = useSelector(state => state.auth)
  
  // const { name, lastname } = useSelector(state => state.auth )
  // //console.log( name, lastname);

  const signOut = () => {
    dispatch( agendaLogout());
    dispatch( clearActiveAppointment() );
    dispatch( startLogout() );
  }

  const history = useHistory();

  function navigateToProfile() {
    history.push("/home");
  }

  function navigateToParametrizer() {
    history.push("/parametrizer");
  }

  function navigateToProductivity() {
    history.push("/panel-productividad");
    }

  function showSIHCronicos() {
      window.open("http://appeon-01/sih_cronicos", "_blank");
  }
  
  function showSurvey() {
      window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=n6uoDFO1S0unh_A8LM11bbI8aIrZDodEtswyqqwoI1VUMzZCQ0VKQUtIRVk5NUZXTUdKODRVQkxROC4u", "_blank");
  }

  const menuItems = useMemo(() => ([
    {
      text: 'Inicio',
      icon: 'home',
      onClick: navigateToProfile
    },
    {
      text: 'Productividad',
      icon: 'chart',
      onClick: navigateToProductivity
    },
    {
      text: 'Links',
      icon: 'link',
        items: [
            {
                text: 'Cronicos',
                icon: 'product',
                onClick: showSIHCronicos
            },
            {
                text: 'Encuesta',
                icon: 'tips',
                onClick: showSurvey
            }
        ]
    },
    // {
    //   text: 'Logout',
    //   icon: 'runner',
    //   onClick: signOut
    // },
    {
      text: 'Parametrizer',
      icon: 'runner',
      onClick: navigateToParametrizer
    },
    {
        text: 'About',
        icon: 'info',
        disabled: true,
    },
  ]), [signOut]);

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            style={{
              //background: `url(${user.avatarUrl}) no-repeat #fff`,
              background: `url(${image}) no-repeat #fff`,
              backgroundSize: 'contain'
            }}
            className={'user-image'} />
        </div>
        <div className={'user-name'}>{name?.split(' ')[0] +' '+ lastname?.split(' ')[0]}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
