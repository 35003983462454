export const defaultMgmtPlan = [
    {
      id: 'mgmtPlan',
      display: 'Plan de manejo',
      options: [
        {
          id: 'prescriptions',
          display: 'Prescripciones',
          options: [
            {
              id: 'medicines',
              display: 'Medicamento',
              options: [],
              type: 'select',
              canWrite: false,
              canDrop: true,
              size: 2,
              description: 'Medicamento',
              maxChar: 3
            },
            {
              id: 'dose',
              display: 'Dosis',
              options: [],
              type: 'int',
              canWrite: false,
              canDrop: false,
              size: 1,
              description: 'Dosis',
              maxChar: 3
            },
            {
              id: 'freq',
              display: 'Frecuencia',
              options: [],
              type: 'display',
              canWrite: false,
              canDrop: true,
              size: 1,
              description: 'Frecuencia',
              maxChar: [
                3,
                2
              ]
            },
            // {
            //   id: 'term',
            //   display: 'Duración',
            //   options: [],
            //   type: 'display',
            //   canWrite: false,
            //   canDrop: true,
            //   size: 1,
            //   description: 'Duración',
            //   maxChar: [
            //     3,
            //     2
            //   ]
            // },
            // {
            //   id: 'indications',
            //   display: 'Indicaciones adicionales',
            //   options: [],
            //   type: 'textarea',
            //   canWrite: false,
            //   canDrop: false,
            //   size: 1,
            //   description: 'Indicaciones adicionales',
            //   maxChar: 3
            // },
            {
              id: 'resume',
              display: 'Instrucciones de administración',
              options: [],
              type: 'resume',
              canWrite: false,
              canDrop: false,
              size: 2,
              description: 'Instrucciones de administración',
              maxChar: 3
            },
          ],
          type: 'form',
          size: 5,
          canWriteDefault: false,
          defaultValue: '',
          message: ''
        },
        {
          id: 'images',
          display: 'Imágenes',
          options: [
            {
              id: 'imgService',
              display: 'Servicio',
              options: [],
              type: 'select',
              canDrop: true,
              size: 2,
              description: 'Servicio',
              maxChar: 3
            },
            {
              id: 'imgSuggDate',
              display: 'Fecha sugerida toma',
              options: [],
              type: 'date',
              canDrop: false,
              size: 1,
              description: 'Fecha sugerida toma',
              maxChar: 3
            },
            // {
            //   id: 'imgLaterality',
            //   display: 'Lateralidad',
            //   options: [],
            //   type: 'display',
            //   canDrop: true,
            //   size: 1,
            //   description: 'Lateralidad',
            //   maxChar: 3
            // },
            {
              id: 'imgPriority',
              display: 'Prioridad (Urgencia)',
              options: [],
              type: 'switch',
              canDrop: false,
              size: 1,
              description: 'Prioridad (Urgencia)',
              maxChar: 3
            },
            {
              id: 'imgQty',
              display: 'Cantidad',
              options: [],
              type: 'int',
              canDrop: false,
              size: 1,
              description: 'Cantidad',
              maxChar: 3
            },
            {
              id: 'imgIndications',
              display: 'Datos clínicos relevantes',
              options: [],
              type: 'display',
              canDrop: true,
              size: 2,
              description: 'Datos clínicos relevantes',
              maxChar: 3
            }
          ],
          type: 'form',
          size: 5,
          canWriteDefault: false,
          defaultValue: '',
          message: ''
        },
        {
          id: 'laboratories',
          display: 'Otros Servicios',
          options: [
            {
              id: 'labService',
              display: 'Servicio',
              options: [],
              type: 'select',
              canDrop: true,
              size: 2,
              description: 'Servicio',
              maxChar: 3
            },
            {
              id: 'labSuggDate',
              display: 'Fecha sugerida toma',
              options: [],
              type: 'date',
              canDrop: false,
              size: 1,
              description: 'Fecha sugerida toma',
              maxChar: 3
            },
            {
              id: 'labPriority',
              display: 'Prioridad (Urgencia)',
              options: [],
              type: 'switch',
              canDrop: false,
              size: 1,
              description: 'Prioridad (Urgencia)',
              maxChar: 3
            },
            {
              id: 'labQty',
              display: 'Cantidad',
              options: [],
              type: 'int',
              canDrop: false,
              size: 1,
              description: 'Cantidad',
              maxChar: 3
            },
            {
              id: 'labIndications',
              display: 'Datos clínicos relevantes',
              options: [],
              type: 'display',
              canDrop: true,
              size: 2,
              description: 'Datos clínicos relevantes',
              maxChar: 3
            }
          ],
          type: 'form',
          size: 5,
          canWriteDefault: false,
          defaultValue: '',
          message: ''
        },
        {
          id: 'interencounter',
          display: 'Interconsultas',
          options: [
            {
              id: 'interEspeciality',
              display: 'Especialidad',
              options: [],
              type: 'select',
              canDrop: true,
              size: 2,
              description: 'Especialidad',
              maxChar: 3
            },
            {
              id: 'interEspecialist',
              display: 'Especialista',
              options: [],
              type: 'select',
              canDrop: true,
              size: 2,
              description: 'Especilista',
              maxChar: 3
            },
            {
              id: 'interPriority',
              display: 'Prioridad (Urgencia)',
              options: [],
              type: 'switch',
              canDrop: false,
              size: 1,
              description: 'Prioridad (Urgencia)',
              maxChar: 3
            },
            {
              id: 'interIndications',
              display: 'Recomendaciones',
              options: [],
              type: 'display',
              canDrop: true,
              size: 2,
              description: 'Recomendaciones',
              maxChar: 3
            }
          ],
          type: 'form',
          size: 5,
          canWriteDefault: false,
          defaultValue: '',
          message: ''
        },
      ]
    }
  ]