import React, {useState} from 'react';
import IPS from './../../../assets/img/logos/IPS.png';
import Indigo from './../../../assets/img/logos/Indigo.png';
export default function Footer ({position, align, color})  {

  const [indigoLoaded, setIndigoLoaded] = useState(false);
  const [IPSLoaded, setIPSLoaded] = useState(false);
  const classNames = require('classnames');
  const handleIndigoLoaded = () => {
    setIndigoLoaded(true)
  }

  const handleIPSLoaded = () => {
    setIPSLoaded(true)
  }

  return <footer style={{
      justifyContent: `flex-${align}`, 
      position: position,  
      right: align === 'end' ? "10px" : "",
      left: align === 'start' ? "10px" : "",
      }}>
    <img src={IPS} alt={'IPS Logo'} className={classNames('ips', {'no-loaded': !IPSLoaded})} draggable="false" onLoad={handleIPSLoaded}/>
    <span className={'powered-by'}>
      <span>Powered by</span>
      <img src={Indigo} alt={'IPS Logo'} className={classNames('indigo', {'no-loaded': !indigoLoaded})} draggable="false" onLoad={handleIndigoLoaded}/> 
    </span>
  </footer>
};
