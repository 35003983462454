import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DropDownButton from 'devextreme-react/drop-down-button';

import Scheduler from 'devextreme-react/scheduler';
import { AppointmentRender } from './AppointmentRender.js';
import 'devextreme/data/odata/store';
import { agendaStartLoading, selectAppointment, selectEspeciality } from '../../redux/actions/agenda.js';

//import { IndigoGeneralSelect } from '../../components/renderer/questions/components/index.js';
import LoadPanel from 'devextreme-react/load-panel';
import { AppointmentTooltip } from './AppointmentTooltip.js';
import { Popup } from 'devextreme-react/popup';
import { reset } from '../../redux/actions/fhir.js';
import { resetFicha } from '../../redux/actions/hcData.js';

//const currentDate = new Date();
const views = ['agenda','week', 'day'];
// const views = ['day', 'week'];


export default function AgendaFilterPage(props) {
  const [currentDate, setCurrentDate] = useState(moment(new Date()));
  const [view, setView] = useState('day');
  const [dates, setDates] = useState({startDay: "", endDay: ""});
  const [activeFilter, setActiveFilter] = useState([]);
  const [filteredAnnotations, setFilteredAnnotations] = useState([]);
  const { specialities } = useSelector(state => state.auth);
  const [popupShow, setPopupShow] = useState(false);
  const [appointmentSelection, setAppointmentSelection] = useState(false);
  const [isToday, setIsToday] = useState(true);
  const dispatch = useDispatch();
  const { appointments, especiality: selectedSpeciality, appointment: selectedAppointment } = useSelector(state => state.agenda);
  const { loading } = useSelector(state => state.ui);
  const classNames = require('classnames');
  const schedulerRef = React.createRef();
  const colors = [
    {
      id: "arrived", color: "#f44336a1",
      display: 'Pendiente', icon: "dx-icon-clock"
    },
    {
      id: "in-progress", color: "#f99807a8",
      display: 'En progreso', icon: "dx-icon-key"
    },
    {
      id: "finished", color: "#07db8d7a",
      display: 'Finalizado', icon: "dx-icon-check"
    }
  ];

  useEffect(() => {
    if (view === 'day' && selectedSpeciality.code)
      dispatch(agendaStartLoading(selectedSpeciality.code, currentDate, view));
  }, [dispatch, selectedSpeciality.code, currentDate, view]);

  useEffect(() => {
    if (dates?.startDay && selectedSpeciality.code)
      dispatch(agendaStartLoading(selectedSpeciality.code, undefined, dates, view));
  }, [dispatch, dates?.startDay, selectedSpeciality.code])

  const onAppointmentFormOpening = (e) => {
    e.cancel = true;
  }

  const onAppointmentClick = (e) => {
    setPopupShow(true);
    dispatch(selectAppointment(e.appointmentData));
    e.event.preventDefault();

    const savedData = localStorage.getItem(e.appointmentData.idEncounterFhir);

    // if(savedData && savedData !== "undefined" && savedData !== "null") {
    //   if(JSON.parse(savedData)["practitionerID"] === e.appointmentData.practitioner) {
    //     setAppointmentSelection(true)
    //   } else {
    //     setAppointmentSelection(false)
    //   }
    // } else {
    //   setAppointmentSelection(true);
    // }
  }

  const scrollIntoCurrentIndicator = (e) => {
    if(e.which !== 32) return;
    const element = document.getElementsByClassName("dx-scheduler-date-time-indicator")[0];
    if(!element) return;
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  useEffect(() => {
    document.addEventListener('keypress',scrollIntoCurrentIndicator);
    return () => {
      document.removeEventListener('keypress',scrollIntoCurrentIndicator);
    }
  }, [])

  // setInterval(() => {
  //   scrollIntoCurrentIndicator({which: 32})
  // }, 5000);


  const { fhir } = useSelector(state => state);

  const closePopup = () => {
    setPopupShow(false);
  }

  const onCurrentDateChange = (e) => {
    let a = moment(e.toISOString());
    setCurrentDate(a);
    //dispatch(agendaStartLoading(selectedSpeciality.code, e));
  }

  const onCurrentViewChange = (e) => {
    setView(e);
  }

  const onDateCellRender = (e) => {
    if (e.date.getDay() === 1 && e.date.toLocaleString() !== dates?.startDay?.toLocaleString()) {
      setDates({ ...dates, 'startDay': e.date })
      // //console.log('startDay');
    } else if (e.date.getDay() === 0 && e.date.toLocaleString() !== dates?.endDay?.toLocaleString()) {
      setDates({ ...dates, 'endDay': e.date })
      // //console.log('EndDay');
    }
    return (
      <>
        <span className="dx-scheduler-header-panel-cell-date">
          {e.text.split(' ')[0]}
        </span>
        <span className="dx-scheduler-header-panel-cell-date">
          {e.text.split(' ')[1]}
        </span>
      </>
    )
  }

  const schedulerContentReady = () => {
    scrollIntoCurrentIndicator({which: 32});
  }

  const handleOnSpecChange = (e) => {
    dispatch(selectEspeciality(e.itemData));
  }

  const filterChange = (filter) => {
    let filterCopy = JSON.parse(JSON.stringify(activeFilter));
    if(filterCopy.includes(filter)) {
      const index = filterCopy.indexOf(filter);
      filterCopy.splice(index, 1);
    } else {
      filterCopy.push(filter);
    }
    setActiveFilter(filterCopy)
    scrollIntoCurrentIndicator({which: 32})
  }

  useEffect(() => {
    if(activeFilter.length === 0) {
      setFilteredAnnotations(appointments)
    } else {
      const result = appointments.filter(appointment => activeFilter.includes(appointment.status));
      setFilteredAnnotations(result);
    }
  }, [JSON.stringify(appointments), JSON.stringify(activeFilter)])


  const Legend = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <DropDownButton
          text={selectedSpeciality.display ? selectedSpeciality.display : "Selecciona Especialidad"}
          dropDownOptions={{ minWidth: 340 }}
          displayExpr={'display'}
          keyExpr={'code'}
          items={specialities}
          onItemClick={handleOnSpecChange}
        />
        <span style={{display: 'flex', alignItems: 'center'}}>
          <span><p><i  className="dx-icon-filter"></i> Filtro | </p></span>
        {
          colors.map( (color, idx) => {
            return (
              <div key={idx} className={classNames('legend-filter', {'active': activeFilter.includes(color.id)})}
                onClick={() => filterChange(color.id)}
                style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20px', width: '20px', borderRadius: '50%', border: `1px solid ${color.color}`, backgroundColor:  activeFilter.includes(color.id) ? color.color : "" , marginRight: '5px'}}>
                    <i style={ activeFilter.includes(color.id) ? {color: "#fff"} : {color: color.color}} className={color.icon}></i>
                  </span>
                <p>{color.display}</p>
              </div>
            )
          })
        }
        </span>
      </div>
    )
  }

  const renderAppointmentTooltip = () => {
    const h = window.innerHeight * 0.15;
    setTimeout(() => {
      const TooltipParent = document.getElementsByClassName('dx-popup-inherit-height').item(0);
      TooltipParent.style.display = 'none';
    }, 50);
  }

  useEffect(() => {
    const today = new Date();
    const agendaDate = new Date(currentDate._i);
    
    const isItToday = (today.getFullYear() === agendaDate.getFullYear() && 
      today.getMonth() === agendaDate.getMonth() && 
      today.getDate() === agendaDate.getDate()
      ) 
      
      //setIsToday(isItToday);
    
  }, [currentDate])

  const renderPopup = (e) => {
    return <AppointmentTooltip data={selectedAppointment} spec={selectedSpeciality.code} isAppoinmentStarted={appointmentSelection} isToday={isToday} closePopup={closePopup}/>
  }

  return (
    <Fragment>
      {
        (loading) ? <LoadPanel visible={true} /> :
          <div className={' main-content-container scheduler-container'} onContextMenu={(e) => e.preventDefault()}>
            <h1 className={'content-block page-title'}>Agenda</h1>
            <div className={classNames('scheduler')}>
              <Legend />
              <Scheduler
                className={'clase-prueba'}
                dataSource={filteredAnnotations}
                appointmentRender={AppointmentRender}
                appointmentDisplayOptions={{ AppointmnetHeight: 300 }}
                descriptionExpr={'text'}
                views={views}
                onContentReady={schedulerContentReady}
                ref={schedulerRef}
                useDropDownViewSwitcher={false}
                defaultCurrentView={view}
                disabled={selectedSpeciality.code === ""}
                startDayHour={6}
                endDayHour={23}
                cellDuration={10}
                scroll
                firstDayOfWeek={1}
                resources={[
                  {
                    fieldExpr: "status",
                    dataSource: colors,
                    label: "Room"
                  }]}
                showAllDayPanel={false}
                showCurrentTimeIndicator
                shadeUntilCurrentTime
                currentIndicator={console.log}
                defaultCurrentDate={currentDate}
                onCurrentDateChange = {onCurrentDateChange}
                onCurrentViewChange = { onCurrentViewChange}
                dateCellRender = { (e)=> onDateCellRender(e)}
                appointmentTooltipRender={(data) => renderAppointmentTooltip(data)}
                onAppointmentClick={onAppointmentClick}
                onAppointmentFormOpening={onAppointmentFormOpening}
                style={{ height: '60vh' }}
                // width={responsiveSmall() ? 120 : 'auto'}
                editing={
                  {
                    allowUpdating: false,
                    allowDeleting: false,
                    allowAdding: false
                  }
                } />
            </div>
          </div>
      }
      {popupShow && <Popup
        visible={popupShow}
        onHiding={closePopup}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={false}
        width={450}
        height={isToday ? /* appointmentSelection ? */ ['arrived', 'in-progress'].includes(selectedAppointment.status) ? 650 : 350 /* : 250 */ : 300}
        contentComponent={renderPopup}
      >
      </Popup>}

    </Fragment>
  )
};


