import { getDiagnosticsData, getFrequences, getImgServices, getLabServices, getMedicationFhir, getPractitionerFhir } from "../../utils/helpersPoints";
import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";
import { getStockMedications } from './../../utils/helpersPoints';


export const startLoadImgServices = () => {
    const auth = sessionStorage.getItem('auth');
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const imgServices = await getImgServices(auth);
            // //console.log('imgServices: ', imgServices)
            if (imgServices) {
                dispatch(setImgServices(imgServices))
            }
            // return imgServices;
        } catch (error) {
            //console.log('Error al obtener imgServices: ', error);
        }
        dispatch(finishLoading());
    }
}

const setImgServices = (imgServices) => ({
    type: types.setImageServices,
    payload: {
        imgServices
    }
});

export const startLoadLabServices = () => {
    const auth = sessionStorage.getItem('auth');
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const labServices = await getLabServices(auth);
            // //console.log('labServices: ', labServices)
            if (labServices) {
                dispatch(setLabServices(labServices))
            }
            // return imgServices;
        } catch (error) {
            //console.log('Error al obtener imgServices: ', error);
        }
        dispatch(finishLoading());
    }
};

const setLabServices = (labServices) => ({
    type: types.setLabServices,
    payload: {
        labServices
    }
});

export const startLoadFrequences = () => {
    const auth = sessionStorage.getItem('auth');
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const frequences = await getFrequences(auth);
            // //console.log('frequences: ', frequences);
            if (frequences) {
                dispatch(setFrequences(frequences))
            }
            // return imgServices;
        } catch (error) {
            //console.log('Error al obtener imgServices: ', error);
        }
        dispatch(finishLoading());
    }
};

const setFrequences = (frequences) => ({
    type: types.setFrequences,
    payload: {
        frequences
    }
});

export const startLoadMedicationFhir = () => {
    const auth = sessionStorage.getItem('auth');
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const medication = await getMedicationFhir(auth);
            // //console.log('medicationFhir: ', medication)
            if (medication) {
                dispatch(setMedicationFhir(medication))
            }
            // return imgServices;
        } catch (error) {
            //console.log('Error al obtener imgServices: ', error);
        }
        dispatch(finishLoading());
    }
};

const setMedicationFhir = (medicationFhir) => ({
    type: types.setMedicationFhir,
    payload: {
        medicationFhir
    }
});

export const startLoadPractitionerFhir = () => {
    const auth = sessionStorage.getItem('auth');
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const practitioners = await getPractitionerFhir(auth);
            // //console.log('practitionersFhir: ', practitioners)
            if (practitioners) {
                dispatch(setPractitionerFhir(practitioners))
            }
            // return imgServices;
        } catch (error) {
            //console.log('Error al obtener imgServices: ', error);
        }
        dispatch(finishLoading());
    }
};

const setPractitionerFhir = (practitionersFhir) => ({
    type: types.setPractitionersFhir,
    payload: {
        practitionersFhir
    }
});


export const startLoadDiagnosticsData = () => {
    const auth = sessionStorage.getItem('auth');
    return async (dispatch) => {
        //dispatch(startLoading());
        try {
            const diagnosticsData = await getDiagnosticsData(auth);
            if (diagnosticsData) {
                const mappedData = diagnosticsData.resources.map( resource =>{
                    let item = {
                        display: resource.resource.concept[0].code + " - " + resource.resource.concept[0].display, 
                        id: resource.resource.concept[0].code, 
                        text: resource.resource.concept[0].display
                    };
                    return item;
                });
                diagnosticsData.mappedData = mappedData;
                dispatch(setDiagnosticsData(diagnosticsData))
            }
            // return imgServices;
        } catch (error) {
            //console.log('Error al obtener imgServices: ', error);
        }
        //dispatch(finishLoading());
    }
};

const setDiagnosticsData = (diagnosticsData) => ({
    type: types.setDiagnosticsData,
    payload: {
        diagnosticsData
    }
});

export const startLoadMedicationStock = (fd) => {
    const auth = sessionStorage.getItem('auth');
    return async (dispatch) => {
        dispatch(setMedicationStock({status: "LOADING"}));
        try {
            const stock = await getStockMedications(auth, fd);
            if (stock) {
                dispatch(setMedicationStock({status: "LOADED", stock}));
                //console.log('stock')
            }
        } catch (error) {
            dispatch(setMedicationStock({status: "ERROR"}));
            //console.log('stock')
        }
    }
};

export const setMedicationStock = (medication) => ({
    type: types.setMedicationStock,
    payload: {
        medication
    }
});