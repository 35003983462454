import React, { useRef, useEffect } from 'react';

import DataGrid, {
    Column,
    Paging,
    Pager,
    Format,
    Button,
    Scrolling
} from 'devextreme-react/data-grid';

export default function MedicalHistoryDataGridSIH(props) {
    const datagrid = useRef(null);
    const { dataSource, handleOpenMedicalRecord, gridSize } = props;
    //const mustRepaint = useRef(false);

    useEffect(() => {
        if (gridSize !== 300) {
            //console.log("repainting SIH:", gridSize)
            datagrid.current.instance.repaint();
            //mustRepaint.current = true;
        }
    }, [gridSize])

    /* const onContentReady = () => {
        if (mustRepaint.current) {
            mustRepaint.current = false;
            //console.log("repaingggggg")
            datagrid.current.instance.option("height", gridSize)
            datagrid.current.instance.repaint()
        }
    } */

    return (
        <div>
            <DataGrid
                ref={datagrid}
                dataSource={dataSource}
                keyExpr="ID"
                showBorders={true}
                //height={gridSize}
                onRowDblClick={handleOpenMedicalRecord}
            //onContentReady={onContentReady}
                elementAttr={{ style: `height: ${gridSize}px` }}
            >
                <Scrolling mode="standard" />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 30, 40]}
                    showInfo={true} />
                <Column dataField="FechaAtencion" dataType='datetime' sortOrder={"desc"} sortIndex={0}>
                    <Format
                        type="dd/MM/yyyy HH:mm"
                        precision={2}
                    />
                </Column>
                <Column dataField="TipoAtencion" />
                <Column dataField="NombreDoctor" />
                <Column dataField="FichaNombre" /* calculateCellValue={this.calculateFormName} */ />
                {/* <Column dataField="NombreDoctor" /> */}
                <Column dataField="Especialidad">
                    {/* <Lookup
                            dataSource={specialties}
                            valueExpr="code"
                            displayExpr="display"
                        /> */}
                </Column>
                <Column type="buttons" width={110}>
                    <Button hint="Abrir"
                        icon="activefolder"
                        onClick={handleOpenMedicalRecord} />
                </Column>
            </DataGrid>
        </div>
    );
}