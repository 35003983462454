import React, { useState, Fragment, useEffect } from 'react';
import './../historial/historial.scss';
import Button from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import SelectedDropDown from './selectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingFicha } from '../../redux/actions/hcData';
import { getTemplateBySpecialty } from '../../utils/helpersPoints';
import Footer from '../../components/ui/footer/footer';
export default function EncounterPage (props) {
    
    const $ = require('jquery');
    const classNames = require('classnames');
    const { appointment: activeAppointment } = useSelector(state => state.agenda);
    const { code: spec } = useSelector(state => state.agenda.especiality);

    const { responsive } = useSelector(state => state.ui);
    const history = useHistory();
    const dispatch = useDispatch();
    const [ espSelected, setEspSelected ] = useState({id:"", name: '', code: 0});
    const [ finalSelect, setFinalSelect ] = useState("");
    const [toggle, setToggle] =  useState(false);
    const auth = sessionStorage.getItem('auth');
    const [fichas, setFichas] = useState([]);

    useEffect(() => {
        getFichas();
        //console.log('activeAppointMent: ', activeAppointment);
    }, [])

    const getFichas = async() => {
        const fichas2 = await getTemplateBySpecialty(auth, spec);
        setFichas(fichas2);
    }

    const opts = fichas?.map( fich => {
        return {
            code: JSON.parse(fich.Data).id,
            id: fich.RowKey,
            text: fich.Name,
            canWrite: false,
        }
    });

    //console.log(opts)
    
    const especializations = {
        id: 'spec',
        text: 'Especialización',
        canWrite: false,
        opts: opts
    }

    const goToEncounter = () => {
        //Template por id fetch.
        //console.log("RUTA ENCUENTRO:", activeAppointment?.idEncounterFhir, espSelected.code)
        history.push('/encuentro/'+activeAppointment?.idEncounterFhir+'/'+espSelected.code)
        dispatch(startLoadingFicha(finalSelect));
    }
    
    const handleSpecSelect = (spec) => {
        //console.log("FICHA:", spec, especializations)
        setEspSelected({name: spec.currentSelectedRowKeys[0].text , id: spec.currentSelectedRowKeys[0].id, code: spec.currentSelectedRowKeys[0].code});
        setFinalSelect(spec.currentSelectedRowKeys[0].id);
        cancel();
    }
    
    const toggleDropDown = () => {
        setToggle(true);
        const element = $("#agendaEspecSelect");
        element?.slideDown('slow');
    }
    
    const save = () => {
        setToggle(false);
    }
    
    const cancel = () => {
        setToggle(false);
        $("#agendaEspecSelect").slideUp('slow');
    }

    return (
        <>
            <h1 className={'content-block page-title roboto'}>Encuentro</h1>
            <Fragment>
                <div  
                    className={classNames('text-input-container flex-center column')}
                    >
                    <div className={'main-field-container hc'}>
                        <div className={classNames('text-input-title')}>
                            Paciente
                        </div>
                        <div className="field-label center">{activeAppointment?.text}</div>
                    </div>
                    <div className={classNames('text-input-title')}>
                        <label className={classNames('mgmt-group-label')}>Ficha</label>
                    </div>
                    <div
                        id={'selectorContainer'}
                        className={'relative'}
                        style={{width: responsive !== 'extrasmall' ? '400px' : '70%'}}
                    >
                        <input 
                            id={"AgendaEpecInput"} 
                            className={'text-input enter-jump mgmt-input mgmt-input-item '} 
                            onClick={toggleDropDown}
                            type={"text"} 
                            required
                            name={"section"}
                            readOnly
                            value={espSelected.id !== "" ? espSelected.name : "Selecciona"}
                            autoComplete={"off"}
                            >
                        </input>
                        {/* {toggle && */}
                            <SelectedDropDown open={toggle} save={save} cancel={cancel} select={handleSpecSelect} dataSource={especializations.opts} responsive={responsive}/>
                        {/* } */}
                    </div>
                </div>
                <div className={'btn-container'}
                >
                    <Button
                        onClick={finalSelect === "" ? null : goToEncounter}
                        text="Iniciar encuentro"
                        type="default"
                        stylingMode={finalSelect === "" ? "outlined" : "contained"}
                        disabled={finalSelect === "" ? true : false}
                    />
                </div>
            </Fragment>
            <Footer  position={'fixed'} align={'end'} />
        </>
    );
};