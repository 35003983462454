import React, {Fragment, useState, useEffect} from 'react';
import ContextMenu from 'devextreme-react/context-menu';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import { IndigoForm, IndigoSearch, IndigoSelect, IndigoTextArea, IndigoSwitch } from './components';
import IndigoItemsList from './components/form/indigoItemsList';

import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../../redux/actions/parametrizer';
import { sectionUpdated, setSection } from '../../../redux/actions/fhir';
import ManagementGeneralInput from './components/default/ManagementGeneralInput';

function IndigoQuestion (props) {

    var classNames = require('classnames');
    const { fhir } = useSelector(state => state)
    const [contextOpen, setContextOpen] = useState(null);
    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);
    const { data : finalData } = useSelector( state => state.parametrizer);
    const { responsive } = useSelector( state => state.ui);
    const [renderChildren, setRenderChildren] = useState(false);
    const dispatch = useDispatch(); 
    const questionRef = React.createRef();
    let questionChildren;
    
    useEffect(() => {
        questionChildren = questionRef.current.lastChild;
        setTimeout(() => {
            setRenderChildren(true)
        }, 500);
    },[])

    const handleDocumentClick = (e) => {
        const children = questionChildren;
        if (children.contains(e.target)) {
            setContextOpen(false);
        } else {
            setContextOpen(null);
        }
    }

    useEffect(() => {
        finalData.map( (data, key) => {
            const element = document.getElementById(data.id+"Section");
            element?.addEventListener('contextmenu', handleDocumentClick);
        
            return function cleanup () {
                element?.removeEventListener('contextmenu', handleDocumentClick);
            }
        })
     }, [])

     const openPopup = () => {
        setCancelPopUpVisible(true)
    }

    const closePopup = () => {
        setCancelPopUpVisible(false)
    }

    const handleDeleteItem = (section) => {
        openPopup()
        setContextOpen(false);
        setTimeout(
            () => {
                setContextOpen(null);
            },
            [300],
        );
    }

    const updateList = (list) => {
        const newData = finalData;        
        newData.some( dato => {
            if (dato.id === data.id){
                dato.options = list;
            }
        })
        dispatch( setData(newData))
    }
    const { data, focused, getFocused, getDropped, activeSection, editable, readOnly } = props
    
    useEffect(() => {
        // if(!fhir.some( sec => sec["title"] === data.id)) {
        //     const structure = 
        //      {
        //         "title": data.id,
        //         "mode": "working",
        //         "entry": []
        //       }
        //       dispatch(setSection(structure))
        // }
    },[])
    

    return(

        <Fragment>
            <div ref={questionRef} id={data.id+"Section"} className={classNames('section-layout', {'active': activeSection.id === data.id})}>
                {/* { !(responsive === "extrasmall" || responsive === "small") && <div className={classNames('section-title', {'active': activeSection.id === data.id})}>{data.display}</div>} */}
                <div className={'section-content'}>
                    {data?.options.length === 0 && <p>No content</p>}
                    {
                        data?.options?.map( (quest, key) => {
                            switch(quest.type){
                                case 'form' :
                                    return (<Fragment key={key}> 
                                            <IndigoForm info={quest} focused={focused} getFocused={getFocused} granPaID={data.id} editable={editable} render={renderChildren} readOnly={readOnly}/>
                                        </Fragment>
                                        )
                                case 'search' :
                                    return (<Fragment key={key}>
                                            <IndigoSearch info={quest} focused={focused} getFocused={getFocused} getDropped={getDropped} granPaID={data.id} editable={editable} render={renderChildren} readOnly={readOnly}/>
                                        </Fragment>
                                        )
                                case 'select' :
                                    return (<Fragment key={key}>
                                            <IndigoSelect info={quest} focused={focused} getFocused={getFocused} granPaID={data.id} editable={editable} render={renderChildren} readOnly={readOnly}/>
                                        </Fragment>
                                        )
                                case 'textarea' :
                                    return (<Fragment key={key}>
                                            <IndigoTextArea info={quest} focused={focused} getFocused={getFocused} granPaID={data.id} editable={editable} render={renderChildren} readOnly={readOnly}/>
                                        </Fragment>
                                        )
                                case 'boolean' :
                                    return (<Fragment key={key}>
                                            <IndigoSwitch info={quest} focused={focused} getFocused={getFocused} granPaID={data.id} editable={editable} render={renderChildren} readOnly={readOnly}/>
                                        </Fragment>
                                        )
    
                                case 'text' :
                                    return (<Fragment key={key}>
                                            <ManagementGeneralInput 
                                                info={quest}
                                                parentInfo={{size: 5}}
                                                focused={focused}
                                                onClick={getFocused}
                                                data={{value: quest?.options[0]?.display}}
                                                readOnly={true}
                                            />
                                        </Fragment>
                                        )
                                default:
                                    break;
                            }
                        })
                    }
                </div>                
            </div>
            {editable && <ContextMenu
                visible={contextOpen}
                dataSource={[{ text: 'Editar ' + data.display, icon: 'rename' }]}
                width={200}
                target={"#"+data.id+'Section'}
                closeOnOutsideClick={true}
                onItemClick={handleDeleteItem} />}
            {(cancelPopupVisible && editable) && <Popup
                visible={cancelPopupVisible}
                onHiding={closePopup}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showTitle={true}
                title={"Editar "+data.display}
                width={350}
                height={500}
                >
                <div className={'cancel-popup'}>
                    <div className={'popup-section'}>
                        <div className={'popup-section-title'}>Ordenar</div>
                        <div className={'popup-section-subtitle'}>Arrastra para ordenar</div>
                        <div className={'popup-section-content form-list'}>
                            <IndigoItemsList items={data.options} updateList={updateList}/>
                        </div>
                    </div>
                    <div className={'cancel-buttons'}>
                        <Button
                        width={130}
                        icon={'check'}
                        text="Cerrar"
                        type="success"
                        stylingMode="contained"
                        onClick={closePopup}                        
                        />
                    </div>

                </div>
            </Popup>}
        </Fragment> 

    )
}

export default React.memo(IndigoQuestion)