import React, { useEffect, useRef, useState } from 'react';
import IndigoSearchDrop from './indigoSearchDrop';


function IndigoSearchInput (props) {
    const { items, onItemClick, id, getFocused, parentName, clearSearch, getCanDrop, getResult, getDropped } = props;

    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [canDrop, setCanDrop] = useState(false);
    const [arrowDropIndex, setArrowDropIndex] = useState(0);
    const [selectList, setSelectList] = useState({start: 0, end: 0});
    const handleOnChange = (e) => {
        setSearchValue(e.target.value)
        searcher(e.target.value)
    }

    const handleOnFocus = (e) => {
        getFocused(id);
        if((searchValue.split("").length > 0) || (searchResult.length > 0)) {
            setCanDrop(true);
            getCanDrop(true);
        }
    }
    
    const handleOnBlur = () => {
        // setTimeout(() => {
        //     setCanDrop(false);
        //     getCanDrop(false);
        //     setArrowDropIndex(-1);
        // },[300])
    }

    const searcher = (input, totalItems=items) => {
        let itemList = [];
        const filter = input.toUpperCase();

        totalItems.some((item) => {
            if(item.display.toUpperCase().indexOf(filter) > -1){
                itemList.push(item);
            }
        })

        if(itemList.length === 0) {
            itemList.push({
                id:"default",
                display:"No hay coincidencias",
                options:[],
                type:"int",
                canWrite:true,
                size:1,
                description:"",
                maxChar:3})
        }

        setSearchResult(itemList)
        getResult(itemList)
    }

    const onClickOutside = () => {
        setCanDrop(false)
        getCanDrop(false);
    }

    useEffect(() => {
        setSearchResult(items)
        getResult(items)
    }, [items])

    useEffect(() => {
        if(clearSearch){
            setSearchValue("")
            setSearchResult(items)
            getResult(items)
        }
    },[clearSearch])

    useEffect(() => {
        if(arrowDropIndex !== -1) return;
        getDropped(false);
        onClickOutside();
    }, [arrowDropIndex])

    var classNames = require('classnames');

    const handleDropOnKey = (e) => {
        switch (e.which) {
            case 40:
            getCanDrop(true);
            setCanDrop(true);
                if(canDrop){
                    if(e.shiftKey){
                        if(selectList.start < searchResult.length-1 && selectList.end < searchResult.length-1){
                            setSelectList(prev => ({...selectList, end: (prev.end + 1)}))
                        }
                    } else {
                        if(arrowDropIndex < searchResult.length-1){
                            setArrowDropIndex(arrowDropIndex => arrowDropIndex += 1);
                            setSelectList({end: (arrowDropIndex + 1), start: (arrowDropIndex + 1)});
                        }
                    }
                }
                break;
            
            case 38:

                if(canDrop){
                    if(e.shiftKey){
                        if(selectList.start >= 0 && selectList.end >= 0){
                            setSelectList(prev => ({...selectList, end: (prev.end - 1)}))
                        } else {
                            setArrowDropIndex(-1);
                            setSelectList({start: -1, end: -1})
                        }
                    } else {
                        if(arrowDropIndex >= 0){
                            setArrowDropIndex(arrowDropIndex => arrowDropIndex -= 1);
                            setSelectList({end: (arrowDropIndex - 1), start: (arrowDropIndex - 1)});
                        } else {
                            setCanDrop(false);
                            getCanDrop(false);
                            setArrowDropIndex(-1);
                        }
                    }
                }
                break;

            case 13: 
                if(arrowDropIndex > -1 && arrowDropIndex < searchResult.length){
                    if(selectList.end === selectList.start) {
                        onItemClick(searchResult[arrowDropIndex].display);
                    } else {
                        let list;
                        if(selectList.start < selectList.end) {
                            list = Array(selectList.end - selectList.start+1).fill().map((_, idx) => selectList.start + idx);
                            list.forEach( item => {
                                onItemClick(searchResult[list[0]]?.display);
                            })
                            setSelectList({start: selectList.end, end: selectList.end});
                        }
                        if(selectList.start > selectList.end) {
                            list = Array(selectList.start - selectList.end+1).fill().map((_, idx) => selectList.end + idx);
                            list.forEach( item => {
                                onItemClick(searchResult[list[0]]?.display);
                            })
                            setSelectList({start: selectList.start, end: selectList.start});
                        }
                        
                    }
                }
                
            break;

            case 9: 
                setCanDrop(false);
            break;

            case 27: 
                setArrowDropIndex(-1);
                setCanDrop(false);
                getCanDrop(false);
            break;

            // case 13: 
            //     setArrowDropIndex(-1);
            //     setCanDrop(false);
            //     getCanDrop(false);
            // break;

            default:
                break;
        }
    }
    
    return (
        <div className={classNames('search-input-container', ('large'))}>
            <input 
                id={id+"Search"} 
                className={classNames('text-input enter-jump')} 
                type={"text"} 
                placeholder={items.length > 0 ? `Buscar ${parentName?.toLowerCase()}` : 'No hay elementos'} 
                name={"section"}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onKeyDown={handleDropOnKey}
                value={searchValue}
                autoComplete={"off"}
                >
            </input>
            {(((searchValue.split("").length > 0) && (searchResult.length > 0)) || canDrop) &&
                <IndigoSearchDrop 
                    parentId={id}
                    items={searchResult} 
                    onItemClick={onItemClick} 
                    onClickOutside={onClickOutside}
                    arrowDropIndex={arrowDropIndex}
                    selectList={selectList}
                    />}
        </div>
    )
}

export default React.memo(IndigoSearchInput)