import React from 'react';

export default function HistorialIcon (props) {

    const {isActive, isClosed} = props
	
    return (
        <svg className={isClosed ? 'menu-icon icon-openned' : 'menu-icon icon-closed'} xmlns="http://www.w3.org/2000/svg" viewBox={isClosed ? "0 0 50 70" : "0 0 50 70"}>
			<g className={isActive ? 'icon-active' : 'icon-inactive'}>
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M42.06,8.65h-5.65V5.29c0-0.34-0.13-0.67-0.37-0.91C35.8,4.14,35.48,4,35.13,4H30.2
                                c-0.97-2.32-3.4-4-6.12-4h-0.02c-2.65-0.02-5.05,1.56-6.08,4h-4.89c-0.72,0-1.32,0.57-1.34,1.29v3.36H6.23
                                C2.82,8.65,0,11.34,0,14.63v43.46c0,3.29,2.82,5.96,6.23,5.96h35.83c3.41,0,6.23-2.67,6.23-5.96V14.63
                                C48.29,11.34,45.48,8.65,42.06,8.65z M14.33,6.59h4.56c0.59,0,1.1-0.4,1.25-0.97c0.46-1.79,2.07-3.03,3.92-3.03h0.01
                                c1.9,0,3.54,1.26,3.92,2.98c0.13,0.59,0.65,1.02,1.26,1.02h4.58v6.46h-19.5V6.59z M45.71,58.09c0,1.86-1.66,3.38-3.65,3.38H6.23
                                c-1.99,0-3.65-1.51-3.65-3.38V14.63c0-1.86,1.66-3.39,3.65-3.39h5.52v3.06c0.02,0.73,0.61,1.32,1.34,1.33h22.04
                                c0.72-0.02,1.29-0.61,1.28-1.33v-3.06h5.65c1.99,0,3.65,1.53,3.65,3.39V58.09z"/>
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M14.62,48.55h19.04c0.71,0,1.29-0.58,1.29-1.29c0-0.71-0.58-1.29-1.29-1.29H14.62
                                c-0.71,0-1.29,0.58-1.29,1.29C13.33,47.97,13.91,48.55,14.62,48.55z"/>
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M37.54,51.14H10.75c-0.71,0-1.29,0.58-1.29,1.29c0,0.71,0.58,1.29,1.29,1.29h26.79
                                c0.71,0,1.29-0.58,1.29-1.29C38.83,51.71,38.26,51.14,37.54,51.14z"/>
                            <path className={isActive ? 'icon-active' : 'icon-inactive'} d="M15.24,34.86h5.04v5.04c0,0.71,0.58,1.29,1.29,1.29h5.17c0.71,0,1.29-0.58,1.29-1.29v-5.04h5.04
                                c0.71,0,1.29-0.58,1.29-1.29v-5.17c0-0.71-0.58-1.29-1.29-1.29h-5.04v-5.04c0-0.71-0.58-1.29-1.29-1.29h-5.17
                                c-0.71,0-1.29,0.58-1.29,1.29v5.04h-5.04c-0.71,0-1.29,0.58-1.29,1.29v5.17C13.95,34.29,14.52,34.86,15.24,34.86z M16.53,29.7
                                h5.04c0.71,0,1.29-0.58,1.29-1.29v-5.04h2.58v5.04c0,0.71,0.58,1.29,1.29,1.29h5.04v2.58h-5.04c-0.71,0-1.29,0.58-1.29,1.29
                                v5.04h-2.58v-5.04c0-0.71-0.58-1.29-1.29-1.29h-5.04V29.7z"/>
            </g>
        </svg>
    )
}