import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './styles.css';
import TabPanel from 'devextreme-react/tab-panel';

import CompanyItem from './CompanyItem.js';
import { Tabular } from './Tabular.js';
import { startLoadingProductivityBySpecialty, startLoadingProductivityByTemplate } from '../../redux/actions/productivity';


export const ProductivityPanel = () => {
 
  const dispatch = useDispatch();
  const [navigation, setNavigation] = useState([
    { 'ID': 1, 'text': 'Graficos',component:  <CompanyItem/>},
    { 'ID': 2, 'text': 'Tabular', component:  <Tabular/> },
  ]);

  const [state, setState] = useState({
    animationEnabled: true,
    swipeEnabled: true,
    loop: false,
    selectedIndex: 0
  });

  const { animationEnabled, loop, selectedIndex, swipeEnabled } = state;
  const {codeSIH} = useSelector(state => state.auth);
  useEffect(() => {
    dispatch(startLoadingProductivityBySpecialty(codeSIH));
    dispatch(startLoadingProductivityByTemplate(codeSIH));

  }, []);

  const itemTitleRender = (company)=> {
    return <span>{company.text}</span>;
  }

  const onSelectionChanged=(args)=> {
    // console.log('argss: ', args);
    if(args.name == 'selectedIndex') {
      setState({ ...state, selectedIndex: args.value });
    }
  }
  
  const ComponentRender = (props) => {
    return (
      <div>
        {props.component}
      </div>
    )
  }

  // const onLoopChanged=(args)=> {
  //   // this.setState({
  //   //   loop: args.value
  //   // });
  //   setState({ ...state, loop: args.value });

  // }

  // const onAnimationEnabledChanged=(args)=> {
  //   // this.setState({
  //   //   animationEnabled: args.value
  //   // });
  //   setState({ ...state, animationEnabled: args.value });

  // }

  // const onSwipeEnabledChanged=(args)=> {
  //   // this.setState({
  //   //   swipeEnabled: args.value
  //   // });
  //   setState({ ...state, swipeEnabled: args.value });
    
  // }
  
  return (
    <div>
      <TabPanel
        height={650}
        dataSource={navigation}
        selectedIndex={selectedIndex}
        onOptionChanged={onSelectionChanged}
        loop={loop}
        itemTitleRender={itemTitleRender}
        itemComponent={ selectedIndex === 0 ? CompanyItem : selectedIndex === 1 ? Tabular : CompanyItem }
        //itemRender={ComponentRender}
        animationEnabled={animationEnabled}
        swipeEnabled={swipeEnabled}
      />
    </div>
  )
}