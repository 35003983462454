import {React, useState, Fragment, useEffect, memo} from 'react';
import Button from 'devextreme-react/button';
import PanelMenu from './panelMenu';
import DeletePopup from './Popups/deletePopup';
import AddPopup from './Popups/addPopup';
import EditPopup from './Popups/editPopup';
import SavePopup from './Popups/savePopup';
import './parametrizer.css';
import { defaultMgmtPlan } from './default/defaultData.js'
import esMessages from 'devextreme/localization/messages/es.json';
import enMessages from 'devextreme/localization/messages/en.json';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import service from './../../globalize/globalize.js';
import { useDispatch, useSelector } from 'react-redux'
import { resetData, setData, /* setTotalData, */ startLoadAllParameters } from '../../redux/actions/parametrizer.js';
import OpenPopup from './Popups/openPopup.js';
import { sectionUpdated } from '../../redux/actions/fhir.js';
// import { finishLoading, startLoading } from '../../redux/actions/ui';

export function Panel (props) {

    const dispatch = useDispatch();
    var classNames = require('classnames');
    // Data
    const [ currentSectionMenu, setCurrentSectionMenu] = useState("");
    const [sectionList, setSectionList] = useState([]);

    const [optionList, setOptionList] = useState([]);

    const [optionOptionList, setOptionOptionList] = useState([]);
    const [currentOptionMenu, setCurrentOptionMenu] = useState({id: "", display: ""});
    const [currentOptionOptionMenu, setCurrentOptionOptionMenu] = useState("");

    const [questionList, setQuestionList] = useState([]);
    const [currentQuestionMenu, setCurrentQuestionMenu] = useState({id: "", display: ""});
    
    const { data : parametrizedData, activeSection, totalData: panelOptions, totalData } = useSelector(state => state.parametrizer);
    const { fhir } = useSelector(state => state);
    // const [panelOptions, setPanelOptions] = useState([]);
 
    const resetPanelData = () => {
        setSectionList([]);
        setQuestionList([]);
        setOptionList([]);
        setOptionOptionList([]);
        dispatch(resetData());
    }

    useEffect(() => {
        console.log("totalData", totalData)
    }, [totalData])
    
    useEffect(() => {
        resetPanelData();
        dispatch(startLoadAllParameters());
    }, []);


    useEffect(() => {
        if(panelOptions.length > 0){
            setSectionSearch({...sectionSearch, searchList: panelOptions});
        }
    }, [JSON.stringify(panelOptions)])

    // Togglers
    const [sectionToggler, setSectionToggler] = useState(true);
    const [optionToggler, setOptionToggler] = useState(true);
    const [optionOptionToggler, setOptionOptionToggler] = useState(true);
    const [questionToggler, setQuestionToggler] = useState(true);

    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);
    const [addPopupVisible, setAddPopUpVisible] = useState(false);
    const [openPopupVisible, setOpenPopUpVisible] = useState(false);

    const [editPopupVisible, setEditPopUpVisible] = useState(false);
    const [savePopupVisible, setSavePopUpVisible] = useState(false);

    const [editSelected, setEditSelected] = useState({item: '', scope:'', parent: {id:'', display: ''}});

    const [sectionDelete, setSectionDelete] = useState('');
    const [questionDelete, setQuestionDelete] = useState('');
    const [optionDelete, setOptionDelete] = useState('');
    const [optionOptionDelete, setOptionOptionDelete] = useState('');
    const [everythingDelete,setEverythingDelete] = useState('');
    const [deleteTrigger, setDeleteTrigger] = useState('');

    const [hover, setHover] = useState({id: '', toggle: false});

    const [isNew, setIsNew] = useState({id: '', bool: ''})

    const [sectionSearch, setSectionSearch] = useState({searchResult: '', searchList : []});
    const [questionSearch, setQuestionSearch] = useState({searchResult: '', searchList : []});
    const [optionSearch, setOptionSearch] = useState({searchResult: '', searchList : []});
    const [optionOptionSearch, setOptionOptionSearch] = useState({searchResult: '', searchList : []});

    const driveToParent = (newItem, parent) => {
        const item = newItem.id
        getSectionMenu(parent)
        getNewItem(item, parent)
    }

    const getNewItem = (item, parent) => {
        const blink = 1;
        const delay = 1000;
        setIsNew({id: item, bool: true})
        setTimeout(() => {
            setIsNew({id: '', bool: false})
        }, blink*delay);
        setTimeout(() => {
            setIsNew({id: item, bool: true})
        }, (blink+1)*delay);
        setTimeout(() => {
            setIsNew({id: '', bool: false})
        }, (blink+2)*delay);
        setTimeout(() => {
            setIsNew({id: item, bool: true})
        }, (blink+3)*delay);
        setTimeout(() => {
            setIsNew({id: '', bool: false})
        }, (blink+4)*delay);
        scrollToItem(item, parent);
    }

    const [seccc, setSeccc] = useState([])
    useEffect(() => {
        setSeccc([]);
        getSeccc();
    },[activeSection.id])

    const getSeccc = () => {
        let sec = seccc; 
        if(activeSection.trigger === "click") {
            sec.push(activeSection.id);
            setSeccc(seccc => seccc = sec);
            setTimeout(() => {
                getSectionMenu(seccc[seccc.length-1])
                scrollToItem(null, seccc[seccc.length-1])
                setSeccc([]);
            }, 400);
        }
    }

    const scrollToItem = (item, parent) => {
        setTimeout(() => {
            if (document.getElementById(parent + "SectionItem") !== null) {
                document.getElementById(parent + "SectionItem").scrollIntoView({behavior: 'smooth'});
           }
        }, 200);
        if(item) {
            setTimeout(() => {
                if (document.getElementById(item + "QuestionItem") !== null) {
                     document.getElementById(item + "QuestionItem").scrollIntoView({behavior: 'smooth'});
                }
            }, 700);
        }
    }

    // Handlers
    const handleSectionToggler = () => {
        setSectionToggler(sectionToggler => !sectionToggler)
    }
    const handleOptionToggler = () => {
        setOptionToggler(optionToggler => !optionToggler)
    }

    const handleOptionOptionToggler = () => {
        setOptionOptionToggler(optionOptionToggler => !optionOptionToggler)
    }

    const handleQuestionToggler = () => {
        setQuestionToggler(questionToggler => !questionToggler)
    }

    const handleHoverEnter = (e) => {
        setHover({id: e, toggle: true});
    }
    const handleHoverLeave = (e) => {
        setHover({id: e, toggle: false});
    }


    // Globalize
    const locales = service.getLocales();
    const getLocale = () => {
        const locale = sessionStorage.getItem('locale');
        return locale != null ? locale : 'es';
    }
    const setLocale = (locale) => {
        sessionStorage.setItem('locale', locale)
    }
    const [local, setLocal] = useState(getLocale());
    locale(local)
    const handleChangeLocale = (e) => {
        setLocal(local => local = e.value);
        setLocale(e.value);
        // document.location.reload();
    }
    const initMessages = () => {
        loadMessages(esMessages);
        loadMessages(enMessages);
        loadMessages(service.getDictionary().panel);
    }
    useEffect(() => {
        initMessages()
    }, [])

    //Updater (Add or Delete)
    const globalUpdater = (scope, item, list, action = true, parentSection, parentQuestion, parentOption) => {
        
        
        const functionDictionary = {
            "section": setSectionList,
            "question": setQuestionList,
            "option": setOptionList,
            "optionOption": setOptionOptionList
        }
    
        const virtualList = list;
        
        if(item === "") return;
        if(action) {
            if(virtualList.includes(item)) return;
            virtualList.push(item);
        } else {
            const index = virtualList.indexOf(item);
            virtualList.splice(index, 1);
        }
        
        functionDictionary[scope](virtualList);
    
        dataUpdater(scope, item, action, parentSection, parentQuestion, parentOption);
        
    }


    const dataUpdater = (scope, item, action, parentSection, parentQuestion, parentOption) => {

        let newData = parametrizedData;
        if(scope === "section") {
            if(action) {
                const updateSection = {...panelOptions.find(section => section.id === item)};
                if(!updateSection) return;
                updateSection["options"] = [];
                newData.unshift(updateSection);
            } else {
                const deleteSectionIndex = newData.findIndex(section => section.id === item);
                newData.splice(deleteSectionIndex, 1);
            }
        }

        if(scope === "question") {
            const dataSectionIndex = newData.findIndex(section => section.id === parentSection);
            if(!newData[dataSectionIndex]) return;
            if(action) {
                const panelSectionIndex = panelOptions.findIndex(section => section.id === parentSection);
                const updateQuestion =  {...panelOptions[panelSectionIndex].options?.find(section => section.id === item)};
                if(!updateQuestion) return;
                updateQuestion["options"] = [];
                newData[dataSectionIndex].options.push(updateQuestion);
            } else {
                const deleteQuestionIndex = newData[dataSectionIndex].options.findIndex(question => question.id === item);
                newData[dataSectionIndex].options.splice(deleteQuestionIndex, 1);
            }
        }

        if(scope === "option") {
            const dataSectionIndex = newData.findIndex(section => section.id === parentSection);
            if(!newData[dataSectionIndex]) return;
            const dataQuestionIndex = newData[dataSectionIndex].options.findIndex(question => question.id === parentQuestion);
            if(!newData[dataSectionIndex].options[dataQuestionIndex]) return;
            if(action) {
                const panelSectionIndex = panelOptions.findIndex(section => section.id === parentSection);
                const panelQuestionIndex = panelOptions[panelSectionIndex]?.options?.findIndex(question=> question.id === parentQuestion);
                const updateOption = {...panelOptions[panelSectionIndex]?.options[panelQuestionIndex]?.options?.find(option => option.id === item)};
                if(!updateOption) return;
                updateOption["options"] = [];
                newData[dataSectionIndex].options[dataQuestionIndex].options?.push(updateOption);
            } else {
                const deleteOptionIndex = newData[dataSectionIndex].options[dataQuestionIndex].options?.findIndex(question => question.id === item);
                newData[dataSectionIndex]?.options[dataQuestionIndex]?.options.splice(deleteOptionIndex, 1);
            }
        }

        if(scope === "optionOption") {
            const dataSectionIndex = newData.findIndex(section => section.id === parentSection);
            if(!newData[dataSectionIndex]) return;
            const dataQuestionIndex = newData[dataSectionIndex].options.findIndex(question => question.id === parentQuestion);
            if(!newData[dataSectionIndex].options[dataQuestionIndex]) return;
            const dataOptionIndex = newData[dataSectionIndex].options[dataQuestionIndex].options.findIndex(option => option.id === parentOption);
            if(!newData[dataSectionIndex].options[dataQuestionIndex].options[dataOptionIndex]) return;
            if(action) {
                const panelSectionIndex = panelOptions.findIndex(section => section.id === parentSection);
                const panelQuestionIndex = panelOptions[panelSectionIndex]?.options?.findIndex(question=> question.id === parentQuestion);
                const panelOptionIndex = panelOptions[panelSectionIndex]?.options[panelQuestionIndex].options.findIndex(option=> option.id === parentOption);
                const newOptionOption = panelOptions[panelSectionIndex]?.options[panelQuestionIndex]?.options[panelOptionIndex].options.find(option => option.id === item);
                if(!newOptionOption) return;
                let updateOptionOption = newOptionOption;
                updateOptionOption["options"] = [];
                newData[dataSectionIndex].options[dataQuestionIndex].options[dataOptionIndex].options?.push(updateOptionOption);
            } else {
                const deleteOptionOptionIndex = newData[dataSectionIndex].options[dataQuestionIndex].options[dataOptionIndex].options.findIndex(question => question.id === item);
                newData[dataSectionIndex].options[dataQuestionIndex].options[dataOptionIndex].options.splice(deleteOptionOptionIndex, 1);
            }
        }

        dispatch( setData(newData));
        console.log("NewData", newData);
    }


    const deleteFromFhir = (id) => {
        const sectionIndex = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[sectionIndex];
        const entryIndex = mySection?.entry?.map( ent => {
            return( ent?.identifier[0]?.value.substring(5,(ent?.identifier[0]?.value.length)))
        }).indexOf(id)
        
        let myEntry = mySection?.entry[entryIndex];

        if(myEntry){
            mySection?.entry.splice(entryIndex,1);
        }

        dispatch(sectionUpdated(mySection));
    }

    // Section
    const getSection = (section, sectionList) => {   
        console.log("Section", section, sectionList);
        getSectionMenu(section)     
        globalUpdater('section', section, sectionList);
    }
    const getSectionMenu = (e) => {
        setCurrentSectionMenu(e);
    }
    const deleteSection = (section) => {
        parametrizedData.some( data => {
            if(data.id === section) {
                setSectionDelete({id: section, display : data.display});
            }
        })
        setDeleteTrigger('section');
        openDeletePopup()
    }

    const deleteSectionConfirm = () => {
        closeDeletePopup();
        setCurrentSectionMenu("");
        setCurrentQuestionMenu({id: "", display: ""});
        setCurrentOptionMenu({id: "", display: ""});
        setCurrentOptionOptionMenu("");
        
        globalUpdater('section', sectionDelete.id, sectionList, false);
        
        const sectionIndex = panelOptions.findIndex(section => section.id === sectionDelete.id);
        const section = panelOptions[sectionIndex];

        if(section?.options.length === 0) return;
        section?.options.forEach( (question) => {
            globalUpdater('question', question.id, questionList, false, currentSectionMenu);
            if(question?.options?.length === 0) return;
            question?.options?.forEach( (option) => {
                globalUpdater('option', option.id, optionList, false, currentSectionMenu, currentQuestionMenu.id);
                if(option?.options?.length === 0) return;
                option?.options?.forEach((optionOption) => {
                    globalUpdater('optionOption', optionOption.id, optionOptionList, false, currentSectionMenu, currentQuestionMenu.id, currentOptionMenu.id);
                })
            })
        })
    }

    // Question
    const getQuestion = (e, list) => {
        globalUpdater('question', e, list, true, currentSectionMenu);
    }
    const getQuestionMenu = (e,f) => {
        setCurrentQuestionMenu({id: e, display: f});
    }

    const getTotalQuestions = () => {
        let itemList = [];
        let total = [];
        if (currentSectionMenu !== "") {
            const question = panelOptions.find( 
                section => section.id === (currentSectionMenu))
            total = question?.options;
        }

        const filter = questionSearch.searchResult?.toUpperCase();
        total?.some((item) => {
            if(item.display?.toUpperCase().indexOf(filter) > -1){itemList.push(item);}
        })

        return itemList
    }

    const getTotalOptions = () => {
        let itemList = [];
        let total = [];
        if (currentQuestionMenu.id !== "" && currentSectionMenu !== "") {
            const option = panelOptions.find( 
                section => section.id === (currentSectionMenu))?.options.find(
                    question => question.id === (currentQuestionMenu.id))
            if(option) {
                total = option?.options
            }
        }

        const filter = optionSearch.searchResult.toUpperCase();
        total?.some((item) => {
            if(item.display?.toUpperCase()?.indexOf(filter) > -1){itemList.push(item);}
        })

        return itemList
    }

    const getTotalOptionOptions = () => {
        let itemList = [];
        let total = [];
        if (currentOptionMenu.id !== "" && currentQuestionMenu.id !== "" && currentSectionMenu !== "") {
            const optionOption = panelOptions.find( 
                section => section.id === (currentSectionMenu))?.options.find(
                    question => question.id === (currentQuestionMenu.id))?.options.find(
                        option => option.id === (currentOptionMenu.id)
                )

            if(optionOption) {
                total = optionOption?.options
            }
        }

        const filter = optionOptionSearch.searchResult.toUpperCase();
        total?.some((item) => {
            if(item.display?.toUpperCase().indexOf(filter) > -1){itemList.push(item);}
        })

        return itemList
    }

    const deleteQuestion = (question) => {

        parametrizedData.some( data => {
            data.options.some( quest => { 
                if(quest.id === question) {
                    setQuestionDelete({id: question, display : quest.display});
                }
            })
            
        })
        setDeleteTrigger('question');

        openDeletePopup()
    }

    const deleteQuestionConfirm = () => {
        closeDeletePopup();
        const sectionIndex = panelOptions.findIndex(section => section.id === currentSectionMenu);
        const section = panelOptions[sectionIndex];

        const questionIndex = section.options?.findIndex(question => question.id === questionDelete.id);
        const question = section.options[questionIndex];

        globalUpdater('question', question.id, questionList, false, currentSectionMenu);
        if(question?.options?.length === 0) return;
        question?.options?.forEach( (option) => {
            globalUpdater('option', option.id, optionList, false, currentSectionMenu, currentQuestionMenu.id);
            if(option?.options?.length === 0) return;
            option?.options?.forEach((optionOption) => {
                globalUpdater('optionOption', optionOption.id, optionOptionList, false, currentSectionMenu, currentQuestionMenu.id, currentOptionMenu.id);
            })
        })

        setCurrentQuestionMenu({id: "", display: ""});
        setCurrentOptionMenu({id:"", display: ""});
        setCurrentOptionOptionMenu("");

        deleteFromFhir(questionDelete.id);
    }

    // Option
    const getOption = (e,d, list) => {
        setCurrentOptionMenu({id: e, display: d});
        globalUpdater('option', e, list, true, currentSectionMenu, currentQuestionMenu.id);
    }
    const deleteOption = (option) => {

        panelOptions.some( data => {
            if(data.id !== currentSectionMenu) return;
            data?.options?.some( quest => { 
                if(quest.id !== currentQuestionMenu.id) return;
                quest?.options?.some ( opt => {
                    if(opt.id === option) {
                        setOptionDelete({id: option, display : opt.display});
                    }
                })
            })
        })
        setDeleteTrigger('option');

        openDeletePopup()
    }
    const deleteOptionConfirm = () => {
        closeDeletePopup();

        setCurrentOptionOptionMenu("");
        setCurrentOptionMenu({id: "", display: ""});


        const sectionIndex = panelOptions?.findIndex(section => section.id === currentSectionMenu);
        const section = panelOptions[sectionIndex];

        const questionIndex = section.options?.findIndex(question => question.id === currentQuestionMenu.id);
        const question = section?.options[questionIndex];

        const optionIndex = question?.options?.findIndex((option => option.id === optionDelete.id));
        const option = question?.options[optionIndex];

        globalUpdater('option', optionDelete.id, optionList, false, currentSectionMenu, currentQuestionMenu.id);

        if(option?.options?.length === 0) return;
        option?.options?.forEach((optionOption) => {
            globalUpdater('optionOption', optionOption.id, optionOptionList, false, currentSectionMenu, currentQuestionMenu.id, currentOptionMenu.id);
        })
        
        deleteFromFhir(optionDelete.id);
    }

    const getOptionMenu = (e,f) => {
        setCurrentOptionMenu({id: e, display: f});
    }

        // OptionOption
        const getOptionOption = (e,list) => {
            globalUpdater('optionOption',e,list, true, currentSectionMenu, currentQuestionMenu.id, currentOptionMenu.id)
        }

        const deleteOptionOption = (option) => {

            const optionText = getTotalOptionOptions()?.find( opt => 
                opt.id === option).display;
            
            setOptionOptionDelete({id: option, display : optionText});
        
            setDeleteTrigger('optionOption');
    
            openDeletePopup()
        }

        const deleteOptionOptionConfirm = () => {
            closeDeletePopup();
            setCurrentOptionOptionMenu("");
            globalUpdater('optionOption', optionOptionDelete.id, optionOptionList, false, currentSectionMenu, currentQuestionMenu.id, currentOptionMenu.id);
        }
    
        const getOptionOptionMenu = (e) => {
            setCurrentOptionOptionMenu(e);
        }
    
    const questionValidator = () => {
        const option = currentQuestionMenu.id;
        const optionLista = getTotalQuestions();
        if(optionLista.some(opt => opt.id === option)) {
            return true
        }
    }


    useEffect(() => {
        const section = props.deleteSection;
        if(section !== "") {
            deleteSection(props.deleteSection)
        }
    }, [props.deleteSection])


    const openDeletePopup = () => {
        setCancelPopUpVisible(true)
    }

    const closeDeletePopup = () => {
        setCancelPopUpVisible(false)
    }

    const openAddPopup = () => {
        setAddPopUpVisible(true)
    }

    const closeAddPopup = () => {
        setAddPopUpVisible(false)
    }

    const openOpenPopup = () => {
        setOpenPopUpVisible(true)
    }

    const closeOpenPopup = () => {
        setOpenPopUpVisible(false)
    }

    const openEditPopup = () => {
        setEditPopUpVisible(true)
    }

    const closeEditPopup = () => {
        setEditPopUpVisible(false)
    }

    const openSavePopup = () => {
        setSavePopUpVisible(true)
    }

    const closeSavePopup = () => {
        setSavePopUpVisible(false)
    }


    // LeftPanel

    // Deshacer
    const deleteEverything = (section) => {
        setEverythingDelete({id: section, display : 'todo'});
        setDeleteTrigger('all');
        openDeletePopup();
    }

    const deleteEverythingConfirm = () => {

        let totalSections = sectionList;
        let totalQstion = questionList;
        let totalOptions = optionList;
        let data = parametrizedData;

        closeDeletePopup();

        setSectionList(sectionList => sectionList = []);
        setQuestionList(questionList => questionList = []);
        setOptionList([]);
        
        dispatch( setData([]));
        window.location.reload();
    }

     // Guardar
     const saveData = () => {
        openSavePopup()
        localStorage.setItem("Ficha", JSON.stringify(parametrizedData));
    }

    // Agregar
    const addData = () => {
        openAddPopup();
    }

    // Abrir
    const openData = () => {
        openOpenPopup();
    }

    //Editar
    const editData = (edit) => {
        openEditPopup()
        setEditSelected(edit)
    }

    const deleteFunc = () => {
        switch(deleteTrigger) {
            case 'section':
                return deleteSectionConfirm()
            case 'question': 
                return deleteQuestionConfirm()
            case 'option': 
                return deleteOptionConfirm()
            case 'optionOption':
                return deleteOptionOptionConfirm()
            case 'all': 
                return deleteEverythingConfirm()
            default: //console.log("Delete Tigger: ", deleteTrigger)
        }
    }

    useEffect(() => {
        const totalDefaultData = defaultMgmtPlan
        dispatch(setData(totalDefaultData))
    },[])


    const handleOnSectionSearch = (input) => {
        let itemList = [];
        const filter = (input.target.value).toUpperCase();
        panelOptions.some((item) => {
            if(item.display.toUpperCase().indexOf(filter) > -1){itemList.push(item);}
        })

        setSectionSearch({searchResult: input.target.value, searchList : itemList})
    }
    
    const handleOnSectionBlur = () => {
        setTimeout(() => {
            setSectionSearch({searchResult:"", searchList : panelOptions})
        }, 300);
    }

    const handleOnQuestionSearch = (input) => {
        setQuestionSearch({...questionSearch,searchResult: input.target.value})
    }

    const handleOnQuestionBlur = () => {
        setTimeout(() => {
            setQuestionSearch({searchResult:"", searchList : getTotalQuestions()})
        }, 300);
    }

    const handleOnOptionSearch = (input) => {
        setOptionSearch({...optionSearch,searchResult: input.target.value})
    }

    const handleOnOptionBlur = () => {
        setTimeout(() => {
            setOptionSearch({searchResult:"", searchList : getTotalOptions()})
        }, 300);
    }

    const handleOnOptionOptionSearch = (input) => {
        setOptionOptionSearch({...optionOptionSearch,searchResult: input.target.value})
    }

    const handleOnOptionOptionBlur = () => {
        setTimeout(() => {
            setOptionOptionSearch({searchResult:"", searchList : getTotalOptionOptions()})
        }, 300);
    }

    const handleOnQuestionKey = (e) => {
        let item;
        if (e.which === 13) {
            if ( getTotalQuestions().length === 1 ) {
                item = getTotalQuestions()[0];
                if (questionList.includes(item.id)) {
                    deleteQuestion(item.id)
                    handleOnQuestionBlur()
                } else {
                    getQuestionMenu(item.id, item.display)
                    getQuestion(item.id, sectionList)
                    handleOnQuestionBlur()
                }
            }
        }
    }

    const handleOnSectionKey = (e) => {
        let item;
        if (e.which === 13) {
            if ( sectionSearch.searchList.length === 1 ) {
                item = sectionSearch.searchList[0];
                if (sectionList.includes(item.id)) {
                    deleteSection(item.id);
                    handleOnSectionBlur();
                } else {
                    getSection(item.id, sectionList);
                    getSectionMenu(item.id);
                    handleOnSectionBlur();
                }
            }
        }
    }

    const handleOnOptionKey = (e) => {
        let item;
        if (e.which === 13) {
            if ( getTotalOptions().length === 1 ) {
                item = getTotalOptions()[0];
                if (optionList.includes(item.id)) {
                    deleteOption(item.id);
                    handleOnOptionBlur();
                } else {
                    getOptionMenu(item.id, item.display);
                    getOption(item.id, item.display, optionList);
                    handleOnOptionBlur();
                }
            }
        }
    }

    const handleOnOptionOptionKey = (e) => {
        let item;
        if (e.which === 13) {
            if ( getTotalOptionOptions().length === 1 ) {
                item = getTotalOptionOptions()[0];
                if (optionOptionList.includes(item.id)) {
                    deleteOptionOption(item.id);
                    handleOnOptionOptionBlur();
                } else {
                    getOptionOptionMenu(item.id, item.display);
                    getOptionOption(item.id, optionOptionList);
                    handleOnOptionOptionBlur();
                }
            }
        }
    }

    return(
        <Fragment>
            <div id={'panelContainer'} onContextMenu={(e) => e.preventDefault()}  className={'sections-container'}>
                <PanelMenu deleteEverything={deleteEverything} saveData={saveData} addData={addData} openData={openData}/>
                {/* Sections */}
                <div className={sectionToggler === true ? 'sections-content opened' : 'sections-content closed'}>
                        {sectionToggler === false 
                        ?
                        <div className={'section-closed-text'}><div className={'closed-text'}>{formatMessage('Sections')}</div></div>
                        :
                        <>
                        <div className={'section-opened-text'}><div className={'opened-text'}>{formatMessage('Sections')}</div></div>
                        <div className={'section-searcher'}>
                            <input 
                                value={sectionSearch.searchResult}
                                placeholder={'Buscar Page'}
                                onChange={handleOnSectionSearch}
                                onKeyDown={handleOnSectionKey}
                                onBlur={handleOnSectionBlur}
                            ></input>
                        </div>
                        <div className={'section-list'}>
                            {sectionSearch.searchList.map((section, secKey) => {
                                return (
                                    <div id={section.id+"SectionItem"} className={currentSectionMenu === section.id ? 'section-btn sb-active' :  'section-btn'} 
                                    key={secKey}
                                    onClick={() => getSectionMenu(section.id)}
                                    onMouseEnter={() => handleHoverEnter(section.id)}
                                    onMouseLeave={() => handleHoverLeave(section.id)}
                                    >
                                        <p id={section.id}>{section.display}</p>
                                        {((hover.id === section.id && hover.toggle) || currentSectionMenu === section.id || sectionList.includes(section.id)) && 
                                        <span>
                                            {!sectionList.includes(section.id) ?
                                            <Button
                                            onClick={() => getSection(section.id, sectionList)} 
                                            icon={'add'} 
                                            stylingMode="text"/> :
                                            <Button
                                            onClick={() => deleteSection(section.id)} 
                                            icon={'trash'} 
                                            stylingMode="text"/>
                                        }
                                        </span>}
                                    </div>
                                )
                            })}
                        </div>
                        </>
                        }
                        <div className={'section-toggler toggler-active'} onClick={handleSectionToggler}>
                                <Button icon={sectionToggler === true ? 'chevronleft' : 'chevronright'} stylingMode="text"/>
                        </div>
                    </div>
                    {/* Questions */}
                    {(currentSectionMenu !== "" && ![""].includes(currentSectionMenu))&&
                        <div className={questionToggler === true ? 'sections-content opened' : 'sections-content closed'}>
                            {questionToggler === false 
                            ?
                            <div className={'section-closed-text'}><div className={'closed-text'}>{formatMessage('Questions')}</div></div>
                            :
                            <>
                            <div className={'section-opened-text'}><div className={'opened-text'}>{formatMessage('Questions')}</div></div>
                            <div className={'section-searcher'}>
                            <input 
                                value={questionSearch.searchResult}
                                disabled={!sectionList.includes(currentSectionMenu)}
                                placeholder={'Buscar Grupo'}
                                onChange={handleOnQuestionSearch}
                                onKeyDown={handleOnQuestionKey}
                                onBlur={handleOnQuestionBlur}
                            ></input>
                            </div>
                            <div className={'section-list'}>
                                {panelOptions &&
                                getTotalQuestions().map((section, secKey) => {
                                    if (sectionList.includes(currentSectionMenu)){
                                        return (
                                            <div className={classNames(currentQuestionMenu.id === section.id ? 'section-btn sb-active' :  'section-btn', (isNew.id === section.id && isNew.bool) && 'new-item' )} 
                                            id={section.id+"QuestionItem"}
                                            onClick={() => getQuestionMenu(section.id, section.display)}
                                            onMouseEnter={() => handleHoverEnter(section.id)}
                                            onMouseLeave={() => handleHoverLeave(section.id)}
                                            key={secKey}>
                                                <p id={section.id}>{section.display}</p>
                                                {((hover.id === section.id && hover.toggle) || currentQuestionMenu.id === section.id || questionList.includes(section.id)) && 
                                                    <span>
                                                    {!questionList.includes(section.id) ?
                                                        <div>
                                                            <Button
                                                            onClick={() => editData({item: section, scope: 'question', parent: { id:panelOptions[panelOptions.map(function(e) { 
                                                                return e.id;
                                                            }).indexOf(currentSectionMenu)].id , display : panelOptions[panelOptions.map(function(e) { 
                                                                return e.id;
                                                            }).indexOf(currentSectionMenu)].display}})} 
                                                            icon={'edit'} 
                                                            stylingMode="text"/>
                                                            <Button
                                                                onClick={() => getQuestion(section.id, questionList)} 
                                                                icon={'add'} 
                                                                stylingMode="text"/>
                                                        </div>
                                                        :
                                                        <div>
                                                            <Button
                                                            onClick={() => editData({item: section, scope: 'question', parent: { id: currentSectionMenu , display : panelOptions[panelOptions.map(function(e) { 
                                                                return e.id;
                                                            }).indexOf(currentSectionMenu)].display}})} 
                                                            icon={'edit'} 
                                                            stylingMode="text"/>
                                                            <Button
                                                                onClick={() => deleteQuestion(section.id)} 
                                                                icon={'trash'} 
                                                                stylingMode="text"/>
                                                        </div>
                                                        }
                                                    </span>}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={classNames('section-btn sb-inactive' , (isNew.id === section.id && isNew.bool) && 'new-item' )} 
                                            id={section.id+"QuestionItem"}
                                            key={secKey}>
                                                <p id={section.id}>{section.display}</p>
                                            </div>
                                        )
                                    }

                                    
                                })}
                            </div>
                            </>
                            }
                            <div className={'section-toggler toggler-active'} onClick={handleQuestionToggler}>
                                    <Button icon={questionToggler === true ? 'chevronleft' : 'chevronright'} stylingMode="text"/>
                            </div>
                        </div>
                    }
                    {/* Options */}
                    {(currentQuestionMenu.id !== "" && !["", "mgmtPlan"].includes(currentSectionMenu)) &&
                        <div className={optionToggler === true ? 'sections-content opened' : 'sections-content closed'}>
                            {optionToggler === false 
                            ?
                            <div className={'section-closed-text'}><div className={'closed-text'}>{formatMessage('Options')}</div></div>
                            :
                            <>
                            <div className={'section-opened-text'}><div className={'opened-text'}>{formatMessage('Options')}</div></div>
                            <div className={'section-searcher'}>
                                <input 
                                    value={optionSearch.searchResult}
                                    disabled={!questionList.includes(currentQuestionMenu.id)}
                                    placeholder={'Buscar Variable'}
                                    onChange={handleOnOptionSearch}
                                    onKeyDown={handleOnOptionKey}
                                    onBlur={handleOnOptionBlur}
                                ></input>
                            </div>
                            <div className={'section-list'}>
                                {(currentQuestionMenu.id !== ""  && questionValidator()) &&
                                    getTotalOptions().map((section, secKey) => {
                                        if(questionList.includes(currentQuestionMenu.id) && sectionList.includes(currentSectionMenu)){
                                            return (
                                                <div className={currentOptionMenu.id === section.id ? 'section-btn sb-active' :  'section-btn'} 
                                                key={secKey}
                                                onClick={() => getOptionMenu(section.id, section.display)}
                                                onMouseEnter={() => handleHoverEnter(section.id)}
                                                onMouseLeave={() => handleHoverLeave(section.id)}
                                                >
                                                    <p id={section.id} >{section.display}</p>
                                                    {((hover.id === section.id && hover.toggle) || currentOptionMenu.id === section.id || optionList.includes(section.id) ) && 
                                                    <span>
                                                        {!optionList.includes(section.id) ?

                                                        <div>
                                                            <Button
                                                                onClick={() => editData({item: section, scope: 'option', parent: { id: currentQuestionMenu.id , display : currentQuestionMenu.display
                                                                }})}
                                                                icon={'edit'} 
                                                                stylingMode="text"/>
                                                            <Button
                                                            onClick={() => getOption(section.id, section.display, optionList)} 
                                                            icon={'add'} 
                                                            stylingMode="text"/> 
                                                        </div>
                                                        :
                                                        <div>
                                                            <Button
                                                                onClick={() => editData({item: section, scope: 'option', parent: { id: currentQuestionMenu.id , display : currentQuestionMenu.display}})} 
                                                                icon={'edit'} 
                                                                stylingMode="text"/>
                                                            <Button
                                                            onClick={() => deleteOption(section.id)} 
                                                            icon={'trash'} 
                                                            stylingMode="text"/>
                                                        </div>
                                                    }
                                                    </span>}
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className={'section-btn sb-inactive'} 
                                                key={secKey}>
                                                    <p id={section.id}>{section.display}</p>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                            </>
                            }
                            <div className={'section-toggler toggler-active'} onClick={handleOptionToggler}>
                                    <Button icon={optionToggler === true ? 'chevronleft' : 'chevronright'} stylingMode="text"/>
                            </div>
                        </div>
                    }
                    {/* Options Options */}
                    {(currentQuestionMenu.id !== "") &&
                        <div className={optionOptionToggler === true ? 'sections-content opened' : 'sections-content closed'}>
                            {optionOptionToggler === false 
                            ?
                            <div className={'section-closed-text'}><div className={'closed-text'}>{formatMessage('Options')}</div></div>
                            :
                            <>
                            <div className={'section-opened-text'}><div className={'opened-text'}>{formatMessage('Options2')}</div></div>
                            <div className={'section-searcher'}>
                                <input 
                                    value={optionOptionSearch.searchResult}
                                    disabled={!optionList.includes(currentOptionMenu.id)}
                                    placeholder={'Buscar Opción'}
                                    onChange={handleOnOptionOptionSearch}
                                    onKeyDown={handleOnOptionOptionKey}
                                    onBlur={handleOnOptionOptionBlur}
                                ></input>
                            </div>
                            <div className={'section-list'}>
                                {getTotalOptionOptions()?.map((section, secKey) => {
                                        if(questionList.includes(currentQuestionMenu.id) && sectionList.includes(currentSectionMenu) && optionList.includes(currentOptionMenu.id) ){
                                            return (
                                                <div className={currentOptionOptionMenu === section.id ? 'section-btn sb-active' :  'section-btn'} 
                                                key={secKey}
                                                onClick={() => getOptionOptionMenu(section.id)}
                                                onMouseEnter={() => handleHoverEnter(section.id)}
                                                onMouseLeave={() => handleHoverLeave(section.id)}
                                                >
                                                    <p id={section.id} >{section.display}</p>
                                                    {((hover.id === section.id && hover.toggle) || currentOptionOptionMenu === section.id || optionOptionList.includes(section.id)) && 
                                                    <span>
                                                        {!optionOptionList.includes(section.id) ?
                                                        <Button
                                                        onClick={() => getOptionOption(section.id, optionOptionList)} 
                                                        icon={'add'} 
                                                        stylingMode="text"/> :
                                                        <Button
                                                        onClick={() => deleteOptionOption(section.id)} 
                                                        icon={'trash'} 
                                                        stylingMode="text"/>
                                                    }
                                                    </span>}
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className={'section-btn sb-inactive'} 
                                                key={secKey}>
                                                    <p id={section.id}>{section.display}</p>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                            </>
                            }
                            <div className={'section-toggler toggler-active'} onClick={handleOptionOptionToggler}>
                                    <Button icon={optionOptionToggler === true ? 'chevronleft' : 'chevronright'} stylingMode="text"/>
                            </div>
                        </div>
                    }
                </div>
                {cancelPopupVisible && 
                    <DeletePopup 
                        visible={cancelPopupVisible}
                        onHiding={closeDeletePopup}
                        dragEnabled={true}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        title="¿Estás seguro?"
                        closePopup={closeDeletePopup}
                        deleteFunc={deleteFunc}
                        deleteTrigger={deleteTrigger}
                        deleteObject={{sectionDelete, questionDelete, optionDelete, everythingDelete, optionOptionDelete}}
                        mainSection={currentSectionMenu}
                    /> 
                }
                {addPopupVisible && 
                    <AddPopup 
                        visible={addPopupVisible}
                        onHiding={closeAddPopup}
                        dragEnabled={true}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        title="Agregar"
                        closePopup={closeAddPopup}
                        parametrizedData={parametrizedData}
                        newItem={driveToParent}
                    /> 
                }
                {editPopupVisible && 
                    <EditPopup 
                        visible={editPopupVisible}
                        onHiding={closeEditPopup}
                        dragEnabled={true}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        title="Editar"
                        closePopup={closeEditPopup}
                        selected={editSelected}
                        mainSection={currentSectionMenu}
                    /> 
                }
                {savePopupVisible && 
                    <SavePopup 
                        visible={savePopupVisible}
                        onHiding={closeSavePopup}
                        dragEnabled={true}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        title="Guardar"
                        closePopup={closeSavePopup}
                        selected={editSelected}
                    /> 
                }

                {openPopupVisible && 
                    <OpenPopup 
                        visible={openPopupVisible}
                        onHiding={closeOpenPopup}
                        dragEnabled={true}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        title="Abrir"
                        closePopup={closeOpenPopup}
                        selected={editSelected}
                        // loadRemoteData={loadRemoteData}
                    /> 
                }
                {/* <div className="option">
                <label htmlFor="selectInput">Language</label>
                &nbsp;
                <SelectBox items={locales}
                valueExpr="Value"
                displayExpr="Name"
                value={local}
                onValueChanged={handleChangeLocale}
                inputAttr={{ id: 'selectInput' }} />
            </div> */}
            {/* {responsiveSmall() && <div className={'panel-toggler'}>

                <Button icon={'chevronup'} onClick={togglePanel} stylingMode="text"/>
            
                </div>} */}
        </Fragment>
    )

}



export const MemoizedPanel = memo(Panel);