import { types } from "../types/types";



export const appointmentSetActive = ( appointment ) => ({
    type: types.appointmentSetActive,
    payload: appointment
});

export const clearActiveAppointment = () => ({ type: types.clearActiveAppointment });
