import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch , useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';
import './agenda.css';
import { startGetPatientByProntuar } from '../../redux/actions/clinicHistory';
import notify from 'devextreme/ui/notify';
import { getTemplateBySpecialty } from '../../utils/helpersPoints';
import SelectedDropDown from './selectDropdown';
import { /* resetFicha, */ startCreateRegisterProductivity, startLoadingFicha, startUpdateStatusEncounter } from '../../redux/actions/hcData';
import { setServerTime } from '../../redux/actions/productivity';
import Button from 'devextreme-react/button';

export const AppointmentTooltip = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { responsive } = useSelector(state => state.ui);
  const { appointment: activeAppointment, especiality } = useSelector(state => state.agenda);
  const { serverTime } = useSelector(state => state.productivityPanel);
  const auth = sessionStorage.getItem('auth');

  const [toggle, setToggle] =  useState(true);
  const [fichas, setFichas] = useState([]);
  const [ espSelected, setEspSelected ] = useState({id:"", name: '', code: 0});
  const [ finalSelect, setFinalSelect ] = useState("");

  const userData  = props.data;
  const spec = props.spec;
  const {status} = props.data;

  const {auth: professional} = useSelector(state => state);

  useEffect(() => {
    getFichas();
  }, [])

  const getFichas = async() => {
    const fichas2 = await getTemplateBySpecialty(auth, spec);
    setFichas(fichas2);
  }

  const opts = fichas?.map( fich => {
      return {
          code: JSON.parse(fich.Data).id,
          id: fich.RowKey,
          text: fich.Name,
          canWrite: false,
      }
  });

  const months = [
    {id: 1, text: 'Enero'},
    {id: 2, text: 'Febrero'},
    {id: 3, text: 'Marzo'},
    {id: 4, text: 'Abril'},
    {id: 5, text: 'Mayo'},
    {id: 6, text: 'Junio'},
    {id: 7, text: 'Julio'},
    {id: 8, text: 'Agosto'},
    {id: 9, text: 'Septiembre'},
    {id: 10, text: 'Octubre'},
    {id: 11, text: 'Noviembre'},
    {id: 12, text: 'Diciembre'},
  ]

  const handleToHistorial = async() => {
    //console.log("USERDATA:", userData)
    const patient = await dispatch(startGetPatientByProntuar(userData.prontuar));
    if (patient?.patient?.id) {
      history.push('/historial-clinico/'+userData.prontuar);
    }else{
      notify({
        message: 'El paciente no tiene historial médico',
        width: 10,
        position: {
            my: 'center top',
            at: 'center top',
        }
    }, 'error', 2000);
    }
  }

  useEffect(() => {
    toggleDropDown();
  }, [])

  const handleToConsulta = () => {
    history.push('/encuentro/'+spec)
  }

  const goToEncounter = () => {
    history.push('/encuentro/'+activeAppointment?.idEncounterFhir+'/'+espSelected.code);
    dispatch(setServerTime());
    const extendCode = `${espSelected?.code}-${activeAppointment?.prontuar}-${activeAppointment?.codeEnterprise}-${activeAppointment?.idEncounterSIH}-${professional?.emailUser?.MedicalCodeSIH}-${spec}-${moment(serverTime).format('YYYYMMDD')}`;
    const tempName = espSelected?.name.split(' - ');
    let data = {
      EncounterId: extendCode/* activeAppointment?.idEncounterSIH */,
      ProfessionalCode: professional?.emailUser?.MedicalCodeSIH,
      ProfessionalName: professional?.displayName,
      SpecialtyCode: spec,
      SpecialtyName: especiality?.display,
      TemplateCode: espSelected?.code,
      TemplateName: tempName[1],
      // TemplateName: espSelected?.name,
    }
    if(status === 'arrived') {
      dispatch(startCreateRegisterProductivity(data));
      dispatch(startUpdateStatusEncounter(activeAppointment?.idEncounterFhir));
    }
    dispatch(startLoadingFicha(finalSelect));
    //console.log("Go To Encounter:", activeAppointment, espSelected, finalSelect)
}

  const handleSpecSelect = (spec) => {
    //console.log(spec)
    setEspSelected({name: spec.currentSelectedRowKeys[0].text , id: spec.currentSelectedRowKeys[0].id, code: spec.currentSelectedRowKeys[0].code});
    setFinalSelect(spec.currentSelectedRowKeys[0].id);
    cancel();
  }
    
  const toggleDropDown = () => {
    setToggle(true);
    const element = $("#agendaEspecSelect");
    element?.slideDown('slow');
  }

  const save = () => {
    setToggle(false);
  }

  const cancel = () => {
      setToggle(false);
      $("#agendaEspecSelect").slideUp('slow');
  }

  const savedData = localStorage.getItem(userData.idEncounterFhir);
    
  let currentSavedData;

  if(savedData && savedData !== "undefined" && savedData !== "null") {
      currentSavedData = JSON.parse(savedData);
  }

  const newAppointment = () => {
    return (
      <div className="agenda-tooltip">
        <div className={'agenda-tooltip-info'}>
        {
          (['arrived', 'in-progress'/* , 'finished' */].includes(status)) &&
          <>
            <h3 style={{fontSize: 50}} className={'content-block roboto'}>Iniciar</h3>
            <h3 style={{fontSize: 50}} className={'content-block roboto'}>Encuentro</h3>
          </>
        }
          <div className={'agenda-tooltip-data'}>
            <p>Nombre</p>
            <h4 className={'title'}><b>{userData.text}</b></h4>
            <p>Edad</p>
            <p className={'title'}>{userData.age}</p>
            <p>Documento</p>
            <p className={'title'}>{userData.prontuar}</p>
          </div>
          {
            (['arrived', 'in-progress'/* , 'finished' */].includes(status)) &&
            <div className={'agenda-tooltip-combo'}>
              <div
                    id={'selectorContainer'}
                    className={'relative'}
                >
                    <input 
                        id={"AgendaEpecInput"} 
                        className={'text-input enter-jump mgmt-input mgmt-input-item '} 
                        onClick={toggleDropDown}
                        type={"text"} 
                        required
                        name={"section"}
                        readOnly
                        value={espSelected.id !== "" ? espSelected.name : "Selecciona una Ficha"}
                        autoComplete={"off"}
                        >
                    </input>
                    {/* {toggle && */}
                        <SelectedDropDown open={toggle} save={save} cancel={cancel} select={handleSpecSelect} dataSource={opts} responsive={responsive}/>
                    {/* } */}
                </div>
            </div>
          }
        </div>
        <div className="agenda-info">
          {
            (['arrived', 'in-progress'/* , 'finished' */].includes(status)) 
              ?
              <>
                <Button
                  onClick={finalSelect === "" ? null : goToEncounter}
                  text="INICIAR ENCUENTRO"
                  type="default"
                  stylingMode={finalSelect === "" ? "outlined" : "contained"}
                  disabled={finalSelect === "" ? true : false}
                  />
                <div className="agenda-btn-container">
                  <div onClick={handleToHistorial} className="agenda-btn">VER HISTORIAL MÉDICO</div>
                </div>
              </>
                :
            <div className="agenda-btn-container">
                <Button
                    onClick={handleToHistorial}
                    text="VER DATOS DE LA ATENCIÓN"
                    type="default"
                    stylingMode={"contained"}
                    />
            </div>
            }
        </div>
      </div>
    )
  }


  const oldAppointment = () => {
    return (
      <div className="agenda-tooltip">
        <div className={'agenda-tooltip-info'}>

          <h3 style={{fontSize: 50}} className={'content-block roboto'}>Encuentro en</h3>
          <h3 style={{fontSize: 50}} className={'content-block roboto'}>progreso por:</h3>

          <div className={'agenda-tooltip-data'}>
              <h4 className={'title'}><b>{currentSavedData?.practitionerName ? currentSavedData?.practitionerName : "Especialista"}</b></h4>
          </div>

        </div>
  
        <div className="agenda-info">
          <div className="agenda-btn-container">
            <div onClick={props.closePopup} className="agenda-btn">CERRAR</div>
          </div>
        </div>
      </div>
    )
  }

  const isTodayAppointment = () => {
    return (
      <div className="agenda-tooltip">
        <div className={'agenda-tooltip-info'}>
          <div className={'agenda-tooltip-data'}>
            <p>Nombre</p>
            <h4 className={'title'}><b>{userData.text}</b></h4>
            <p>Edad</p>
            <p className={'title'}>{userData.age}</p>
            <p>Prontuario</p>
            <p className={'title'}>{userData.prontuar}</p>
          </div>
        </div>
        <div className="agenda-info">
            <div className="agenda-btn-container">
                <Button
                    onClick={handleToHistorial}
                    text="VER DATOS DE LA ATENCIÓN"
                    type="default"
                    stylingMode={"contained"}
                    />
            </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {!props.isToday ? isTodayAppointment() : newAppointment() 
      }
    </Fragment>
  )

}

