import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//microsoftTeams testt
import * as microsoftTeams from "@microsoft/teams-js";

import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
  } from "react-router-dom";

// import LoadPanel from 'devextreme-react/load-panel';
  
import { AuthRouter } from './AuthRouter';
import { DashboardRoutes } from './DashboardRoutes';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { startLogin } from '../redux/actions/auth';
import { Dashboard } from '../pages/retryPanel/Dashboard';
import { LoginForm } from '../pages/retryPanel/LoginForm';
import { setAdmin, startLoginAdmin } from '../redux/actions/dashboard';

import 'devextreme-intl';
// import esMessages from 'devextreme/localization/messages/es.json!json';
import * as esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';

export const AppRouter = () => {
    // Initialize the Microsoft Teams SDK
    //microsoftTeams.initialize();
    loadMessages(esMessages);
    locale(navigator.language || navigator.browserLanguage)

    const dispatch = useDispatch(); 
    
    //autenticación
    const [ isLoggedIn, setIsLoggedIn ] = useState(false);
    const {uid} = useSelector(state => state.auth); //No borrar, pendiente de cambio.    
    //const token = localStorage.getItem('token');
   
   //ADMIN ROWKEY PANEL 
    const [logAdmin, setLogAdmin] = useState(false);
    const {isAdmin} = useSelector(state => state.dashboard); 
    const rowKeyAdmin = sessionStorage.getItem('rowKeyAdmin');
    const email = sessionStorage.getItem('email');
    const pass = sessionStorage.getItem('pass');
    useEffect(() => {
        if (isAdmin) {
            ////console.log('isAdmin', isAdmin);
            setLogAdmin(true);
        }else{
            //dispatch(startLogin());
            if (rowKeyAdmin) {
                ////console.log('rowKeyAdmin: ', rowKeyAdmin);
                setAdmin(rowKeyAdmin);
                dispatch(startLoginAdmin(email, pass));
                setLogAdmin(true);
            }else{
                setLogAdmin(false);
            }
        }
        microsoftTeams.initialize();
        window.localStorage.fluentUIDebug = true;
        window.localStorage.felaDevMode = true;
        ////console.log('::TOKEN:: ', token);
        if(uid){
            //dispatch(login(token, email, type))
            setIsLoggedIn(true);            
        }else{
            dispatch(startLogin());
            setIsLoggedIn(false);
        }
    }, [ dispatch, uid, isAdmin, sessionStorage])

    return (
        <Router>
            {/* <div> */}
                <Switch>
                        {
                            (logAdmin) ?
                            <Route path="/dashboard/one-view">
                                <Dashboard />
                            </Route> :
                            <Route path="/dashboard">
                                <LoginForm />
                            </Route>
                        }

                        <PublicRoute
                            path="/auth"
                            //isAuthenticated={ isLoggedIn }
                            isAuthenticated={ uid ? true : false }
                            component={ AuthRouter }
                            
                        />

                        <PrivateRoute
                            //exact
                            isAuthenticated={ isLoggedIn }
                            path="/"
                            //path = { lastPath }
                            component = { DashboardRoutes }
                        />
                    <Redirect to="/auth/tab" />
                </Switch>
            {/* </div> */}
        </Router>
    )
  }