import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'devextreme-react/button';
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';
import Popup, { Position, ToolbarItem } from 'devextreme-react/popup';
import TextArea from 'devextreme-react/text-area';
import notify from 'devextreme/ui/notify';
import LoadPanel from 'devextreme-react/load-panel';

import { JsonView } from './JsonView';
import { startLoadRetries } from '../../redux/actions/dashboard';
import { retryLog, validateRetry } from '../../utils/helpersPoints';
import { finishLoading, startLoading } from '../../redux/actions/ui';

export const Panela = () => {
    const dispatch= useDispatch();
    const [events, setEvents] = useState([]);
    const {  registers, email, password } = useSelector(state => state.dashboard);
    const {  loading  } = useSelector(state => state.ui);

    useEffect(() => {
        if (!registers) {
            dispatch(startLoadRetries(email, password));
        }
    }, []);
    const logEvent = (eventName) => {
        return setEvents( [eventName].concat(events) );
    };

    const clearEvents = () => {
        setEvents([]);
    };

    ///POPUP
    const [statePopup, setStatePopup] = useState({
        currentEmployee: {},
        popupVisible: false,
        positionOf: ''
    });

    const [editData, setEditData] = useState({
        currentElement: {},
        popupVisible: false,
        positionOf: ''
    });

    const closeJsonView = () => {
        setEditData({ ...editData, popupVisible: false});
    }

    const hideInfo = () => {
        setStatePopup({...statePopup, currentEmployee: {}, popupVisible: false});
    }
    const hideInfo2 = () => {
        setEditData({...editData, currentElement: {}, popupVisible: false});
    }
    const sendEmail = () => {
        const message = `Email is sent to ${statePopup.currentEmployee.FirstName} ${statePopup.currentEmployee.LastName}`;
        notify({
          message: message,
          position: {
            my: 'center top',
            at: 'center top'
          }
        }, 'success', 3000);
      }

    const saveJsonButton =  {
        icon: 'floppy',
        text: 'Guardar',
        onClick: sendEmail
    };

      const closeButtonOptions =  {
        text: 'Cancelar',
        onClick: hideInfo2
    };

    const popupData = () => {
        return (
            <div>
            <Popup
                visible={statePopup.popupVisible}
                onHiding={hideInfo}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Information"
                container=".dx-viewport"
                width={620}
                height={"auto"}
                >
                    <TextArea
                        height="auto"
                        value={statePopup.currentEmployee.fhirExceptions}
                    />
            </Popup>
            </div>
        )
    }

    const jsonView = (data) => {
        return <JsonView data={ data } setEditData={closeJsonView}/>
    }
    const popupEditData = (data) => {
        ////console.log('data2::: ', JSON.parse(data?.FhirObject))
        return (
            <div>
                <Popup
                    visible={editData.popupVisible}
                    onHiding={hideInfo2}
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    title="Message to Synch"
                    container=".dx-viewport"
                    width={700}
                    //height={620}
                    height={"auto"}

                    contentRender={()=>jsonView(data)}
                >
                {/* <Position
                at="bottom"
                my="center"
                of={statePopup.positionOf}
                />  */}
                {/* <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={saveJsonButton}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonOptions}
                /> */}
                </Popup>
            </div>
        )
    }

    ///
    const view = (e) => {
        ////console.log('click view: ', e);
        setStatePopup( {...statePopup, currentEmployee: {...e},  popupVisible: true});
    }

    const edit = async(e) => {
        console.log('eeeedit: ', e)
        const { type, id } = e;
        let ids = id.split('-');
        if (["CreateComposition", "CreateObservations", "CreateMedicationRequest", "CreateServiceRequest"].includes(type)) {
            let id_patient = ids[1] ;
            let id_encounter = id ;
            //console.log(id_patient, id_encounter);
            dispatch(startLoading());
            try {
                const resp = await validateRetry(email, password, id_patient, id_encounter);
                //console.log('resppp: ', resp);
                e.fhirObject = resp;
                setEditData( {...editData, currentElement: {...e.fhirObject},  popupVisible: true});    
            } catch (error) {
                console.log('Error: ', error);
            }
            dispatch(finishLoading());
        }else{
            let data = JSON.parse(e.fhirObject);
            setEditData( {...editData, currentElement: {...data},  popupVisible: true});
        }
    }

    const retry = async(e) => {
        // console.log('click retry: ', e);
        const { id, fhirObject} = e;
        let ids = id.split('-');
        let id_patient = ids[1] ;
        let id_encounter = id ;

        if (fhirObject !== null) {
            dispatch(startLoading());
            try {
                //console.log('envie petición diferente de null');
                let retry = await retryLog(email, password, e);
                // console.log('retrry: ', retry); 
            } catch (error) {
                console.log('error: ', error);
            }
            dispatch(finishLoading());
        }else{
            console.log('Fhir Object es null ! ');
            try {
                const resp = await validateRetry(email, password, id_patient, id_encounter);
                //console.log('respp: ', resp);
                e.fhirObject = resp;
                let retry = await retryLog(email, password, e);

                //console.log('e.fhirObject: ', e.fhirObject);
                //console.log('peticion enviada: ', e);
            } catch (error) {
                console.log('error::: ', error)
            }
        }
    }

    const cellButton = (e) => {
        return <div>
        <Button
            width={24}
            icon="txtfile"
            //text="Ver"
            //type="normal"
            //stylingMode="contained"
            onClick={()=>view(e.data)}
            />

        <Button
            width={24}
            icon="edit"
            //text="Editar"
            //type="normal"
            //stylingMode="contained"
            onClick={()=>edit(e.data)}
            />

        <Button
            width={24}
            icon="refresh"
            //text="Reintentar"
            //type="normal"
            //stylingMode="contained"
            onClick={()=>retry(e.data)}
            />
    </div>
        
    }

    

    return (
        <>
        {
          (loading)&&<LoadPanel visible={true} /> 
        }
         <div>
            <DataGrid
                id="gridContainer"
                dataSource={registers}
                //keyExpr="ID"
                allowColumnReordering={true}
                showBorders={true}
                onEditingStart={()=> logEvent('EditingStart')}
                onInitNewRow={()=> logEvent('InitNewRow')}
                onRowInserting={()=> logEvent('RowInserting')}
                onRowInserted={()=> logEvent('RowInserted')}
                onRowUpdating={()=> logEvent('RowUpdating')}
                onRowUpdated={()=> logEvent('RowUpdated')}
                onRowRemoving={()=> logEvent('RowRemoving')}
                onRowRemoved={()=> logEvent('RowRemoved')}
                onSaving={()=> logEvent('Saving')}
                onSaved={()=> logEvent('Saved')}
                onEditCanceling={()=> logEvent('EditCanceling')}
                onEditCanceled={()=> logEvent('EditCanceled')}

            >

            <Paging enabled={true} />
            <Column dataField="id" caption="ID-" />
            <Column dataField="retries" caption="Reintentos" />
            <Column dataField="type" caption="Tipo" />
            <Column
                dataField="timestamp"
                //width={130}
                caption="Fecha"
                dataType="date" />
            <Column
                caption="Acciones"
                cellRender={(e) => cellButton(e)}
               // dataField="ID"
                //editCellRender={statusEditorRender}
                />

            <Paging enabled={true} />
            <Paging defaultPageSize={10} />
                
            </DataGrid>

            <div id="events">
                {/* <div>
                    <div className="caption">Fired events</div>
                    <Button id="clear" text="Clear" onClick={clearEvents} />
                </div>
                <ul>
                    {events.map((event, index) => <li key={index}>{event}</li>)}
                </ul> */}
                {
                    popupData()
                }
                {
                    // (editData.popupVisible) && 
                    // <JsonView data={ editData.currentElement } />
                    popupEditData(editData.currentElement)
                }
            </div>
        </div>
        </>
        

    )
}
