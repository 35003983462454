import React, { useState, useEffect, Fragment } from 'react';

import { useParams } from 'react-router-dom';

import IndigoSelectDrop from './indigoSelectDrop.jsx';
import IndigoItemsList from '../form/indigoItemsList.jsx';
import { Tooltip } from 'devextreme-react/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../../../../redux/actions/parametrizer.js';
import moment from 'moment';

import Button from 'devextreme-react/button';
import ContextMenu from 'devextreme-react/context-menu';
import { Popup } from 'devextreme-react/popup';
import IndigoCommentInput from '../indigoCommentInput.jsx';
import Observation from '../../utils/fhir/observation.js';
import { sectionUpdated } from '../../../../../redux/actions/fhir.js';
import { setAutoSaveSelectInput } from '../../../../../redux/actions/autoSave.js';

function IndigoSelect(props) {
    var classNames = require('classnames');
    const { info, focused, getFocused, granPaID, editable, render } = props;

    const dispatch = useDispatch();
    const { fhir } = useSelector(state => state)
    const { data } = useSelector( state => state.parametrizer);
    const { responsive } = useSelector(state => state.ui);
    const { id: encounterId } = useParams();
    const { patient, practitioner, startDate } = useSelector(state => state.agenda.appointment);
    
    const autoSave = useSelector( state => state.autoSave);
    const savedData = localStorage.getItem(encounterId);

    let defaultValue = "Selecciona";
    let defaultComment = "";
    if(savedData && savedData !== "undefined" && savedData !== "null") {
        if(JSON.parse(savedData)[info.id]?.value) {
            defaultValue = JSON.parse(savedData)[info.id]?.value;
        }
        if(JSON.parse(savedData)[info.id]?.comment) {
            defaultComment = JSON.parse(savedData)[info.id]?.comment;
        }
    }
    const [selectValue, setSelectValue] = useState({display: defaultValue});
    const [comment, setComment] = useState(defaultComment);
    const [showTooltip, setShowTooltip] = useState(false);
    const [canDrop, setCanDrop] = useState(true);
    const [arrowDropIndex, setArrowDropIndex] = useState(-1);
    const [contextOpen, setContextOpen] = useState(null);
    const [cancelPopupVisible, setCancelPopUpVisible] = useState(false);
    const [isWritable, setIsWritable] = useState(false);

    const [sectionSizes, setInputSizes] = useState([]);
    const [maxSizeDefault, setMaxSizeDefault] = useState({XL: info.size, L: (parseInt(info.size,10)-1), M: (parseInt(info.size,10)-2), S: 2, XS: 1});
    const [newObservation, setNewObservation] = useState(new Observation());

    useEffect(() => {
        localStorage.setItem(encounterId, JSON.stringify(autoSave[encounterId]));
    }, [JSON.stringify(autoSave[encounterId])])

    useEffect(() => {
        newObservation.patientID = patient ? patient : null;
        newObservation.encounterID = encounterId ? encounterId : null;
        newObservation.date = moment(new Date(startDate)).format('YYYY-MM-DD');
        newObservation.practitionerID = practitioner ? practitioner : null;

        newObservation.inputID = {type: "select", id: info.id, context: "000"};
        newObservation.canWrite = true;
        newObservation.label = info.display;
        newObservation.questionNumber = info.questionNumber;
        newObservation.type = 1;
        sizeConvert()
        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[index];
        if(!mySection) return;
        if(!mySection?.entry.some( ent => {return ent?.identifier[0].value.includes(info.id)})) {
            mySection?.entry?.push(newObservation.newObvervation);
        }
        dispatch(sectionUpdated(mySection));
    },[render])

    const sizeConvert = () => {
        switch(info.size){
            case 1:
                setMaxSizeDefault({XL: info.size, L: 1, M: 1, S: 1, XS: 1});
                break;
            case 2:
                setMaxSizeDefault({XL: info.size, L: 2, M: 2, S: 1, XS: 1});
                break;
            case 3:
                setMaxSizeDefault({XL: info.size, L: 2, M: 2, S: 1, XS: 1});
                break;
            default:
                setMaxSizeDefault({XL: info.size, L: 3, M: 2, S: 1, XS: 1});
                break;
        }
    }

    const windowSizing = () => {
        switch (responsive) {
            case "extralarge":
                setInputSizes([1,2,3,4,5]);
                updateSize(5);
                break;

            case "large":
                setInputSizes([1,2,3]);
                updateSize(3);
            break;

            case "medium":
                setInputSizes([1,2]);
                updateSize(2);
            break;

            case "small":
                setInputSizes([1]);
                updateSize(1);
            break;

            default:
                setInputSizes([1]);
                updateSize(1);
                break;
        }
        
        return window.screen.width
    }
    
    const updateSize = (size) => {
        const sizeCon = {
             "extralarge": maxSizeDefault.XL,
             "large": maxSizeDefault.L,
             "medium": maxSizeDefault.M,
             "small": maxSizeDefault.S,
             "extrasmall": maxSizeDefault.XS,
        }

        handleChangeSize(sizeCon[Object.keys(sizeCon).find( el => 
            el === responsive
        )])

        if(info.size > size) {
            handleChangeSize(size)
        }
    }

    useEffect(() => {
        windowSizing();
      }, [responsive]);

    const toggleEnterTooltip = () => {
        setShowTooltip(true);
    }

    const toggleLeaveTooltip = () => {
        setShowTooltip(false);
    }

  const openPopup = () => {
    setCancelPopUpVisible(true)
    }

    const closePopup = () => {
        setCancelPopUpVisible(false)
        sizeConvert();
    }

    const handleDeleteItem = () => {
        openPopup()
        setContextOpen(false);
        setTimeout(
            () => {
                setContextOpen(null);
            },
            [300],
        );
    }

    const handleChangeSize = size => {
        const newData = data;        
        newData.some( data => {
            data.options.some( quest => {
                if(quest.id === info.id){
                    quest.size = size
                }
            })
        })
        dispatch( setData(newData))
    }

    const updateList = (list) => {
        const newData = data;        
        newData.some( data => {
            data.options.some( quest => {
                if(quest.id === info.id){
                    quest.options = list
                }
            })
        })
        dispatch( setData(newData))
    }

    const handleOnClick = (e) => {
        if(info.options?.length > 0) {
            if(focused === info.id) {
                setCanDrop(true);
            } else {
                setCanDrop(candrop => candrop = !canDrop);
            }
        } else {
            setCanDrop(false);
        }
    }

    const handleDropOnKey = (e) => {
        switch (e.which) {
            case 40:
                setCanDrop(true);
                if(canDrop){
                    if(arrowDropIndex < info.options?.length-1){
                        setArrowDropIndex(arrowDropIndex => arrowDropIndex += 1);
                    }
                }
                break;
            
            case 38:
                if(canDrop){
                    if(arrowDropIndex > 0){
                        setArrowDropIndex(arrowDropIndex => arrowDropIndex -= 1);
                    } else {
                        setCanDrop(false);
                        setArrowDropIndex(-1);
                    }
                }
                break;

            case 13: 
                    setCanDrop(false); 
                    setArrowDropIndex(-1);
                break;
                
            case 27: 
                if(selectValue.display !== "Selecciona") {
                    resetSelection();
                }
                break;
            default:
                break;
        }
    }

    const navigateDrop = () => {
        setArrowDropIndex(1)
    }

    const handleGetFocused = () => {
        getFocused(info.id);
        setCanDrop(true);
    }

    const handleUnFocused = () => {
        // setTimeout(() => {
        //     setCanDrop(false);
        // }, 300);
    }

    const onItemFocus = (e) => {
        let valueDisplay = null;
        if(e.display.split("")?.length === 0) {
            valueDisplay = null;
        } else {
            valueDisplay = e.display;
        }
        setSelectValue(e)
        newObservation.canWrite = e.canWrite;
        newObservation["value"] = {display: valueDisplay, code: e.questionNumber};
        if(e.canWrite){
            setIsWritable(true)
        } else {
            setIsWritable(false)
        }
    }
    
    const onItemClick = (e) => {
        let valueDisplay = null;
        if(e.display.split("")?.length === 0) {
            valueDisplay = null;
        } else {
            valueDisplay = e.display;
        }
        let element;
        // setCanDrop(false)
        setSelectValue(e)
        newObservation.canWrite = e.canWrite;
        newObservation["value"] = {display: valueDisplay, code: e.questionNumber};
        setArrowDropIndex(-1);
        if(e.canWrite){
            setIsWritable(true)
            setTimeout(() => {
                element = document.getElementById(e.id+'SearchComment')
                if(element) {
                    element.focus();
                }
            }, 200);
        } else {
            setIsWritable(false)
        }
    }

    const resetSelection = () => {
        setSelectValue({display: "Selecciona"});
        setIsWritable(false);
    }

    const resetSelectionOnKey = (e) => {
        switch (e.which) {
            case 13:
                resetSelection();
                const element = document.getElementById(e.id+'SelectInput');
                element?.focus();
                element?.click();
                break;
            default:
                break;
        }
    }

    const onClickOutside = () => {
        setCanDrop(false)
    }

    const getCommentary = (e) => {
        newObservation.componentCommentary = e;
        setComment(e)
    }

    useEffect(() => {
        const index = fhir.map( f => {return (f?.title)}).indexOf("Observations");
        let mySection = fhir[index];
        const entryIndex = mySection?.entry?.map( ent => {
            return( ent?.identifier[0]?.value.substring(5,(ent?.identifier[0]?.value?.length)))
        }).indexOf(info.id)

        let myEntry = mySection?.entry[entryIndex];

        let valueDisplay = null;
        let commmentDisplay = null;

        if(myEntry){
            if(selectValue.display?.length === 0) {
                valueDisplay = null;
            } else {
                valueDisplay = selectValue.display;
            }

            if(!isWritable || comment?.length === 0) {
                commmentDisplay = null;
            } else {
                commmentDisplay = comment;
            }

            myEntry["component"][0]["code"]["coding"][0]["code"] = selectValue?.questionNumber;
            myEntry["component"][0]["code"]["coding"][0]["display"] = valueDisplay;
            myEntry["component"][0]["code"]["text"] = valueDisplay;
            myEntry["component"][0]["valueString"] = commmentDisplay;

            if(!mySection) return;
            mySection?.entry?.splice(entryIndex,1);
            mySection?.entry?.splice(entryIndex,0,myEntry)
            dispatch(sectionUpdated(mySection));
        }

        const autoSaveData = {
            id: info.id,
            value: valueDisplay,
            comment: commmentDisplay,
            encounterId: encounterId,
        }

        dispatch(setAutoSaveSelectInput(autoSaveData));

    },[JSON.stringify(selectValue), comment, isWritable])

  return (
      <Fragment>
        <div id={info.id+"FormContainer"} className={classNames('form-container', (isWritable && !(responsive === "extrasmall" || responsive === "small"))? (info.size === 1 ? 'section-w-size-'+(info.size+1) : 'section-w-size-'+(info.size)) : 'section-w-size-'+info.size)}>
                <div className={'text-input-container'}>
                    <div className={classNames('text-input-title', {'active' : (canDrop || focused === info.id || focused === selectValue.id)})}>
                        <div>{info.display}</div>
                    </div>
                    <div className={classNames('select-input-group', {'small' : (responsive === "extrasmall" || responsive === "small")})}>
                        <div className={classNames('select-input-container', {'commentable' : selectValue.canWrite}, {'large': !info.canWrite}, {'small' : (responsive === "extrasmall" || responsive === "small")})}>
                            <div
                                id={info.id+"Select"}
                                className={classNames('select-input', {'active' : (canDrop || focused === info.id || focused === selectValue.id)})} 
                                onClick={handleOnClick}
                                onMouseEnter={toggleEnterTooltip}
                                onMouseLeave={toggleLeaveTooltip}
                                onKeyDown={handleDropOnKey}
                                > 
                                <input 
                                    id={info.id+"SelectInput"}
                                    className={'text-input enter-jump'}
                                    placeholder={selectValue.display} 
                                    // readOnly
                                    onFocus={handleGetFocused}
                                    onBlur={handleUnFocused}
                                >
                                </input>
                                {selectValue.display !== "Selecciona" &&
                                    <span className={'input-eliminator'} onClick={resetSelection} tabIndex={0} onKeyDown={resetSelectionOnKey}>
                                        <span className={'input-eliminator-btn'}>
                                        </span>
                                    </span>
                                }
                            </div>
                            <span className={classNames('select-input-icon', {'active' : (canDrop || focused === info.id)})} onClick={handleOnClick}>{canDrop ? `▲` : `▼`}</span>
                            {(canDrop) && 
                                <IndigoSelectDrop items={info.options} 
                                onItemClick={onItemClick}
                                onItemFocus={onItemFocus}
                                onClickOutside={onClickOutside}
                                arrowDropIndex={arrowDropIndex}
                                />
                                }
                        </div>
                            {(selectValue.canWrite) &&
                                    <div className={'search-comment-container'}>
                                        <IndigoCommentInput id={selectValue.id} isFocused={focused === info.id} getFocused={getFocused} valueToParent={getCommentary} valueFromParent={comment}/>
                                    </div>
                            }
                    </div>
                </div>
                {(selectValue.display?.length > 0 && !canDrop && !(selectValue.display === "Selecciona")) && 
                <Tooltip
                    target={"#"+info.id.toString()+"Select"}
                    visible={showTooltip}
                    closeOnOutsideClick={false}
                >
                    <div>
                    {selectValue.display}
                    </div>
                </Tooltip>}
                {editable && <ContextMenu
                    visible={contextOpen}
                    dataSource={[{ text: 'Editar ' + info.display, icon: 'rename' }]}
                    width={200}
                    target={"#"+info.id+"FormContainer"}
                    closeOnOutsideClick={true}
                    onItemClick={handleDeleteItem} />}

                {(cancelPopupVisible && editable) && <Popup
                visible={cancelPopupVisible}
                onHiding={closePopup}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showTitle={true}
                title={"Editar "+info.display}
                width={350}
                height={650}
                >
                <div className={'cancel-popup'}>

                    <div className={'popup-section'}>
                        <div className={'popup-section-title'}>Ordenar</div>
                        <div className={'popup-section-subtitle'}>Arrastra para ordenar</div>
                        <div className={'popup-section-content form-list'}>
                            <IndigoItemsList items={info.options} updateList={updateList}/>
                        </div>
                    </div>
                    <div className={'popup-section'}>
                        <div className={'popup-section-title'}>Tamaño</div>
                        <div className={'popup-section-subtitle'}>¿Cúantas columnas quieres que ocupe {info.display}?</div>
                        <div className={'popup-section-content size-btns'}>
                            {sectionSizes.map( (size, key ) => {
                                return (
                                    <Button
                                        key={key}
                                        text={size.toString()}
                                        type={size === info.size ? "success" : "normal"}
                                        stylingMode={size === info.size ? "contained" : "outlined"}
                                        onClick={() => handleChangeSize(size)}
                                    /> 
                                );
                            })
                            }
                        </div>
                    </div>
                    <div className={'cancel-buttons'}>
                        <Button
                        width={130}
                        icon={'check'}
                        text="Cerrar"
                        type="success"
                        stylingMode="contained"
                        onClick={closePopup}                        
                        />
                    </div>
                </div>
            </Popup>}
        </div>
    </Fragment>
  )
}

export default IndigoSelect