import React, { useEffect, useState } from 'react';
import { vitalSigns } from '../../../../utils/units';


function IndigoCommentInput (props) {
    const { id, isExam, getFocused, isFocused, defaultComment, valueToParent, valueFromParent, maxChar, readOnly, unitDisplay } = props;

    const [value, setValue] = useState('');

    const handleOnChange = (e) => {
        setValue(e.target.value)
        if(valueToParent){
            valueToParent(e.target.value)
        }
    }

    const handleOnFocus = () => {
        getFocused(id);
    }

    useEffect(() => {
        if(defaultComment) {
            setValue(defaultComment)
        } else {
            setValue('')
        }
    },[defaultComment])


    var classNames = require('classnames');

    var element = document.getElementById(id+"SearchComment");

    return (
        <div className={classNames('commentor')}>
            <input 
                id={id+"SearchComment"} 
                className={classNames('text-input enter-jump commentor',{'no-focus' : isFocused})} 
                type={"text"} 
                name={"section"}
                readOnly={readOnly}
                disabled={readOnly}
                maxLength={maxChar}
                onChange={handleOnChange}
                onFocus={readOnly ? null : handleOnFocus}
                value={valueFromParent || value}
                autoComplete={"off"}
                >
            </input>
            {vitalSigns[unitDisplay]?.includes("**") ?
                <span>
                    {vitalSigns[unitDisplay].split("**")[0]}<sup>{vitalSigns[unitDisplay].split("**")[1]}</sup>
                </span>
                :
                <span>
                    {vitalSigns[unitDisplay]}
                </span>
                        
            }
        </div>
    )
}

export default React.memo(IndigoCommentInput)