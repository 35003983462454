import React, { Fragment, useEffect, useState } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';

export default function Indications(props) {

    const [canDrop, setCanDrop] = useState(false);
    const [contentShown, setContentShown] = useState(false);
    const [warningShown, setWarningShown] = useState(false);
    const [warning, setWarning] = useState(false);
    const [dose, setDose] = useState({"term": "", "quantity": "","unit": "", "display": ""})
    const mgmtDropRef = React.createRef();
    let mgmtParentRef;
    const classNames = require('classnames');
    const { opened, close, open, parent, getData, data, getReset } = props;

    const handleDocumentClick = (e) => {
        const target = e.target;
        const element = mgmtParentRef;
        const popup = document.getElementsByClassName('dx-scrollview-content')[0];
        const inputs = document.getElementsByClassName('mgmt-input-item');
        const drops = document.getElementsByClassName('mgmt-dropdown-container');
        let inputContains = [];
        let dropsContains = [];
        for(let i = 0; i < inputs.length; i++){
            inputContains.push(inputs[i].contains(target));
        }
        for(let i = 0; i < drops.length; i++){
            dropsContains.push(drops[i].contains(target));
        }
        if( (!element?.contains(target) 
            || 
            element?.firstChild.contains(target))
            &&
            popup?.contains(target)
            &&
            !inputContains.includes(true)
            &&
            !dropsContains.includes(true)
        ) {
            close(parent);
            setWarningShown(false);
        }
    }

    const getCurrentData = () => {
        let dosis = dose;
        dosis.indication = data?.indication;
        dosis.display = data?.display;
        setDose(prev => prev = JSON.parse(JSON.stringify(data)));   
    }

    useEffect(() => {
        mgmtParentRef = mgmtDropRef.current.parentElement;
    },[])

    useEffect(() => {
        getCurrentData()
    },[data?.display])

    useEffect(() => {
        if(opened) {
            setTimeout(() => {
                setCanDrop(true)
            },200)
            setTimeout(() => {
                setContentShown(true)
            },400)
        } else {
                setContentShown(false)
                setCanDrop(false)
        }
    },[opened])

    useEffect(() => {
        if(warning) {
            setTimeout(() => {
                setWarningShown(true)
            },300)
        } else {
            setWarningShown(false)
        }
    },[warning])

    useEffect(() => {
        setCanDrop(opened);
        setWarning(false);
        document.addEventListener('click', handleDocumentClick);

        return function cleanup () {
            document.removeEventListener('click', handleDocumentClick);
        }
    },[])

    const handleOnChange = (value, key) => {
        setDose({...dose, 'display': value.target.value, [key]: value.target.value});
    }

    const cancel = () => {
        const currentDose = dose.display
        const currentData = data.display
        if(currentDose === currentData){
            close(parent);
        } else {
            setWarning(true);
        }
    }

    const save = () => {
        getData('imgIndications', dose);
        setWarning(false);
        close(parent);
    }

    const reset = () => {
        let dosis = dose;
        dosis.quantity = "";
        dosis.unit = "";
        dosis.display = "";
        dosis.term = "";
        setDose(prev => prev = dosis)
    }

    const closeNoSave = () => {
        getCurrentData();
        setWarning(false);
        close(parent);
    }

    const canSave = () => {
        if(dose.indication !== "") {
            return false
        } 
        else {
            return true
        }
    }



    return(
        <Fragment>
            <div id={parent+"MgtmDropdown"} ref={mgmtDropRef} style={{minHeight: canDrop ? warning ? '250px' : '230px' : '0px'}} className={classNames('mgmt-dropdown-container', {'droped' : canDrop})}>
                <div className={classNames('mgmt-dropdown-content', {'droped' : contentShown})}>
                    <div className={classNames('mgmt-dropdown-group-nopadding')}>
                        <div id={parent+'UniteInputContainer'}>
                                    <div className={classNames('mgmt-dropdown-label')}>
                                        Indicaciones
                                    </div>
                                    <div className={classNames('mgmt-input')}>
                                        <textarea 
                                            id={"Input"} 
                                            className={classNames('mgmt-drop-input')} 
                                            type={"number"}
                                            min={0}
                                            pattern="[0-9]*"
                                            required
                                            name={"section"}
                                            onChange={(e) => handleOnChange(e, 'indication')}
                                            value={dose['indication'] ? dose['indication'] : ""}
                                            autoComplete={"off"}
                                            >
                                        </textarea>
                                    </div>
                            </div>
                    </div>
                    <div className={classNames('mgmt-dropdown-group-horizontal flex')}>
                            <div className={classNames('mgmt-input mgmt-cancel-btn')} onClick={cancel}>
                                CANCELAR
                            </div>
                            <div className={classNames('mgmt-input')}>
                                <Button 
                                    type={"default"}
                                    stylingMode={"contained"}
                                    text={'Aceptar'}
                                    onClick={save}
                                />
                            </div>
                    </div>
                    {warningShown && 
                    <div className={classNames('mgmt-dropdown-group-horizontal')}>
                        <div className={classNames('mgmt-dropdown-warning')}>
                        <i class="fas fa-exclamation-triangle"></i> Hay cambios sin guardar <br></br>
                            <span onClick={closeNoSave}>Cerrar sin guardar</span> | <span  className={classNames({'warning-disabled' : canSave()})} onClick={canSave() ? null : save}>Guardar y cerrar</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}