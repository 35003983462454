import React from 'react';
import { MemoizedPanel } from './panel.js';
import Ficha from './../../components/ficha/ficha.jsx';
import './parametrizer.css';
import { connect } from 'react-redux';
import { reset } from '../../redux/actions/fhir.js';

const mapStateToProps = state => ({
    activeSection: state.parametrizer.activeSection,
    data: state.parametrizer.data,
    responsive: state.ui.responsive
})

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch(reset())
    }
} ;
export default connect(mapStateToProps,mapDispatchToProps)(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteSection: '',
            height: 0,
        }
    }

    getHeights = (element) => {
        const elmt = document.getElementById(element);
        if (elmt) {
            this.setState({height: elmt.offsetHeight})
            return elmt.offsetHeight
        }
    }

    componentDidMount(){
        this.getHeights('mainContainer')
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.responsive !== this.props.responsive) {
            this.getHeights('mainContainer')
        }
    }

    deleteItem = (del) => {
        this.setState({deleteSection : del})
    } 

    componentWillUnmount() {
        this.props.reset();
    }
    render () {
    const { data, activeSection } = this.props;
    return(
        <div id={'mainContainer'} className={'main-container'}>
            <MemoizedPanel 
                deleteSection={this.state.deleteSection}
                />
            <Ficha
                parentHeight={this.state.height}
                deleteSection={this.deleteItem}
                dataQuestions={data.find( data => data.id === activeSection.id)} 
                activeSection={activeSection}
                editable={true}
            />
        </div>
    );
    }
})