import React, { Fragment, useEffect, useState } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';
import { NumberBox } from 'devextreme-react/number-box';

export default function Doses(props) {

    const [canDrop, setCanDrop] = useState(false);
    const [contentShown, setContentShown] = useState(false);
    const [warningShown, setWarningShown] = useState(false);
    const [warning, setWarning] = useState(false);
    const [dose, setDose] = useState({"unique": false, "quantity": "","unit": "", "display": ""})
    const mgmtDropRef = React.createRef();
    let mgmtParentRef;
    const classNames = require('classnames');
    const { opened, close, open, parent, getData, data } = props;
    const units = [
        {id: '16568', display: 'Ibuprofeno'},
        {id: '15478', display: 'Paracetamol'}
    ]

    const handleDocumentClick = (e) => {
        const target = e.target;
        const element = mgmtParentRef;
        const popup = document.getElementsByClassName('dx-scrollview-content')[0];
        const inputs = document.getElementsByClassName('mgmt-input-item');
        const drops = document.getElementsByClassName('mgmt-dropdown-container');
        let inputContains = [];
        let dropsContains = [];
        for(let i = 0; i < inputs.length; i++){
            inputContains.push(inputs[i].contains(target));
        }
        for(let i = 0; i < drops.length; i++){
            dropsContains.push(drops[i].contains(target));
        }
        if( (!element?.contains(target) 
            || 
            element?.firstChild.contains(target))
            &&
            popup?.contains(target)
            &&
            !inputContains.includes(true)
            &&
            !dropsContains.includes(true)
        ) {
            close(parent);
            setWarningShown(false);
        }
    }

    const getCurrentData = () => {
        let dosis = dose;
        dosis.quantity = data?.quantity;
        dosis.display = data?.display;
        dosis.unique = data?.unique === undefined ? false : data?.unique;
        dosis.unit = data?.unit;
        setDose(prev => prev = JSON.parse(JSON.stringify(data)));   
    }

    useEffect(() => {
        mgmtParentRef = mgmtDropRef.current.parentElement;
    },[])

    useEffect(() => {
        getCurrentData()
    },[data?.display])

    useEffect(() => {
        if(opened) {
            setTimeout(() => {
                setCanDrop(true)
            },200)
            setTimeout(() => {
                setContentShown(true)
            },400)
        } else {
            setContentShown(false)
            setCanDrop(false)
        }
    },[opened])

    useEffect(() => {
        if(warning) {
            setTimeout(() => {
                setWarningShown(true)
            },300)
        } else {
            setWarningShown(false)
        }
    },[warning])

    useEffect(() => {
        //console.log("DOSE")
        setCanDrop(opened);
        setWarning(false);

        document.addEventListener('click', handleDocumentClick);

        return function cleanup () {
            document.removeEventListener('click', handleDocumentClick);
        }
    },[])

    useEffect(() => {
        let dosis = dose;
        if(dose.unique === true) {
            dosis.quantity = "";
            dosis.unit = "";
            dosis.display = "";
            setDose(prev => prev = dosis)
        }
    }, [dose.unique])

    useEffect(() => {
        generateDisplay();
    },[dose.unit, dose.quantity, dose.unique])

    const handleOnChange = (value, key) => {
        switch(key){
            case 'quantity':
                setDose({...dose, [key]: value.target.value});
                break;
            default:
                setDose({...dose, [key]: value.value});
            break;
        }
    }

    const generateDisplay = () => {
        const index = units.map( unit => {return unit.id}).indexOf(dose.unit);
        let newDisplay = "";
        // if(index !== -1) {
            newDisplay = dose.quantity;
            // newDisplay = dose.quantity + " " + units[index]?.display;
        // } else {
        //     newDisplay =  "";
        // }
        if(dose.unique){
            setDose({...dose, display: "Única vez"})
        } else {
            setDose({...dose, display: newDisplay !== undefined ? newDisplay : ""})
        }
    }

    const reset = () => {
        const currentDose = dose.display
        const currentData = data.display
        if(currentDose === currentData){
            close(parent);
        } else {
            setWarning(true);
        }
    }

    const save = () => {
        getData('dose', dose);
        setWarning(false);
        close(parent);
    }

    const closeNoSave = () => {
        getCurrentData();
        setWarning(false);
        close(parent);
    }

    const canSave = () => {
        const unidades = units.map( unit => { return unit.id })
        if(dose.unique === true) {
            return false
        } 
        else {
            if(dose.quantity > 0) {
                return false
            } else {
                return true
            }
        }
    }



    return(
        <Fragment>
            <div id={parent+"MgtmDropdown"} ref={mgmtDropRef} style={{height: canDrop ? warning ? '260px' : '220px' : '0px'}} className={classNames('mgmt-dropdown-container', {'droped' : canDrop})}>
                <div className={classNames('mgmt-dropdown-content', {'droped' : contentShown})}>
                    <div className={classNames('mgmt-dropdown-group-horizontal mgmt-dropdown-group flex')}>
                        <div className={classNames('mgmt-dropdown-label')}>
                            Dosis única
                        </div>
                        <CheckBox 
                        value={dose['unique']} 
                        onValueChanged={(e) => handleOnChange(e, 'unique')} 
                        />
                    </div>
                    <div className={classNames('mgmt-dropdown-group-nopadding mgmt-dropdown-group')}>
                        <div id={parent+'DoseInputContainer'} 
                                className={classNames('text-input-container mgmt-input-container')}
                            >
                                <div className={classNames('mgmt-dropdown-label', {'disabled': dose.unique})}>
                                    Dosis
                                </div>
                                {/* <input 
                                    id={"Input"} 
                                    className={classNames('mgmt-drop-input', {'disabled': dose.unique})} 
                                    type={"number"}
                                    min={0}
                                    pattern="[0-9]*"
                                    required
                                    readOnly={dose['unique']}
                                    name={"section"}
                                    onChange={(e) => handleOnChange(e, 'quantity')}
                                    value={dose['quantity'] ? dose['quantity'] : ""}
                                    autoComplete={"off"}
                                    >
                                </input> */}
                        </div>
                    </div>
                    <div className={classNames('mgmt-dropdown-group-horizontal')}>
                    <div id={parent+'UniteInputContainer'}>
                                <div className={classNames('mgmt-dropdown-label', {'disabled': dose.unique})}>
                                    Unidad de medida
                                </div>
                                <div className={classNames('mgmt-input')}>
                                    <SelectBox 
                                        dataSource={units}
                                        searchEnabled={true}
                                        value={dose.unit}
                                        valueExpr={'id'}
                                        disabled={dose.unique}
                                        placeholder={'Seleccionar'}
                                        displayExpr={'display'}
                                        onValueChanged={(e) => handleOnChange(e, 'unit')} 
                                    />
                                </div>
                        </div>
                        
                    </div>
                    <div className={classNames('mgmt-dropdown-group-horizontal flex')}>
                                <div className={classNames('mgmt-input mgmt-cancel-btn')} onClick={reset}>
                                    CANCELAR
                                </div>
                                <div className={classNames('mgmt-input')}>
                                    <Button 
                                        type={"default"}
                                        stylingMode={"contained"}
                                        text={'Aceptar'}
                                        onClick={save}
                                        disabled={canSave()}
                                    />
                                </div>
                    </div>
                    {warningShown && 
                    <div className={classNames('mgmt-dropdown-group-horizontal')}>
                        <div className={classNames('mgmt-dropdown-warning')}>
                        <i class="fas fa-exclamation-triangle"></i> Hay cambios sin guardar <br></br>
                            <span onClick={closeNoSave}>Cerrar sin guardar</span> | <span  className={classNames({'warning-disabled' : canSave()})} onClick={canSave() ? null : save}>Guardar y cerrar</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}