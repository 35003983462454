import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import ChartData from './Chart'
import RadioGroup from 'devextreme-react/radio-group';
import DropDownButton from 'devextreme-react/drop-down-button';
import SelectedDropDown from '../agenda/selectDropdown';
import SelectBox from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';


import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { setProductivityFichas, startLoadingProductivityBySpecialty, startLoadingProductivityByTemplate, startProductivityFilterByMonth } from '../../redux/actions/productivity';
import { getTemplateBySpecialty } from '../../utils/helpersPoints';

//filtro por mes
const meses = [
    {id: 0, month: 'Enero'}, 
    {id: 1, month: 'Febrero'}, 
    {id: 2, month: 'Marzo'}, 
    {id: 3, month: 'Abril'}, 
    {id: 4, month: 'Mayo'}, 
    {id: 5, month: 'Junio'}, 
    {id: 6, month: 'Julio'}, 
    {id: 7, month: 'Agosto'}, 
    {id: 8, month: 'Septiembre'}, 
    {id: 9, month: 'Octubre'}, 
    {id: 10, month: 'Noviembre'}, 
    {id: 11, month: 'Diciembre'}
];
const now = '2021-01-01' /* new Date() */;

export const Graph = (props) => {
    const dispatch = useDispatch();
    
    // const {productivitySpecialty, productivityTemplate} = useSelector(state => state.productivityPanel);
    const options = ['Tiempo promedio(min)', 'Atenciones'];
    const [selection, setSelection] = useState(options[0]);
    const { specialities } = useSelector(state => state.auth);
    const {codeSIH} = useSelector(state => state.auth);
    const {productivityFichas} = useSelector(state => state.productivityPanel);
    const [especialidad, setEspecialidad] = useState({
        code: '',
        display: ''
    });

    const [selectFicha, setSelectFicha] = useState({
        code: '',
        text: ''
    })

    const filterOptions = ['Ultimo mes', 'Mes', 'Rango de fechas'];
    const [filterTime, setFilterTime] = useState({});
   
    const [date1, setDate1] = useState('');
    const [date2, setDate2] = useState('');

    /////
    useEffect(() => {
        if (especialidad?.code !== '') {
            dispatch(setProductivityFichas(especialidad.code));
        }
    }, [especialidad]);

    /////
    const onChangeDate1 = (e) => {
        setDate1(e);
    }
    const onChangeDate2 = (e) => {
        setDate2(e);
    }

    const buttonDropDownOptions = { width: 91 };

    //console.log('propsss: ', props);
    const changeSelection=(e)=> {
        setSelection(e);
    }

    const handleOnSpecChange = (e) => {
        console.log('Código de especialidad: ', e.itemData.code);
        dispatch(startLoadingProductivityBySpecialty(codeSIH, date1 === '' ? null : date1, date2 === '' ? null : date2, e.itemData?.code));
        setEspecialidad(e.itemData);
    }

    const handleOnTemChange = (e) => {
        const codeTemp = e.itemData?.code;
        dispatch(startLoadingProductivityByTemplate(codeSIH, date1 === '' ? null : date1, date2 === '' ? null : date2, codeTemp));
        setSelectFicha(e.itemData);
    }

    const clickFilterByMonth = (e) => {
        const mes = e.itemData;
        let a = moment();
        a.month(mes.id);
        let start =  a.startOf('month').format('YYYY-MM-DD');
        let end =  a.endOf('month').format('YYYY-MM-DD');
        setDate1(start);
        setDate2(end);
        if (props.index === 0) {
            dispatch(startLoadingProductivityBySpecialty(codeSIH, start, end));
        }else if(props.index === 1) {
            dispatch(startLoadingProductivityByTemplate(codeSIH, start, end, selectFicha?.code !== '' ? selectFicha?.code : null));
        }
        setFilterTime(mes);
        setStatePopup({...statePopupMonth, popupVisible: false});
    }

    const [statePopupMonth, setStatePopup] = useState({
        currentEmployee: {},
        popupVisible: false,
        positionOf: ''
    });

    const [statePopupRange, setStatePopupRange] = useState({
        currentEmployee: {},
        popupVisible: false,
        positionOf: ''
    });

    const hideInfo2 = () => {
        setStatePopupRange({...statePopupRange, currentEmployee: {}, popupVisible: false});
    }

    const hideInfo = () => {
        setStatePopup({...statePopupMonth, currentEmployee: {}, popupVisible: false});
    }

    const filtrar = (e) => {
        console.log('ee: FILTRAR::: ', e);
        let start = moment(date1).format('YYYYY-MM-DD');
        let end = moment(date2).format('YYYYY-MM-DD');
       
        if (props.index === 0) {
            dispatch(startLoadingProductivityBySpecialty(codeSIH, start, end)); 
        }else if (props.index === 1) {
            dispatch(startLoadingProductivityByTemplate(codeSIH, start, end, selectFicha?.code !== '' ? selectFicha?.code : null));
        }
        const message = `${start} - ${end}`;
        notify({
          message: message,
          position: {
            my: 'center top',
            at: 'center top'
          }
        }, 'success', 3000);
        setStatePopupRange({...statePopupRange, popupVisible: false});

    }

    const closeButtonOptions = {
        text: 'Cancelar',
        onClick: hideInfo
    };

    const handleFilterButton = {
        //icon: 'email',
        text: 'Filtrar',
        onClick: filtrar
    };

    function filterSelected(e) {
        console.log('filterSelected: ', e.itemData);
        switch (e.itemData) {
            case 'Ultimo mes':
                if (props.index === 0) {
                    dispatch(startLoadingProductivityBySpecialty(codeSIH));
                }else if (props.index === 1) {
                    dispatch(startLoadingProductivityByTemplate(codeSIH, null, null, selectFicha?.code !== '' ? selectFicha?.code : null ));
                }
                break;
            case 'Mes':
                setStatePopup({...statePopupMonth, popupVisible: true});
                console.log('mes');
            break;
            case 'Rango de fechas':
                setStatePopupRange({...statePopupRange, popupVisible: true});
                console.log('Rango de fechas');
                break;
            default:
                break;
        }
    };

    const popMonth = () => {
        return (
            <div>
                <Popup
                visible={statePopupMonth.popupVisible}
                onHiding={hideInfo}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={false}
                //title="Information"
                container=".dx-viewport"
                width={200}
                height={100}
                >
                    <DropDownButton
                        text={filterTime?.month ? filterTime?.month : 'Selecciona Mes'}
                        icon="event"
                        style={{height: '30px'}}
                        dropDownOptions={buttonDropDownOptions}
                        items={meses}
                        keyExpr={'id'}
                        displayExpr={'month'}
                        onItemClick={clickFilterByMonth}
                    />
                </Popup>
            </div>
        )
    }

    const inputsDate = () => {
        return (
            <>
                  {/* <div className="dx-field-value"> */}
                        <DateBox 
                            defaultValue={date1}
                            type="date" 
                            onValueChange={onChangeDate1}
                        />

                        <DateBox 
                            defaultValue={date2}
                            onValueChange={onChangeDate2}
                            type="date" 
                        />
                    {/* </div> */}
            </>
        )
    }

    const popRange= () => {
        return (
            <>
                <Popup
                visible={statePopupRange.popupVisible}
                onHiding={hideInfo2}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title="Rango de fechas"
                container=".dx-viewport"
                width={200}
                height={250}
                contentRender={ inputsDate }
                >
                    <Position
                        at="center"
                        my="center"
                        of={statePopupRange.positionOf}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={handleFilterButton}
                    />
                </Popup>
            </>
        )
    }

    console.log('selectedIndexx:2 ', props.index);
    return (
        <>  
            {
                popMonth()
            }
            {
                popRange()
            }
            <div style={{marginTop: '10px'}}>
                {   (props.index === 0) &&
                        <DropDownButton 
                            text={especialidad.display ? especialidad.display : "Selecciona Especialidad"}
                            dropDownOptions={{ minWidth: 340 }}
                            displayExpr={'display'}
                            keyExpr={'code'}
                            items={specialities}
                            onItemClick={handleOnSpecChange}
                        />
                }
                {
                    (props.index === 1) &&
                        <DropDownButton 
                            text={selectFicha?.text ? selectFicha?.text: "Selecciona Ficha"}
                            dropDownOptions={{ minWidth: 340 }}
                            displayExpr={'text'}
                            keyExpr={'code'}
                            //items={(()=> {console.log("fichasssssss:", fichas); return fichas})()}
                            items={productivityFichas}
                            onItemClick={handleOnTemChange}
                        />
                }

                <RadioGroup items={options} defaultValue={selection} onValueChange={changeSelection} layout="horizontal" />

                <div display="flex" style={{width: '40%'}}>
                    <SelectBox 
                        // dataSource={filterOptions}
                        items={filterOptions}
                        //onValueChange={(e)=> test(e)}
                        showClearButton={true}
                        onItemClick={filterSelected}
                    />
                </div>

               
            </div>
            <ChartData data={props.index} selection={selection} /* productivitySpecialty = {productivitySpecialty} productivityTemplate={productivityTemplate} */ />
        </>
    )
}
