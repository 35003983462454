const dictionary = {
    'panel' : {
            'en': {
                'Questions': 'Questions',
                'Sections': 'Sections',
                'Options': 'Options',
                'Controls': 'Controls',
                'Add': 'Add',
                'Detele': 'Delete',
                'Revert' : 'Revert',
                'Save' : 'Save',
                'Open' : 'Open',
                'Type' : 'Type',
            },
            'es': {
                'Questions': 'Grupos',
                'Sections': 'Pages',
                'Options': 'Variables',
                'Options2': 'Opciones',
                'Controls': 'Controles',
                'Add': 'Agregar',
                'Delete' : 'Eliminar',
                'Revert' : 'Deshacer',
                'Save' : 'Guardar',
                'Open' : 'Abrir',
                'Type' : 'Tipo',
            }
        }
}

const locales = [
    {
        'Name': 'English',
        'Value': 'en'
      },
    {
        'Name': 'Español',
        'Value': 'es'
    },
]

export default { 
    getDictionary() { 
        return dictionary;
    },
    getLocales() {
        return locales;
    }
}