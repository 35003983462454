import * as microsoftTeams from "@microsoft/teams-js";

// 1. Get auth token
// Ask Teams to get us a token from AAD
export const getClientSideToken = async () => {
    return new Promise((resolve, reject) => {
        microsoftTeams.authentication.getAuthToken({
            successCallback: (result) => {
                resolve(result);
            },
            failureCallback: function (error) {
                reject("Error getting token: " + error);
            }
        });
    });
}

// 2. Exchange that token for a token with the required permissions
//    using the web service (see /auth/token handler in app.js)

export const getServerSideToken = async(clientSideToken) => {

    return new Promise((resolve, reject) => {

        microsoftTeams.getContext((context) => {

            fetch('/auth/token', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'tid': context.tid,
                    'token': clientSideToken
                }),
                mode: 'cors',
                cache: 'default'
            })
                .then((response) => {
                    if (response.ok) {
                        return response.text();
                    } else {
                        reject(response.error);
                    }
                })
                .then((responseJson) => {
                    if (responseJson.error) {
                        reject(responseJson.error);
                    }
                    else if ("unauthorized_client" === responseJson || "invalid_grant" === responseJson) {
                        reject(responseJson);
                    } else {
                        const serverSideToken = responseJson;
                        resolve(serverSideToken);
                    }
                });
        });
    });
}

// 3. Get the server side token and use it to call the Graph API
export const serverSideToken = async (data) => {

    try {
        const profile = await fetch("https://graph.microsoft.com/v1.0/me/",
        {
            method: 'GET',
            headers: {
                "accept": "application/json",
                "authorization": "bearer " + data
            },
            mode: 'cors',
            cache: 'default'
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw (`Error ${response.status}: ${response.statusText}`);
            }
        })
        .then((profile) => {
            return profile;
        });
        return profile;
    } catch (error) {
        //console.log('error', error);
    } 

}

export const callGraphFromClient = async (upn, tokenn) => {
    let graphPhotoEndpoint = 'https://graph.microsoft.com/v1.0/users/'+upn+'/photo/$value';
    let graphRequestParams = {
        method: 'GET',
        headers: {
            'Content-Type': 'image/jpg',
            "authorization": "bearer " + tokenn 
            }
        }
    let response = await fetch(graphPhotoEndpoint,graphRequestParams).catch(console.debug('Error'));
    ////console.log('response', response);
    if (!response.ok) {    
        throw (`Error ${response.status}: ${response.statusText}`);
    }
    
    let imageBlog = await response.blob().catch(console.debug('Error blob')); //Get image data as raw binary data
    //dispatch( setImageUrl(URL.createObjectURL(imageBlog)));
    ////console.log(URL.createObjectURL(imageBlog));
    return URL.createObjectURL(imageBlog);
}

//Show the consent pop-up
export const requestConsent = () => {
    return new Promise((resolve, reject) => {
        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/auth/start",
            width: 600,
            height: 535,
            successCallback: (result) => {
                //let data = localStorage.getItem(result);
                //localStorage.removeItem(result);
                //resolve(data);
                resolve(result);
            },
            failureCallback: (reason) => {
                reject(JSON.stringify(reason));
            }
        });
    });
}

//Add text to the display in a <p> or other HTML element
export const display = (text, elementTag) => {
    var logDiv = document.getElementById('logs');
    if(logDiv){
        var newElement = document.createElement(elementTag ? elementTag : "p");
        newElement.innerText = text;
        logDiv.append(newElement);
        //console.log("sso: " + text);
        return newElement;
    }
}

