import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import SaveTable from './save/saveTable';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, Paging, Pager, FilterRow, Scrolling, Column } from 'devextreme-react/data-grid';
import { resetData, setData, setRemoteData, setRowKey, startLoadAllTemplates } from '../../../redux/actions/parametrizer';
import { getAllTemplates } from '../../../utils/helpersPoints';

export default function OpenPopup (props) {

    const { visible, 
            onHiding, 
            dragEnabled, 
            closeOnOutsideClick, 
            showTitle, 
            title,
            loadRemoteData,
            deleteFunc,
            closePopup} = props

    const selectGridRef = React.useRef();
    const dispatch = useDispatch();
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const { data } = useSelector( state => state.parametrizer);
    const [rowKeySelection, setrowKeySelection] = useState('');
    const [gridRef, setGridRef] = useState({});
    const [hcMetadata, setHcMetadata] = useState({
        id: 1210,
        active: false,
        name: '',
        description: '',
        HCBoard: false,
        parent: {
            type: 1,
            parentList: [
            ]
        },
        hcStructure: data
    })
    // const [templateList, setTemplateList] = useState([]);
    const { templateList} = useSelector(state => state.parametrizer);
    const handleSelect = async(data) => {
        await setSelectedTemplate(JSON.parse(data.selectedRowKeys[0]?.Data));
        const rowKey =  await data?.selectedRowKeys[0]?.RowKey;
        setrowKeySelection(rowKey && rowKey);
    }

    const saveMetadata = () => {
        const data = selectedTemplate.hcStructure;
        dispatch(resetData());
        dispatch(setRemoteData(JSON.parse(JSON.stringify(data))));
        dispatch(setData(JSON.parse(JSON.stringify(data))));
        dispatch(setRowKey(rowKeySelection));
        closePopup()
    }

    // const loadAllTemplates = async() => {
    //     const auth = sessionStorage.getItem('auth');
    //     const templates = await getAllTemplates(auth);
    //     //console.log("All tempaltes:",templates)
    //     setTemplateList(templates);
    // }
    

    useEffect(() => {
        // loadAllTemplates();
        dispatch(startLoadAllTemplates());
    }, [])

    const itemRender = (data) => {
        return (
            <Fragment key={data.id}>
                <DataGrid
                    ref={selectGridRef}
                    dataSource={templateList}
                    valueExpr={'rowKey'}
                    displayExpr={'Name'}
                    onSelectionChanged={handleSelect}
                    hoverStateEnabled={true}
                    height={360}
                    selectedRowKeys={selectedTemplate}
                >
                    <Paging enabled={true} defaultPageSize={10}></Paging>
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 20]}
                        showInfo={true} />
                    {/* <Column dataField="rowKey" caption="Código" width={'8%'} /> */}
                    <Column dataField="Name" caption="Nombre Ficha" width={'8%'} />
                    <Selection mode="single"></Selection>
                    {/* <Scrolling mode="virtual"></Scrolling> */}
                    <FilterRow visible={true}></FilterRow>
                </DataGrid>
            </Fragment>
        )
    }

    return (
        <Popup
            visible={visible}
            onHiding={onHiding}
            dragEnabled={dragEnabled}
            closeOnOutsideClick={closeOnOutsideClick}
            showTitle={showTitle}
            title={title}
            height={'90vh'}
            >
            <div className={'cancel-popup'}>
                <div style={{flexGrow: 1}} className={'popup-content flex-center'}> 
                    <div className={'popup-group big-group'}>
                        <div className={'popup-title'}>
                            Seleccionar ficha
                        </div>
                        <DropDownBox 
                                // value={selectedList[0].display}
                                dataSource={templateList}
                                valueExpr={'rowKey'}
                                displayExpr={'name'}
                                deferRendering={false}
                                placeholder={'Selecciona'}
                                onValueChanged={handleSelect}
                                contentRender={itemRender}
                                width={'100%'}
                            />
                    </div>
                </div>

                <div className={'cancel-buttons'}>
                    <Button
                    width={130}
                    icon={'check'}
                    text="Abrir"
                    type="default"
                    stylingMode="contained"
                    onClick={saveMetadata}                        
                    />
                    <Button
                    width={120}
                    text="Cancelar"
                    type="default"
                    stylingMode="outlined"
                    onClick={closePopup}
                    /> 
                </div>
            </div>
        </Popup>
    )
} 