import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IndigoLogo from './../../assets/img/logos/indigoLogo.jpeg'
import go from './../../assets/img/logos/go.png'

import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  EmailRule,
  RequiredRule,
  AsyncRule
} from 'devextreme-react/form';
import { useHistory } from 'react-router-dom';
import notify from 'devextreme/ui/notify';

import 'devextreme-react/autocomplete';
import { startLoginAdmin } from '../../redux/actions/dashboard';
import { LoadPanel } from 'devextreme-react';

export const LoginForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.ui);

    const passwordOptions = {
      mode: 'password'
    };
    
    const [user, setUser] = useState({
      Email: '',
      Password: '',
      Admin: false,
      Accepted: false
    });
    
    const handleSubmit = (e) => {

      const {Email, Password} = user;
      ////console.log('User data: ', Email, Password);
     dispatch(startLoginAdmin(Email, Password))
      
      // if(sessionStorage.getItem('rowKeyAdmin')){
      //   notify({
      //     message: 'Ingreso correctamente',
      //     width: 10,
          
      //     position: {
      //       my: 'center top',
      //       at: 'center top',
      //     }
      //   }, 'success', 3000);
         history.push('/dashboard/one-view');
      // }else{
      //   alert('Datos incorrectos, comuniquese con administrador')
      // }

      e.preventDefault();
    }

    const sendRequest = (value) => {
      const validEmail = 'test@dx-email.com';
      return new Promise((resolve) => {
        setTimeout(function() {
          resolve(value !== validEmail);
        }, 1000);
      });
    }
    
    const asyncValidation = (params) => {
      return sendRequest(params.value);
    }

    // const handleAdmin = (e) => {
    //   if (e.dataField === 'Admin') {
    //     setUser({ ...user, Admin: e.value})
    //   }
    // }

    const buttonOptions = {
      text: 'Ingresar',
      type: 'default',
      useSubmitBehavior: true,
      disabled: loading
    };

    
    return (
        <div style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center' , alignItems: 'center'}}>
            <form action="your-action" onSubmit={handleSubmit} 
            className={'form-card'}
                style={{margin: '40px',  maxHeight: '80vh', overflow: 'auto'}}
            >
                <img src={IndigoLogo} style={{ width: '200px', display: 'block', marginLeft: 'auto', marginRight:'auto' }} />
            <Form
                formData={user}
                readOnly={false}
                showColonAfterLabel={true}
                showValidationSummary={true}
                validationGroup="customerData"
                
                //onFieldDataChanged= { (e)=> handleAdmin(e)}
            > 
                <GroupItem /*caption="Ingreso Admin"*/>
                    <Label text="INGRESO ADMIN" alignment="center" />
                    <SimpleItem dataField="Email" editorType="dxTextBox">
                    <Label text="Correo electronico" />
                    <RequiredRule message="Correo es requerido" />
                    <EmailRule message="Correo invalido" />
                    <AsyncRule
                        message="El correo ya se encuentra registrado"
                        validationCallback={asyncValidation} />
                    </SimpleItem>
                
                    <SimpleItem dataField="Password" editorType="dxTextBox" editorOptions={passwordOptions}>
                        <Label text="Contraseña" />
                        <RequiredRule message="Contraseña es requerida" />
                    </SimpleItem>
                {
                  (loading) ? <LoadPanel visible={loading} /> 
                  : 
                  <ButtonItem horizontalAlignment="center"
                      buttonOptions={buttonOptions}
                  />
                }
                </GroupItem>
            </Form>
            </form>
        </div>
    )
}
