import { types } from "../types/types";


const initialState = {
    data: [],
    remoteData: [],
    totalData: [],
    formStructure: {},
    activeSection: {id: '', trigger: ''},
    deleteSection: '',
    rowKey: '',
    parametersList: []
}


export const parametrizerReducer = ( state = initialState, action ) => {
    
    switch(action.type) {

        case types.parametrizerLoadData:
            return {
                ...state,
                data: [...action.payload.data],
            }
        

        case types.parametrizerLoadRemoteData:
            return {
                ...state,
                remoteData: [...action.payload.remoteData],
            }
        case types.parametrizerResetData:
            return {
                ...state,
                data: action.payload,
                remoteData: action.payload
            }
        case types.parametrizerSetRowKey:
            return {
                ...state,
                rowKey: action.payload.rowKey
            }

        case types.parametrizerGetForm:

            return {
                ...state,
                formStructure: action.payload.form
            }

        case types.setActiveSection:

            return {
                ...state,
                activeSection: action.payload.section
            }

        case types.setDeleteSection:

            return {
                ...state,
                deleteSection: action.payload.section
            }

        case types.parametrizerTotalData:

            return {
                ...state,
                totalData: [...action.payload.data]
            }

        case types.setTemplateList:
            return {
                ...state,
                templateList: action.payload.templateList
            }
    
        case types.setParametersList:
            return {
                ...state,
                parametersList: action.payload.parametersList
            }

        default:
            return state;

    }



}
