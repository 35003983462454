import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingProductivityTabular, startProductivityFilterByMonth } from '../../redux/actions/productivity';
import DataGrid, { Column, Export, Paging } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import DropDownButton from 'devextreme-react/drop-down-button';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';


export const Tabular = () => {
    const dispatch = useDispatch();
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const meses2 = { 
        Enero: {
            start: '2021-01-01',
            end: '2021-01-31'
        },
        Febrero: {
            start: '2021-02-01',
            end: '2021-02-28'
        },
        Marzo: {
            start: '2021-03-01',
            end: '2021-03-31'
        },
        Abril: {
            start: '2021-04-01',
            end: '2021-04-30'
        },
        Mayo: {
            start: '2021-05-01',
            end: '2021-05-31'
        },
        Junio: {
            start: '2021-06-01',
            end: '2021-06-30'
        },
        Julio: {
            start: '2021-07-01',
            end: '2021-07-31'
        },
        Agosto: {
            start: '2021-08-01',
            end: '2021-08-31'
        },
        Septiembre: {
            start: '2021-09-01',
            end: '2021-09-30'
        },
        Octubre: {
            start: '2021-10-01',
            end: '2021-10-31'
        },
        Noviembre: {
            start: '2021-11-01',
            end: '2021-11-30'
        },
        Diciembre: {
            start: '2021-12-01',
            end: '2021-12-31'
        }
        

    }
    const [valueMes, setValueMes] = useState("Mes");
    const buttonDropDownOptions = { width: 91 };

    const {codeSIH} = useSelector(state => state.auth);
    const {productivityTabular} = useSelector(state => state.productivityPanel);

    // console.log('productivityTabular: ', productivityTabular);
    
    useEffect(() => {
        dispatch(startLoadingProductivityTabular(codeSIH));
    }, []);

    function onItemClick(e) {
        let mes = e.itemData;
        let filter = meses2[mes];
        if (filter) {
            console.log('Filterrr: ', filter);
            setValueMes(mes);
            let { start, end } = filter;
            dispatch(startProductivityFilterByMonth( codeSIH, start, end));
            notify(e.itemData.name || e.itemData, 'success', 600);
        }else{
            setValueMes("Mes");
        }
    }

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
    
        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          autoFilterEnabled: true
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
          });
        });
        e.cancel = true;
      }

    return (
        <>
            <div className="dx-field" style={{height: '32px', margin:'0 0 0px'}}>
                <div className="dx-field-label">
                    Filtro por mes
                </div>
                <div className="dx-field-value" >
                <DropDownButton
                    text={valueMes}
                    icon="event"
                    style={{height: '30px'}}
                    dropDownOptions={buttonDropDownOptions}
                    items={meses}
                    onItemClick={onItemClick}
                />
                </div>
            </div>
            <DataGrid
                id="gridContainer"
                dataSource={productivityTabular}
                //keyExpr="ID"
                allowColumnReordering={true}
                showBorders={true}
                onExporting={onExporting}
                style={{height: '100%'}}
            >

            <Paging enabled={true} />
            <Column dataField="EncounterId" caption="ID Encuentro" />
            {/* <Column dataField="ProfessionalName" caption="Profesional" /> */}
            {/* <Column dataField="StartDate" caption="Fecha de Inicio" /> */}
            <Column
                dataField="StartDate"
                //width={130}
                caption="Fecha de Inicio"
                dataType="date" />
            <Column dataField="SpecialtyName" caption="Nombre especialidad" />
            <Column dataField="SpecialtyCode" caption="Código especialidad" />
            <Column dataField="TemplateCode" caption="Código Ficha" />
            <Column dataField="TemplateName" caption="Nombre Ficha" />
        
            <Export  enabled={true} allowExportSelectedData={false}  />

            {/* <Column
                dataField="timestamp"
                //width={130}
                caption="Fecha"
                dataType="date" />
            <Column
                caption="Acciones"
                cellRender={(e) => cellButton(e)}
               // dataField="ID"
                //editCellRender={statusEditorRender}
                /> */}

            <Paging enabled={true} />
            <Paging defaultPageSize={10} />
                
            </DataGrid>
        </>
    )
}
