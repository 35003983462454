import { types } from "../types/types";



export const setSection = ( section) => ({
    type: types.setSection,
    payload: section
});

export const sectionUpdated = ( section ) => ({
    type: types.sectionUpdated,
    payload: section
});

export const reset = () => ({
    type: types.reset
});