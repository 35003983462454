import { createRegisterProductivity, getTemplateByRowKey, updateStatusEncounter } from "../../utils/helpersPoints";
import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";



export const resetFicha = () => {
    return  async (dispatch) => {
        try {
            dispatch(setMcData([]));
        } catch (error) {
            //console.log('error', error);
        }
    }
}


export const startLoadingFicha = (rowKey) => {
    const auth = sessionStorage.getItem('auth');
    //const rowKey = '1cab50d8-b28e-4bb0-b21f-53cc56a4fd9a';
    return  async (dispatch )=> {
        
        dispatch(startLoading());
        try {
            const ficha = await getTemplateByRowKey(auth, rowKey);
            const data = JSON.parse(ficha?.Data).hcStructure;
            dispatch(setMcData(data));
        } catch (error) {
            //console.log('error', error);
        }
        dispatch(finishLoading());
    }
}

export const setHcData = (data) => ({

    type: types.setHcData,
    payload: {
        data
    }

});

export const setMcData = (data) => ({
    type: types.setMcData,
    payload: {
        data
    }
})

export const setItemsList = (data) => ({

    type: types.setItemsList,
    payload: {
        data
    }

});
export const startUpdateStatusEncounter = ( idEncounter) => {
    const form_data = new FormData;
    form_data.append('id_encounter', idEncounter);
    form_data.append('status_encounter', 'in-progress');
    form_data.append('status_appointment', 'arrived');

    return async (dispatch)=> {
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {
            const update = await updateStatusEncounter(auth, form_data);
            //console.log('update: ', update);
            //ispatch(setMcData(data));
        } catch (error) {
            //console.log('error', error);
        }
        dispatch(finishLoading());
    }
}

export const startCreateRegisterProductivity = (data) => {
    const {EncounterId, ProfessionalCode, ProfessionalName, SpecialtyCode, 
        SpecialtyName, TemplateCode, TemplateName
    } = data;

    const form_data = new FormData;
    form_data.append('EncounterId', EncounterId);
    form_data.append('ProfessionalCode', ProfessionalCode);
    form_data.append('ProfessionalName', ProfessionalName);
    form_data.append('SpecialtyCode', SpecialtyCode);
    form_data.append('SpecialtyName', SpecialtyName);
    form_data.append('TemplateCode', TemplateCode);
    form_data.append('TemplateName', TemplateName);

    return async (dispatch)=> {
        const auth = sessionStorage.getItem('auth');
        dispatch(startLoading());
        try {
            const registerProductivity = await createRegisterProductivity(auth, form_data);
            console.log('registerProductivity: ', registerProductivity);
        } catch (error) {
            console.log('error', error);
        }
        dispatch(finishLoading());
    }
}
