import React from 'react';
import DataGrid, { Column, Editing, Form, Lookup, RangeRule, RequiredRule, RowDragging, ValidationRule } from 'devextreme-react/data-grid';
// import { Lookup } from 'devextreme-react/lookup';
import { Item } from 'devextreme-react/form';
import NewItemPopup from './newItemPopup';
import NumberBox from 'devextreme-react/number-box';

class AddTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      formInputRef: '',
      inputTypeRef: React.createRef(),
      events: [], 
      typeSelect: "",
      optionDataSource: [],
      showDragIcons: true
    };
    this.logEvent = this.logEvent.bind(this);
    this.onEditingStart = this.logEvent.bind(this, 'EditingStart');
    this.onInitNewRow = this.logEvent.bind(this, 'InitNewRow');
    this.onRowInserting = this.logEvent.bind(this, 'RowInserting');
    this.onRowInserted = this.logEvent.bind(this, 'RowInserted');
    this.onRowUpdating = this.logEvent.bind(this, 'RowUpdating');
    this.onRowUpdated = this.logEvent.bind(this, 'RowUpdated');
    this.onRowRemoving = this.logEvent.bind(this, 'RowRemoving');
    this.onRowRemoved = this.logEvent.bind(this, 'RowRemoved');
    this.onSaving = this.logEvent.bind(this, 'Saving');
    this.onSaved = this.logEvent.bind(this, 'Saved');
    this.onEditCanceling = this.logEvent.bind(this, 'EditCanceling');
    this.onEditCanceled = this.logEvent.bind(this, 'EditCanceled');
    this.clearEvents = this.clearEvents.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.newRow =this.newRow.bind(this);
    this.valuechanged = this.valuechanged.bind(this);
    // this.onChangesChange = this.onChangesChange.bind(this);
    this.openOptionsGrid = this.openOptionsGrid.bind(this);
  }

  logEvent(eventName) {
    this.setState((state) => {
      return { events: [eventName].concat(state.events) };
    }, this.openOptionsGrid(eventName));
    this.props.dataSource.forEach( option => {
      if(["search", "select"].includes(option.type)) {
        option["size"] = 2;
        option["options"] = [];
      } else {
        option["size"] = 1;
      }
    })
    
    this.props.updateOptions(this.props.dataSource);

    if(eventName === "InitNewRow" || eventName === "EditingStart") {
      this.props.tipToggle(true)
    } else if(eventName === "Saved" || eventName === "EditCanceling"){
      this.props.tipToggle(false)
    }
  }
  
  openOptionsGrid (eventName) {
    const element = document.getElementsByClassName('dx-row-focused')[0];
    let rowIndex;
    if(element) {
      rowIndex = element.getAttribute('aria-rowindex');
    }
    if((eventName === "EditingStart" || eventName === "InitNewRow") && 
        ((this.props.dataSource[rowIndex-1])?.Type === "search" || 
        (this.props.dataSource[rowIndex-1])?.Type === "select")) {
        this.setState({typeSelect: true, optionDataSource: rowIndex - 1})
      if(this.props.dataSource[rowIndex-1]?.Details.options === undefined){
        this.props.dataSource[rowIndex-1].Details.options = []
      }
    } else if (["Saving", "Saved"].includes(eventName)){
      this.idGenerator()
    } else {
      this.setState({typeSelect: false})
    }
  }

  idGenerator () {
    const { dataSource, parentType } = this.props;
    const last = this.props.dataSource.length - 1;
    let newId;
    if(dataSource[last]) {
      newId = dataSource[last].display?.split(" ")[0].slice(0,3) + parentType + "q" + last + (Math.ceil(Math.random()*999));
      this.props.dataSource[last]["id"] = newId;
    }
  }

  clearEvents() {
    this.setState({ events: [] });
  }

  handleTypeChange(value){
    this.setState({typeSelect: value})
  }

  saveShortCut(e) {
    if(e.which === 78 && e.shiftKey){
      e.preventDefault();
      const button = document.querySelector('.dx-datagrid-toolbar-button');
      button.click();
    }
  }
  
  shouldComponentUpdate(nextProps, nexState) {
    if(nexState !== this.state){
      return true
    } else if (nextProps.parentType !== this.props.parentType) {
      return true
    } if (nextProps.dataSource !== this.props.dataSource) {
      return true
    }
    else return false
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("prevProps", prevProps);
    console.log("prevState", prevState);
  }
  
  componentDidMount() {
    // if(this.props.dataSource === undefined) {
    //   this.props.dataSource = [];
    // }
    document.addEventListener('keydown', this.saveShortCut);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.saveShortCut);
  }

  newRow = (e) => {
    e.data.canWrite = false;
  }

  getFormRef = (e) => {
    this.setState({formInputRef : e})
  }
  
  valuechanged = (e) => {
    console.log("Value changed", e)
    //console.log(this.state.inputTypeRef)
  }

  onSaved (e) {
    console.log("Saved", e)
  }
  render() {

    const { parentType } = this.props;

    const { typeSelect, optionDataSource } = this.state;

    const inputTypes = [
      {id: 'text', display: 'Texto'},
      {id: 'int', display: 'Entero'},
      {id: 'float', display: 'Flotante'},
      {id: 'textarea', display: 'Area de Texto'},
      {id: 'search', display: 'Buscador'},
      {id: 'select', display: 'Selector'},
      {id: 'date', display: 'Fecha (dd/MM/YYY)'},
      {id: 'time', display: 'Hora (hh:mm)'},
    ];

    const defaultGenres = 
        [{id: 'male', display: 'Masculino'}, 
        {id: 'female', display: 'Femenino'},
        {id: 'both', display: 'Ambos'}
        ];


    return (
      <React.Fragment>
        <DataGrid
          id="gridContainer"
          dataSource={this.props.dataSource}
          keyExpr="id"
          allowColumnReordering={true}
          showBorders={true}
          focusedRowEnabled={true}
          onEditingStart={this.onEditingStart}
          onInitNewRow={this.newRow}
          onRowInserting={this.onRowInserting}
          onRowInserted={this.onRowInserted}
          onRowUpdating={this.valuechanged}
          onRowUpdated={this.onRowUpdated}
          onRowRemoving={this.onRowRemoving}
          onRowRemoved={this.onRowRemoved}
          onSaving={this.onSaving}
          onSaved={this.onSaved}
          onEditCanceling={this.onEditCanceling}
          onEditCanceled={this.onEditCanceled}
          height={330}
          >
          <Editing
            mode={"row"}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
            selectTextOnEditStart={true}
            useIcons={true}
            > 
          </Editing>

          <Column dataField="questionNumber" caption="Código" dataType={'number'} width={80} >
            <RequiredRule />
            <RangeRule min={0}/>
          </Column>
          <Column dataField="display" caption="Descripción"> 
            <RequiredRule />
          </Column>
          <Column dataField="description" caption="Tooltip" />

          {parentType === "form" && 
            <Column dataField="type" caption="Tipo" width={180}>
              <Lookup 
                dataSource={inputTypes} 
                displayExpr="display" 
                valueExpr="id" 
                defaultValue={"text"}
                />
              <RequiredRule />
            </Column>
          }

          <Column dataField="canWrite" caption="Comentable" width={100}>
            <Lookup 
              dataSource={[{id: true, display: 'Sí'}, {id: false, display: 'No'}]}
              displayExpr="display" 
              valueExpr="id"
              />
          </Column>
                    
          <Column dataField="maxChar" caption="MáxChar" dataType={'number'} width={80}>
            <RequiredRule />
            <RangeRule min={0}/>
          </Column>
          
          <Column dataField="minValue" caption="MinValue" dataType={'number'} width={80}>
            <RequiredRule />
            <RangeRule min={0}/>
          </Column>

          <Column dataField="maxValue" caption="MáxValue" dataType={'number'} width={90} >
            <RequiredRule />
            <RangeRule min={0}/>
          </Column>

          <Column dataField="genre" caption="Género" width={120}>
            <Lookup  
              dataSource={defaultGenres}
              displayExpr="display" 
              valueExpr="id"
              />
            <RequiredRule />
          </Column>

          <Column dataField="minAge" caption="MinAge" dataType={'number'} width={80}  >
            <RequiredRule />
            <RangeRule min={0}/>
          </Column>

          <Column dataField="maxAge" caption="MáxAge" dataType={'number'} width={90}  >
            <RequiredRule />
            <RangeRule min={0}/>
          </Column>
        </DataGrid>
      </React.Fragment>
    );
  }
}

export default AddTable;