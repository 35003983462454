import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactSelect from 'react-select';

import { Button } from 'devextreme-react/button';

import ReactJson from 'react-json-view';
import { Code } from './helpers/Code';

import './style/demo.css';
import { setChangesJsonView } from '../../redux/actions/dashboard';

export const JsonView = ({data, setEditData}) => {
    const dispatch = useDispatch();
    const { pendingChanges } = useSelector(state => state.dashboard );
    ////console.log('data::: ', data)
    const defaultProps = {
        theme: 'monokai',
        src: data,
        collapsed: false,
        collapseStringsAfter: 15,
        onAdd: true,
        onEdit: true,
        onDelete: true,
        displayObjectSize: true,
        enableClipboard: true,
        indentWidth: 4,
        displayDataTypes: true,
        iconStyle: 'triangle'
    };

    const [state, setState] = useState({
        ...defaultProps
    });
    
    const style = {
        padding: '10px',
        borderRadius: '3px',
        //margin: '10px 0px',
        height: '59vh',
        overflow: 'auto'
    };

    const { theme, src, collapsed, collapseStringsAfter, onAdd,
            onEdit, onDelete, displayObjectSize, enableClipboard, 
            indentWidth, displayDataTypes, iconStyle } = state;

    
    const getNotes = (on_edit_enabled, on_add_enabled) => {

        let notes = [];
        if (on_edit_enabled) {
            notes.push(
                <span>
                    To edit a value, try <Code>ctrl/cmd + click</Code> enter
                    edit mode
                </span>
            );
            notes.push(
                <span>
                    When editing a value, try <Code>ctrl/cmd + Enter</Code> to
                    submit changes
                </span>
            );
            notes.push(
                <span>
                    When editing a value, try <Code>Escape</Code> key to cancel
                </span>
            );
        }
        if (on_add_enabled) {
            notes.push(
                <span>
                    When adding a new key, try <Code>Enter</Code> to submit
                </span>
            );
            notes.push(
                <span>
                    When adding a new key, try <Code>Escape</Code> to cancel
                </span>
            );
        }

        if (notes.length === 0) {
            return null;
        }

        return (
            <div style={{ marginTop: '20px', fontStyle: 'italic' }}>
                Keyboard Shortcuts
                <ul>
                    {notes.map((note, idx) => {
                        return <li key={idx}>{note}</li>;
                    })}
                </ul>
            </div>
        );
    };
 
    const getIconStyleInput = iconStyle => {
        return (
            <ReactSelect
                name="icon-style"
                value={iconStyle}
                options={[
                    { value: 'circle', label: 'circle' },
                    { value: 'square', label: 'square' },
                    { value: 'triangle', label: 'triangle' }
                ]}
                onChange={val => {
                    set('iconStyle', val);
                }}
            />
        );
    };

    const getEditInput = onEdit => {
        return (
            <ReactSelect
                name="enable-edit"
                value={onEdit}
                options={[
                    { value: true, label: 'true' },
                    { value: false, label: 'false' }
                ]}
                onChange={val => {
                    set('onEdit', val);
                }}
            />
        );
    };

    const getAddInput = onAdd => {
        return (
            <ReactSelect
                name="enable-add"
                value={onAdd}
                options={[
                    { value: true, label: 'true' },
                    { value: false, label: 'false' }
                ]}
                onChange={val => {
                    set('onAdd', val);
                }}
            />
        );
    };

    const getDeleteInput = onDelete => {
        return (
            <ReactSelect
                name="enable-delete"
                value={onDelete}
                options={[
                    { value: true, label: 'true' },
                    { value: false, label: 'false' }
                ]}
                onChange={val => {
                    set('onDelete', val);
                }}
            />
        );
    };

    const getEnableClipboardInput = enableClipboard => {
        return (
            <ReactSelect
                name="enable-clipboard"
                value={enableClipboard}
                options={[
                    { value: true, label: 'true' },
                    { value: false, label: 'false' }
                ]}
                onChange={val => {
                    set('enableClipboard', val);
                }}
            />
        );
    };

    const getObjectSizeInput = displayObjectSize => {
        return (
            <ReactSelect
                name="display-object-size"
                value={displayObjectSize}
                options={[
                    { value: true, label: 'true' },
                    { value: false, label: 'false' }
                ]}
                onChange={val => {
                    set('displayObjectSize', val);
                }}
            />
        );
    };

    const getDataTypesInput = displayDataTypes => {
        return (
            <ReactSelect
                name="display-data-types"
                value={displayDataTypes}
                options={[
                    { value: true, label: 'true' },
                    { value: false, label: 'false' }
                ]}
                onChange={val => {
                    set('displayDataTypes', val);
                }}
            />
        );
    };

    const getCollapsedStringsInput = collapseStringsAfter => {
        return (
            <ReactSelect
                name="collapse-strings"
                value={collapseStringsAfter}
                options={[
                    { value: false, label: 'false' },
                    { value: 5, label: 5 },
                    { value: 10, label: 10 },
                    { value: 15, label: 15 },
                    { value: 20, label: 20 }
                ]}
                onChange={val => {
                    set('collapseStringsAfter', val);
                }}
            />
        );
    };

    const getCollapsedInput = collapsed => {
        return (
            <ReactSelect
                name="collapsed"
                value={collapsed}
                options={[
                    { value: true, label: 'true' },
                    { value: false, label: 'false' },
                    { value: 1, label: 1 },
                    { value: 2, label: 2 }
                ]}
                onChange={val => {
                    set('collapsed', val);
                }}
            />
        );
    };

    const getIndentWidthInput = indentWidth => {
        return (
            <ReactSelect
                name="indent-width"
                value={indentWidth}
                options={[
                    { value: 0, label: 0 },
                    { value: 1, label: 1 },
                    { value: 2, label: 2 },
                    { value: 3, label: 3 },
                    { value: 4, label: 4 },
                    { value: 5, label: 5 },
                    { value: 6, label: 6 },
                    { value: 7, label: 7 },
                    { value: 8, label: 8 },
                    { value: 9, label: 9 },
                    { value: 10, label: 10 }
                ]}
                onChange={val => {
                    set('indentWidth', val);
                }}
            />
        );
    };

    const  getThemeInput = (theme) => {
        return (
            <ReactSelect
                name="theme-select"
                value={theme}
                options={[
                    { value: 'apathy', label: 'apathy' },
                    { value: 'apathy:inverted', label: 'apathy:inverted' },
                    { value: 'ashes', label: 'ashes' },
                    { value: 'bespin', label: 'bespin' },
                    { value: 'brewer', label: 'brewer' },
                    { value: 'bright:inverted', label: 'bright:inverted' },
                    { value: 'bright', label: 'bright' },
                    { value: 'chalk', label: 'chalk' },
                    { value: 'codeschool', label: 'codeschool' },
                    { value: 'colors', label: 'colors' },
                    { value: 'eighties', label: 'eighties' },
                    { value: 'embers', label: 'embers' },
                    { value: 'flat', label: 'flat' },
                    { value: 'google', label: 'google' },
                    { value: 'grayscale', label: 'grayscale' },
                    {
                        value: 'grayscale:inverted',
                        label: 'grayscale:inverted'
                    },
                    { value: 'greenscreen', label: 'greenscreen' },
                    { value: 'harmonic', label: 'harmonic' },
                    { value: 'hopscotch', label: 'hopscotch' },
                    { value: 'isotope', label: 'isotope' },
                    { value: 'marrakesh', label: 'marrakesh' },
                    { value: 'mocha', label: 'mocha' },
                    { value: 'monokai', label: 'monokai' },
                    { value: 'ocean', label: 'ocean' },
                    { value: 'paraiso', label: 'paraiso' },
                    { value: 'pop', label: 'pop' },
                    { value: 'railscasts', label: 'railscasts' },
                    { value: 'rjv-default', label: 'rjv-default' },
                    { value: 'shapeshifter', label: 'shapeshifter' },
                    {
                        value: 'shapeshifter:inverted',
                        label: 'shapeshifter:inverted'
                    },
                    { value: 'solarized', label: 'solarized' },
                    { value: 'summerfruit', label: 'summerfruit' },
                    {
                        value: 'summerfruit:inverted',
                        label: 'summerfruit:inverted'
                    },
                    { value: 'threezerotwofour', label: 'threezerotwofour' },
                    { value: 'tomorrow', label: 'tomorrow' },
                    { value: 'tube', label: 'tube' },
                    { value: 'twilight', label: 'twilight' }
                ]}
                onChange={val => {
                    set('theme', val);
                }}
            />
        );
    };

    const set = (field, value) => {
        let state = {};
        state[field] = value.value;
        setState(state);
    };

    const handleSave = (e) => {
        //console.log('Guardar cambios JsonView');
        //console.log('Cambios pendientes a enviar: ', pendingChanges);
        setEditData();
    }

    return (
        <div className="rjv-demo">
       
        <ReactJson
            name={false}
            collapsed={collapsed}
            style={style}
            theme={theme}
            src={data}
            collapseStringsAfterLength={collapseStringsAfter}
            onEdit={
                onEdit
                    ? e => {
                            //console.log(e);
                            dispatch(setChangesJsonView(e.updated_src))
                            setState( {...state, src: e.updated_src})
                            //this.setState({ src: e.updated_src });
                        }
                    : false
            }
            onDelete={
                onDelete
                    ? e => {
                            //console.log(e);
                            dispatch(setChangesJsonView(e.updated_src))
                            setState( {...state, src: e.updated_src})
                            //this.setState({ src: e.updated_src });
                        }
                    : false
            }
            onAdd={
                onAdd
                    ? e => {
                            //console.log(e);
                            dispatch(setChangesJsonView(e.updated_src))
                            setState( {...state, src: e.updated_src})
                            //this.setState({ src: e.updated_src });
                        }
                    : false
            }
            displayObjectSize={displayObjectSize}
            enableClipboard={enableClipboard}
            indentWidth={indentWidth}
            displayDataTypes={displayDataTypes}
            iconStyle={iconStyle}
        />
        <br/>
        <Button
            width={120}
            text="Guardar"
            type="default"
            stylingMode="contained"
            onClick={handleSave}
        />
        {/* </div> */}
        {/* </Popup> */}

                {/* <div className="rjv-settings">
                    <div className="rjv-input">
                        <div className="rjv-label">Theme:</div>
                        {getThemeInput(theme)}
                    </div>
                    <div className="rjv-input">
                        <div className="rjv-label">Icon Style:</div>
                        {getIconStyleInput(iconStyle)}
                    </div>
                    <div className="rjv-input">
                        <div className="rjv-label">Enable Edit:</div>
                        {getEditInput(onEdit)}
                    </div>
                    <div className="rjv-input">
                        <div className="rjv-label">Enable Add:</div>
                        {getAddInput(onAdd)}
                    </div>
                    <div className="rjv-input">
                        <div className="rjv-label">Enable Delete:</div>
                        {getDeleteInput(onDelete)}
                    </div>
                    <div className="rjv-input">
                        <div className="rjv-label">Enable Clipboard:</div>
                        {getEnableClipboardInput(enableClipboard)}
                    </div>
                </div>

                <div className="rjv-settings">
                    <div className="rjv-input">
                        <div className="rjv-label">Display Data Types:</div>
                        {getDataTypesInput(displayDataTypes)}
                    </div>
                    <div className="rjv-input">
                        <div className="rjv-label">Display Object Size:</div>
                        {getObjectSizeInput(displayObjectSize)}
                    </div>
                    <div className="rjv-input">
                        <div className="rjv-label">Indent Width:</div>
                        {getIndentWidthInput(indentWidth)}
                    </div>
                    <div className="rjv-input">
                        <div className="rjv-label">Collapsed:</div>
                        {getCollapsedInput(collapsed)}
                    </div>
                    <div className="rjv-input">
                        <div className="rjv-label">
                            Collapse Strings After Length:
                        </div>
                        {getCollapsedStringsInput(collapseStringsAfter)}
                    </div>
                </div> */}

                {/* {getNotes(onEdit, onAdd)} */}
            </div>
    );
    
}
