import React, {Fragment, memo, useEffect, useState} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { setData, activateSection } from '../../../../../redux/actions/parametrizer';
import ManagementBox from './ManagementBox.jsx'
function ManagementPlanPanel (props) {

    const [grabbed, setGrabbed] = useState('');

    const [ active, setActive ] = useState('');
    const { data: sectionList } = useSelector(state => state.parametrizer);
    const { responsive } = useSelector(state => state.ui);
    const { info, getSelected } = props
    const dispatch = useDispatch()
    const $ = require('jquery');
    const handleOnDragStart = start => {
        setGrabbed(start.draggableId);
    }

    const handleOnDragEnd = result => {
        if (!result.destination) return;
        let virtualData = sectionList;
        const items = Array.from(info);
        const [reorderedItem] = items.splice(result.source.index, 1);
        const index = virtualData.map(section =>{
            return section.id
        }).indexOf('mgmtPlan');
        items.splice(result.destination.index, 0, reorderedItem);
        handleOnClickSection(reorderedItem.id,result.destination.index);
        virtualData[index].options = items
        dispatch(setData(virtualData));
        setGrabbed('');
    }

    const handleOnClickSection = (section, index) => {
        setActive(section);
        getSelected(index);
    }

    const selectionChooser = (e) => {
        const functionKeys = {112: "prescriptions",114: "laboratories",113: "images",115: "interencounter"};
        if(Object.keys(functionKeys).includes(e.which.toString())) {
            e.preventDefault();
            handleOnClickSection(functionKeys[e.which], (e.which - 112));
        }
    }

    useEffect( () => {
        handleOnClickSection(info[0]?.id, 0);
        const scroll =  function(e) {
            if (e.deltaY > 0) this.scrollLeft += 110;
	        else this.scrollLeft -= 110;
            e.preventDefault();
        }
        document.getElementById('mgnt-horizontal-panel')?.addEventListener('mousewheel',scroll)
        document.addEventListener('keydown', selectionChooser);
        if(["large","extralarge"].includes(responsive)) {
            document.getElementById('mgnt-horizontal-panel')?.removeEventListener('mousewheel', scroll);
        };
        return function cleanup () {
            document.getElementById('mgnt-horizontal-panel')?.removeEventListener('mousewheel', scroll);
            document.removeEventListener('keydown', selectionChooser);
        }
    },[responsive])

    useEffect(() => {
        if(info.length > 0) {
            handleOnClickSection(info[0]?.id, 0);
        }
    },[info.length])


        return (
            <Fragment>
                <DragDropContext
                    onDragEnd={handleOnDragEnd}
                    onDragStart={handleOnDragStart}
                >
                    <Droppable 
                        droppableId="sectionList"
                        direction="horizontal"
                    >
                        { provided => (
                            <ul id={'mgnt-horizontal-panel'} className={'management-list-container'}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {info.length > 0 && info.map((sections, key) => {
                                    return(
                                        <Draggable key={sections.id} draggableId={sections.id} index={key} isDragDisabled={!props.editable}>
                                            {(provided, snapshot) => (
                                                <ManagementBox 
                                                    sections={sections} 
                                                    provided={provided} 
                                                    snapshot={snapshot}
                                                    index={key}
                                                    onClick={handleOnClickSection}
                                                    isActive={active === sections.id}
                                                    isGrabbed={grabbed === sections.id}
                                                    deleteSection={props.deleteSection}
                                                    editSection={props.editSection}
                                                    />
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                        
                    </Droppable>
                </DragDropContext>
            </Fragment>
        );    
}

export default ManagementPlanPanel