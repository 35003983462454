import React, {useEffect} from 'react';
import ContextMenu from 'devextreme-react/context-menu';

export default function IndigoContextMenu (props) {

    const { onItemClick, dataSource, width, target, closeOnOutsideClick, open, toggle} = props;

    const contextRef = React.createRef();
    let contextParent;

    const handleDocumentClick = (e) => {
        if (e && e.which === 3) return;
        const container = contextParent;
        if (!container.contains(e.target)) {
            handleCloseContext();
        }
    }

    const handleCloseContext = (e) => {
        // toggle(false);
        toggle(null);
        setTimeout(
            () => {
            },
            [300],
        );
    }

    useEffect(() => { 
        document.addEventListener('click', handleDocumentClick);
          
        return function cleanup () {
            document.removeEventListener('click', handleDocumentClick);
        }

    }, [])

    useEffect(() => {
        contextParent = contextRef.current.parentNode;
    },[])
    
    return (
        <div
            ref={contextRef}
        >
            <ContextMenu
                visible={open}
                dataSource={dataSource}
                width={width}
                target={target}
                closeOnOutsideClick={open ? false : closeOnOutsideClick}
                onItemClick={onItemClick} />
        </div>
    )
}