export const infoPerFicha = {
    810: [
        {
            "display": "Edad",
            "questionNumber" : 10
        }
    ],
    1000: [
        {
            "display": "Anos",
            "questionNumber" : 2
        },
        {
            "display": "Meses",
            "questionNumber" : 3
        },
        {
            "display": "Dias",
            "questionNumber" : 4
        },
    ],
    451: [
        {
            "display": "Edad",
            "questionNumber" : 50
        },
    ],
    450: [
        {
            "display": "Edad",
            "questionNumber" : 5
        },
    ]
}