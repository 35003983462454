import Button from 'devextreme-react/button';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import TextBox from 'devextreme-react/text-box';
import Footer from '../ui/footer/footer';

export default function Header(props) {

    const { patient, especiality, getFilterValue, readOnly, infoExists } = props
    const { responsive } = useSelector(state => state.ui);
    const { location } = useSelector(state => state?.agenda?.appointment)
    var classNames = require('classnames');
    var $ = require('jquery')


    const responsiveSmall = () => {
        if (["extrasmall", "small", "medium"].includes(responsive)) {
            return true
        } else { 
            $("#headerContainer").slideDown(500);
            $(".header-component").slideDown(500);
        }
    }

    const togglePanel = () => {
        $("#headerContainer").slideToggle("fast");
        $(".header-component").slideToggle("fast");
        $('.dx-icon-chevronup').toggleClass('flip');
        props.headToggled && props.headToggled();
    }

    function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    return (
        <Fragment>
            <div id={'headerContainer'} className={classNames('header-container', { 'small': responsiveSmall() })}>
                <div className={'header-content'}>
                    {especiality ?
                        <span className={classNames({'loading': !especiality || !infoExists})}>{especiality}</span>
                        : <p></p>
                    }
                    <h5 className={classNames({'loading': !patient?.display || !infoExists})}>{patient?.display}</h5>
                    <span className={'header-info'}>
                        <span>
                            <p><b>ID:</b></p>
                            <p className={classNames({'loading': !patient?.id || !infoExists})}>{patient?.id}</p>
                        </span>
                        <span>
                            <p><b>Género:</b></p>
                            <p className={classNames({'loading': !patient?.sex || !infoExists})}>{patient?.sex}</p>
                        </span>
                        <span>
                            <p><b>Edad:</b></p>
                            <p className={classNames({'loading': !patient?.age || !infoExists})}>{patient?.age}</p>
                        </span>
                    </span>
                    {location && <span>
                        <p><b>Localidad: </b> 
                            <span className={classNames({'loading': !location || !infoExists})}>
                                {location[0]?.name} - {location[1]?.name} 
                            </span>
                        </p>
                    </span> }
                </div>
                {!responsiveSmall() && readOnly &&
                    <TextBox placeholder="Buscar"
                        stylingMode="outlined"
                        width="400"
                        onKeyUp={debounce((e) => getFilterValue(e.element.getElementsByTagName("input")[0].value), 400)}
                    />
                }
                <Footer />
            </div>
            {responsiveSmall() && <div className={'panel-toggler'} onClick={togglePanel}>
                <Button icon={'chevronup'} stylingMode="text" />
            </div>}
        </Fragment>
    )
}