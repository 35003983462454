import React, { Fragment, useEffect } from 'react';
import DataGrid, { Selection, Paging, FilterRow, Scrolling, Pager, Column } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';


export default function SelectedDropDown(props) {

    const {open, cancel, select, dataSource, responsive, parentRef} = props;
    const classNames = require('classnames');
    var mgmtParentRef;
    const dropRef = React.createRef();
    const handleDocumentClick = (e) => {
        const target = e.target;
        const element = mgmtParentRef;
        const another = document.getElementById('selectorContainer')
        if(!element?.contains(target) && !another?.contains(target)) {
            cancel();
        }
    }

    const handleOnSelect = async (selection) => {
        // //console.log("Selection:", selection)
        await select(selection);
    }

    useEffect(() => {
        mgmtParentRef = dropRef?.current;
    },[dropRef])

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return function cleanup () {
            document.removeEventListener('click', handleDocumentClick);
        }
    },[]);

    const onItemClick = (e) => {
        if(e.rowType === "filter") return;
        cancel();
    }

    return(
        <Fragment>
            <div id={'agendaEspecSelect'} ref={dropRef} style={{display: "none", minHeight: open ? '250px' : '0px', width: '100%', zIndex: 99999, top: '-5px'}} className={classNames('mgmt-dropdown-container absolute ', {'droped' : open})}>
                <div className={classNames('mgmt-dropdown-content', {'droped' : open})}>
                    <div className={classNames('mgmt-dropdown-group-nopadding')}>
                        <DataGrid
                            dataSource={dataSource}
                            noDataText={'Cargando...'}
                            onSelectionChanged={handleOnSelect}
                            onCellClick={onItemClick}
                            hoverStateEnabled={true}
                            style={{maxHeight: '32vh'}}
                            showBorders={true}
                            columnHidingEnabled={true}
                            id={'agendaEspecialities'}
                        >
                            <Paging enabled={true} defaultPageSize={10}></Paging>
                            <Pager
                                // showPageSizeSelector={true}
                                // allowedPageSizes={[10, 20]}
                                showInfo={true} />
                            <Column dataField="text" caption=""/>
                            <Selection mode="single"></Selection>
                            <FilterRow visible={true}></FilterRow>
                        </DataGrid>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}