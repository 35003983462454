import config from '../config';

///////////////////////////////////////////////////// Patient /////////////////////////////////////////////////////////
export const getAllPatients = async (result) => {

    const allPatients = await fetch('/api/patient/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allPatients;
}

export const createPatient = (result) => {
    fetch('/api/patient/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            Name: [{
                FamilyElement: {
                    Value: "Steven Rojas New"
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const deletePatient = (result) => {
    fetch('/api/patient/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: '3dc1bebe-8808-4ec9-a527-3ac61016061f',
                ElementId: null,
                Extension: []
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const updatePatient = (result) => {
    fetch('/api/patient/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: '7f0c77c1-9241-4f67-8044-1b1d77915923',
                ElementId: null,
                Extension: []
            },
            Name: [{
                FamilyElement: {
                    Value: "Steven Rojas V3"
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Practitioner /////////////////////////////////////////////////////////
export const getAllPractitioners = async (result) => {

    const allPractitioners = await fetch('/api/practitioner/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allPractitioners;
}

export const createPractitioner = (result) => {
    fetch('/api/practitioner/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            Name: [{
                familyElement: {
                    Value: "New New"
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const deletePractitioner = (result) => {
    fetch('/api/practitioner/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "c2db0244-4d7e-4c76-b426-3a35d64d793b",
                ElementId: null,
                Extension: []
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const updatePractitioner = (result) => {
    fetch('/api/practitioner/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "a155aa18-27c8-4529-85d5-a334b46baa9a",
                ElementId: null,
                Extension: []
            },
            Name: [{
                FamilyElement: {
                    Value: "Prueba"
                },
                GivenElement: [{
                    Value: "Nueva"
                },
                {
                    Value: "Vieja"
                }]
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Organization /////////////////////////////////////////////////////////
export const getallOrganizations = async (result) => {
    const allOrganizations = await fetch('/api/organization/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allOrganizations;
}
export const createOrganization = (result) => {
    fetch('/api/organization/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            NameElement: {
                Value: "New Organization"
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteOrganization = (result) => {
    fetch('/api/organization/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "a1012316-8878-4119-b30e-fde0d5116a34",
                ElementId: null,
                Extension: []
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateOrganization = (result) => {
    fetch('/api/organization/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "91bef2fd-7ecd-463f-bcc6-e8987ee7d037",
                ElementId: null,
                Extension: []
            },
            NameElement: {
                Value: "Organization Modified"
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Location /////////////////////////////////////////////////////////

export const getAllLocations = async (result) => {
    const allLocations = await fetch('/api/location/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allLocations;

}
export const createLocation = (result) => {
    fetch('/api/location/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            NameElement: {
                Value: "In Your House ._."
            },
            DescriptionElement: {
                Value: "Behind You"
            },
            Address: {
                CityElement: {
                    Value: "Papua"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteLocation = (result) => {
    fetch('/api/location/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "8abaae37-f8a1-4174-8b40-924ed282c4b4",
                ElementId: null,
                Extension: []
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateLocation = (result) => {
    fetch('/api/location/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "a31325f2-ed51-487e-86f4-9518458efaf4",
                ElementId: null,
                Extension: []
            },
            NameElement: {
                Value: "In Your House x.x"
            },
            DescriptionElement: {
                Value: "Behind You"
            },
            Address: {
                CityElement: {
                    Value: "I Dont Know"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Schedule /////////////////////////////////////////////////////////
export const getAllSchedules = async (result) => {
    const allSchedules = await fetch('/api/schedule/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allSchedules;
}
export const createSchedule = (result) => {
    fetch('/api/schedule/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            CommentElement: {
                Value: "In Your House ._."
            },
            Actor: [{ //Required
                ReferenceElement: {
                    Value: "Location/In Your House"
                }
            }],
            Specialty: [{
                Coding: [{
                    DisplayElement: {
                        Value: "Clinic"
                    }
                }]
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteSchedule = (result) => {
    fetch('/api/schedule/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "08ebeb32-6e35-4566-a714-ef40de23b3b9",
                ElementId: null,
                Extension: []
            },
            Actor: [{ //Required
                ReferenceElement: {
                    Value: "Location/In Your House"
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateSchedule = (result) => {
    fetch('/api/schedule/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "d7c7f483-cf68-47aa-bdd2-40d363f1ceef",
                ElementId: null,
                Extension: []
            },
            CommentElement: {
                Value: "In Your House 2.0"
            },
            Actor: [{ //Required
                ReferenceElement: {
                    Value: "Location/In Your House 2.0"
                }
            }],
            Specialty: [{
                Coding: [{
                    DisplayElement: {
                        Value: "Clinic 2.0"
                    }
                }]
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Appointment /////////////////////////////////////////////////////////
export const getAllAppointments = async (result) => {

    const appointments = await fetch('/api/appointment/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return appointments;
}
export const createAppointment = (result) => {
    fetch('/api/appointment/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: { //Required
                Value: 3
            },
            Participant: [{
                StatusElement: { //Required
                    Value: 2
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteAppointment = (result) => {
    fetch('/api/appointment/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "f2bee6b2-986b-4010-84d6-39b455208e5c",
                ElementId: null,
                Extension: []
            },
            StatusElement: { //Required
                Value: 3
            },
            Participant: [{
                StatusElement: { //Required
                    Value: 2
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateAppointment = (result) => {
    fetch('/api/appointment/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "93f38b88-4b1d-4022-b387-ccca9fd20033",
                ElementId: null,
                Extension: []
            },
            StatusElement: { //Required
                Value: 3
            },
            Participant: [{
                StatusElement: { //Required
                    Value: 2
                }
            }],
            DescriptionElement: {
                Value: "Modificaci�n"
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Slot /////////////////////////////////////////////////////////
export const getAllSlots = async (result) => {

    const allSlots = await fetch('/api/slot/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allSlots;
}
export const createSlot = (result) => {
    fetch('/api/slot/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            Schedule: {
                ReferenceElement: { //Required
                    Value: "Schedule Create"
                }
            },
            StatusElement: { //Required
                Value: 2
            },
            StartElement: { //Required
                Value: "2021-04-14"
            },
            EndElement: { //Required
                Value: "2021-04-14"
            },
            CommentElement: {
                Value: "Prueba Crear"
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteSlot = (result) => {
    fetch('/api/slot/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "aa623770-37bf-4bd6-8ab9-e39165ced353",
                ElementId: null,
                Extension: []
            },
            Schedule: {
                ReferenceElement: { //Required
                    Value: "Schedule Create"
                }
            },
            StatusElement: { //Required
                Value: 2
            },
            StartElement: { //Required
                Value: "2021-04-14"
            },
            EndElement: { //Required
                Value: "2021-04-14"
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateSlot = (result) => {
    fetch('/api/slot/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "061a8c17-505b-4d70-9a1c-85350dba7642",
                ElementId: null,
                Extension: []
            },
            Schedule: {
                ReferenceElement: { //Required
                    Value: "Schedule Create"
                }
            },
            StatusElement: { //Required
                Value: 2
            },
            StartElement: { //Required
                Value: "2021-04-14"
            },
            EndElement: { //Required
                Value: "2021-04-14"
            },
            CommentElement: {
                Value: "Prueba Crear - Modificar"
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Medication /////////////////////////////////////////////////////////

export const getAllMedications = async (result) => {
    const allMedications = await fetch('/api/medication/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allMedications;
}
export const createMedication = (result) => {
    fetch('/api/medication/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            Code: {
                TextElement: {
                    Value: "Loracepan"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteMedication = (result) => {
    fetch('/api/medication/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "07badf92-72b1-4193-bf5a-eeb4056a3dc5",
                ElementId: null,
                Extension: []
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateMedication = (result) => {
    fetch('/api/medication/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "d2742b1d-52b8-4a52-a2bb-6708616f7933",
                ElementId: null,
                Extension: []
            },
            Code: {
                TextElement: {
                    Value: "Loracepan Create - Modified"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// MedicationRequest /////////////////////////////////////////////////////////

export const getAllMedicationRequests = async (result) => {
    const allMedicationRequests = await fetch('/api/medicationrequest/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    // //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allMedicationRequests;
}
export const createMedicationRequest = (result) => {
    fetch('/api/medicationrequest/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {
                Value: 2
            },
            IntentElement: {
                Value: 2
            },
            PriorityElement: {
                Value: 2
            },
            Subject: {
                ReferenceElement: {
                    Value: "Patient/pat1"
                },
                DisplayElement: {
                    Value: "Franchesco"
                }
            },
            Medication: {
                ReferenceElement: {
                    Value: "310"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteMedicationRequest = (result) => {
    fetch('/api/medicationrequest/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "90d6900e-7786-43e4-9275-d67b152bea91",
                ElementId: null,
                Extension: []
            },
            Subject: {
                ReferenceElement: {
                    Value: "Patient/1"
                }
            },
            Medication: {
                ReferenceElement: {
                    Value: "#med0310"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateMedicationRequest = (result) => {
    fetch('/api/medicationrequest/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "d2742b1d-52b8-4a52-a2bb-6708616f7933",
                ElementId: null,
                Extension: []
            },
            Code: {
                TextElement: {
                    Value: "Loracepan Create - Modified"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Bundle /////////////////////////////////////////////////////////

export const getAllBundles = async (result) => {
    const allBundles = await fetch('/api/bundle/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allBundles;
}
export const createBundle = (result) => {
    fetch('/api/bundle/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            TypeElement: {
                Value: 6
            },
            Link: [{
                RelationElement: {
                    Value: "Create"
                },
                UrlElement: {
                    Value: "Paciente"
                }
            }],
            Entry: [{
                Request: {
                    MethodElement: {
                        Value: 3
                    },
                    UrlElement: {
                        Value: "Patient"
                    }
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteBundle = (result) => {
    fetch('/api/bundle/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "85eb591e-47be-4a76-881b-ea9bb2c52902",
                ElementId: null,
                Extension: []
            },
            TypeElement: {
                Value: 6
            },
            Link: [{
                RelationElement: {
                    Value: "Create"
                },
                UrlElement: {
                    Value: "Paciente"
                }
            }],
            Entry: [{
                Request: {
                    MethodElement: {
                        Value: 3
                    },
                    UrlElement: {
                        Value: "Patient"
                    }
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateBundle = (result) => {
    fetch('/api/bundle/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "9a287ee2-4b88-4606-a162-b82e92f59dfe",
                ElementId: null,
                Extension: []
            },
            TypeElement: {
                Value: 6
            },
            Link: [{
                RelationElement: {
                    Value: "Create-Update"
                },
                UrlElement: {
                    Value: "Paciente"
                }
            }],
            Entry: [{
                Request: {
                    MethodElement: {
                        Value: 3
                    },
                    UrlElement: {
                        Value: "Patient"
                    }
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// CodeSystem /////////////////////////////////////////////////////////

export const getAllCodesystems = async (result) => {
    const allCodesystems = await fetch('/api/codesystem/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allCodesystems;
}
export const createCodesystem = (result) => {
    fetch('/api/codesystem/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {    //Required
                Value: 2
            },
            ContentElement: {    //Required
                Value: 3
            },
            Filter: [{    //Required
                CodeElement: {
                    Value: "Plasma"
                },
                OperatorElement: [{
                    Value: 1
                }],
                ValueElement: {
                    Value: "True or False"
                }
            }],
            Concept: [{    //Required
                CodeElement: {
                    Value: "Trato"
                },
                Designation: []
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteCodesystem = (result) => {
    fetch('/api/codesystem/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "26a56ca6-9cdd-4947-a38d-fc1422d21ebf",
                ElementId: null,
                Extension: []
            },
            StatusElement: {    //Required
                Value: 2
            },
            ContentElement: {    //Required
                Value: 3
            },
            Filter: [{    //Required
                CodeElement: {
                    Value: "Plasma"
                },
                OperatorElement: [{
                    Value: 1
                }],
                ValueElement: {
                    Value: "True or False"
                }
            }],
            Concept: [{    //Required
                CodeElement: {
                    Value: "Trato"
                },
                Designation: []
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });

}
export const updateCodesystem = (result) => {
    fetch('/api/codesystem/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "f5fcc17f-c8bd-4ce5-99a7-bff500ed72da",
                ElementId: null,
                Extension: []
            },
            StatusElement: {    //Required
                Value: 3
            },
            ContentElement: {    //Required
                Value: 1
            },
            Filter: [{    //Required
                CodeElement: {
                    Value: "Plasma"
                },
                OperatorElement: [{
                    Value: 1
                }],
                ValueElement: {
                    Value: "True or False"
                }
            }],
            Concept: [{    //Required
                CodeElement: {
                    Value: "Trato"
                },
                Designation: []
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// ValueSet /////////////////////////////////////////////////////////


export const getAllSpecialitiesFhir = async (auth) => {
    try {
        const resp = await fetch('/api/valueset/get/especialities/fhir', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`
            },
            mode: 'cors',
            cache: 'default'
        });
        const specialities = await resp.json();
        return specialities;
    } catch (error) {
        //console.log('error: ', error)
    }
}

export const getImgServices = async (auth) => {
    try {
        const resp = await fetch('/api/valueset/get/imgservices', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`
            },
            mode: 'cors',
            cache: 'default'
        });
        const imgServices = await resp.json();
        return imgServices;
    } catch (error) {
        //console.log('error: ', error)
    }
}

export const getLabServices = async (auth) => {
    try {
        const resp = await fetch('/api/valueset/get/labservices', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`
            },
            mode: 'cors',
            cache: 'default'
        });
        const labServices = await resp.json();
        return labServices;
    } catch (error) {
        //console.log('error: ', error)
    }
}

export const getFrequences = async (auth) => {
    try {
        const resp = await fetch('/api/valueset/get/frequences', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`
            },
            mode: 'cors',
            cache: 'default'
        });
        const frequences = await resp.json();
        return frequences;
    } catch (error) {
        //console.log('error: ', error)
    }
}
//////Medication
export const getMedicationFhir = async (auth) => {
    try {
        const resp = await fetch('/api/medication/getAll/fhir', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`
            },
            mode: 'cors',
            cache: 'default'
        });
        const medication = await resp.json();
        return medication;
    } catch (error) {
        //console.log('error: ', error)
    }
}

//Practitioner
export const getPractitionerFhir = async (auth) => {
    try {
        const resp = await fetch('/api/practitioner/get/practitioners', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`
            },
            mode: 'cors',
            cache: 'default'
        });
        const practitioners = await resp.json();
        return practitioners;
    } catch (error) {
        //console.log('error: ', error)
    }
}

export const getDiagnosticsData = async (auth) => {
    try {
        const resp = await fetch('/api/codesystem/get/cie', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`
            },
            mode: 'cors',
            cache: 'default'
        });
        const practitioners = await resp.json();
        return practitioners;
    } catch (error) {
        //console.log('error: ', error)
    }
}

export const getAllValueSets = async (auth) => {
    const allValueSets = await fetch('/api/valueset/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    // //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allValueSets;
}
export const createValueSet = (result) => {
    fetch('/api/valueset/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {    //Required
                Value: 2
            },
            Compose: {    //Required
                Include: [{
                    Filter: [{
                        PropertyElement: {
                            Value: "Pariente"
                        },
                        OpElement: {
                            Value: 1
                        },
                        ValueElement: {
                            Value: "NNC4"
                        }
                    }]
                }]
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteValueSet = (result) => {
    fetch('/api/valueset/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "13a14642-4666-4afe-9bf1-b094b0469123",
                ElementId: null,
                Extension: []
            },
            StatusElement: {    //Required
                Value: 2
            },
            Compose: {    //Required
                Include: [{
                    Filter: [{
                        PropertyElement: {
                            Value: "Pariente"
                        },
                        OpElement: {
                            Value: 1
                        },
                        ValueElement: {
                            Value: "NNC4"
                        }
                    }]
                }]
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateValueSet = (result) => {
    fetch('/api/valueset/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "775c5160-153d-4638-bb4c-3b63fc1d517f",
                ElementId: null,
                Extension: []
            },
            StatusElement: {    //Required
                Value: 2
            },
            Compose: {    //Required
                Include: [{
                    Filter: [{
                        PropertyElement: {
                            Value: "Pariente"
                        },
                        OpElement: {
                            Value: 1
                        },
                        ValueElement: {
                            Value: "Cosa de Cosas"
                        }
                    }]
                }]
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// ServiceRequest /////////////////////////////////////////////////////////
export const getAllServiceRequests = async (result) => {
    const allServiceRequests = await fetch('/api/servicerequest/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allServiceRequests;
}
export const createServiceRequest = (result) => {
    fetch('/api/servicerequest/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {    //Required
                Value: 2
            },
            IntentElement: {    //Required
                Value: 3
            },
            Subject: {    //Required
                ReferenceElement: {
                    Value: "Paciente/Dicom"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteServiceRequest = (result) => {
    fetch('/api/servicerequest/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "6c05407b-ae23-4ad4-9cd2-53be7b88edde",
                ElementId: null,
                Extension: []
            },
            StatusElement: {    //Required
                Value: 2
            },
            IntentElement: {    //Required
                Value: 3
            },
            Subject: {    //Required
                ReferenceElement: {
                    Value: "Paciente/Dicom"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateServiceRequest = (result) => {
    fetch('/api/servicerequest/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "12ef7320-3617-4354-9c1d-1ea8ee88fa0d",
                ElementId: null,
                Extension: []
            },
            StatusElement: {    //Required
                Value: 2
            },
            IntentElement: {    //Required
                Value: 3
            },
            Subject: {    //Required
                ReferenceElement: {
                    Value: "Paciente/Dicom - Modified"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });

}

///////////////////////////////////////////////////// SupplyRequest /////////////////////////////////////////////////////////

export const getAllSupplyRequests = async (result) => {
    const allSupplyRequests = await fetch('/api/supplyrequest/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allSupplyRequests;
}
export const createSupplyRequest = (result) => {
    fetch('/api/supplyrequest/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            Item: { //Error
                Coding: [{
                    CodeElement: {
                        Value: "Azullll"
                    },
                    DisplayElement: {
                        Value: "NN"
                    }
                }]
            },
            Quantity: {
                ValueElement: {
                    Value: 6
                }
            },
            Ocurrence: {
                Value: "2021-04-15"
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteSupplyRequest = (result) => {
    fetch('/api/supplyrequest/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "6c05407b-ae23-4ad4-9cd2-53be7b88edde",
                ElementId: null,
                Extension: []
            },
            Item: {
                Coding: [{
                    CodeElement: {
                        Value: "Azullll"
                    }
                }]
            },
            Quantity: {
                ValueElement: {
                    Value: 6
                }
            },
            Ocurrence: {
                Value: "2021-04-15"
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateSupplyRequest = (result) => {
    fetch('/api/supplyrequest/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "12ef7320-3617-4354-9c1d-1ea8ee88fa0d",
                ElementId: null,
                Extension: []
            },
            Item: { //Error
                Coding: [{
                    CodeElement: {
                        Value: "Azullll"
                    }
                }]
            },
            Quantity: {
                ValueElement: {
                    Value: 6
                }
            },
            Ocurrence: {
                Value: "2021-04-15"
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });

}

///////////////////////////////////////////////////// Observation /////////////////////////////////////////////////////////

export const getAllObservations = async (result) => {
    const allObservations = await fetch('/api/observation/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allObservations;
}
export const createObservation = (result) => {
    fetch('/api/observation/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {  //Required
                Value: 3
            },
            Code: {  //Required
                Coding: [{
                    SystemElement: {
                        Value: "http://Lol.org"
                    }
                }]
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteObservation = (result) => {
    fetch('/api/observation/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "438ba7ed-e911-4a0d-87d2-89a5004b0df7",
                ElementId: null,
                Extension: []
            },
            StatusElement: {  //Required
                Value: 3
            },
            Code: {  //Required
                Coding: [{
                    SystemElement: {
                        Value: "http://Lol.org"
                    }
                }]
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateObservation = (result) => {
    fetch('/api/observation/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "d1ee80a7-359f-4043-ab51-a85c008d257d",
                ElementId: null,
                Extension: []
            },
            StatusElement: {  //Required
                Value: 3
            },
            Code: {  //Required
                Coding: [{
                    SystemElement: {
                        Value: "http://Modified.org"
                    }
                }]
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Composition /////////////////////////////////////////////////////////

export const getAllCompositions = async (result) => {
    const allCompositions = await fetch('/api/composition/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allCompositions;
}

export const createComposition = (auth, data) => {
    fetch('/api/composition/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        body: JSON.stringify({
            StatusElement: {  //Required
                Value: 3
            },
            Type: {  //Required
                Coding: [{
                    SystemElement: {
                        Value: "http://Create.org"
                    }
                }]
            },
            DateElement: {  //Required
                Value: "2021-04-15"
            },
            Author: [{  //Required
                ReferenceElement: {
                    Value: "Profesional/Autor"
                }
            }],
            TitleElement: {  //Required
                Value: "Servicio"
            },
            Attester: [{  //Required
                ModeElement: {
                    Value: 1
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteComposition = (result) => {
    fetch('/api/composition/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "12224068-c489-431f-89de-ffa36bb1733c",
                ElementId: null,
                Extension: []
            },
            StatusElement: {  //Required
                Value: 3
            },
            Type: {  //Required
                Coding: [{
                    SystemElement: {
                        Value: "http://Create.org"
                    }
                }]
            },
            DateElement: {  //Required
                Value: "2021-04-15"
            },
            Author: [{  //Required
                ReferenceElement: {
                    Value: "Profesional/Autor"
                }
            }],
            TitleElement: {  //Required
                Value: "Servicio"
            },
            Attester: [{  //Required
                ModeElement: {
                    Value: 1
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });

}
export const updateComposition = (result) => {
    fetch('/api/composition/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "78471f0e-7022-4a60-84bc-7d29261287ee",
                ElementId: null,
                Extension: []
            },
            StatusElement: {  //Required
                Value: 3
            },
            Type: {  //Required
                Coding: [{
                    SystemElement: {
                        Value: "http://Create.org"
                    }
                }]
            },
            DateElement: {  //Required
                Value: "2021-04-15"
            },
            Author: [{  //Required
                ReferenceElement: {
                    Value: "Profesional/Autor"
                }
            }],
            TitleElement: {  //Required
                Value: "Servicio Personalizado"
            },
            Attester: [{  //Required
                ModeElement: {
                    Value: 1
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Binary /////////////////////////////////////////////////////////

export const getAllBinarys = async (result) => {
    const allBinarys = await fetch('/api/binary/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allBinarys;
}
export const createBinary = (result) => {
    fetch('/api/binary/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            ContentTypeElement: {
                Value: "Image/jpeg"
            },
            DataElement: {    //Error
                Value: ""
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteBinary = (result) => {
    fetch('/api/binary/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "3f0a4554-801d-43f0-ba2e-30f8cce7b3c1",
                ElementId: null,
                Extension: []
            },
            ContentTypeElement: {
                Value: "image/jpeg"
            },
            DataElement: {}
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateBinary = (result) => {
    fetch('/api/binary/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "78471f0e-7022-4a60-84bc-7d29261287ee",
                ElementId: null,
                Extension: []
            },
            StatusElement: {  //Required
                Value: 3
            },
            Type: {  //Required
                Coding: [{
                    SystemElement: {
                        Value: "http://Create.org"
                    }
                }]
            },
            DateElement: {  //Required
                Value: "2021-04-15"
            },
            Author: [{  //Required
                ReferenceElement: {
                    Value: "Profesional/Autor"
                }
            }],
            TitleElement: {  //Required
                Value: "Servicio Personalizado"
            },
            Attester: [{  //Required
                ModeElement: {
                    Value: 1
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

/////////////////////////////////////////////////////// User /////////////////////////////////////////////////////////
//ADMIN PANEL
//Retry Panel
//LoginUser
export const loginUser = async (email, pass) => {

    let form_data = new FormData();
    form_data.append('email', email);
    form_data.append('password', pass);
    ///api/user/auth/admin
    //http://localhost:52109/
    const user = await fetch(config.External + "api/adminpanel/auth",
        {
            body: form_data,
            method: "post"
        })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                return response.error;
            }
        }).then((resJson) => {
            if (resJson) {
                if (resJson.error) {
                    ////console.log('Error: ', resJson.error);
                } else {
                    // //console.log('ResJson: ', resJson)
                    return resJson;
                }
            }
        });
    return user;

}
export const getAllRetries = async (email, pass) => {

    const retries = await fetch(config.External + "api/adminpanel/list/registers/oneview", {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
            'Content-Type': 'application/json'
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.error;
        }
    }).then((resJson) => {
        if (resJson) {
            if (resJson.error) {
                //console.log(resJson.error);
            } else {
                // //console.log(resJson);
                return resJson;
            }
        }
    });
    return retries;
}

//edicion de reintentos json
export const validateRetry = async (email, pass, idPatient, idEncounter) => {
    let form_data = new FormData();
    form_data.append('id_patient', idPatient);
    form_data.append('id_encounter', idEncounter);
    try {
        const resp = await fetch(config.External +"api/adminpanel/get/json/composition", {
            method: 'post',
            body: form_data,
            headers: new Headers({
                'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
            })
        })
        if (resp.status === 200) {
            let res = await resp.json();
            return res;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        console.log('Error: ', error);
    }
}

//peticion reintentos
export const retryLog = async (email, pass, log) => {
    let form_data = new FormData();
    form_data.append('log', JSON.stringify(log));
    try {
        const resp = await fetch(config.External +"api/adminpanel/retry/resource", {
            method: 'post',
            body: form_data,
            headers: new Headers({
                'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
            })
        })
        if (resp.status === 200) {
            let res = await resp.json();
            return res;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        console.log('Error: ', error);
    }
}

export const getAllUsersPanel = async (email, pass) => {
    const allUsers = await fetch(config.External + "api/adminpanel/list/users/oneview", {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
            'Content-Type': 'application/json'
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.error;
        }
    }).then((resJson) => {
        if (resJson) {
            if (resJson.error) {
                //console.log(resJson.error);
            } else {
                // //console.log(resJson);
                return resJson;
            }
        }
    });
    return allUsers;
}

export const createOrUpdateUserPanel = async (email, pass, user) => {
    let response = await fetch(config.External + "api/adminpanel/create/users/oneview", {
        method: 'post',
        headers: new Headers({
            'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
            'Content-Type': 'application/json'
        }),
        body: user,
        mode: 'cors',
        cache: 'default'
    })
    //.then((response) => {
    let jsonResponse = await response.text();
    ////console.log('responsee: ', response)
    return jsonResponse;
}

export const updateUserPanel = async (email, pass, user) => {
    let response = await fetch(config.External + 'api/adminpanel/update/users/oneview', {
        method: 'post',
        headers: new Headers({
            'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
            'Content-Type': 'application/json'
        }),
        body: user,
        mode: 'cors',
        cache: 'default'
    })
    //.then((response) => {
    let jsonResponse = await response.text();
    ////console.log('responsee: ', response)
    return jsonResponse;
}

//Portals
export const getAllPortals = async (email, pass) => {
    const allPortals = await fetch(config.External + "api/adminpanel/list/portals/oneview", {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
            'Content-Type': 'application/json'
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.error;
        }
    }).then((resJson) => {
        if (resJson) {
            if (resJson.error) {
                ////console.log(resJson.error);
            } else {
                ////console.log(resJson);
                return resJson;
            }
        }
    });
    return allPortals;
}

export const updatePortal = async (email, pass, portal) => {
    let response = await fetch(config.External + 'api/adminpanel/update/portals/oneview', {
        method: 'post',
        headers: new Headers({
            'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
            'Content-Type': 'application/json'
        }),
        body: portal,
        mode: 'cors',
        cache: 'default'
    });
    //.then((response) => {
    let jsonResponse = await response.text();
    ////console.log('responsee: ', response)
    return jsonResponse;
}

export const createPortal = async (email, pass, portal) => {
    let response = await fetch(config.External + "api/adminpanel/create/portals/oneview", {
        method: 'post',
        headers: new Headers({
            'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
            'Content-Type': 'application/json'
        }),
        body: portal,
        mode: 'cors',
        cache: 'default'
    })
    //.then((response) => {
    let jsonResponse = await response.text();
    ////console.log('responsee: ', response)
    return jsonResponse;
}/////////////////////////////////////////////////

export const getAllUsers = async (auth) => {
    const allUsers = await fetch('/api/user/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    // //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allUsers;
}

export const createOrUpdateUser = (result) => {
    fetch('/api/user/createorupdate', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            partitionKey: "User",
            rowKey: "df98bb43-8a28-41e9-973e-5322fc07e786",
            lastName: "Rojas Serrano",
            firstName: "Steven"
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const deleteUser = (result) => {
    fetch('/api/user/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: "0773142f-1649-411a-bd03-737efa171737",
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Encounter /////////////////////////////////////////////////////////
///ENDPOINTS NICOLAS 


//Productivity get date information
export const productivityGetDateInformation = async () => {
    try {
        const resp = await fetch('/api/Productivity/get/date/information', {
            method: 'get',
        })

        if (resp.status === 200) {
            let res = await resp.json();
            return res;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        //console.log('Error: ', error);
    }
}

//Productivity create register
export const createRegisterProductivity = async (auth, formData) => {
    try {
        const resp = await fetch('/api/Productivity/create/register', {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        })

        if (resp.status === 200) {
            let res = await resp.json();
            return res;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        //console.log('Error: ', error);
    }
}

//Productivity
export const getAllProductivity = async () => {
    try {
        const resp = await fetch('/api/Productivity/get/all', {
            method: 'get',
        })

        if (resp.status === 200) {
            let allProductivity = await resp.json();
            console.log('AllProductivity::: ', allProductivity);
            return allProductivity;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        console.log('Error: ', error);
    }
}

export const getAllProductivitySpecialty = async (auth, formData) => {
    try {
        const resp = await fetch('/api/Productivity/get/by/specialty', {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        });

        if (resp.status === 200) {
            let res = await resp.json();
            return res;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        //console.log('Error: ', error);
    }
}

export const productivityGetByTemplate= async (auth, formData) => {
    try {
        const resp = await fetch('/api/Productivity/get/by/template', {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        });

        if (resp.status === 200) {
            let res = await resp.json();
            return res;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        //console.log('Error: ', error);
    }
}

export const getProductivityTabular = async(auth, formData) => {
    try {
        const resp = await fetch('/api/Productivity/filter/by/range/dates', {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        });

        if (resp.status === 200) {
            let res = await resp.json();
            return res;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        //console.log('Error: ', error);
    }
}

//filter month
export const productivityFilterByMonth = async(auth, formData) => {
    try {
        const resp = await fetch('/api/Productivity/filter/by/range/dates', {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        });

        if (resp.status === 200) {
            let res = await resp.json();
            return res;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        //console.log('Error: ', error);
    }
}

///

export const getEncountersToday = async (auth, formData) => {
    try {
        const resp = await fetch('/api/schedule/get/encounters/doctor/today', {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        })

        if (resp.status === 200) {
            let encounters = await resp.json();
            return encounters;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        //console.log('Error: ', error);
    }
}

export const getEncountersWeek = async (auth, formData) => {
    try {
        const resp = await fetch('/api/schedule/get/encounters/doctor', {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        })

        if (resp.status === 200) {
            let encounters = await resp.json();
            return encounters;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        //console.log('Error: ', error);
    }
}
//LOCATION-location id
export const getByEnterprise = async (auth, id) => {
    try {
        const resp = await fetch(`/api/location/getbyenterprise/${id}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        })
        if (resp.status === 204) return;
        let location = await resp.json();
        console.log('Location Helper: ', location);
        return location;
    } catch (error) {
        console.log('Error: ', error);
    }

}
/////////////////////////////////////////////////////////////////HISTORIAL CLINICO////////////////////////////////////
export const getPatientByProtuarId = async (auth, id) => {
    try {
        const resp = await fetch(`/api/patient/get/compositions/of/patient/by/prontuar/${id}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        })
        if (resp.status === 204) return;
        let patient = await resp.json();
        return patient;
    } catch (error) {
        console.log('Error: ', error);
    }

}


export const updateStatusEncounter = async (auth, formData) => {
    try {
        const resp = await fetch('/api/encounter/update/status/encounter', {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        })

        if (resp.status === 200) {
            let status = await resp.json();
            return status;
        } else if (resp.status === 204) {
            return [];
        }

    } catch (error) {
        //console.log('Error: ', error);
    }
}


export const getPatientByIdentifier = async (auth, id) => {
    try {
        const resp = await fetch(`/api/patient/get/compositions/of/patient/by/identifier/${id}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        })
        let patient = await resp.json();
        ////console.log('Patient: ', patient);
        return patient;

    } catch (error) {
        //console.log('Error: ', error);
    }
}

export const getComposition = async (auth, id) => {
    try {
        const resp = await fetch(`/api/composition/get/composition/${id}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        })
        let composition = await resp.json();
        return composition;

    } catch (error) {
        //console.log('Error: ', error);
    }
}

export const getCompositionSIH = async (auth, formData) => {
    try {
        const resp = await fetch(`/api/ClinicalReport/get/composition/from/sih`, {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${auth}`
            },
        })
        let composition = await resp.json();
        return composition;

    } catch (error) {
        //console.log('Error: ', error);
    }
}

export const getAllEncounters = async (auth) => {
    const encounters = await fetch('/api/encounter/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return encounters;
}

export const createEncounter = (result) => {
    fetch('/api/encounter/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {
                Value: 4
            },
            Class: {
                CodeElement: {
                    Value: "FF"
                }
            },
            Location: [{
                Location: {
                    ReferenceElement: {
                        Value: "#Work"
                    }
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const deleteEncounter = (result) => {
    fetch('/api/encounter/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "854256e2-fb4b-4d36-bded-b13f49c778d3",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 4
            },
            Class: {
                CodeElement: {
                    Value: "FF"
                }
            },
            Location: [{
                Location: {
                    ReferenceElement: {
                        Value: "#Work"
                    }
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const updateEncounter = (result) => {
    fetch('/api/encounter/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "0e41604a-0edb-4743-9bcd-5058278ea93b",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 4
            },
            Class: {
                CodeElement: {
                    Value: "FF - ZZ"
                }
            },
            Location: [{
                Location: {
                    ReferenceElement: {
                        Value: "#Work"
                    }
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}


////////////////////////////////////////////// Server Time ////////////////////////////////////////////

export const getServerTime = async (auth) => {
    const time = await fetch('/api/Productivity/get/date/information', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    console.log(responseJson.error);
                } else {
                    console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return time;
}

///////////////////////////////////////////////////// Task /////////////////////////////////////////////////////////

export const getAllTasks = async (result) => {
    const allTasks = await fetch('/api/task/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    // //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allTasks;
}
export const createTask = (result) => {
    fetch('/api/task/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {
                Value: 2
            },
            IntentElement: {
                Value: 2
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteTask = (result) => {
    fetch('/api/task/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "e631b188-4a63-4696-9437-aba1ef8f3bea",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 2
            },
            IntentElement: {
                Value: 2
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateTask = (result) => {
    fetch('/api/task/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "f3ea1840-ff70-4d0a-b6eb-644d39b9be75",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 3
            },
            IntentElement: {
                Value: 3
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// AllergyIntolerance /////////////////////////////////////////////////////////
export const getAllAllergyIntolerances = async (result) => {
    const allAllergyIntolerances = await fetch('/api/allergyintolerance/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    // //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allAllergyIntolerances;
}

export const createAllergyIntolerance = (result) => {
    fetch('/api/allergyintolerance/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            Patient: {
                ReferenceElement: {
                    Value: "Patient/Example - Create"
                }
            },
            Reaction: [{
                Manifestation: [{
                    Coding: [{
                        CodeElement: {
                            Value: "25"
                        }
                    }]
                }]
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const deleteAllergyIntolerance = (result) => {
    fetch('/api/allergyintolerance/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "142f994d-8ebc-4f6b-8c13-2dcd885b2f7b",
                ElementId: null,
                Extension: []
            },
            Patient: {
                ReferenceElement: {
                    Value: "Patient/Example"
                }
            },
            Reaction: [{
                Manifestation: [{
                    Coding: [{
                        CodeElement: {
                            Value: "13"
                        }
                    }]
                }]
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const updateAllergyIntolerance = (result) => {
    fetch('/api/allergyintolerance/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "2275ac38-eed1-492c-a1e7-89ac97a31ce6",
                ElementId: null,
                Extension: []
            },
            Patient: {
                ReferenceElement: {
                    Value: "Patient/Example - Update"
                }
            },
            Reaction: [{
                Manifestation: [{
                    Coding: [{
                        CodeElement: {
                            Value: "153465151"
                        }
                    }]
                }]
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

///////////////////////////////////////////////////// Condition /////////////////////////////////////////////////////////
export const createCondition = (result) => {
    fetch('/api/condition/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            Subject: {
                ReferenceElement: {
                    Value: "Patient/Example - Delete"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteCondition = (result) => {
    fetch('/api/condition/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "18e27068-1e8f-4ca3-90bb-a4f3ce6a1667",
                ElementId: null,
                Extension: []
            },
            Subject: {
                ReferenceElement: {
                    Value: "Patient/Example - Delete"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateCondition = (result) => {
    fetch('/api/condition/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "fd2ad97d-33ac-48de-92ef-1d9a2fddf5a1",
                ElementId: null,
                Extension: []
            },
            Subject: {
                ReferenceElement: {
                    Value: "Patient/Example - Delete and Update"
                }
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const getallConditions = async (result) => {
    const allConditions = await fetch('/api/condition/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    // //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allConditions;
}

///////////////////////////////////////////////////// FamilyMemberHistory /////////////////////////////////////////////////////////

export const createFamilyMemberHistory = (result) => {
    fetch('/api/familymemberhistory/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {
                Value: 2
            },
            Patient: {
                ReferenceElement: {
                    Value: "Patient/1313"
                }
            },
            Relationship: {
                Coding: [{
                    CodeElement: {
                        Value: "FFG"
                    }
                }]
            },
            Condition: [{
                Code: {
                    Coding: [{
                        CodeElement: {
                            Value: "897654"
                        }
                    }]
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });

}
export const deleteFamilyMemberHistory = (result) => {
    fetch('/api/familymemberhistory/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "a8fa6ad4-a69d-4f2d-b407-585c1e2f6002",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 2
            },
            Patient: {
                ReferenceElement: {
                    Value: "Patient/200"
                }
            },
            Relationship: {
                Coding: [{
                    CodeElement: {
                        Value: "FFG"
                    }
                }]
            },
            Condition: [{
                Code: {
                    Coding: [{
                        CodeElement: {
                            Value: "897654"
                        }
                    }]
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateFamilyMembersHistory = (result) => {
    fetch('/api/familymemberhistory/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "e3d1edba-972e-47e1-9a94-d1b0c5d01b30",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 2
            },
            Patient: {
                ReferenceElement: {
                    Value: "Patient - Update/1313"
                }
            },
            Relationship: {
                Coding: [{
                    CodeElement: {
                        Value: "FFG"
                    }
                }]
            },
            Condition: [{
                Code: {
                    Coding: [{
                        CodeElement: {
                            Value: "897654"
                        }
                    }]
                }
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const getAllFamilyMemberHistory = async (result) => {
    const allFamilyMemberHistory = await fetch('/api/familymemberhistory/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allFamilyMemberHistory;
}

///////////////////////////////////////////////////// DiagnosticReport /////////////////////////////////////////////////////////

export const createDiagnosticReport = (result) => {
    fetch('/api/diagnosticreport/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {
                Value: 2
            },
            Code: {
                Coding: [{
                    CodeElement: {
                        Value: "FFFF-999"
                    }
                }]
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const deleteDiagnosticReport = (result) => {
    fetch('/api/diagnosticreport/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "3a1cb53d-9b3f-49bc-a943-677b37c79ddd",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 2
            },
            Code: {
                Coding: [{
                    CodeElement: {
                        Value: "FFFF-999"
                    }
                }]
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const updateDiagnosticReport = (result) => {
    fetch('/api/diagnosticreport/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "b233e5a6-dbb6-4993-a014-09657fe1de72",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 3
            },
            Code: {
                Coding: [{
                    CodeElement: {
                        Value: "ZZZ-007"
                    }
                }]
            }
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}

export const getallDiagnosticReport = async (result) => {
    const allDiagnosticReport = await fetch('/api/diagnosticreport/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allDiagnosticReport;
}

///////////////////////////////////////////////////// ImagingStudy /////////////////////////////////////////////////////////

export const createImaginStudy = (result) => {
    fetch('/api/imagingstudy/create', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            StatusElement: {
                Value: 2
            },
            Subject: {
                Referenceelement: {
                    Value: "Patient/Dicom"
                }
            },
            Series: [{
                UidElement: {
                    Value: "2.16.124.113543.5220.1154777499.30246.19789.3503430045.1"
                },
                Modality: {
                    CodeElement: {
                        Value: "XD"
                    }
                },
                Performer: [{
                    Actor: {
                        ReferenceElement: {
                            Value: "Practitioner/Example"
                        }
                    }
                }]
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const deleteImaginStudy = (result) => {
    fetch('/api/imagingstudy/delete', {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "c9a0c6ea-2647-454b-b128-e483d6d280c0",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 2
            },
            Subject: {
                Referenceelement: {
                    Value: "Patient/Dicom"
                }
            },
            Series: [{
                UidElement: {
                    Value: "2.16.124.113543.5220.1154777499.30246.19789.3503430045.1"
                },
                Modality: {
                    CodeElement: {
                        Value: "XD"
                    }
                },
                Performer: [{
                    Actor: {
                        ReferenceElement: {
                            Value: "Practitioner/Pablo veredas"
                        }
                    }
                }]
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const updateImaginStudy = (result) => {
    fetch('/api/imagingstudy/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        body: JSON.stringify({
            IdElement: {
                Value: "c9a0c6ea-2647-454b-b128-e483d6d280c0",
                ElementId: null,
                Extension: []
            },
            StatusElement: {
                Value: 2
            },
            Subject: {
                Referenceelement: {
                    Value: "Patient/Dicom"
                }
            },
            Series: [{
                UidElement: {
                    Value: "2.16.124.113543.5220.1154777499.30246.19789.3503430045.1"
                },
                Modality: {
                    CodeElement: {
                        Value: "XD"
                    }
                },
                Performer: [{
                    Actor: {
                        ReferenceElement: {
                            Value: "Practitioner/Pablo veredas"
                        }
                    }
                }]
            }]
        }),
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                }
            }
        });
}
export const getAllImaginStudies = async (result) => {
    const allImaginStudies = await fetch('/api/imagingstudy/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${result}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    // //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return allImaginStudies;
}

//////////////////////////////////////////////////// GetSingle ///////////////////////////////////////////////
export const getPatiendId = async (auth, idPatient) => {
    //f23abc41-fd12-4af7-8f01-fea4ff2fc5df
    const patient = await fetch(`/api/patient/getsingle/${idPatient}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return patient;
}

export const getPractitionerId = async (auth, idPractitioner) => {
    //9efafa84-8da9-47e1-9882-3154a42236b1
    const practitioner = await fetch(`/api/practitioner/getsingle/${idPractitioner}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return practitioner;
}

export const getPractitionerByCode = async (auth, idPractitioner) => {
    //9efafa84-8da9-47e1-9882-3154a42236b1
    if (!idPractitioner) {
        return null;
    }else{
        const practitioner = await fetch(`/api/practitioner/getsingle/Identifier/${idPractitioner}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`
            },
            mode: 'cors',
            cache: 'default'
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.error;
                }
            })
            .then((responseJson) => {
                if (responseJson) {
                    if (responseJson.error) {
                        //console.log(responseJson.error);
                    } else {
                        //console.log(responseJson);
                        return responseJson;
                    }
                }
            });
        return practitioner;
    }
}

export const getLocationId = async (auth, idLocation) => {
    //de9a7fb2-791e-40e6-a47a-1498c5a71587
    const location = await fetch(` /api/location/getsingle/${idLocation}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return location;
}

export const getEncounterId = async (auth, idEncounter) => {
    //8945b98f-c800-4999-81b2-49bab6dbce24
    const encounter = await fetch(`/api/encounter/getsingle/${idEncounter}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return encounter;
}

export const getAppointmentId = async (auth, idAppointment) => {
    //eb3d68e5-59fc-4792-9b8e-e1282733d15e
    const appointment = await fetch(`/api/appointment/getsingle/${idAppointment}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return appointment;
}

export const getValusetId = (auth) => {

    const valueSet = fetch('/api/valueset/getsingle/01cfe4b3-6700-4fc6-94bd-b6d25b580f19', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return valueSet;
}

export const getUserByMail = async (auth, email) => {
    const userByMail = await fetch(`/api/user/getbyemail/${email}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return userByMail;
}

export const getAppointmentsBySpeciality = async (auth, id) => {

    const appointments = await fetch(`/api/appointment/getbyspecialty/${id}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response && response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        })
    return appointments;

}

export const getEncounterByAppointment = async (auth, appointment) => {
    //0214dee0-c190-4fe7-8d44-b67b3eb2be35
    const encounter = await fetch(`/api/encounter/getbyappointment/${appointment}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return encounter;
}

export const getAllParameters = async (auth) => {
    const parameters = await fetch('/api/parameter/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    // //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return parameters;
}

export const getAllTemplates = async (auth) => {
    //console.log('template List: entrada ', auth)
    const templates = await fetch('/api/template/getall', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                    return responseJson;
                }
            }
        });
    //console.log('template List: templates ', templates);

    return templates;

}

export const createOrUpdateTemplate = async (auth, data) => {
    await fetch('/api/template/createorupdate', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        body: data,
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                    //return responseJson;
                }
            }
        });

}

export const getTemplateBySpecialty = async (auth, spec) => {
    //713
    const template = await fetch(`/api/template/getbyspecialty/${spec}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    ////console.log(responseJson);
                    return responseJson;
                }
            }
        });
    return template;
}

export const getTemplateByRowKey = async (auth, rowKey) => {
    // 1cab50d8-b28e-4bb0-b21f-53cc56a4fd9a
    const ficha = await fetch(`/api/template/gettemplate/${rowKey}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.error;
            }
        })
        .then((responseJson) => {
            if (responseJson) {
                if (responseJson.error) {
                    //console.log(responseJson.error);
                } else {
                    //console.log(responseJson);
                    return responseJson;
                }
            }
        });

    return ficha;
}

export const openViewer = async (patienID, AccessionNumber) => {
    const { AgfaToken, AgfaViewer, AgfaUser, AgfaPassword } = config;
    const response = await fetch(AgfaToken.replace("{0}", AgfaUser).replace("{1}", AgfaPassword).replace("{2}", AccessionNumber).replace("{3}", patienID), {
        method: 'get',
        mode: 'cors',
        cache: 'default'
    });

    if (!response.ok) throw new Error("Error al generar el token");

    let token = await response.text();
    let viewerURL = AgfaViewer.replace("{0}", token);
    window.open(viewerURL);
}

export const openAgnes = async (auth, location, agnesIdentifier) => {
    const { Agnes } = config;
    const response = await fetch("/api/location/getbylocation/portals/" + location, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        },
        mode: 'cors',
        cache: 'default'
    });

    if (!response.ok) throw new Error("Error al generar el token");

    let portal = await response.json();
    let siteURL = Agnes + portal.IdAgnes + "/" + agnesIdentifier;
    window.open(siteURL);
}

export const createNewComposition = async (auth, formData) => {

    await fetch('/api/composition/save/composition', {
        method: 'post',
        headers: {
            'Authorization': `Bearer ${auth}`,
        },
        body: formData
    })
        .then((response) => {
            if (response.ok) {
                return response;
            } else {
                return response.error;
            }
        })

}

export const historicPagination = async (auth, formData) => {
    try {
        const resp = await fetch('/api/Patient/get/compositions/of/patient/paginate', {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${auth}`,
            },
            body: formData
        })
        if(resp.status === 204) return;
        let patient = await resp.json();
        return patient;
    } catch (error) {
        console.error('Error: ', error);
    }
}


export const getStockMedications = async (auth, formData) => {
    let response = await fetch('/api/medication/get/stock/medications', {
        method: 'post',
        headers: {
            'Authorization': `Bearer ${auth}`,
        },
        body: formData
    });

    if (response.ok) {
        return response.text();
    }
}