import React, { useEffect, useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import { Template } from 'devextreme-react/core/template';
import './header.scss';
import { useDispatch } from 'react-redux';
import { setResponsive } from '../../../redux/actions/ui';
import { useHistory } from 'react-router-dom';


export default function Header ({ menuToggleEnabled, title, toggleMenu }) { 
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [width, setWidth] = useState('extralarge');
  const windowSingin = () => {
    if (window.innerWidth >= 1285){
      dispatch(setResponsive('extralarge'));
      setWidth('extralarge');
    } else if (window.innerWidth >= 1080 && window.innerWidth < 1285) {
      dispatch(setResponsive('large'));
      setWidth('large');
    } else if (window.innerWidth >= 768 && window.innerWidth < 1080) {
      dispatch(setResponsive('medium'));
      setWidth('medium');
    } else if (window.innerWidth >= 600 && window.innerWidth < 768) {
      dispatch(setResponsive('small'));
      setWidth('small');
    } else if (window.innerWidth < 600) {
      dispatch(setResponsive('extrasmall'));
      setWidth('extrasmall');
    }
    
    return window.innerWidth
  }

  const goBack = () => {
    history.goBack();
  }

  const goForward = () => {
    history.goForward();
  }

  useEffect(() => {
      windowSingin();
      window.addEventListener("resize", windowSingin);
      return () => window.removeEventListener("resize", windowSingin);
    }, []);

  
  return(
  <header className={'header-component'}>
    <Toolbar className={'header-toolbar'}>
      <Item
        visible={menuToggleEnabled}
        location={'before'}
        widget={'dxButton'}
        cssClass={'menu-button'}
      >
        <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
      </Item>
      <Item
        location={'before'}
        locateInMenu={'auto'}
        menuItemTemplate={'userPanelTemplate'}
      >
          <Button
          onClick={goBack}
          icon={'back'}
          >
          </Button>
          <Button
          icon={'chevronnext'}
          onClick={goForward}
          >
          </Button>
      </Item>
      {!["extrasmall", "small"].includes(width) && 
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
      }
      {/* <Template name={'userPanelTemplate'}>
        <UserPanel menuMode={'context'} />
      </Template> */}
    </Toolbar>
  </header>
)};

