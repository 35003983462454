import React, { Fragment, useState } from 'react';
import Button from 'devextreme-react/button';
import { formatMessage } from 'devextreme/localization';

function PanelMenu (props) {

    const handleDelete = () => {
        props.deleteEverything('all');
    }

    const handleSave = () => {
        props.saveData();
    }

    const handlOpen = () => {
        props.openData();
    }

    const handleAdd = () => {
        props.addData();
    }

    const [menuList, setMenuList] = useState([
        {id: 'add', display: 'Nuevo', icon: 'plus', func: handleAdd, active: false, disable: false},
        {id: 'save', display: 'Guardar', icon: 'save', func: handleSave, active: false, disable: false},
        {id: 'open', display: 'Abrir', icon: 'folder', func: handlOpen, active: false, disable: false},
        {id: 'revert', display: 'Deshacer', icon: 'revert', func: handleDelete, active: false, disable: false},
    ])

    const [tooltipVisible, setTooltipVisible] = useState('Controls') 

    const handleToogleTooltip = (e) => {
        switch(e.target.id){
            case 'add':
                return setTooltipVisible('Add')
            case 'save':
                return setTooltipVisible('Save')
            case 'open':
                return setTooltipVisible('Open')
            case 'revert':
                return setTooltipVisible('Revert')
        }
        
    } 

    const handleToogleTooltipOut = () => {
        setTooltipVisible('Controls');
    }

    
    
    return (
        <div className={'menu-panel-container'}>
            <div className={'menu-panel'}>
                {menuList.map ((item, key) => {
                    return (
                        <div id={item.id} key={key}
                            className={'menu-panel-item'}
                            onMouseEnter={handleToogleTooltip}
                            onMouseLeave={handleToogleTooltipOut}
                            onClick={item.func}
                            >
                            <Button
                                icon={item.icon} 
                                stylingMode="text"/>
                        </div>
                    )
                }) }          
            </div>
            <div className={'section-opened-text'}><div className={'opened-text'}>{formatMessage(tooltipVisible)}</div></div>
        </div>
        
    )
}

export default PanelMenu