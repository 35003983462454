import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './home.scss';
import AgendaIcon from '../../assets/img/icons/Agenda.jsx';
import HistorialIcon from '../../assets/img/icons/Historial.jsx';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/ui/footer/footer';
import { selectEspeciality } from '../../redux/actions/agenda';

export default function HomePage () {

  const history = useHistory();
  const dispatch = useDispatch();
  // const {auth} = useSelector(state => state)
  // //console.log(auth);
  const { name } = useSelector( state => state.auth );
  const { auth } = useSelector( state => state );
  //console.log('Auth: ', auth);
  function agendaPath() {
    //navigateToProfile('/agenda')
    history.push('/agenda');
  }

  function historialPath() {
    //navigateToProfile('/historial-clinico')
    history.push('/historial-clinico');
    } 
  
  useEffect(() => {
    dispatch(selectEspeciality({code: "", display: ""}));
  }, [])

//<a href="https://connect.amdagnes.com/dezeta-ad">AGNES INTERNAL</a>
//<a href="https://connect.amdagnes.com/dezeta-ad" target="_blank">AGNES NEW WINDOWS</a>

  return (
  <>
    <div className={'home-container '}>
      <div className={'top-frame'}></div>
      <div className={'home-content-container'}>
            <div className={'home-header-container'}>
              <h1 className={'content-block page-title roboto'}>Hola, { name?.split(' ')[0] } </h1>
              <div className="hero-message">¿Qué deseas ver?</div>
            </div>
            <div className={'home-options-container'}>
                <div onClick={agendaPath} className={'home-option'}>
                  <AgendaIcon />
                  <div className={'home-option-title'}>Agenda Médica</div>
                </div>
                <div onClick={historialPath} className={'home-option'}>
                  <HistorialIcon />
                  <div className={'home-option-title'}>Historial Clínico</div>
                </div>
            </div>
      </div>
      <Footer position={'fixed'} align={'center'} />
    </div>
  </>
)};
