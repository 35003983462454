import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../../../../../redux/actions/parametrizer.js';

import Diag from './LabsInputs/Diag.jsx';

import Button from 'devextreme-react/button';
import ManagementDataGrid from '../ManagementDataGrid.jsx'
import ManagementGeneralInput from '../ManagementGeneralInput.jsx';
import Services from './LabsInputs/Services.jsx';
import Indications from './LabsInputs/Indications.jsx';
import ServiceRequest from '../../../utils/fhir/serviceRequest.js';
import { sectionUpdated } from '../../../../../../redux/actions/fhir.js';
import { useParams } from 'react-router-dom';
import moment from "moment";

export default function LabsInputPanel(props) {

    var classNames = require('classnames');

    const dispatch = useDispatch();
    const $ = require('jquery');
    const { data } = useSelector(state => state.parametrizer);
    const { patient: patientID, practitioner: practitionerID } = useSelector(state => state.agenda.appointment);
    const { id: encounterId } = useParams();
    const { responsive } = useSelector(state => state.ui);
    const { fhir } = useSelector(state => state);
    const { info, getFocused, focused, readOnly, encounterInfo } = props;
    const [editingMode, setEditingMode] = useState(false);
    const [layout, setLayout] = useState({ columns: [], rows: 1, phantomItem: 0 });
    const [inputSizes, setInputSizes] = useState([]);
    const [maxSizeDefault, setMaxSizeDefault] = useState({ XL: info.size, L: (parseInt(info.size, 10)), M: (parseInt(info.size, 10)), S: 2, XS: 1 });
    const [formData, setFormData] = useState({});
    const [selected, setSelected] = useState({});
    const [toggle, setToggle] = useState(true);
    const [gridRef, setGridRef] = useState();
    const [current, setCurrent] = useState(true);

    useEffect(() => {
        sizeConvert();
        createObject();
        windowSingin();
    }, [])

    useEffect(() => {
        createObject();
    }, [info.id])

    const createObject = () => {
        let object = {};
        let status = {}
        info?.options?.forEach(opt => {
            object[opt.id] = {};
            status[opt.id] = false;
        })
        object.id = idGenerator();
        object.labSuggDate = {
            value: new Date().toString(),
            display: new Date().toLocaleDateString('es-CO') + " " + new Date().getHours() + ":" + new Date().getMinutes()
        }
        object.labPriority = { value: false, display: 'Rutinario' };        
        delete object['labQty']
        setFormData(prev => prev = object);
        setSelected(prev => prev = status);
        setEditingMode(false);
    }

    const idGenerator = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    const windowSingin = () => {
        switch (responsive) {
            case "extralarge":
                setInputSizes([1, 2, 3, 4, 5]);
                updateSize(5);
                break;

            case "large":
                setInputSizes([1, 2, 3, 4]);
                updateSize(4);
                break;

            case "medium":
                setInputSizes([1, 2, 3]);
                updateSize(3);
                break;

            case "small":
                setInputSizes([1, 2]);
                updateSize(2);
                break;

            default:
                setInputSizes([1]);
                updateSize(1);
                break;
        }
        layouSizeUpdater()

        return window.screen.width
    }

    const updateSize = (size) => {
        const sizeCon = {
            "extralarge": maxSizeDefault.XL,
            "large": maxSizeDefault.L,
            "medium": maxSizeDefault.M,
            "small": maxSizeDefault.S,
            "extrasmall": maxSizeDefault.XS,
        }

        handleChangeSize(sizeCon[Object.keys(sizeCon).find(el =>
            el === responsive
        )])

        if (info.size > size) {
            handleChangeSize(size)
        }
    }

    useEffect(() => {
        windowSingin();
    }, [responsive, info.id]);


    const sizeConvert = () => {
        switch (info.size) {
            case 1:
                setMaxSizeDefault({ XL: info.size, L: 1, M: 1, S: 2, XS: 1 });
                break;
            case 2:
                setMaxSizeDefault({ XL: info.size, L: 2, M: 2, S: 2, XS: 1 });
                break;
            case 3:
                setMaxSizeDefault({ XL: info.size, L: 2, M: 2, S: 2, XS: 1 });
                break;
            case 4:
                setMaxSizeDefault({ XL: info.size, L: 3, M: 2, S: 2, XS: 1 });
                break;
            default:
                setMaxSizeDefault({ XL: info.size, L: 4, M: 3, S: 2, XS: 1 });
                break;
        }
    }

    const handleChangeSize = size => {
        const newData = data;
        newData.some(data => {
            data.options.some(quest => {
                if (quest.id === info.id) {
                    quest.size = size
                }
            })
        })
        dispatch(setData(newData))
    }

    const layouSizeUpdater = () => {
        let columns = 0;
        let phantomItem = 0;
        const newData = data;
        newData.some(data => {
            data.options.some(quest => {
                if (quest.id === info.id) {
                    quest.options.some(option => {
                        columns += option.size
                    })
                }
            })
        })

        if (columns <= info.size) {
            phantomItem = (info.size - columns) / info.size
        } else {
            let phantomSize = 0;
            let phantomColumn = columns;
            while ((phantomColumn + phantomSize) % info.size !== 0) {
                phantomSize += 1;
            }
            phantomItem = phantomSize / info.size
        }
        setLayout({ columns: columns, rows: [1], phantomItem: phantomItem })
    }

    const handleOnChange = (data, key) => {
        setFormData(prev => ({ ...prev, [key]: data.target.value }))
    }

    const handleOnGeneralChange = (value, key) => {
        setFormData(prev => ({ ...prev, [key]: value }))
    }

    const openPanel = () => {
        setToggle(prev => prev = true);
        $(`#${info.id + "inputPanel"}`).slideDown(300);
        createObject();
    }

    const isCurrent = () => {
        setCurrent(prev => prev = true);
        openPanel();
    }

    const isPast = () => {
        setCurrent(prev => prev = false);
        closePanel();
    }

    const closePanel = () => {
        setToggle(prev => prev = false);
        $(`#${info.id + "inputPanel"}`).slideUp(300);
    }

    const createServiceRequest = (id) => {
        let newServiceRequest = new ServiceRequest();
        newServiceRequest.encounterID = encounterId ? encounterId : null;
        newServiceRequest.practitionerID = practitionerID ? practitionerID : null;
        newServiceRequest.patientID = patientID ? patientID : null;
        newServiceRequest.date = moment(new Date()).format('YYYY-MM-DD');
        newServiceRequest.note = formData?.labIndications?.display ? formData?.imgIndications?.display : "No hay nota";
        newServiceRequest.occurrence = moment(new Date(formData?.labSuggDate?.value)).format('YYYY-MM-DD');
        newServiceRequest.priority = formData?.labPriority?.value ? "urgent" : "routine";
        newServiceRequest.id = id;
        newServiceRequest.service = formData?.labService?.medicine;
        newServiceRequest.type = "lab";
        // newServiceRequest.quantity = parseInt(formData?.labQty?.value, 10);
        const index = fhir.map(f => { return (f?.title) }).indexOf("ServiceRequest");
        let mySection = fhir[index];
        if (!mySection?.entry.some(ent => { return ent?.identifier[0].value.includes(id) })) {
            mySection?.entry?.push(newServiceRequest.newServiceRequest);
        }
        dispatch(sectionUpdated(mySection));
    }

    const saveData = () => {
        createServiceRequest(formData.id);
        let currentList = props.data[info.id];
        currentList.push(formData);
        let dataGridSourceList = props.dataGrid[info.id];

        let dataGrid = {};

        Object.keys(formData).forEach(data => {
            if (data === "id") {
                dataGrid[data] = formData[data] ? formData[data] : ''
            } else {
                dataGrid[data] = formData[data]?.display ? formData[data]?.display : ''
            }
        })

        dataGridSourceList.push(dataGrid);

        gridRef?.refresh();
        props.getData(info.id, currentList);
        createObject();
        props.getDataGrid(info.id, dataGridSourceList);
    }

    const editData = () => {
        let newServiceRequest = new ServiceRequest();
        newServiceRequest.encounterID = encounterId ? encounterId : null;
        newServiceRequest.practitionerID = practitionerID ? practitionerID : null;
        newServiceRequest.patientID = patientID ? patientID : null;
        newServiceRequest.date = moment(new Date()).format('YYYY-MM-DD');
        newServiceRequest.note = formData?.imgIndications?.display ?  formData?.labIndications?.display : "No hay nota";
        newServiceRequest.occurrence = moment(new Date(formData?.labSuggDate?.value)).format('YYYY-MM-DD');
        newServiceRequest.priority = formData?.labPriority?.value ? "urgent" : "routine";
        newServiceRequest.id = formData?.id;
        newServiceRequest.service = formData?.labService?.medicine;
        newServiceRequest.type = "lab";
        // newServiceRequest.quantity = parseInt(formData?.labQty?.value, 10);
        const index = fhir.map(f => { return (f?.title) }).indexOf("ServiceRequest");
        let mySection = fhir[index];
        const entryIndex = mySection?.entry?.map(ent => {
            return (ent?.identifier[0]?.value)
        }).indexOf(formData?.id);

        mySection?.entry?.splice(entryIndex, 1, newServiceRequest.newServiceRequest);

        dispatch(sectionUpdated(mySection));

        let currentList = props.data[info.id];
        let dataGridSourceList = props.dataGrid[info.id];

        let dataGrid = {};

        Object.keys(formData).forEach(data => {
            if (data === "id") {
                dataGrid[data] = formData[data] ? formData[data] : ''
            } else {
                dataGrid[data] = formData[data]?.display ? formData[data]?.display : ''
            }
        })
        const deleteIndex = currentList.map(item => { return item.id })?.indexOf(formData.id);
        currentList.splice(deleteIndex, 1, formData);
        dataGridSourceList.splice(deleteIndex, 1, dataGrid);
        props.getData(info.id, currentList);
        props.getDataGrid(info.id, dataGridSourceList);
        gridRef?.refresh();
        createObject();
        setEditingMode(false);
    }

    const duplicateData = () => {
        let currentList = props.data[info.id];
        let currentData = formData;
        currentData.id = '_' + Math.random().toString(36).substr(2, 9)
        currentList.push(currentData);
        createServiceRequest(currentData.id);
        let dataGridSourceList = props.dataGrid[info.id];

        let dataGrid = {};

        Object.keys(formData).forEach(data => {
            if (data === "id") {
                dataGrid[data] = currentData.id;
            } else {
                dataGrid[data] = formData[data]?.display ? formData[data]?.display : ''
            }
        })

        dataGridSourceList.push(dataGrid);

        gridRef?.refresh();
        props.getData(info.id, currentList);
        createObject();
        props.getDataGrid(info.id, dataGridSourceList);
        setEditingMode(false);
    }

    const getEditItem = (e) => {
        openPanel();
        setEditingMode(true);
        let currentList = props.data[info.id];
        const editItem = (currentList.filter(item => { return item?.id === e })[0])
        if (editItem) {
            setFormData(prev => prev = JSON.parse(JSON.stringify(editItem)))
        }
    }

    const getDeleteItem = (e) => {
        let currentList = props.data[info.id];
        const deleteIndex = currentList.map(item => { return item.id })?.indexOf(e);
        currentList.splice(deleteIndex, 1);
    }

    const getGridRef = (e) => {
        setGridRef(e.current._instance)
    }

    useEffect(() => {
        layouSizeUpdater();
    }, [data, inputSizes, info.id])

    const inputClicked = (id) => {
        getFocused(id);
        let currentSelect = JSON.parse(JSON.stringify(selected));
        if (!currentSelect[id]) {
            currentSelect[id] = true;
            setSelected(currentSelect);
        }
    }

    const closeDropdown = (id) => {
        let currentSelect = JSON.parse(JSON.stringify(selected));
        currentSelect[id] = false;
        setSelected(currentSelect)
    }

    const openDropdown = (id) => {
        let currentSelect = selected;
        currentSelect[id] = true
    }

    const getData = (key, value) => {
        setFormData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (formData?.dose?.unique === true) {
            formData.freq = {};
            formData.term = {}
        }
    }, [formData?.dose?.unique])

    const disableItems = (id) => {
        if (formData?.labService) {
            if (id !== "labService") {
                return (!(Object.keys(formData?.labService).length > 0))
            }
        }
    }

    const canSave = () => {
        const totalKeys = Object.keys(formData);
        let values = [];
        totalKeys.forEach(key => {
            if (["id", "labIndications"].includes(key)) return
            switch (typeof formData[key]) {
                case "object":
                    values.push(Object.keys(formData[key]).length > 0)
                    break;
                case "number":
                    values.push(formData[key] > 0)
                    break;
                case "boolean":
                    values.push(true)
                    break;
                case "string":
                    values.push(formData[key] !== "")
                    break;
            }
        })
        if (values.includes(false)) {
            return true
        } else return false
    }


    const handleGetFocused = (e) => {
        const id = e.target.id.split("Input")[0];
        getFocused(id);
        if (selected[id]) return;
        inputClicked(id);
    }
    return (
        <div id={info.id+"FormContainer"} className={classNames('form-container-white')}>
            {!readOnly && <>
            <div className={'unique-add-btn-container'}>
                {/* <div className={'past-current-btn-group'}>
                    <Button
                        text={'Actuales'}
                        type={"success"}
                        stylingMode={current ? "contained" : 'outlined'}
                        onClick={isCurrent}
                    />
                    <Button
                        text={'Anteriores'}
                        type={"success"}
                        stylingMode={current ? "outlined" : 'contained'}
                        onClick={isPast}
                    />
                </div>
                <div className={'past-current-title'}>
                    {`${info.display}  ${current ? 'Actuales' : 'Anteriores'}`}
                </div> */}
                {current ? toggle ?
                    <Button
                        icon={'chevronup'}
                        stylingMode="text"
                        stylingMode={"outlined"}
                        onClick={closePanel}
                    />
                    :
                    <Button
                        text={'Nuevo'}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={openPanel}
                    />
                    : null
                }
            </div>

            <div id={info.id + "inputPanel"} className={'form-content'}
                style={{
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                }}
            >

                {(toggle ? current ? info.options : [] : []).map((option, key) => {
                    return (<Fragment key={key}>
                        {(option.canDrop) ?

                            <div id={option.id + 'InputContainer'}
                                className={classNames('text-input-container mgmt-input-container', { 'focused': focused === option.id }, 'input-size-' + option.size + '-' + info.size)}
                            >
                                <div className={classNames('text-input-title')}>
                                    <label className={classNames('mgmt-group-label')} htmlFor={option.id} >{option.display}</label>
                                </div>
                                <input
                                    id={option.id + "Input"}
                                    className={'text-input enter-jump mgmt-input mgmt-input-item'}
                                    type={"text"}
                                    required
                                    readOnly
                                    tabIndex={0}
                                    name={"section"}
                                    disabled={disableItems(option.id)}
                                    onFocus={handleGetFocused}
                                    onChange={!(disableItems(option.id)) ? (e) => handleOnChange(e, option.id) : null}
                                    value={formData[option.id]?.display ? formData[option.id]?.display : ""}
                                    onClick={!(disableItems(option.id)) ? () => inputClicked(option.id) : null}
                                    autoComplete={"off"}
                                >
                                </input>
                                {(selected[option.id] && option.id === "labService") &&
                                    <Services encounterInfo={encounterInfo} parent={option.id} opened={selected[option.id]} close={closeDropdown} open={openDropdown} getData={getData} data={formData.labService} getFocused={getFocused} />
                                }
                                {(selected[option.id] && option.id === "labDiag") &&
                                    <Diag parent={option.id} opened={selected[option.id]} close={closeDropdown} open={openDropdown} getData={getData} data={formData.labDiag} getFocused={getFocused} />
                                }
                                {(selected[option.id] && option.id === "labIndications") &&
                                    <Indications parent={option.id} opened={selected[option.id]} close={closeDropdown} open={openDropdown} getData={getData} data={formData.labIndications} getFocused={getFocused} />
                                }
                            </div>

                            :

                            <ManagementGeneralInput
                                info={option}
                                parentInfo={info}
                                focused={focused}
                                value={formData[option.id]?.display ? formData[option.id]?.display : ""}
                                onChange={handleOnGeneralChange}
                                onClick={inputClicked}
                                data={formData[option.id]}
                                disabled={disableItems(option.id)}
                            />
                        }
                    </Fragment>
                    )
                })}


                {
                    layout.phantomItem !== 0 && <div style={{ width: (layout.phantomItem * 99) + '%' }}></div>
                }
            </div>
            {toggle &&
                <div className={'unique-add-btn-container'}>
                    <div style={{ marginRight: '30px' }} className={classNames('mgmt-input mgmt-cancel-btn')} onClick={createObject}>
                        {editingMode ? 'CANCELAR' : 'DESHACER'}
                    </div>
                    {editingMode && <div style={{ marginRight: '30px' }} className={classNames('mgmt-input mgmt-cancel-btn')}>
                        <Button
                            text={'Duplicar'}
                            type={'normal'}
                            disabled={Object.values(formData).includes("")}
                            stylingMode={'outlined'}
                            onClick={duplicateData}
                        />
                    </div>
                    }
                    <Button
                        text={editingMode ? 'Guardar edición' : 'Agregar'}
                        type={'normal'}
                        disabled={canSave()}
                        stylingMode={'contained'}
                        onClick={editingMode ? editData : saveData}
                    />
                </div>
            }
            </>
            }
            <ManagementDataGrid info={info} dataSource={current ? props.dataGrid[info.id] : []} current={current} getGridRef={getGridRef} getEditItem={getEditItem} getDeleteItem={getDeleteItem} readOnly={readOnly}/>

        </div>
    );
}