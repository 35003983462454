import { createStore } from 'devextreme-aspnet-data';

export const getDataStore = (uri) => {
    const store = createStore({
        key: "Auto",
        loadUrl: uri,
        onBeforeSend: (method, options) => {
            const auth = sessionStorage.getItem('auth');
            options.headers = {
                "Authorization": `Bearer ${auth}`
            };
        }
    })
    return store;
}
