import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import { NumberBox } from 'devextreme-react/number-box';
import AddTable from './add/addTable';
import { parameterListConstructor } from '../../../redux/actions/parametrizer';
import { localPanel } from './../localPanel.js';


export default function AddPopup (props) {

    const { visible, 
            onHiding, 
            dragEnabled, 
            closeOnOutsideClick, 
            showTitle, 
            title,
            closePopup} = props

    const dispatch = useDispatch();
    const classNames = require('classnames');
    const { parametersList, totalData } = useSelector(state => state.parametrizer);
    const defaultSizes = {'search': 2, 'textarea': 5, 'select': 1, 'form': 5, 'boolean': 1}
    const defaultTypes = 
    [{id: 'search', display: 'Buscador'}, 
    {id: 'textarea', display: 'Área de texto'},
    {id: 'select', display: 'Selector'},
    {id: 'boolean', display: 'Booleano'}, 
    {id: 'form', display: 'Formulario'}];
    const defaultGenres = 
    [{id: 'male', display: 'Masculino'}, 
    {id: 'female', display: 'Femenino'},
    {id: 'both', display: 'Ambos'}
    ];
    const defaultBoolean = 
    [{id: true, display: 'Sí'}, 
    {id: false, display: 'No'}];

    const [showOptions, setShowOptions] = useState(false);

    const [currentSectionsList, setCurrentSectiosList] = useState([]);
    const [chosenSection, setChosenSection] = useState('');
    const [tipToggle, setTipToggle] = useState(false);
    const [newItem, setNewItem] = useState(
        {ID: '', 
        Display: '', 
        questionNumber: 0,
        Type: '', 
        Details: {
            size: 1, 
            canWrite: false,
            genre: 'both',
            dependency: {},
            dependsOn: '',
            expression: {},
            active: true,
            minAge: 0,
            maxAge: 120,
            maxChar: 1000,
            value: '',
            message: '',
            description: '',
            options: []
        }
    })

    const getTipToggle = (toggle) => {
        setTipToggle(toggle);
    }

    const currentSections = () => {
        let currentSections = [];
        const newData = totalData;
        newData.map((data) => {
            currentSections.push(data);
        })
        setCurrentSectiosList(currentSections)
    }

    const handleChoseSection = (section) => {
        setChosenSection(section);
    }

    const handlSelectType = (value) => {
        setNewItem({...newItem, Type: value, Details: {...newItem.Details, size: defaultSizes[value]}})
        if(value === 'textarea' || value === 'boolean') {
            setShowOptions(false)
        } else {
            setShowOptions(true)
        }
    }

    const handleOptionList = (options) => {
        setNewItem({...newItem, Details: {...newItem.Details, options: options}})
    }

    const handleOnChange = (data) => {
        setNewItem({...newItem, [data.element.id]: data.value})
        const specialParameter = {
            'Type': handlSelectType,
            'Hierarchy': handleChoseSection
        }
        if(Object.keys(specialParameter).includes(data.element.id)) {specialParameter[data.element.id](data.value)};
    }

    const handleOnDetailChaged = (data) => {
        setNewItem(prev => ({...newItem, detail: {...prev.detail, [data.element.id]: data.value}}));

    }

    const idAssigner = () => {
        const newData = totalData;
        let newId = '';
        newData.map((section) => {
            if(section.id === chosenSection) {
                newId = newItem.Display.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').split(" ").join("").slice(0,2) + newItem.Type + (Math.ceil(Math.random()*999))
            }
        })
        setNewItem({...newItem, ID: newId})
    }

    const itemSaver = () => {
        let newData = [...totalData];
        let newItemCopy = {...newItem};
        newItemCopy.Parent = chosenSection;
        newItemCopy.Hierarchy = 'question';
        if(newData.length > 0 ) {
            newData.forEach((data) => {
                if (data.id === chosenSection){
                    data.options.push(newItemCopy)
                } 
            })
        } else if (newData.length === 0) {
            newData.push({id: chosenSection, options: [newItemCopy]})
        }
        dispatch(parameterListConstructor(parametersList, newItemCopy));
        // setTotalData(newData)
        // createPanelTotal()
        closePopup()
        props.newItem(newItem, chosenSection);
    }

    const createPanelTotal = () => {
        const local = JSON.parse(JSON.stringify(localPanel));
        let totalSections = [];
        const remote = JSON.parse(localStorage.getItem('panel'))
        let totalOptions = [];
        
        local.map( localSection => {
            totalSections.push(localSection)
        })
        // totalSections.map( totalSection => {
        //     remote.map( remoteSection => {
        //         if(totalSection.id === remoteSection.id){
        //             totalOptions = (totalSection.options);
        //             totalSection.options = totalOptions.concat(remoteSection.options)
        //         }
        //     })
        // })
        // dispatch(setTotalData(totalSections));
        totalSections = [];
        totalOptions = [];
    }

    useEffect(() => {
        createPanelTotal();
    },[])

    useEffect(() => {
        idAssigner()
    },[chosenSection])

    useEffect(() => {
        currentSections()
    },[props])

    const heigthCalculator = () => {
        let selector;
        if(showOptions) {
            selector = "options"
        } else if (newItem.type === "boolean") 
            {
                selector = "boolean"   
            } else {
                selector = "normal"
            }
        switch(selector) {
            case "normal":
                return 400
            case "boolean":
                return 470
            case "options":
                return 750
        }
    }
    return (
        <Popup
            visible={visible}
            onHiding={onHiding}
            dragEnabled={dragEnabled}
            closeOnOutsideClick={closeOnOutsideClick}
            showTitle={showTitle}
            title={title}
            height={heigthCalculator()}
            >
            <div className={'cancel-popup'}>
                <div className={'popup-content'}> 
                    <div className={'popup-id-title'}>
                        <div className={'popup-group'}>
                            <div className={'popup-title'}>
                                Nombre
                            </div>
                            <TextBox 
                                id={'Display'}
                                placeholder={newItem.Display}
                                onValueChanged={handleOnChange}
                            />
                        </div>
                        <div className={'popup-id-title'}>
                            <div className={'popup-group'}>
                                <div className={'popup-title'}>
                                        Código
                                </div>
                                <NumberBox 
                                        id={'questionNumber'}
                                        min={0}
                                        placeholder={newItem.questionNumber.toString()}
                                        onValueChanged={handleOnDetailChaged}
                                    />
                            </div>
                            <div className={'popup-group'}>
                                <div className={'popup-title'}>
                                        Género
                                </div>
                                <SelectBox
                                    id={'genre'}
                                    dataSource={defaultGenres}
                                    searchEnabled={true}
                                    searchMode={'contains'}
                                    searchExpr={'display'}
                                    searchTimeout={200}
                                    displayExpr="display"
                                    defaultValue={newItem.genre}
                                    onValueChanged={handleOnDetailChaged}
                                    valueExpr="id"/>
                            </div>
                        </div>
                        
                    </div>
                    <div className={'popup-id-title'}>
                        <div className={'popup-id-title w-half'}>
                            <div className={'popup-group'}>
                                <div className={'popup-title'}>
                                    Sección a asociar
                                </div>
                                <SelectBox 
                                    id={'Hierarchy'}
                                    dataSource={currentSectionsList}
                                    searchEnabled={true}
                                    searchMode={'contains'}
                                    searchExpr={'display'}
                                    searchTimeout={200}
                                    displayExpr="display"
                                    onValueChanged={handleOnChange}
                                    valueExpr="id" />
                            </div>
                            <div className={'popup-group'}>
                                    <div className={'popup-title'}>
                                        Tipo
                                    </div>
                                    <SelectBox 
                                        id={'Type'}
                                        dataSource={defaultTypes}
                                        searchEnabled={true}
                                        searchMode={'contains'}
                                        searchExpr={'display'}
                                        searchTimeout={200}
                                        displayExpr="display"
                                        onValueChanged={handleOnChange}
                                        valueExpr="id"/>
                            </div>
                            {newItem.type === "textarea" && 
                                <div className={'popup-group'}>
                                    <div className={'popup-title'}>
                                        Máximos Caracteres
                                    </div>
                                    <NumberBox 
                                        id={'maxChar'}
                                        min={0}
                                        placeholder={newItem.maxChar.toString()}
                                        onValueChanged={handleOnDetailChaged}
                                    />
                                </div>}
                        </div>
                        <div className={'popup-id-title w-half'}>
                            <div className={'popup-group'}>
                                <div className={'popup-title'}>
                                    Edad mínima
                                </div>

                                <NumberBox
                                    id={'minAge'}
                                    min={0}
                                    max={newItem.maxAge}
                                    defaultValue={0}
                                    showSpinButtons={true}
                                    onValueChanged={handleOnDetailChaged}
                                />
                            </div>
                            <div className={'popup-group'}>
                                <div className={'popup-title'}>
                                    Edad máxima
                                </div>
                                <NumberBox
                                    id={'maxAge'}
                                    min={newItem.minAge}
                                    max={120}
                                    defaultValue={120}
                                    showSpinButtons={true}
                                    onValueChanged={handleOnDetailChaged}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'popup-id-title'}>
                        
                    </div>

                    
                    {newItem.type === "boolean" &&
                        <div className={'popup-id-title'}>
                            <div className={'popup-group value'}>
                                <div className={'popup-title'}>
                                    Valor default
                                </div>
                                <SelectBox 
                                    items={defaultBoolean} 
                                    displayExpr="display"
                                    onValueChanged={handleOnDetailChaged}
                                    valueExpr="id"/>
                            </div>
                            <div className={'popup-group value'}>
                                <div className={'popup-title'}>
                                    Comentable
                                </div>
                                <SelectBox 
                                    items={defaultBoolean} 
                                    displayExpr="display"
                                    onValueChanged={handleOnDetailChaged}
                                    valueExpr="id"/>
                            </div>
                            {newItem.canWrite ?
                                    <div className={'popup-group value'}>
                                        <div className={'popup-title'}>
                                            C. default
                                        </div>
                                        <SelectBox 
                                            items={defaultBoolean} 
                                            displayExpr="display"
                                            onValueChanged={handleOnDetailChaged}
                                            valueExpr="id"/>
                                    </div> :
                                    <div className={'popup-group value'}>
                                    </div>
                            }
                            {newItem.canWriteDefault && newItem.canWrite ?
                                <div className={'popup-group'}>
                                    <div className={'popup-title'}>
                                        Comentario default
                                    </div>
                                    <TextBox 
                                        placeholder={newItem.display}
                                        onValueChanged={handleOnDetailChaged}
                                        />
                                </div> :
                                <div className={'popup-group'}>
                                </div>
                            } 
                        </div>
                    }

                    {showOptions &&
                        <div className={'popup-group'}>
                                <div className={'popup-title'}>
                                    Opciones
                                </div>
            
                                <div className={classNames('popup-alert', tipToggle ? 'on' : 'off')}>
                                    <li className={'dx-link dx-link-tips dx-icon-tips dx-link-icon'}></li> 
                                    Es necesario dar <b> click </b> a<li className={'dx-link dx-link-save dx-icon-save dx-link-icon'}></li> 
                                    para que el elemento se <b> guarde correctamente </b>.
                                </div>
                            
                                <AddTable dataSource={newItem.Details.options} parentType={newItem.type} types={defaultTypes} updateOptions={handleOptionList} tipToggle={getTipToggle}/>
                        </div>
                    }
                </div>

                <div className={'cancel-buttons'}>
                    <Button
                    icon={'add'}
                    text="Local"
                    type="success"
                    stylingMode="contained"
                    onClick={itemSaver}                        
                    />
                    <Button
                    icon={'upload'}
                    text="Nube"
                    type="success"
                    stylingMode="contained"
                    onClick={itemSaver}                        
                    />
                    <Button
                    text="Cancelar"
                    type="normal"
                    stylingMode="outlined"
                    onClick={closePopup}
                    /> 
                </div>
            </div>
        </Popup>
    )
} 