import { combineReducers } from 'redux';
import { agendaReducer } from './agendaReducer';
import { authReducer } from './authReducer';
import { dashboardReducer } from './dashboardReducer';
import { fhirReducer } from './fhirReducer';
import { hcDataReducer } from './hcDataReducer';
import { parametrizerReducer } from './parametrizerReducer';
import { patientReducer } from './patientReducer';
import { uiReducer } from './uiReducer';
import { clinicHistoryReducer } from './clinicHistoryReducer';
import { mgmtPlanReducer } from './mgmtPlanReducer';
import { autoSaveReducer } from './autoSaveReducer';
import { productivityReducer } from './productivityReducer';
import { validationReducer } from './validationsReducer';

export const rootReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    parametrizer: parametrizerReducer,
    agenda: agendaReducer,
    patient: patientReducer,
    hcData: hcDataReducer,
    fhir: fhirReducer,
    dashboard: dashboardReducer,
    clinicHistory: clinicHistoryReducer,
    managmentPlan: mgmtPlanReducer,
    autoSave: autoSaveReducer,
    productivityPanel: productivityReducer,
    validation: validationReducer,
})