import React from 'react';
import { Redirect, Route, Switch, useHistory} from 'react-router-dom';

import { ParameterPage, AgendaPage, HistorialPage, HomePage, MC, AgendaFilterPage, HistorialClinicoPage, Preview } from '../pages';

//Prueba
import { SideNavInnerToolbar as SideNavBarLayout } from '../layouts';
import appInfo from '../app-info';
import EncounterPage from '../pages/agenda/encounter';

import { ProductivityPanel } from '../pages/productivityPanel/Dashboard';
import ErrorBoundaryWrapper from '../errors/ErrorBoundary';
//import { ParameterPage } from '../pages/parametrizer/parametrizer';

export const DashboardRoutes = () => {
    let history = useHistory();
    // console.log(history.location);
    const userType = localStorage.getItem('type');
    ////console.log(typeUser);
    return (
        <ErrorBoundaryWrapper >
            <Switch>
                <Route exact path="/" component={HomePage} />                   

                <Route exact path="/historial-clinico" >
                    <SideNavBarLayout title = { appInfo.title }>
                        <HistorialPage />
                    </SideNavBarLayout>
                </Route>

                <Route exact path="/panel-productividad" >
                    <SideNavBarLayout title = { appInfo.title }>
                        <ProductivityPanel />
                    </SideNavBarLayout>
                </Route>

                <Route exact path="/agenda" render= {
                (props) => 
                    <SideNavBarLayout title = { appInfo.title }>
                        <AgendaFilterPage {...props}/>
                    </SideNavBarLayout>
                }/>

                <Route exact path="/historial-clinico/:id" render= {
                (props) => 
                    <SideNavBarLayout title = { appInfo.title }>
                        <HistorialClinicoPage {...props} />
                        {/* <EncounterPage {...props}/> */}
                    </SideNavBarLayout>
                }/>

                <Route exact path="/preview/:fromType" render= {
                (props) => 
                    <SideNavBarLayout title = { appInfo.title }>
                        <Preview {...props} />
                    </SideNavBarLayout>
                }/>

                <Route exact path="/encuentro/:id" render= {
                (props) => 
                    <SideNavBarLayout title = { appInfo.title }>
                        <EncounterPage {...props}/>
                    </SideNavBarLayout>
                }/>

                <Route exact path="/encuentro/:id/:spec" render= {
                (props) => 
                    <SideNavBarLayout title = { appInfo.title }>
                        <MC {...props} showAgnesButton={true}/>
                    </SideNavBarLayout>
                }/>

                <Route exact path="/parametrizer" >
                    <SideNavBarLayout title = { appInfo.title }>
                        {
                            (userType === '2') ? <ParameterPage />
                                    : <Redirect to="/home"/> 
                        }
                    </SideNavBarLayout>
                </Route> 
                <Redirect to="/" />
            </Switch>
        </ErrorBoundaryWrapper>
    )
}
