import { types } from "../types/types";



export const mgmtPlanReducer = (state = {}, action) => {

    switch (action.type) {
        case types.setImageServices:
            return {
                ...state,
                imgServices: action.payload.imgServices
            }

        case types.setLabServices:
            return {
                ...state,
                labServices: action.payload.labServices
            }

        case types.setFrequences:
            return {
                ...state,
                frequences: action.payload.frequences
            }

        case types.setMedicationFhir:
            return {
                ...state,
                medicationFhir: action.payload.medicationFhir
            }
        case types.setPractitionersFhir:
            return {
                ...state,
                practitionersFhir: action.payload.practitionerFhir
            }
        case types.setDiagnosticsData:
            return {
                ...state,
                diagnosticsData: action.payload.diagnosticsData
            }
        case types.setMedicationStock:
            return {
                ...state,
                medicationStock: action.payload.medication
            }

        default:
            return state;
    }
}
