import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../../../../../redux/actions/parametrizer.js';

import Button from 'devextreme-react/button';
import ManagementDataGrid from '../ManagementDataGrid.jsx'
import Doses from './PrescriptonsInputs/Doses.jsx';
import Diag from './PrescriptonsInputs/Diag.jsx';
import Freq from './PrescriptonsInputs/Freq.jsx';
import Term from './PrescriptonsInputs/Term.jsx';
import Indications from './PrescriptonsInputs/Indications';
import ManagementDropGrid from './PrescriptonsInputs/ManagementDropGrid.jsx';
import { sectionUpdated } from '../../../../../../redux/actions/fhir.js';
import MedicationRequest from '../../../utils/fhir/medicationRequest.js';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ManagementGeneralInput from '../ManagementGeneralInput.jsx';

export default React.memo(function PrescriptionsPlanInputPanel(props) {

    var classNames = require('classnames');
    const dispatch = useDispatch();
    const $ = require('jquery');
    const { data } = useSelector(state => state.parametrizer)
    const { responsive } = useSelector(state => state.ui)
    const { fhir } = useSelector(state => state)
    const { patient: patientID, practitioner: practitionerID } = useSelector(state => state.agenda.appointment);
    const [value, setValue] = useState(0);
    const medicationStock = useSelector(state => state?.managmentPlan?.medicationStock);

    const { id: encounterId } = useParams();
    const { info, getFocused, focused, readOnly, encounterInfo } = props;
    const [editingMode, setEditingMode] = useState(false);
    const [layout, setLayout] = useState({ columns: [], rows: 1, phantomItem: 0 });
    const [inputSizes, setInputSizes] = useState([]);
    const [maxSizeDefault, setMaxSizeDefault] = useState({ XL: info.size, L: (parseInt(info.size, 10)), M: (parseInt(info.size, 10)), S: 2, XS: 1 });
    const [canSave, setCanSave] = useState(false);
    const [formData, setFormData] = useState({});
    const [display, setDisplay] = useState('');
    const [selected, setSelected] = useState({});
    const [toggle, setToggle] = useState(true);
    const [gridRef, setGridRef] = useState();
    const [current, setCurrent] = useState(true);

    useEffect(() => {
        sizeConvert();
        createObject();
        windowSingin();
    }, [])

    useEffect(() => {
        createObject();
    }, [info.id])

    const createObject = () => {
        let object = {};
        let status = {}
        info?.options?.forEach(opt => {
            object[opt.id] = {};
            status[opt.id] = false;
        })
        object.id = idGenerator();
        setFormData(prev => prev = object);
        setSelected(prev => prev = status);
        setEditingMode(false);
    }

    const idGenerator = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    const windowSingin = () => {
        switch (responsive) {
            case "extralarge":
                setInputSizes([1, 2, 3, 4, 5]);
                updateSize(5);
                break;

            case "large":
                setInputSizes([1, 2, 3, 4]);
                updateSize(4);
                break;

            case "medium":
                setInputSizes([1, 2, 3]);
                updateSize(3);
                break;

            case "small":
                setInputSizes([1, 2]);
                updateSize(2);
                break;

            default:
                setInputSizes([1]);
                updateSize(1);
                break;
        }
        layouSizeUpdater()

        return window.screen.width
    }

    const updateSize = (size) => {
        const sizeCon = {
            "extralarge": maxSizeDefault.XL,
            "large": maxSizeDefault.L,
            "medium": maxSizeDefault.M,
            "small": maxSizeDefault.S,
            "extrasmall": maxSizeDefault.XS,
        }

        handleChangeSize(sizeCon[Object.keys(sizeCon).find(el =>
            el === responsive
        )])

        if (info.size > size) {
            handleChangeSize(size)
        }
    }

    useEffect(() => {
        windowSingin();
    }, [responsive, info.id]);


    const sizeConvert = () => {
        switch (info.size) {
            case 1:
                setMaxSizeDefault({ XL: info.size, L: 1, M: 1, S: 2, XS: 1 });
                break;
            case 2:
                setMaxSizeDefault({ XL: info.size, L: 2, M: 2, S: 2, XS: 1 });
                break;
            case 3:
                setMaxSizeDefault({ XL: info.size, L: 2, M: 2, S: 2, XS: 1 });
                break;
            case 4:
                setMaxSizeDefault({ XL: info.size, L: 3, M: 2, S: 2, XS: 1 });
                break;
            default:
                setMaxSizeDefault({ XL: info.size, L: 4, M: 3, S: 2, XS: 1 });
                break;
        }
    }

    const handleChangeSize = size => {
        const newData = data;
        newData.some(data => {
            data.options.some(quest => {
                if (quest.id === info.id) {
                    quest.size = size
                }
            })
        })
        dispatch(setData(newData))
    }

    const layouSizeUpdater = () => {
        let columns = 0;
        let phantomItem = 0;
        const newData = data;
        newData.some(data => {
            data.options.some(quest => {
                if (quest.id === info.id) {
                    quest.options.some(option => {
                        columns += option.size
                    })
                }
            })
        })

        if (columns <= info.size) {
            phantomItem = (info.size - columns) / info.size
        } else {
            let phantomSize = 0;
            let phantomColumn = columns;
            while ((phantomColumn + phantomSize) % info.size !== 0) {
                phantomSize += 1;
            }
            phantomItem = phantomSize / info.size
        }
        setLayout({ columns: columns, rows: [1], phantomItem: phantomItem })
    }

    const handleOnChange = (data, key) => {
        setFormData(prev => ({ ...prev, [key]: { display: data.target.value } }))
    }

    const handleOnGeneralChange = (value, key) => {
        setFormData(prev => ({ ...prev, [key]: value }))
    }

    const openPanel = () => {
        setToggle(prev => prev = true);
        $(`#${info.id + "inputPanel"}`).slideDown(300);
        createObject();
    }

    const isCurrent = () => {
        setCurrent(prev => prev = true);
        openPanel();
    }

    const isPast = () => {
        setCurrent(prev => prev = false);
        closePanel();
    }

    const closePanel = () => {
        setToggle(prev => prev = false);
        $(`#${info.id + "inputPanel"}`).slideUp(300);
    }

    const qtyDic = {
        'd': 24,
        'wk': 24 * 7,
        'mo': 730,
        'a': 8760
    }

    const createMedicationRequest = (id) => {
        const newMedicationRequest = new MedicationRequest();
        newMedicationRequest.encounterID = encounterId;
        newMedicationRequest.practitionerID = practitionerID;
        newMedicationRequest.patientID = patientID;
        newMedicationRequest.date = moment(new Date()).format('YYYY-MM-DD');
        newMedicationRequest.note = formData?.resume?.display ? formData?.resume?.display : "No hay nota";
        newMedicationRequest.occurrence = moment(new Date(formData?.freq?.start)).format('YYYY-MM-DD');
        newMedicationRequest.id = id;
        newMedicationRequest.type = formData?.medicines?.type;
        newMedicationRequest.frequency = { code: formData?.freq?.code.split("-")[0], display: formData?.freq?.display, time: (parseInt(formData?.freq?.code.split("-")[1]) / 60) };
        newMedicationRequest.term = parseInt(formData?.term?.quantity) * qtyDic[formData?.term?.unit];
        newMedicationRequest.quantity = formData?.dose.display;
        newMedicationRequest.service = { code: formData?.medicines?.medicine?.code, display: formData?.medicines?.medicine?.display };
        const index = fhir.map(f => { return (f?.title) }).indexOf("MedicationRequest");
        let mySection = fhir[index];
        if (!mySection?.entry.some(ent => { return ent?.identifier[0].value.includes(id) })) {
            mySection?.entry?.push(newMedicationRequest.newMedicationRequest);
        }
        dispatch(sectionUpdated(mySection));
    }

    const saveData = () => {
        let currentList = props.data[info.id];
        currentList.push(formData);
        let dataGridSourceList = props.dataGrid[info.id];

        let dataGrid = {};

        Object.keys(formData).forEach(data => {
            if (data === "id") {
                dataGrid[data] = formData[data] ? formData[data] : ''
            } else {
                dataGrid[data] = formData[data]?.display ? formData[data]?.display : ''
            }
        })

        dataGridSourceList.push(dataGrid);
        createMedicationRequest(formData?.id);
        gridRef?.refresh();
        props.getData(info.id, currentList);
        createObject();
        closePanel();
        props.getDataGrid(info.id, dataGridSourceList);
    }

    const editData = () => {

        let newMedicationRequest = new MedicationRequest();
        newMedicationRequest.encounterID = encounterId;
        newMedicationRequest.practitionerID = practitionerID;
        newMedicationRequest.patientID = patientID;
        newMedicationRequest.date = moment(new Date()).format('YYYY-MM-DD');
        newMedicationRequest.note = formData?.resume?.display ? formData?.resume?.display : "No hay nota";
        newMedicationRequest.occurrence = moment(new Date(formData?.freq?.start)).format('YYYY-MM-DD');
        newMedicationRequest.id = formData?.id;
        newMedicationRequest.type = formData?.medicines?.type;
        newMedicationRequest.frequency = { code: formData?.freq?.code.split("-")[0], display: formData?.freq?.display, time: (parseInt(formData?.freq?.code.split("-")[1]) / 60) };
        newMedicationRequest.quantity = formData?.dose.display;
        newMedicationRequest.term = parseInt(formData?.term?.quantity) * qtyDic[formData?.term?.unit];
        newMedicationRequest.service = { code: formData?.medicines?.medicine?.code, display: formData?.medicines?.medicine?.display };
        const index = fhir.map(f => { return (f?.title) }).indexOf("MedicationRequest");
        let mySection = fhir[index];
        const entryIndex = mySection?.entry?.map(ent => {
            return (ent?.identifier[0]?.value)
        }).indexOf(formData?.id);

        mySection?.entry?.splice(entryIndex, 1, newMedicationRequest.newMedicationRequest);

        dispatch(sectionUpdated(mySection));



        let currentList = props.data[info.id];
        let dataGridSourceList = props.dataGrid[info.id];

        let dataGrid = {};

        Object.keys(formData).forEach(data => {
            if (data === "id") {
                dataGrid[data] = formData[data] ? formData[data] : ''
            } else {
                dataGrid[data] = formData[data]?.display ? formData[data]?.display : ''
            }
        })
        const deleteIndex = currentList.map(item => { return item.id })?.indexOf(formData.id);
        currentList.splice(deleteIndex, 1, formData);
        dataGridSourceList.splice(deleteIndex, 1, dataGrid);
        props.getData(info.id, currentList);
        props.getDataGrid(info.id, dataGridSourceList);
        gridRef?.refresh();
        createObject();
        closePanel();
        setEditingMode(false);
    }

    const duplicateData = () => {
        let currentList = props.data[info.id];
        let currentData = formData;
        currentData.id = '_' + Math.random().toString(36).substr(2, 9)
        currentList.push(currentData);
        let dataGridSourceList = props.dataGrid[info.id];

        let dataGrid = {};

        Object.keys(formData).forEach(data => {
            if (data === "id") {
                dataGrid[data] = currentData.id;
            } else {
                dataGrid[data] = formData[data]?.display ? formData[data]?.display : ''
            }
        })

        dataGridSourceList.push(dataGrid);
        createMedicationRequest(currentData.id);
        gridRef?.refresh();
        props.getData(info.id, currentList);
        createObject();
        closePanel();
        props.getDataGrid(info.id, dataGridSourceList);
        setEditingMode(false);
    }

    const getEditItem = (e) => {
        openPanel();
        setEditingMode(true);
        let currentList = props.data[info.id];
        const editItem = (currentList.filter(item => { return item?.id === e })[0])
        if (editItem) {
            setFormData(prev => prev = JSON.parse(JSON.stringify(editItem)))
        }
    }

    const getDeleteItem = (e) => {
        let currentList = props.data[info.id];
        const deleteIndex = currentList.map(item => { return item.id })?.indexOf(e);
        currentList.splice(deleteIndex, 1);
    }

    const getGridRef = (e) => {
        setGridRef(e.current._instance)
    }

    useEffect(() => {
        layouSizeUpdater();
    }, [data, inputSizes, info.id])

    const inputClicked = (id) => {
        getFocused(id);
        let currentSelect = JSON.parse(JSON.stringify(selected));
        if (!currentSelect[id]) {
            currentSelect[id] = true;
            setSelected(currentSelect);
        }
    }

    const closeDropdown = (id) => {
        let currentSelect = JSON.parse(JSON.stringify(selected));
        currentSelect[id] = false;
        setSelected(currentSelect)
    }

    const openDropdown = (id) => {
        let currentSelect = selected;
        currentSelect[id] = true
    }

    const getData = (key, value) => {
        setFormData(prev => ({ ...prev, [key]: value }))
    }

    const generateDisplay = () => {
        const disp =
            (formData.dose?.display ? `${formData.dose?.display} ` : '') + " " +
            (formData.medicines?.display ? formData.medicines?.display : '') +
            (formData.freq?.start ? `, INICIA ${moment(formData.freq?.start).fromNow().toUpperCase()}` : '') + " " +
            (formData.freq?.display ? formData.freq?.display : '') + " " +
            (formData.term?.display ? formData.term?.display : '') + " " +
            (formData.indications?.display ? `| NOTA:  ${formData.indications?.display}` : '');
        let currentData = formData;
        if (currentData['resume']) {
            currentData['resume']['display'] = disp;
        }
        setDisplay(prev => prev = disp);
    }

    useEffect(() => {
        generateDisplay();
        handleCanSave();
    }, [JSON.stringify(formData), JSON.stringify(medicationStock)])

    const disableItems = (id) => {
        if (id === "medicines") {
            return false
        }
        else {
            // return true
            if (formData.medicines?.display) {
                return false
            } else {
                return true
            }
        }
    }

    const handleCanSave = () => {
        const totalKeys = Object.keys(formData);
        let values = [];
        totalKeys.forEach(key => {
            if (key !== "id" && key !== "indications") {
                values.push(Object.keys(formData[key]).length > 0)
            }
        })
        if (values.includes(false)) {
            setCanSave(true)
        } else setCanSave(false)

    }

    const handleGetFocused = (e) => {
        const id = e.target.id.split("Input")[0];
        getFocused(id);
        if (selected[id]) return;
        inputClicked(id);
    }

    return (
        <div id={info.id + "FormContainer"} className={classNames('form-container-white')}>
            {!readOnly &&
                <>
                    <div className={'unique-add-btn-container'}>
                        {/* <div className={'past-current-btn-group'}>
                    <Button
                        text={'Actuales'}
                        type={"success"}
                        stylingMode={current ? "contained" : 'outlined'}
                        onClick={isCurrent}
                    />
                    <Button
                        text={'Anteriores'}
                        type={"success"}
                        stylingMode={current ? "outlined" : 'contained'}
                        onClick={isPast}
                    />
                </div> */}
                        {/* <div className={'past-current-title'}>
                    {`${info.display}  ${current ? 'Actuales' : 'Anteriores'}`}
                </div> */}
                        {current ? toggle ?
                            <Button
                                icon={'chevronup'}
                                stylingMode="text"
                                stylingMode={"outlined"}
                                onClick={closePanel}
                            />
                            :
                            <Button
                                text={'Nuevo'}
                                type={"success"}
                                stylingMode={"contained"}
                                onClick={openPanel}
                            />
                            : null
                        }
                    </div>

                    <div id={info.id + "inputPanel"} className={'form-content'}
                        style={{
                            display: 'inline-flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                        }}
                    >

                        {(toggle ? current ? info.options : [] : []).map((option, key) => {
                            return (<Fragment key={key}>
                                {option.canDrop
                                    ?
                                    <div id={option.id + 'InputContainer'}
                                        className={classNames('text-input-container mgmt-input-container', { 'focused': focused === option.id }, 'input-size-' + option.size + '-' + info.size)}
                                    >
                                        <div className={classNames('text-input-title')}>
                                            <label className={classNames('mgmt-group-label', { 'disabled': (disableItems(option.id)) })} htmlFor={option.id} >{option.display}</label>
                                        </div>
                                        <input
                                            id={option.id + "Input"}
                                            className={classNames('text-input enter-jump mgmt-input mgmt-input-item', { 'disabled': (disableItems(option.id)) })}
                                            type={"text"}
                                            required
                                            name={"section"}
                                            readOnly={true}
                                            tabIndex={0}
                                            onFocus={handleGetFocused}
                                            disabled={(disableItems(option.id))}
                                            // onChange={(e) => handleOnChange(e, option.id)}
                                            value={formData[option.id]?.display ? formData[option.id]?.display : ""}
                                            // onClick={!(disableItems(option.id)) ? () => inputClicked(option.id) : null}
                                            autoComplete={"off"}
                                        >
                                        </input>
                                        {(selected[option.id] && option.id === "dose") &&
                                            <Doses parent={option.id} opened={selected[option.id]} close={closeDropdown} open={openDropdown} getData={getData} data={formData.dose} getFocused={getFocused} />
                                        }
                                        {(selected[option.id] && option.id === "freq") &&
                                            <Freq parent={option.id} opened={selected[option.id]} close={closeDropdown} open={openDropdown} getData={getData} data={formData.freq} getFocused={getFocused} />
                                        }
                                        {(selected[option.id] && option.id === "term") &&
                                            <Term parent={option.id} opened={selected[option.id]} close={closeDropdown} open={openDropdown} getData={getData} data={formData.term} getFocused={getFocused} />
                                        }
                                        {(selected[option.id] && option.id === "indications") &&
                                            <Indications parent={option.id} opened={selected[option.id]} close={closeDropdown} open={openDropdown} getData={getData} data={formData.indications} getFocused={getFocused} />
                                        }
                                        {(selected[option.id] && option.id === "medicines") &&
                                            <ManagementDropGrid encounterInfo={encounterInfo} parent={option.id} opened={selected[option.id]} close={closeDropdown} open={openDropdown} getData={getData} data={formData.medicines} getFocused={getFocused} />
                                        }
                                    </div>
                                    :
                                    <ManagementGeneralInput
                                        info={option}
                                        parentInfo={info}
                                        focused={focused}
                                        value={formData[option.id]?.display ? formData[option.id]?.display : ""}
                                        onChange={handleOnGeneralChange}
                                        onClick={inputClicked}
                                        data={formData[option.id]}
                                        disabled={disableItems(option.id)}
                                        display={display}
                                    />
                                }
                            </Fragment>
                            )
                        })}


                        {
                            layout.phantomItem !== 0 && <div style={{ width: (layout.phantomItem * 99) + '%' }}></div>
                        }
                    </div>
                    {toggle &&
                        <div className={'unique-add-btn-container'}>
                            <div style={{ marginRight: '30px' }} className={classNames('mgmt-input mgmt-cancel-btn')} onClick={createObject}>
                                {editingMode ? 'CANCELAR' : 'DESHACER'}
                            </div>
                            {editingMode && <div style={{ marginRight: '30px' }} className={classNames('mgmt-input mgmt-cancel-btn')}>
                                <Button
                                    text={'Duplicar'}
                                    type={'normal'}
                                    disabled={Object.values(formData).includes("")}
                                    stylingMode={'outlined'}
                                    onClick={duplicateData}
                                />
                            </div>
                            }
                            <Button
                                text={editingMode ? 'Guardar edición' : 'Agregar'}
                                type={'normal'}
                                disabled={canSave}
                                stylingMode={'contained'}
                                onClick={editingMode ? editData : saveData}
                            />
                        </div>
                    }
                </>
            }
            <ManagementDataGrid info={info} dataSource={current ? props.dataGrid[info.id] : []} current={current} getGridRef={getGridRef} getEditItem={getEditItem} getDeleteItem={getDeleteItem} readOnly={readOnly} />
        </div>
    );
})