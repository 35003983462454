
export default class Observation {

    constructor (patientID = '', encounterID = '', date = '', practitionerID = '', label= '', type= '', questionNumber= 0, value = null, inputID = '', canWrite = false, commentary = null, multipleCommentary = false) {
        this._patientID = patientID;
        this._practitionerID = practitionerID;
        this._encounterID = encounterID;
        this._date = date;
        this._label = label;
        this._type = type;
        this._questionNumber = questionNumber;
        this._value = value;
        this._inputID = inputID;
        this._canWrite = canWrite;
        this._componentCommentary = commentary;
        this._multipleCommentary = multipleCommentary;
    }

    set patientID (id) {
        this._patientID = id;
    }

    set practitionerID (id) {
        this._practitionerID = id;
    }

    set encounterID (id) {
        this._encounterID = id;
    }

    set label (label) {
      this._label = label;
    }

    set type (type) {
      this._type = type;
    }

    set questionNumber (qn) {
      this._questionNumber = qn;
    }

    set value (value) {
        this._value = value;
    }

    set date (date) {
        this._date = date;
    }

    set inputID (inputID) {
      this._inputID = TypeSelector(inputID);
    }

    set canWrite (canWrite) {
      this._canWrite = canWrite || false;
    }

    set componentCommentary (commentary) {
      this._componentCommentary = commentary
    }

    set multipleCommentary (bool) {
      this._multipleCommentary = bool
    }

    get newObvervation () {
        return new ObservationObject(
          this._patientID, 
          this._practitionerID, 
          this._encounterID, 
          this._date, 
          this._label,
          this._type,
          this._questionNumber,
          this._value, 
          this._inputID,
          this._canWrite,
          this._componentCommentary,
          this._multipleCommentary
          )
    }

}

function ObservationObject (patientID, practitionerID, encounterID, date, label, type, questionNumber, value, inputID, canWrite, commentary, multipleCommentary) {
    
    const estructure = {
        "resourceType": "Observation",
        "status": "registered",
        "identifier": [
          {
            "use": "official",
            "system": "http://indigo.tech",
            "value": inputID
          },
          { //Tipo
            "use": "usual",
            "system": "http://indigo.tech",
            "value": type
          },
          { //Número de Pregunta
            "use": "usual",
            "system": "http://indigo.tech",
            "value": questionNumber
          },
          { //Label
            "use": "usual",
            "system": "http://indigo.tech",
            "value": label
          }
        ],
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "271713000",
              "display": "General unsteadiness"
            }
          ],
          "text": "General unsteadiness"
        },
        "subject": {
          "reference": patientID
        },
        "encounter": {
          "reference": "Encounter/"+encounterID
        },
        "issued": date,
        "performer": [
          {
            "reference": practitionerID
          }
        ],
    }

    switch(type) {
      case 0:
        estructure["component"] = [
          {"code": 
            {"coding": [{
                "system": "http://indigo.tech/...../..../..../codigorespuesta",
                "code": "Default Code",
                "display": value
              }],
              "text": value
            },
            "valueString": canWrite ? commentary : null
          }
        ];
      break;

      case 1:
        estructure["component"] = [
          {"code":
            {"coding": [{
                "system": "http://indigo.tech/...../..../..../codigorespuesta",
                "code": !value?.code ? null : value?.code,
                "display": value?.display
              }],
              "text": value?.display
            },
            "valueString": canWrite ? commentary : null
          }
        ];
      break;

    }

    // if(canWrite) {
    //   estructure["component"] = ComponentCommentary(commentary, multipleCommentary, inputID)
    // }

    return estructure
}


function ValueSelector (inputID, values) {
  switch (inputID.substring(0,2)) {
    case "01":
      return (
        {"id":
        "valueQuantity", 
        "value": {
          "value": values,
          "unit": "degrees C",
          "system": "http://unitsofmeasure.org",
          "code": "Cel"}
        }
      );
    case "02":
      return (
        {"id":
        "valueQuantity", 
        "value": {
          "value": values,
          "unit": "degrees C",
          "system": "http://unitsofmeasure.org",
          "code": "Cel"}
        }
      );    
    case "06":
      return (
        {"id":
        "valueBoolean", 
        "value": values
      }
      );
      
    case "08":
      return (
        {"id":
        "valueDatetime", 
        "value": values
      }
      );

    case "09":
      return (
        {"id":
        "valueTime", 
        "value": values
      }
      );

    default:
      return (
        {"id":
        "valueString", 
        "value": values
      }
      );
  }
}

function TypeSelector (inputID) {
  const rest = inputID.context+inputID.id;
  switch (inputID.type) {
    case "int":
      return "01"+rest;
      
    case "float":
      return "02"+rest;
      
    case "text":
      return "03"+rest;
      
    case "search":
      return "04"+rest;
      
    case "select":
      return "05"+rest;
      
    case "boolean":
      return "06"+rest;
      
    case "textarea":
      return "07"+rest;
      
    case "date":
      return "08"+rest;
    
    case "time":
      return "09"+rest;

    default:
      break;
  }
}



