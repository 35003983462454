import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import SaveTable from './save/saveTable';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, Paging, Pager, FilterRow, Scrolling, Column } from 'devextreme-react/data-grid';
import Switch from 'devextreme-react/switch';
import { createOrUpdateTemplate } from '../../../utils/helpersPoints';

export default function SavePopup (props) {

    const { visible, 
            onHiding, 
            dragEnabled, 
            closeOnOutsideClick, 
            showTitle, 
            title,
            deleteFunc,
            closePopup} = props

    const classNames = require('classnames');
    const selectGridRef = React.useRef();
    const selectRef = React.useRef();
    const [selectedList, setSelectedList] = useState({ 'activities': [],
    'especialities' : []
    });
    const [isUpToDate, setIsUpToDate] = useState(false);
    const { data, rowKey } = useSelector( state => state.parametrizer);
    const { specialities } = useSelector(state => state.auth);
    const { templateList } = useSelector(state => state.parametrizer);

    const [gridRef, setGridRef] = useState({});
    const [hcMetadata, setHcMetadata] = useState({
        id: 1210,
        active: false,
        name: '',
        description: '',
        HCBoard: false,
        parent: {
            type: true,
            parentList: { 'activities': [],
            'especialities' : []
            }
        },
        hcStructure: data
    })

    const defaultState = 
        [{id: true, display: 'Activo'}, 
        {id: false, display: 'Inactivo'}];

    const defaultSpec = specialities.map( spec => {
        return {
            id: spec.codeElement?.value,
            display: spec.displayElement?.value,
        }
    })
    


    const handleOnChange = (e, name) => {
        setHcMetadata(prev => ({...prev, [name]: e.value}))
    }

    const handleOnTypeChange = (e) => {
        let virtualParentList = hcMetadata.parent;
        virtualParentList.type = e.value;
        setHcMetadata(prev => ({...prev, parent : virtualParentList}))
        selectGridRef?.current?._instance?.refresh(true);
        //console.log(selectGridRef)
        getIsUpToDate();
    }

    const handleActSelect = (data) => {
        let currentActList = selectedList;
        currentActList.activities = data.selectedRowKeys.length && data.selectedRowKeys || []
        setSelectedList( prev => prev = currentActList);
        getIsUpToDate();
    }

    const handleEspecSelect = (data) => {
        
        let virtualParentList = hcMetadata.parent;
        virtualParentList.parentList.especialities = data.selectedRowKeys;
        setHcMetadata(prev => ({...prev, parent : virtualParentList}))
        
        addParent();
    }

    const addParent = () => {
        gridRef?.refresh(true)
        selectGridRef?.current?._instance?.refresh(true);
    }

    const deleteItem = (id) => {

        let virtualList = selectedList;
        if(hcMetadata.parent.type){
            const index = selectedList.especialities.map( item => {return item.id}).indexOf(id);
            virtualList.especialities.splice(index,0);
            let itemIndexes = []
            virtualList.especialities.map(item => {
                itemIndexes.push(
                    selectGridRef.current._instance._controllers.selection._dataController._items.map( item => {
                        {return item.data.id}
                    }).indexOf(item.id)
                )
            })
            selectGridRef.current._instance.selectRowsByIndexes(itemIndexes)
        } else {
            const index = selectedList.activities.map( item => {return item.id}).indexOf(id);
            virtualList.activities.splice(index,0);
            let itemIndexes = []
            virtualList.activities.map(item => {
                itemIndexes.push(
                    selectGridRef.current._instance._controllers.selection._dataController._items.map( item => {
                        {return item.data.id}
                    }).indexOf(item.id)
                )
            })
            selectGridRef.current._instance.selectRowsByIndexes(itemIndexes)
        }

        // setSelectedList(prev => prev = virtualList)
        selectGridRef.current._instance?.refresh(true)
    }

    const getGridRef = (ref) => {
        if(ref) {
            setGridRef(ref)
        }
    } 

    const getIsUpToDate = () => {
        if(hcMetadata.parent.type) {
            if(hcMetadata.parent.parentList.especialities === selectedList.especialities) {
                setIsUpToDate(true)
                return true
            } else {
                setIsUpToDate(false)
                return false
            }
        } else {
            if(hcMetadata.parent.parentList.activities === selectedList.activities) {
                setIsUpToDate(true)
                return true
            } else {
                setIsUpToDate(false)
                return false
            }
        }
    }

    const saveMetadata = (guardar = false) => {
        //console.log(templateList);
        localStorage.setItem('hcMetadata', JSON.stringify(hcMetadata));
        const auth = sessionStorage.getItem('auth');
        const data = JSON.stringify(hcMetadata);
        const specialities = `'[${hcMetadata.parent.parentList.especialities.map( spec => {
            return  `"${spec.code.toString()}"`
        })}]'`;

        const name = hcMetadata.name;
        const isActive = hcMetadata.active;

        let dataFetch = {
            partitionKey: "Templates",
            data,
            name,
            isActive,
            specialities,
        };
        if (guardar === true){
            dataFetch['rowKey'] = rowKey
        };
        createOrUpdateTemplate(auth, JSON.stringify(dataFetch));
        //console.log(JSON.stringify(dataFetch))
        closePopup();
    }

    useEffect(() => {
        let remoteInfo = JSON.parse(localStorage.getItem('hcMetadata'));
        let defaultInfo = JSON.parse(JSON.stringify(hcMetadata));
        //console.log("remoteInfo: ",remoteInfo);
        if(remoteInfo !== null) {
            if(remoteInfo?.name !== defaultInfo) {
                remoteInfo.hcStructure = data;
                setHcMetadata(prev => prev = remoteInfo);
            }
            let virtualList = remoteInfo?.parent;
            let virtualSelect = selectedList;
            virtualSelect.especialities = virtualList.parentList.especialities;
            virtualSelect.activities = virtualList.parentList.activities
            setSelectedList( prev => prev = virtualSelect);
            setTimeout(() => {
                updateParentList(remoteInfo);
                setHcMetadata(prev => ({...prev, parent : virtualList}))
            },[1000])
        } else {
            localStorage.setItem('hcMetadata', JSON.stringify(hcMetadata))
        }
    }, [])

    const updateParentList = (remoteInfo) => {
        let virtualList = remoteInfo.parent.parentList.especialities;
        let itemIndexes = [];

        virtualList.map(item => {
            itemIndexes.push(
                selectGridRef?.current?._instance._controllers.selection._dataController._items.map( item => {
                    {return item.data.id}
                }).indexOf(item.id)
            )
        })

        selectGridRef?.current?._instance.selectRowsByIndexes(itemIndexes)
        selectGridRef?.current?._instance?.refresh(true)
    }

    const itemRender = (data) => {
        return (
            <Fragment key={data.id}>
                <DataGrid
                    ref={selectGridRef}
                    dataSource={specialities}
                    onSelectionChanged={handleEspecSelect}
                    hoverStateEnabled={true}
                    height={317}
                >
                    <Paging enabled={true} defaultPageSize={5}></Paging>
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column dataField="code" caption="Código" width={'8%'} />
                    <Column dataField="display" caption="Nombre Especialidad" width={'8%'} />
                    <Selection mode="multiple"></Selection>
                    <FilterRow visible={true}></FilterRow>
                </DataGrid>
            </Fragment>
        )
    }
    return (
        <Popup
            visible={visible}
            onHiding={onHiding}
            dragEnabled={dragEnabled}
            closeOnOutsideClick={closeOnOutsideClick}
            showTitle={showTitle}
            title={title}
            height={'90vh'}
            >
            <div className={'cancel-popup'}>
                <div className={'popup-content'}> 
                    <div className={'popup-group big-group'}>
                        <div className={'popup-title'}>
                            Datos HC
                        </div>
                            <div className={'popup-id-title'}>
                                <div className={'popup-id-title'}>
                                    <div className={'popup-group'}>
                                        <div className={'popup-title'}>
                                            Código
                                        </div>
                                        
                                        <TextBox 
                                            value={hcMetadata.id.toString()}
                                            onValueChanged={(e) => handleOnChange(e,'id')}
                                            disabled
                                        />
            
                                    </div>
                                    <div className={'popup-group'}>
                                            <div className={'popup-title'}>
                                                Estado
                                            </div>
                                            <SelectBox 
                                                    items={defaultState} 
                                                    displayExpr="display"
                                                    defaultValue={true}
                                                    value={hcMetadata.active}
                                                    onValueChanged={(e) => handleOnChange(e,'active')}
                                                    valueExpr="id"/>
                                    </div>
                                </div>
                                <div className={'popup-group'}>
                                    <div className={'popup-title'}>
                                        Nombre
                                    </div>
                                    <TextBox 
                                        value={hcMetadata.name}
                                        onValueChanged={(e) => handleOnChange(e,'name')}
                                    />
                                </div>
                            </div>
                            <div className={'popup-id-title'}>
                                <div className={'popup-group fullwidth'}>
                                    <div className={'popup-title'}>
                                        Descripción
                                    </div>
                                    <TextBox 
                                        value={hcMetadata.description}
                                        onValueChanged={(e) => handleOnChange(e,'description')}
                                    />
                                </div>
                                <div style={{width: '12%'}} className={'popup-group fullwidth flex-center'}>
                                    <div className={'popup-title'}>
                                        HC Junta Médica
                                    </div>
                                    <CheckBox value={hcMetadata.HCBoard} onValueChanged={(e) => handleOnChange(e,'HCBoard')} />
                                </div>
                            </div>
                    </div>
                </div>
                <div className={'popup-group big-group'}>
                        {/* <div style={{padding: '0px 0'}} className={'popup-title'}>
                            <div className={classNames('add-switch-container')}>
                                <span className={classNames('switch-text')}>
                                    Actividades
                                </span>
                                <Switch 
                                    onValueChanged={handleOnTypeChange}
                                    switchedOffText={"Actividades"}
                                    switchedOnText={"Especialidades"}
                                    hoverStateEnabled={false}
                                    value={hcMetadata.parent.type} 
                                />
                                <span className={classNames('switch-text')}>
                                    Especialidades
                                </span>
                            </div>
                        </div> */}

                        <div className={'popup-group row big-group'}>
                            <div className={'popup-title'}>
                                {!hcMetadata.parent.type ? 'Actividad médica' : 'Especialidad médica'}
                            </div>
                            <DropDownBox 
                                ref={selectRef}
                                dataSource={specialities}
                                valueExpr={'code'}
                                displayExpr={'display'}
                                deferRendering={false}
                                placeholder={'Selecciona'}
                                onValueChanged={handleEspecSelect}
                                contentRender={itemRender}
                                width={'100%'}
                            />
                        </div>
                        <SaveTable dataSource={hcMetadata.parent.parentList.especialities} getGridRef={getGridRef} deleteItem={deleteItem}/>
                </div>
                <div className={'cancel-buttons'}>
                    <div className={'save-buttons'}>
                        <Button
                        // width={130}
                        // icon={'save'}
                        text="Guardar"
                        type="default"
                        stylingMode="contained"
                        onClick={()=>saveMetadata(true)}                        
                        />
                        <Button
                        icon={'newfolder'}
                        text="Guardar como"
                        type="default"
                        stylingMode="contained"
                        onClick={saveMetadata}                        
                        />
                    </div>
                    <Button
                    width={120}
                    text="Cancelar"
                    type="default"
                    stylingMode="outlined"
                    onClick={closePopup}
                    /> 
                </div>
            </div>
        </Popup>
    )
} 