import React from 'react';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import { useSelector, useDispatch } from 'react-redux';
import { sectionUpdated } from '../../../redux/actions/fhir';

export default function DeletePopup (props) {

    const dispatch = useDispatch(); 
    const {fhir} = useSelector(state => state)
    const { visible, 
            onHiding, 
            dragEnabled, 
            closeOnOutsideClick, 
            showTitle, 
            title, 
            deleteFunc, 
            deleteTrigger, 
            deleteObject, 
            closePopup} = props

    const deletingFunction = () => {
        deleteFunc();
        if(deleteTrigger === "section") return;
        const itemKey = Object.keys(deleteObject).find(key => key.includes(deleteTrigger));
        const itemID = deleteObject[itemKey].id;

        const index = fhir.map( f => {return (f?.title)}).indexOf(props.mainSection);
        let mySection = fhir[index];
        const entryIndex = mySection?.entry?.map( ent => {
            return( ent?.identifier[0]?.value.substring(5,(ent?.identifier[0]?.value.length)))
        }).indexOf(itemID);
        
        mySection?.entry.splice(entryIndex,1);
    
        dispatch(sectionUpdated(mySection));
    }

    return (
        <Popup
            visible={visible}
            onHiding={onHiding}
            dragEnabled={dragEnabled}
            closeOnOutsideClick={closeOnOutsideClick}
            showTitle={showTitle}
            title={title}
            width={350}
            height={270}
            >
            <div className={'cancel-popup'}>
                <div className={'cancel-title'}>
                    Si eliminas <b>{
                    (deleteTrigger === 'section' && deleteObject.sectionDelete.display) || 
                    (deleteTrigger === 'question' && deleteObject.questionDelete.display) || 
                    (deleteTrigger === 'option' && deleteObject.optionDelete.display) ||
                    (deleteTrigger === 'optionOption' && deleteObject.optionOptionDelete.display) ||
                    (deleteTrigger === 'all' && deleteObject.everythingDelete.display)
                    }</b>, 
                    {
                    (deleteTrigger === 'section' && ` la configuración actual de la sección se perderá para siempre`) || 
                    (deleteTrigger === 'question' && ` la configuración actual de la pregunta se perderá para siempre`) || 
                    (deleteTrigger === 'option' && ` la configuración actual de la opción se perderá para siempre`) || 
                    (deleteTrigger === 'optionOption' && ` la configuración actual de la opción se perderá para siempre`) || 
                    (deleteTrigger === 'all' && ` la configuración actual de la ficha se perderá para siempre`)
                    }
                </div>
                <div className={'cancel-buttons'}>
                    <Button
                    width={120}
                    text="Conservar"
                    type="danger"
                    stylingMode="outlined"
                    onClick={closePopup}
                    /> 
                    <Button
                    width={130}
                    icon={'trash'}
                    text="Eliminar"
                    type="danger"
                    stylingMode="contained"
                    onClick={deletingFunction}                        
                    />
                </div>

            </div>
        </Popup>
    )
} 