import React, {Fragment, memo, useEffect, useState} from 'react';
import './../ficha-styles.css';
import SectionBox from './section-box.jsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { setData, activateSection } from '../../../redux/actions/parametrizer.js';
import Button from 'devextreme-react/button';

function Sections (props) {
    const { editable, readOnly, infoExists } = props;
    const classNames = require('classnames');
    const [grabbed, setGrabbed] = useState('');
    const { activeSection: active } = useSelector(state => state.parametrizer);
    const [closed, setClosed] = useState(false);
    const { data: sectionList } = useSelector(state => state.parametrizer)
    const { mcData } = useSelector(state => state.hcData)
    const dispatch = useDispatch()

    const handleOnDragStart = start => {
        setGrabbed(start.draggableId);
    }

    const handleOnDragEnd = result => {
        if (!result.destination) return;
        const items = Array.from(sectionList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch(setData(items));
        setGrabbed('')
        dispatch(activateSection({id: sectionList[result.source.index].id, trigger: 'click'}))
        document
            .getElementById(sectionList[result.destination.index].id + "Section")
            .scrollIntoView();
        if(closed) {
            toggleSectionList();
        }
    }

    const handleOnClickSection = (section) => {
        dispatch(activateSection({id: section, trigger: 'click'}))
    }

    const goToFinal = () => {
        dispatch(activateSection({id: 'confirmationSection', trigger: 'click'}))
        document
        .getElementById("confirmationSection")
        .scrollIntoView({behavior: 'smooth'});
    }

    const toggleSectionList = () => {
        setClosed(prev => prev = !prev);
    }
        return (
            <Fragment>
                <div className={classNames('section-list-container', {'section-list-closed': closed})}>
                <DragDropContext
                    onDragEnd={handleOnDragEnd}
                    onDragStart={handleOnDragStart}
                >
                    <Droppable droppableId="sectionList">
                        { provided => (
                            <ul
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {(editable ? sectionList : mcData).map((sections, key) => {
                                    return(
                                        <Draggable key={sections.id} draggableId={sections.id} index={key} isDragDisabled={!editable}>
                                            {(provided, snapshot) => (
                                                <SectionBox 
                                                    sections={sections} 
                                                    provided={provided} 
                                                    snapshot={snapshot}
                                                    onClick={handleOnClickSection}
                                                    isActive={active.id === sections.id}
                                                    isGrabbed={grabbed === sections.id}
                                                    deleteSection={props.deleteSection}
                                                    editSection={props.editSection}
                                                    closed={closed}
                                                    toggle={toggleSectionList}
                                                    />
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                                
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
                    {!readOnly && <div className={'send-section'}>
                        {!closed && <div className={'send-btn'} onClick={goToFinal}>Ir abajo
                        </div> }
                    </div> }
                    <span className={'section-list-toggler'}>
                        <Button 
                            onClick={toggleSectionList}
                            icon={closed ? 'chevronright' : 'chevronleft'}
                        />
                    </span>
                </div>
            </Fragment>
        );    
}

export default Sections