import React, { Fragment } from 'react';

export const AppointmentRender = (props) => {
    
    const {appointmentData} = props
    //console.log('appointmentData: ',appointmentData);
    const start = () => {
        const hours = appointmentData.startDate.getHours();
        const minutes = appointmentData.startDate.getMinutes();
        return (hours+":"+minutes)
    }

    const end = () => {
        const hours = appointmentData.endDate.getHours();
        ////console.log('appointmentData.endDate: ', appointmentData.endDate);
        const minutes = appointmentData.endDate.getMinutes().toString();
        const min = minutes.padStart(2,'0');
        ////console.log('minutes: ', min);
        return (hours+":"+min)
    }
    return(
        <Fragment>
            <div className={'appointment-name'}><b>{appointmentData.text}</b></div>
            <div className={'appointment-name'}><b>Prontuario :</b> {appointmentData.prontuar}</div>
            <div className={'appointment-info'}><b>Edad :</b> {appointmentData.age}</div>{/*  - {appointmentData.sex}</div> */}
            <div className={'appointment-info'}><b>Hora :</b> {start()} - {end()}</div>
            <div className={'appointment-info'}><b>Localidad :</b> {appointmentData?.location[0]?.name} - {appointmentData?.location[1]?.name}</div>{/*  - {appointmentData.sex}</div> */}

            {/* <div className={'appointment-info'}>{appointmentData.startDate} - {appointmentData.endDate}</div> */}

        </Fragment>
    )
}