import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DataGrid, { Column, HeaderFilter, Paging, Pager, Editing } from 'devextreme-react/data-grid';
import Popup, { ToolbarItem } from 'devextreme-react/popup';

import Button from 'devextreme-react/button';
import { RegisterUser } from './RegisterUser';
import { startLoadPortals, startLoginAdmin, startUpdatePortal, startUpdateUser } from '../../redux/actions/dashboard';
import { RegisterPortal } from './RegisterPortal';

export const Portals = () => {
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);
    const [createPortal, setCreatePortal] = useState(false);
    const { portals, email, password } = useSelector(state => state.dashboard);
    const [editData, setEditData] = useState({
        selectTextOnEditStart: true,
        startEditAction: 'dblClick'
    })

    useEffect(() => {
        dispatch(startLoadPortals(email, password));
    }, []);
    const logEvent = (eventName) => {
        return setEvents( [eventName].concat(events) );
    };
   
    const handleNewUser = () => {
        ////console.log('New  userrrr');
        setCreatePortal(true);
    }

    const saveDataEdit = (e) => {
        dispatch(startUpdatePortal(email, password, JSON.stringify(e.data)));
    }

    const newUser = () => {
        return (
            <div>
            <Popup
                visible={createPortal}
                onHiding={()=> setCreatePortal(false)}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={false}
                //title="Information"
                container=".dx-viewport"
                width={550}
                height={"auto"}
                contentRender={()=> <RegisterPortal setCreatePortal={setCreatePortal} />}
                >
            {/* <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={saveJsonButton}
                /> */}
            </Popup>
            </div>
        )
    }

    return (
        <>
        <div>
            <Button 
                icon = "add"
                text = "Agregar"
                stylingMode="outlined" 
                onClick = {handleNewUser}
        />
        </div>
    
        <div>
            <DataGrid
                id="gridContainer"
                //dataSource={my_json_object}
                dataSource={portals}
                //keyExpr="ID"
                height="auto"
                allowColumnReordering={true}
                showBorders={true}
                onEditingStart={()=> logEvent('EditingStart')}
                onInitNewRow={()=> logEvent('InitNewRow')}
                onRowInserting={()=> logEvent('RowInserting')}
                onRowInserted={()=> logEvent('RowInserted')}
                onRowUpdating={()=> logEvent('RowUpdating')}
                onRowUpdated={(e)=> saveDataEdit(e)}
                onRowRemoving={()=> logEvent('RowRemoving')}
                onRowRemoved={()=> logEvent('RowRemoved')}
                onSaving={()=> logEvent('Saving')}
                onSaved={()=> logEvent('Saved')}
                onEditCanceling={()=> logEvent('EditCanceling')}
                onEditCanceled={()=> logEvent('EditCanceled')}

            >
            <Editing
                mode="batch"
                //mode="popup"
                allowUpdating={true}
                allowAdding={false}
                allowDeleting={false}
                selectTextOnEditStart={editData.selectTextOnEditStart}
                
                startEditAction={editData.startEditAction} />
                     
            <HeaderFilter visible={true} />
            
            <Column dataField="rowKey" caption="ID-Azure" allowEditing={false}/>
            <Column dataField="idAgnes" caption="ID-Agnes" />
            <Column dataField="location" caption="Ubicación" />
            <Column dataField="name" caption="Nombre" />
            <Column dataField="isActive" caption="Activo" />

            <Pager 
                visible={true}
                showInfo={true}
                showNavigationButtons={true}
                showPageSizeSelector={true} />
            <Paging 
                enabled={true} 
                defaultPageSize={10} />

            {/* <Column
                dataField="Timestamp"
                //width={130}
                caption="Fecha"
                dataType="date" /> */}
                
            </DataGrid>
            {
                newUser()
            }
            
        </div>
        </>
    )
}
