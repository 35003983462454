import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { SingleCard } from '../layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm } from '../components';
import { TabComponent } from './Tab';
import { ConsentPopup } from './ConsentPopup';
import { ClosePopup } from './ClosePopup';
import { Configuration } from './Configuration';



export const AuthRouter = () => {

    //const history = useHistory();
    ////console.log(history);
    return (

    
    <Switch>

        <Route exact path="/auth/tab" component={TabComponent} />
        <Route exact path="/auth/start" component={ConsentPopup} />
        <Route exact path="/auth/end" component={ClosePopup} />
        <Route exact path="/auth/config" component={Configuration} />


        {/* <Route exact path='/auth/login' >
            <SingleCard title="Sign In">
                <LoginForm />
            </SingleCard>
        </Route>

        <Route exact path='/auth/register' >
            <SingleCard title="Sign Up">
                <CreateAccountForm />
            </SingleCard>
        </Route>

        <Route exact path='/auth/reset-password' >
            <SingleCard
            title="Reset Password"
            description="Please enter the email address that you used to register,and we will send you a link to reset your password via Email."
            >
                <ResetPasswordForm />
            </SingleCard>
        </Route>

        <Route exact path='/auth/change-password/:recoveryCode' >
            <SingleCard title="Change Password">
                <ChangePasswordForm />
            </SingleCard>
        </Route> */}

        <Redirect to= "/auth/tab" />
        {/* <Redirect to="/auth/login" /> */}

    </Switch>

    )
}
