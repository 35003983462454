import { types } from "../types/types";


export const productivityReducer = (state = {}, action) => {
    switch ( action.type ) {
        
        case types.setAllProductivity:
            return {
                ...state,
                allProductivity: action.payload
            }

        case types.setProductivityBySpecialty: 
            return {
                ...state,
                productivitySpecialty: action.payload,
            }
        case types.setProductivityByTemplate: 
            return {
                ...state,
                productivityTemplate: action.payload,
            }   

        case types.setProductivityTabular: 
            return {
                ...state,
                productivityTabular: action.payload,
            }
        
        case types.setProductivityFichas:
            return {
                ...state,
                productivityFichas: action.payload,
            }

        case types.setServerTime:
            return {
                ...state,
                serverTime: action.payload.ActualDate,
            }
            
        default:
            return state;
    }
}
