import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DataGrid, { Column, HeaderFilter, Paging, Pager, Editing } from 'devextreme-react/data-grid';
import Popup, { ToolbarItem } from 'devextreme-react/popup';

import Button from 'devextreme-react/button';
import { RegisterUser } from './RegisterUser';
import { startLoginAdmin, startUpdateUser } from '../../redux/actions/dashboard';


export const Users = () => {
    const [events, setEvents] = useState([]);
    const [createUser, setCreateUser] = useState(false);
    const dispatch = useDispatch();
    const { users, email, password } = useSelector(state => state.dashboard);
    const [editData, setEditData] = useState({
        selectTextOnEditStart: true,
        startEditAction: 'dblClick'
    });

    const [licenseText, setLicenceText] = useState('Licencias ')

    const logEvent = (eventName) => {
        return setEvents( [eventName].concat(events) );
    };



    const handleNewUser = () => {
        ////console.log('New  userrrr');
        setCreateUser(true);
    }

    const saveDataEdit = (e) => {
        dispatch(startUpdateUser(email, password, JSON.stringify(e.data)));
    }

    const onContentReadyDGuser = (e) => {
        //console.log('dg', e)
        setTimeout(() => {
            let dataAux = e.component.option("dataSource")
            if (dataAux) {
                let indigoUser = dataAux.filter(x => x.email.indexOf("indigo") != -1)
                let dezetaUser = dataAux.filter(x => x.email.indexOf("dezeta") != -1)
                let inactiveUser = dataAux.filter(x => !x.isActive)

                let lic = (dataAux.length - indigoUser.length)
                let textAux = ` Licencias ${lic} / 50 [ dezeta_User = ${dezetaUser.length}, Inactive_User = ${inactiveUser.length}]`
                setLicenceText(textAux)
                
            }
        },1000)
    }

    const newUser = () => {
        return (
            <div>
            <Popup
                visible={createUser}
                onHiding={()=> setCreateUser(false)}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={false}
                //title="Information"
                container=".dx-viewport"
                width={600}
                height={"auto"}
                contentRender={()=> <RegisterUser setCreateUser={setCreateUser} />}
                >
            {/* <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={saveJsonButton}
                /> */}
            </Popup>
            </div>
        )
    }

    return (
        <>
        <div>
            <Button 
                icon="add" 
                text="Agregar"
                stylingMode="outlined" 
                onClick={handleNewUser}
            />

                <Button
                    icon="info"
                    text={licenseText}
                    stylingMode="text"
                    disabled={false}
                    rtlEnabled={true}
                //onClick={handleNewUser}
            />
                
        </div>
        <div >
            <DataGrid
                id="gridContainer"
                //dataSource={my_json_object}
                dataSource={users}
                //keyExpr="ID"
                height="auto"
                allowColumnReordering={true}
                    showBorders={true}
                    onContentReady={onContentReadyDGuser}
                onEditingStart={()=> logEvent('EditingStart')}
                onInitNewRow={()=> logEvent('InitNewRow')}
                onRowInserting={()=> logEvent('RowInserting')}
                onRowInserted={()=> logEvent('RowInserted')}
                onRowUpdating={()=> logEvent('RowUpdating')}
                onRowUpdated={(e)=> saveDataEdit(e)}
                onRowRemoving={()=> logEvent('RowRemoving')}
                onRowRemoved={()=> logEvent('RowRemoved')}
                onSaving={()=> logEvent('Saving')}
                onSaved={()=> logEvent('Saved')}
                onEditCanceling={()=> logEvent('EditCanceling')}
                onEditCanceled={()=> logEvent('EditCanceled')}

            >
            <Editing
                mode="batch"
                //mode="popup"
                allowUpdating={true}
                allowAdding={false}
                allowDeleting={false}
                selectTextOnEditStart={editData.selectTextOnEditStart}
                startEditAction={editData.startEditAction} 
            />
                     
            <HeaderFilter visible={true} />
            
            <Column dataField="rowKey" caption="ID-Azure" allowEditing={false}/>
            <Column dataField="firstName" caption="Nombre" />
            <Column dataField="lastName" caption="Apellidos" />
            <Column dataField="email" caption="Correo Electronico" />
            <Column dataField="codeSIH" caption="Código Agenda SIH" />
            <Column dataField="userCodeSIH" caption="Código Usuario SIH" />
            <Column dataField="medicalCodeSIH" caption="Código Medico SIH" />
            <Column dataField="specialty" caption="Especialidades" />
            <Column dataField="isActive" caption="Activo" />
            {/* <Column dataField="isAdmin" caption="Admin" /> */}

            <Pager 
                visible={true}
                showInfo={true}
                showNavigationButtons={true}
                showPageSizeSelector={true} />
            <Paging 
                enabled={true} 
                defaultPageSize={10} 
                />

            {/* <Column
                dataField="Timestamp"
                //width={130}
                caption="Fecha"
                dataType="date" /> */}
                
            </DataGrid>
            {
                newUser()
            }
            
        </div>
        </>
    )
}
