import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import AddTable from './add/addTable';
import { setTotalData } from '../../../redux/actions/parametrizer';
import { localPanel } from './../localPanel.js';
import NumberBox from 'devextreme-react/number-box';
import { sectionUpdated } from '../../../redux/actions/fhir';


const mapDispatchToProps = (dispatch) => {
    return {
        setTotalData: (data) => dispatch(setTotalData(data)),
        sectionUpdated: (fhir) => dispatch(sectionUpdated(fhir))
    }
} ;
const mapStateToProps = state => ({
    totalData: state.parametrizer.totalData,
    fhir: state.fhir
})
export default connect(mapStateToProps, mapDispatchToProps)(class EditPoup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOptions: false,
            tipToggle: false,
            currentSectionsList: [],
            newItem: 
                {id: '', 
                display: '', 
                type: '', 
                size: 1, 
                questionNumber: 0,
                canWrite: false,
                genre: 'both',
                dependency: {},
                dependsOn: '',
                expression: {},
                active: true,
                minAge: 0,
                maxAge: 120,
                maxChar: 1000,
                value: '',
                message: '',
                description: '',
                options: []
            }
        }
    }    

    handleNameChange = (e) => {
        this.setState({newItem: {...this.state.newItem, display: e.value}})
    }

    handleBooleanSelect = (bool) => {
        this.setState({newItem: {...this.state.newItem, defaultValue: bool.value}})
    }

    handleCommentSelect = (bool) => {
        this.setState({newItem: {...this.state.newItem, canWrite: bool.value}})
    }

    handleCommentDefaultSelect = (bool) => {
        this.setState({newItem: {...this.state.newItem, canWriteDefault: bool.value}})
    }

    handleCommentChange = (comment) => {
        this.setState({newItem: {...this.state.newItem, message: comment.value}})
    }

    handleOnChange = (value) => {
        this.setState({newItem: {...this.state.newItem, [value.element.id]: value.value}})
    }

    componentDidMount() {
        this.currentSections()
        this.props.selected.item.type && this.dropOptions(this.props.selected.item.type);

        let newItemCopy = {};
        Object.keys(this.props.selected.item).forEach( key => {
            newItemCopy[key] = this.props.selected.item[key]
        })

        this.setState({newItem: newItemCopy});

    }

    componentDidUpdate(props, state) {
        if((props.selected.item.display !== this.props.selected.item.display) || 
            (props.selected.item.options !== this.props.selected.item.options) ||
            (props.selected.item.id !== this.props.selected.item.id) || 
            (props.selected.item.questionNumber !== this.props.selected.item.questionNumber)
        ) {
            this.currentSections()
            this.props.selected.item.type && this.dropOptions(this.props.selected.item.type)
            let newItemCopy = {};
            Object.keys(this.props.selected.item).forEach( key => {
                newItemCopy[key] = this.props.selected.item[key]
            })

            this.setState({newItem: newItemCopy});
        }

        if(JSON.stringify(state.newItem) !== JSON.stringify(this.state.newItem)) {
            const index = this.props.fhir.map( f => {return (f?.title)}).indexOf("Observations");
            let mySection = this.props.fhir[index];
            const entryIndex = mySection?.entry?.map( ent => {
                return( ent?.identifier[0]?.value.substring(5,(ent?.identifier[0]?.value.length)))
            }).indexOf(this.state.newItem.id)
            
            let myEntry = mySection?.entry[entryIndex];

            if(myEntry){
        
                myEntry["identifier"][2]["value"] = this.state.newItem.questionNumber;
                myEntry["identifier"][3]["value"] = this.state.newItem.display;
        
            }

            this.props.sectionUpdated(mySection)
        }
    }
    
    currentSections = () => {
        let currentSections = [];
        const newData = this.props.totalData;
        newData.map((data) => {
            currentSections.push(data);
        })
        this.setState({currentSectionsList : currentSections})
    }

    dropOptions = (type) => {
        if(type === 'textarea' || type === 'boolean') {
            this.setState({showOptions: false})
        } else {
            this.setState({showOptions: true})
        }
    }

    getTipToggle = (toggle) => {
        this.setState({tipToggle: toggle});
    }

    handleOptionList = (options) => {
        this.setState({newItem: {...this.state.newItem, options: options}})
    }

    itemSaver = () => {
        let newData = JSON.parse(localStorage.getItem('panel'));
        const scope = this.props.selected.scope;
        switch(scope) {
            case "question": 
                newData.map((data) => {
                    if (data.id === this.props.selected.parent.id){
                        data.options.map( (option, index) => {
                            if(option.id === this.props.selected.item.id) {
                                data.options.splice(index,1)
                                data.options.splice(index,0, this.state.newItem)
                            }
                        })
                    }
                })
            break;

            case "option":
                newData.map((data) => {
                        data.options.map( (option, index) => {
                            if(option.options) {
                                option.options.map( (opt, indice) => {
                                    if(opt.id === this.props.selected.item.id) {
                                        option.options.splice(indice,1)
                                        option.options.splice(indice,0, this.state.newItem)
                                    }
                                } )
                            } else {
                                option.options.map( (opt, indice) => {
                                    if(opt.id === this.props.selected.item.id) {
                                        option.options.splice(indice,1)
                                        option.options.splice(indice,0, this.state.newItem)
                                    }
                                } )
                            }
                        })
                })
            break;
        }
        localStorage.setItem('panel', JSON.stringify(newData))
        this.createPanelTotal()
        this.props.closePopup()
    }

    createPanelTotal = () => {
        const local = JSON.parse(JSON.stringify(localPanel));
        let totalSections = [];
        const remote = JSON.parse(localStorage.getItem('panel'))
        let totalOptions = [];
        
        local.map( localSection => {
            totalSections.push(localSection)
        })
        totalSections.map( totalSection => {
            remote.map( remoteSection => {
                if(totalSection.id === remoteSection.id){
                    totalOptions = (totalSection.options);
                    totalSection.options = totalOptions.concat(remoteSection.options)
                }
            })
        })
        this.props.setTotalData(totalSections)
        totalSections = [];
        totalOptions = [];
    }

    shouldComponentUpdate(nexProps, nexState){
        if(nexProps.selected.item.display !== this.props.selected.item.display) {
            return true
        } else if (nexProps.selected.item.options !== this.props.selected.item.options) {
            return true
        } else if (nexState !== this.state) {
            return true
        }else return false
    }

    render() {

        const classNames = require('classnames');

        const { visible, 
            onHiding, 
            dragEnabled, 
            closeOnOutsideClick, 
            showTitle, 
            title,
            closePopup,
            selected} = this.props

        const parent = this.props.selected.parent;

        const defaultSizes = {'search': 2, 'textarea': 5, 'select': 1, 'form': 5};

        const defaultTypes = 
        [{id: 'search', display: 'Buscador'}, 
        {id: 'textarea', display: 'Área de texto'},
        {id: 'select', display: 'Selector'}, 
        {id: 'boolean', display: 'Booleano'}, 
        {id: 'form', display: 'Formulario'}]

        const defaultBoolean = 
        [{id: true, display: 'Sí'}, 
        {id: false, display: 'No'}];

        const defaultGenres = 
        [{id: 'male', display: 'Masculino'}, 
        {id: 'female', display: 'Femenino'},
        {id: 'both', display: 'Ambos'}
        ];

        const heigthCalculator = () => {
            let selector;
            if(this.state.showOptions && this.props.selected.item.type) {
                selector = "options"
            } else if (this.state.newItem.type === "boolean") 
                {
                    selector = "boolean"   
                } else {
                    selector = "normal"
                }
            switch(selector) {
                case "normal":
                    return 480
                case "boolean":
                    return 580
                case "options":
                    return 880
            }
        }

        return (
            <Popup
                visible={visible}
                onHiding={onHiding}
                dragEnabled={dragEnabled}
                closeOnOutsideClick={closeOnOutsideClick}
                showTitle={showTitle}
                title={title}
                height={heigthCalculator()}
            // width={0}
            >
            <div className={'cancel-popup'}>
                <div className={'popup-content'}> 
                    <div className={'popup-id-title'}>
                        <div className={'popup-group'}>
                            <div className={'popup-title'}>
                                {this.props.selected.scope === "question" ? 'Sección asociada' : 'Pregunta asociada'}
                            </div>
                                <TextBox 
                                    placeholder={parent.display}
                                    disabled={true} />
                        </div>
                        <div className={'popup-id-title'}>
                            <div className={'popup-group'}>
                                    <div className={'popup-title'}>
                                        Código
                                    </div>
                                    <NumberBox 
                                        id={"questionNumber"}
                                        value={this.state.newItem.questionNumber}
                                        onValueChanged={this.handleOnChange} />
                            </div>
                            
                            {this.props.selected.item.type &&
                            <div className={'popup-group'}>
                                    <div className={'popup-title'}>
                                        Tipo
                                    </div>
                                    <TextBox 
                                        placeholder={this.props.selected.item.type}
                                        disabled={true} />
                            </div>
                            }
                        </div>
                        
                    </div>

                    <div className={'popup-id-title'}>
                        <div className={'popup-group'}>
                            <div className={'popup-title'}>
                                Nombre
                            </div>
                            <TextBox 
                                value={this.state.newItem.display}
                                placeholder={this.props.selected.item.display}
                                onValueChanged={this.handleNameChange}
                            />
                        </div>
                    </div>

                    <div className={'popup-id-title'}>
                        {this.props.selected.item.type === "textarea" &&
                            <div className={'popup-group'}>
                                <div className={'popup-title'}>
                                    Máximos Caracteres
                                </div>
                                <NumberBox 
                                    id={'maxChar'}
                                    min={0}
                                    value={this.state.newItem.maxChar}
                                    onValueChanged={this.handleOnChange}
                                />
                            </div>
                        }
                        <div className={'popup-group'}>
                            <div className={'popup-title'}>
                                    Género
                            </div>
                                <SelectBox
                                    id={'genre'}
                                    dataSource={defaultGenres}
                                    searchEnabled={true}
                                    searchMode={'contains'}
                                    searchExpr={'display'}
                                    searchTimeout={200}
                                    displayExpr="display"
                                    value={this.state.newItem.genre}
                                    onValueChanged={this.handleOnChange}
                                    valueExpr="id"/>
                            </div>
                        <div className={'popup-group'}>
                            <div className={'popup-title'}>
                                Edad mínima
                            </div>
                            <NumberBox 
                                    id={'minAge'}
                                    min={0}
                                    max={this.state.newItem.maxAge}
                                    value={this.state.newItem.minAge}
                                    onValueChanged={this.handleOnChange}
                                />
                        </div>
                        <div className={'popup-group'}>
                            <div className={'popup-title'}>
                                Edad máxima
                            </div>
                            <NumberBox 
                                    id={'maxAge'}
                                    min={this.state.newItem.mminAge}
                                    max={120}
                                    value={this.state.newItem.maxAge}
                                    onValueChanged={this.handleOnChange}
                                />
                        </div>
                    </div>

                    {this.props.selected.item.type === "boolean" &&
                        <div className={'popup-id-title'}>
                            <div className={'popup-group value'}>
                                <div className={'popup-title'}>
                                    Valor default
                                </div>
                                <SelectBox 
                                    items={defaultBoolean}
                                    value={this.state.newItem.defaultValue} 
                                    displayExpr="display"
                                    onValueChanged={this.handleBooleanSelect}
                                    valueExpr="id"/>
                            </div>
                            <div className={'popup-group value'}>
                                <div className={'popup-title'}>
                                    Comentable
                                </div>
                                <SelectBox 
                                    items={defaultBoolean}
                                    value={this.state.newItem.canWrite} 
                                    displayExpr="display"
                                    onValueChanged={this.handleCommentSelect}
                                    valueExpr="id"/>
                            </div>
                            {this.state.newItem.canWrite ?
                                    <div className={'popup-group value'}>
                                        <div className={'popup-title'}>
                                            C. default
                                        </div>
                                        <SelectBox 
                                            items={defaultBoolean}
                                            value={this.state.newItem.canWriteDefault} 
                                            displayExpr="display"
                                            onValueChanged={this.handleCommentDefaultSelect}
                                            valueExpr="id"/>
                                    </div> :
                                    <div className={'popup-group value'}>
                                    </div>
                            }
                            {this.state.newItem.canWriteDefault && this.state.newItem.canWrite ?
                                <div className={'popup-group'}>
                                    <div className={'popup-title'}>
                                        Comentario default
                                    </div>
                                    <TextBox 
                                        value={this.state.newItem.message}
                                        placeholder={this.state.newItem.message}
                                        onValueChanged={this.handleCommentChange}
                                        />
                                </div> :
                                <div className={'popup-group'}>
                                </div>
                            } 
                        </div>
                    }
                    {(this.state.showOptions && this.props.selected.item.type) &&
                        <div className={'popup-group'}>
                                <div className={'popup-title'}>
                                    Opciones
                                </div>

                                <div className={classNames('popup-alert', this.state.tipToggle ? 'on' : 'off')}>
                                    <li className={'dx-link dx-link-tips dx-icon-tips dx-link-icon'}></li> 
                                    Es necesario dar <b> click </b> a<li className={'dx-link dx-link-save dx-icon-save dx-link-icon'}></li> 
                                    para que el elemento se <b> guarde correctamente </b>.
                                </div>

                                <AddTable dataSource={this.state.newItem.options} parentType={this.state.newItem.type} types={defaultTypes} updateOptions={this.handleOptionList} tipToggle={this.getTipToggle}/>
                        </div>
                    }
                </div>

                <div className={'cancel-buttons'}>
                    <Button
                    width={130}
                    icon={'check'}
                    text="Aceptar"
                    type="default"
                    stylingMode="contained"
                    onClick={this.itemSaver}                        
                    />
                    <Button
                    width={120}
                    text="Cancelar"
                    type="default"
                    stylingMode="outlined"
                    onClick={closePopup}
                    /> 
                </div>
            </div>
        </Popup>
    )
    } 
})