import React, { useEffect, useRef, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';

//import TreeView from 'devextreme-react/tree-view';
//import { useNavigation } from '../../../contexts/navigation';
//import { useScreenSize } from '../../../utils/media-query';

import './side-navigation-menu.scss';
import NavigationButton from './navigation-button.js';
import AgendaIcon from '../../../assets/img/icons/Agenda.jsx';
import SideNavigationContainer from './side-navigation-elements.js';
import HistorialIcon from '../../../assets/img/icons/Historial.jsx';


import * as events from 'devextreme/events';
//import { DX_REMOVE_EVENT } from 'devextreme-react/core/component-base';

export default function SideNavigationMenu (props) {

  const [menuSelect, setMenuSelect] = useState('Agenda');
  const [historyPath, setHistoryPath] = useState('/home');

  const {
    children,
    openMenu,
    compactMode,
    isClosed,
    toggleMenu
  } = props;


  const history = useHistory();
  const currentPath = history.location.pathname;
  ////console.log(history.location.pathname);

  //const { isLarge } = useScreenSize();
  
  
  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);

 useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  const selectAgenda = (e) => {
    handleSelectMenu('Agenda');
    toggleMenu(e);
  }

  const selectHistorial = (e) => {
    handleSelectMenu('historial-clinico');
    toggleMenu(e);
  }

  const handleSelectMenu = (option) => {
    setMenuSelect(option)
  }

  useEffect(() => {
    const path = history.location.pathname;
    setHistoryPath(path);
    handleSelectMenu(path);
  }, [history.location.pathname])  

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <SideNavigationContainer>

          <NavLink to="/agenda">
            <NavigationButton select={selectAgenda} isClosed={isClosed} isActive={menuSelect === 'Agenda' || historyPath === '/agenda' ? true: false} title={'Agenda'}>
              <AgendaIcon isClosed={isClosed} isActive={menuSelect === 'Agenda' || historyPath === '/agenda' ? true: false}/>
            </NavigationButton>
          </NavLink>

          <NavLink to="/historial-clinico">
            <NavigationButton select={selectHistorial} isClosed={isClosed} isActive={menuSelect === 'Historial' || historyPath.includes('/historial-clinico') ? true: false} title={'Historial Clinico'}>
              <HistorialIcon isClosed={isClosed} isActive={menuSelect === 'Historial' || historyPath.includes('/historial-clinico') ? true: false} />
            </NavigationButton>
          </NavLink>

        </SideNavigationContainer>
      </div>
    </div>
  );
}
